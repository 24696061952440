import { Grid, IconButton, Typography, Box } from "@mui/material";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";
import { labels } from "../../../labels";

const { AVVISO_SESSIONE_ELIMINAZIONE_PROGETTO } = labels;

export const AvvisoElimModal = (props) => {
  let open = props.setOpen;

  return (
    <Box className="gestisciProgetti">
      <Grid container className="deleteModal">
        {" "}
        <Grid item xs={12}>
          <IconButton disableRipple aria-label="delete" className="iconClose" onClick={() => open(false)}>
            <CancelOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-40px" }}>
          <Typography className="textTitleModalDelete">{AVVISO_SESSIONE_ELIMINAZIONE_PROGETTO}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
