import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Stack } from "@mui/material";
import { Profilo, Strumenti, PageNotFound, SessionCountdown, PrivacyError } from "../components";
import { Mappa } from "../components/pages/Mappa";
import { DatiSintesi } from "../components/pages/DatiSintesi";
import { Homepage } from "../components/pages/home/Homepage";
import { GestisciProgetti } from "../components/pages/gestisciProgetti/GestisciProgetti";
import { Ricerca } from "../components/pages/ricercaOsservatorio/Ricerca";
import { IstitutiScolastici } from "../components/pages/ricercaOsservatorio/istituti/IstitutiScolastici";
import { DettagliPage } from "../../src/components/pages/dettagliProgetto/DettagliPage";
import { ProgettiInnovazioneScuola } from "../components/pages/ricercaOsservatorio/progettiInnovazione/ProgettiInnovazioneScuola";
import { ProgettiCurrExtraCurr } from "../components/pages/ricercaOsservatorio/progettiCurricolari/ProgettiCurrExtraCurr";
import { FiltriProgCurrExtraCurr } from "../components/pages/ricercaOsservatorio/progettiCurricolari/FiltriProgCurrExtraCurr";
import { FiltriProgInnSc } from "../components/pages/ricercaOsservatorio/progettiInnovazione/FiltriProgInnSc";
import { Istituto } from "../components/pages/schedaIstituti/Istituto";
import { CurricolareExtraCurrurricolare } from "../components/pages/inserisciProgetti/curricolareExtraCurricolare/CurricolareExtraCurrurricolare";
import { Innovazione } from "../components/pages/inserisciProgetti/innovazione/Innovazione";
import {
  PATH_HOME,
  PATH_PROFILO,
  PATH_STRUMENTI,
  PATH_CURRICOLARE_EXTRA_CURRICOLARE,
  PATH_INNOVAZIONE,
  PATH_ISTITUTI_SCOLASTICI,
  PATH_INNOVAZIONE_MODIFICA,
  PATH_MAPPA,
  PATH_PROGETTI_CURRICOLARI_O_EXTRA_CURRICOLARI,
  PATH_PROGETTI_INNOVAZIONE_SCUOLA,
  PATH_RICERCA,
  PATH_RICERCA_CURRICOLARE_EXTRA_CURRICOLARE,
  PATH_RICERCA_PROGETTI_INNOVAZIONE_SCUOLA,
  PATH_SCRIVANIA,
  PATH_SCHEDA_ISTITUTO,
  PATH_DETTAGLI_PROGETTO,
  PATH_DATI_SINTESI,
  PATH_CURRICOLARE_EXTRA_CURRICOLARE_MODIFICA,
} from "../path";
import { LoadingSpinner } from "../components/shared";
import { fetchCommonInfoEnded } from "../modules/common";
import { fetchInfoProfiloEnded } from "../modules/profilo";
import { PageCurrModifica } from "../components/pages/inserisciProgetti/curricolareExtraCurricolare/PageCurrModifica";
import { PageInnModifica } from "../components/pages/inserisciProgetti/innovazione/PageInnModifica";

export const SwitchContent = () => {
  const commonInfoEnded = useSelector(fetchCommonInfoEnded);
  const fetchProfiloEnded = useSelector(fetchInfoProfiloEnded);

  return (
    <Stack id="mainContent">
      <PrivacyError />
      <Routes>
        <Route exact path={PATH_HOME} element={<Homepage />} />
        <Route exact path={PATH_PROFILO} element={<Profilo />} />
        <Route exact path={PATH_STRUMENTI} element={<Strumenti />} />
        <Route path={PATH_MAPPA} element={<Mappa />} />
        <Route path={PATH_RICERCA} element={<Ricerca />} />
        <Route path={PATH_SCRIVANIA} element={<GestisciProgetti />} />
        <Route path={PATH_INNOVAZIONE} element={<Innovazione />} />
        <Route path={PATH_SCHEDA_ISTITUTO} element={<Istituto />} />
        <Route path={PATH_ISTITUTI_SCOLASTICI} element={<IstitutiScolastici />} />
        <Route path={PATH_DATI_SINTESI} element={<DatiSintesi />} />
        <Route path={PATH_DETTAGLI_PROGETTO} element={<DettagliPage />} />
        <Route path={PATH_CURRICOLARE_EXTRA_CURRICOLARE} element={<CurricolareExtraCurrurricolare />} />
        <Route path={PATH_CURRICOLARE_EXTRA_CURRICOLARE_MODIFICA} element={<PageCurrModifica />} />
        <Route path={PATH_PROGETTI_INNOVAZIONE_SCUOLA} element={<ProgettiInnovazioneScuola />} />
        <Route path={PATH_INNOVAZIONE_MODIFICA} element={<PageInnModifica />} />
        <Route path={PATH_PROGETTI_CURRICOLARI_O_EXTRA_CURRICOLARI} element={<ProgettiCurrExtraCurr />} />
        <Route path={PATH_RICERCA_PROGETTI_INNOVAZIONE_SCUOLA} element={<FiltriProgInnSc />} />
        <Route path={PATH_RICERCA_CURRICOLARE_EXTRA_CURRICOLARE} element={<FiltriProgCurrExtraCurr />} />
        <Route path="*" exact={true} element={<PageNotFound />} />
      </Routes>
      <SessionCountdown />
      <LoadingSpinner loading={!commonInfoEnded || !fetchProfiloEnded} />
    </Stack>
  );
};
