import { Grid, Button, IconButton, Typography, Box } from "@mui/material";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";
import { labels } from "../../labels";
import { useSelector } from "react-redux";
import { getInfoProfilo } from "../../modules/profilo";
import { getDettagliProgetto } from "../../modules/datiProgetti";
import { PATH_CURRICOLARE_EXTRA_CURRICOLARE_MODIFICA, PATH_INNOVAZIONE_MODIFICA } from "../../path";
import { useNavigate } from "react-router-dom";

const { BUTTON_CHIUDI, BUTTON_APRI } = labels;

export const ModificaModal = (props) => {
  let utenteInModifica = props.utenteInModifica;
  let open = props.setOpen;
  const navigate = useNavigate();
  const profilo = useSelector(getInfoProfilo);
  const progetto = useSelector(getDettagliProgetto);
  let tipologia = progetto.data.descr_t_progetto;

  return (
    <Box className="gestisciProgetti">
      <Grid container className="deleteModal">
        {" "}
        <Grid item xs={12}>
          <IconButton disableRipple aria-label="delete" className="iconClose" onClick={() => open(false)}>
            <CancelOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-40px" }}>
          {profilo.nome + " " + profilo.cognome !== utenteInModifica ? (
            <Typography className="textTitleModalDelete">{"Azione non consentita: " + utenteInModifica + " sta modificando il progetto."}</Typography>
          ) : (
            <Typography className="textTitleModalDelete">
              {"Hai una sessione aperta sulla modifica di questo progetto, vuoi aprirne una nuova? "}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: "-100px",
            justifyContent: "center",
            display: "flex",
            "@media (max-width: 460px)": {
              marginTop: "-50px",
            },
          }}
        >
          {profilo.nome + " " + profilo.cognome === utenteInModifica ? (
            <Button
              variant="contained"
              className="buttonVerde"
              onClick={() => {
                open(false);
                if (tipologia === "Innovazione della Scuola") {
                  navigate(PATH_INNOVAZIONE_MODIFICA);
                } else {
                  navigate(PATH_CURRICOLARE_EXTRA_CURRICOLARE_MODIFICA);
                }
              }}
            >
              {BUTTON_APRI}
            </Button>
          ) : null}
          <Button
            variant="contained"
            type="submit"
            form="myform"
            className="buttonVerde"
            onClick={() => {
              open(false);
            }}
          >
            {BUTTON_CHIUDI}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
