import { Typography, Grid, InputLabel, ListItemText, FormControl, Select, Checkbox, MenuItem, List, Box, Stack } from "@mui/material";
import { ExpandCircleDownOutlined as ExpandCircleDownOutlinedIcon } from "@mui/icons-material/";
import { deleteOptionSelect, getDatiProgetto, saveOptionSelect, saveValueProgetto } from "../../../../modules/configuration";
import { useDispatch, useSelector } from "react-redux";
import { OptionsCheckModale } from "../../../shared/OptionsCheckModale";
import { labels } from "../../../../labels";
import { useEffect } from "react";

const { DISCIPLINE_COINVOLTE, PLACEHOLDER_DISCIPLINE_COINVOLTE } = labels;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  MenuListProps: {
    style: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    sx: {
      ".Mui-selected": {
        backgroundColor: "transparent !important",
        ".MuiTypography-root": {
          fontWeight: 600,
        },
      },
    },
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const SelectContesto = ({ formik, name, optionsSelected, optionsCheckList }) => {
  const ariaLabel = PLACEHOLDER_DISCIPLINE_COINVOLTE.replaceAll(" ", "_").toLowerCase();
  const ariaLabelInputLabel = ariaLabel + "_inputLabel";
  const ariaLabelStack = ariaLabel + "_stack";

  const dispatch = useDispatch();
  const datiProg = useSelector(getDatiProgetto);

  const handleChange = (value) => {
    dispatch(saveOptionSelect(value));
  };

  // Fix accessibilità
  useEffect(() => {
    const selects = document.getElementsByClassName("MuiSelect-select");
    if (selects.length > 0) {
      for (let i = 0; i < selects.length; i++) {
        selects[i].removeAttribute("role");
      }
    }
    const stack = document.getElementById(ariaLabelStack);
    if (stack != null) {
      const customSelects = stack.getElementsByClassName("selectContesto");
      if (customSelects.length > 0)
        for (var i = 0, max = customSelects.length; i < max; i++) {
          const selects = customSelects[i].getElementsByClassName("MuiSelect-select");
          if (selects.length > 0) {
            const nativeSelect = customSelects[i].getElementsByClassName("MuiSelect-nativeInput");
            if (nativeSelect.length > 0)
              for (var k = 0; k < nativeSelect.length; k++) {
                nativeSelect[k].setAttribute("id", ariaLabel);
                nativeSelect[k].setAttribute("aria-label", ariaLabel);
              }
          }
        }
    }
    const label = document.getElementById(ariaLabelInputLabel);
    if (label != null) label.setAttribute("for", ariaLabel);
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container>
      <Typography sx={{ fontSize: "16px !important", fontWeight: "Bold" }}>{DISCIPLINE_COINVOLTE}</Typography>
      <FormControl sx={{ marginTop: 1, width: "100%" }}>
        <InputLabel
          id={ariaLabelInputLabel}
          sx={{
            transform: "none",
            transition: "none",
            animation: "none",
          }}
        >
          <Typography
            sx={{
              marginBottom: "6px",
              marginLeft: "15px",
              fontStyle: "italic",
              color: "#595959",
              fontSize: "16px",
              opacity: 1,
              ".MuiInputLabel-root": {
                color: "#595959",
              },
            }}
          >
            {PLACEHOLDER_DISCIPLINE_COINVOLTE}
          </Typography>
        </InputLabel>
        <Stack id={ariaLabelStack}>
          <Select
            className="selectContesto"
            multiple
            name={name}
            size="small"
            value={datiProg.contesto.discipline !== undefined ? datiProg.contesto.discipline.map((opt) => opt) : optionsSelected}
            onChange={(e) => {

              const selected = e.target.value;

              dispatch(
                saveValueProgetto({
                  fieldSection: "contesto",
                  fieldName: name,
                  fieldValue: selected,
                })
              );

              handleChange(selected);
              formik.handleChange(e);
            }}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            renderValue={() => ""}
            MenuProps={MenuProps}
            IconComponent={ExpandCircleDownOutlinedIcon}
            sx={{
              marginBottom: "30px",
              marginTop: "-8px",
              width: "100%",
              border: "none",
              boxShadow: "0px 3px 6px #000000C7",
              opacity: "1",
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              ".MuiSelect-icon": {
                color: "black",
              },
              ".MuiButtonBase-root-MuiMenuItem-root": {
                opacity: 1,
              },
            }}
          >
            {optionsCheckList?.map((option, index) => (
              <MenuItem key={index} value={option.id}>
                <Checkbox
                  checked={datiProg.contesto.discipline !== undefined ? datiProg.contesto.discipline.indexOf(option.id) > -1 : null}
                  sx={{
                    color: "black",
                    "&.Mui-checked": {
                      color: "black",
                    },
                  }}
                />
                <ListItemText
                  primary={option.descrizione}
                  sx={{
                    whiteSpace: "break-spaces",
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Box className="center">
          {optionsSelected.length !== 0 ? (
            <List
              sx={{
                overflow: "auto",
                maxWidth: "800px",
                maxHeight: "300px",
                backgroundColor: "#F0F0F0",
                borderRadius: "20px",
                "@media (max-width:480px)": {
                  width: "270px",
                  height: "400px",
                },
              }}
            >
              {optionsSelected[0] !== undefined
                ? optionsSelected[0].map((elemento, index) =>
                    optionsCheckList.map((e) =>
                      elemento === e.id ? (
                        <Box sx={{ marginLeft: "10px" }}>
                          <OptionsCheckModale
                            color={"white"}
                            border={"solid 3px #FABE10"}
                            width={"98%"}
                            elemento={e.descrizione}
                            index={index}
                            formik={formik}
                            click={(e) => {
                              dispatch(deleteOptionSelect(elemento));
                            }}
                          />
                        </Box>
                      ) : null
                    )
                  )
                : null}
            </List>
          ) : null}
        </Box>
      </FormControl>
    </Grid>
  );
};
