import { Typography, Button, Grid, LinearProgress, Dialog, DialogContent, DialogContentText, DialogActions, IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { labels } from "../../../labels";
import { resetIndex, setButtTypeFooterInsProg } from "../../../modules/configuration";
import { POST_PUBBLICAZIONE, PUT_SBLOCCO_MODIFICA } from "../../../apiUrl";
import { createRequest } from "../../../utility";
import { notificationCodes, setNotification } from "../../../modules/notification";
import { PATH_HOME } from "../../../path";
import { useNavigate } from "react-router";

const {
  INFO_CAMPI_OBBLIGATORI,
  BUTTON_SALVA_ESCI,
  BUTTON_SALVA_AVANTI,
  TEXT_DIALOG_COMPLETAMENTO_PROGETTI,
  TEXT_DIALOG_PROGETTO_COMPLETATO,
  BUTTON_SALVA,
  BUTTON_CONTINUA_MODIFICA,
} = labels;

export const FooterInserisciProgetti = (props) => {
  const [DialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  let progress = props.percentuale;
  let onSave = props.onSave;
  const navigate = useNavigate();
  const choosecolor = (progress) => {
    if (progress <= 50) return "linear-gradient(to right, #FFFF99,#FFFF66,#FFFF33)";
    if (progress > 50 && progress <= 80) return "linear-gradient(to right,  #FFFF99,#FFFF66,#FFFF33,#CCFF66,#CCFF33,#66CC00)";
    if (progress > 80) return "linear-gradient(to right,  #FFFF99,#FFFF66,#FFFF33,#CCFF66,#CCFF33,#66CC00,#339900)";
  };
  if (progress > 100) {
    progress = 100;
  }

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          {props.pubblicazione === true ? null : <Typography className="infoCampiObbligatori">{INFO_CAMPI_OBBLIGATORI}</Typography>}
        </Grid>
      </Grid>
      <Grid item xs={12} className="positionLinearP">
        <LinearProgress
          variant="determinate"
          value={progress}
          color="primary"
          className="linearProgress"
          sx={{
            ".MuiLinearProgress-bar": {
              backgroundImage: choosecolor(progress) + " !important",
            },
          }}
        />
        <Typography className="textLinearProgress">{progress}%</Typography>
      </Grid>
      {props.dettagli === true ? null : (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className="buttonSalvaEsci"
            onClick={() => {
              if (props.pubblicazione === true) {
                setDialogOpen(true);
              } else {
                dispatch(setButtTypeFooterInsProg("SALVA"));
                onSave();
              }
            }}
          >
            <Typography className="textButton">{BUTTON_SALVA_ESCI}</Typography>
          </Button>
          <Dialog
            open={DialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="inserimentoProgetto"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {props.percentuale === 100 ? (
                  <Typography className="textDialog">{TEXT_DIALOG_COMPLETAMENTO_PROGETTI}</Typography>
                ) : (
                  <Typography className="textDialog">{TEXT_DIALOG_PROGETTO_COMPLETATO}</Typography>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="buttonDialog"
                onClick={() => {
                  dispatch(resetIndex());
                  createRequest(POST_PUBBLICAZIONE.method, POST_PUBBLICAZIONE.baseUrl, POST_PUBBLICAZIONE.apiUrl, null, {
                    codice_scuola: props.codiceScuola,
                    uuid_progetto: props.idData,
                    id_stato_progetto: props.idPubblicazione === "BOZZA" ? 1 : props.idPubblicazione === "MAPPA" ? 3 : 2,
                    completamento: props.percentuale > 100 ? 100 : props.percentuale,
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
                          uuid_progetto: props.idData,
                        })
                          .then((response) => {
                            if (response.status === 200) {
                              dispatch(setButtTypeFooterInsProg("SALVA"));
                              setDialogOpen(false);
                              navigate(PATH_HOME);
                            }
                          })
                          .catch((e) => {
                            dispatch(
                              setNotification({
                                tipo: notificationCodes.ERROR,
                                message: "Errore durante il cambio stato del progetto in modifica",
                                description: "Errore durante il cambio stato del progetto in modifica",
                              })
                            );
                          });
                      }
                    })
                    .catch((e) => {
                      dispatch(
                        setNotification({
                          tipo: notificationCodes.ERROR,
                          message: "Errore durante il salvataggio dello stato di pubblicazione ",
                          description: "Si è verificato un errore imprevisto nel salvataggio dello stato di pubblicazione",
                        })
                      );
                    });
                }}
              >
                <Typography className="textButton">{BUTTON_SALVA}</Typography>
              </Button>
              {props.percentuale !== 100 ? (
                <Button
                  className="buttonDialog"
                  onClick={() => {
                    setDialogOpen(false);
                  }}
                >
                  <Typography className="textButton">{BUTTON_CONTINUA_MODIFICA}</Typography>
                </Button>
              ) : null}
            </DialogActions>
          </Dialog>
          {props.pubblicazione === true ? null : (
            <Button
              onClick={() => {
                dispatch(setButtTypeFooterInsProg("AVANTI"));
                onSave();
              }}
              className="buttonSalvaAvanti"
            >
              <Typography className="textButton">{BUTTON_SALVA_AVANTI}</Typography>
            </Button>
          )}
        </Grid>
      )}
      <IconButton aria-label="submit" type="submit" sx={{ display: "none" }} />
    </Grid>
  );
};
