import RadioGroup from "@mui/material/RadioGroup";
import { Button, Radio, Paper } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDropDown, setFilterTipologia } from "../../../modules/configuration/actions";
import { MyFormControlLabel } from "./StyleDropdown";
import { getFilterTipologia } from "../../../modules/configuration";
import { labels } from "../../../labels";

const { BUTTON_PULISCI_FILTRI, BUTTON_APPLICA_FILTRI } = labels;

export const Tipologia = () => {
  const dispatch = useDispatch();
  const filterTipologia = useSelector(getFilterTipologia);
  const [check, setCheck] = useState(filterTipologia === "Extracurricolare" ? true : false);
  const [check1, setCheck1] = useState(filterTipologia === "Innovazione" ? true : false);
  const [check2, setCheck2] = useState(filterTipologia === "Curricolare" ? true : false);
  const [value, setValue] = useState("");

  return (
    <Paper className="dropdown">
      <RadioGroup name="use-radio-group" sx={{ margin: "15px", "& .MuiTypography-root": { textAlign: "left" } }} aria-label="tipologia">
        <MyFormControlLabel
          value="Curricolare"
          label="Curricolare"
          control={
            <Radio
              onClick={(e) => {
                setCheck(false);
                setCheck1(false);
                setCheck2(true);
                setValue(e.target.value);
              }}
              checked={check2}
            />
          }
        />
        <MyFormControlLabel
          value="Extracurricolare"
          label="Extracurricolare"
          control={
            <Radio
              onClick={(e) => {
                setCheck(true);
                setCheck1(false);
                setCheck2(false);
                setValue(e.target.value);
              }}
              checked={check}
            />
          }
        />
        <MyFormControlLabel
          value="Innovazione della Scuola"
          label="Innovazione della Scuola"
          control={
            <Radio
              onClick={(e) => {
                setCheck2(false);
                setCheck1(true);
                setCheck(false);
                setValue(e.target.value);
              }}
              checked={check1}
            />
          }
        />
      </RadioGroup>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonPulisci"
        onClick={() => {
          setCheck(false);
          setCheck1(false);
          setValue("");
        }}
      >
        {BUTTON_PULISCI_FILTRI}
      </Button>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonApplica"
        onClick={() => {
          dispatch(setDropDown());
          dispatch(setFilterTipologia(value));
        }}
      >
        {BUTTON_APPLICA_FILTRI}
      </Button>
    </Paper>
  );
};
