import { Box, Typography, Card, Modal, Grid, CardActionArea } from "@mui/material";
import { ControlPoint as ControlPointIcon } from "@mui/icons-material";
import { useState } from "react";
import { labels } from "../../labels";
import { ModaleAttivita } from "../pages/inserisciProgetti/pageAttivita/ModaleAttivita";
import { setCopiaAtt } from "../../modules/configuration";
import { useDispatch } from "react-redux";

const { ATTIVITA, PLACEHOLDER_INSERISCI } = labels;

export const FinalitaFiltro = ({ attivita, formik }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  return (
    <Box sx={{ marginBottom: "20px" }}>
      <Typography className="fieldLabel">{ATTIVITA}</Typography>
      <CardActionArea
        onClick={() => {
          let tipo = "ricerca";
          let section = "ricerca";
          setOpen(true);
          dispatch(setCopiaAtt({ tipo, section }));
        }}
      >
        <Card className="cardfiltroFinalita">
          <Grid container spacing={2} sx={{ margin: "6px", position: "relative" }}>
            <Typography className="placeholderFiltroFinalita">{PLACEHOLDER_INSERISCI}</Typography>
            <Box className="iconFiltroFinalita" sx={{ position: "absolute", right: "25px", top: "5px" }}>
              <ControlPointIcon />
            </Box>
          </Grid>
        </Card>
      </CardActionArea>
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box>
          <ModaleAttivita
            modal={setOpen}
            tipo={"ricerca"}
            section={"ricerca"}
            titolo={ATTIVITA}
            formik={formik}
            attivita={attivita}
            textField={true}
          />
        </Box>
      </Modal>
    </Box>
  );
};
