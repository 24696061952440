import { Button, Card, CardContent, CardHeader, Grid, Link, Stack } from "@mui/material";
import { labels } from "../../../labels";
import {
  CercaHomeIcon,
  DatiSintesiIcon,
  InserisciHomeIcon,
  LogoScuolaDigitaleVerticaleIcon,
  MappaHomeIcon,
  ScrivaniaIcon,
  LoghiCoesioneIcon,
  RegioneLiguriaIcon,
} from "../../../styles/icons";
import { useNavigate } from "react-router-dom";
import { PATH_DATI_SINTESI, PATH_MAPPA, PATH_RICERCA, PATH_SCRIVANIA } from "../../../path";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InserisciProgettiModal } from "./InserisciProgettiModal";
import { fetchSezioneProgettiCurricolariExtraCurricolari } from "../../../modules/datiProgetti";
import { PROFILO_RUOLO, RUOLO_IST, getInfoProfilo } from "../../../modules/profilo";
import { LoggedMenu, MobileLoggedMenu } from "../../";
import { fetchSezioneIstitutiScolastici } from "../../../modules/istituti";
import { NavigationType, useNavigationType } from "react-router-dom";
import { PUT_SBLOCCO_MODIFICA } from "../../../apiUrl";
import { createRequest } from "../../../utility";
import { notificationCodes, setNotification } from "../../../modules/notification";
import { getIdProgetto } from "../../../modules/configuration";

const { BUTTON_GESTIONE_PROGETTI, BUTTON_INSERIMENTO, BUTTON_MAPPA, BUTTON_RICERCA, BUTTON_DATI_SINTESI } = labels;

const ButtonTitle = [
  {
    id: "0",
    testo: BUTTON_MAPPA,
    class: "buttonMappa",
    icon: <MappaHomeIcon />,
  },
  {
    id: "1",
    testo: BUTTON_RICERCA,
    class: "buttonRicerca",
    icon: <CercaHomeIcon />,
  },
  {
    id: "2",
    testo: BUTTON_DATI_SINTESI,
    class: "buttonDatiSintesi",
    icon: <DatiSintesiIcon />,
  },
];

export const Homepage = () => {
  const navigationType = useNavigationType(); //per button indietro da browser
  const [navigation, setNavigation] = useState(navigationType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const profilo = useSelector(getInfoProfilo);
  let ruolo = profilo !== null ? profilo[PROFILO_RUOLO] : null;
  let uuid_progetto = useSelector(getIdProgetto);

  //sblocco progetto in inserimento a seguito del ritorno alla home
  useEffect(() => {
    return () => {
      if (navigation === NavigationType.Pop) {
        createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
          uuid_progetto: uuid_progetto,
        })
          .then((response) => {
            if (response.status === 200) {
            }
          })
          .catch((e) => {
            dispatch(
              setNotification({
                tipo: notificationCodes.ERROR,
                message: "Errore durante il cambio stato del progetto in modifica",
                description: "Errore durante il cambio stato del progetto in modifica",
              })
            );
          });
        setNavigation(null);
      }
    };
    // eslint-disable-next-line
  });

  //recupero tutti i dati dei progetti
  useEffect(() => {
    dispatch(fetchSezioneIstitutiScolastici());
    dispatch(fetchSezioneProgettiCurricolariExtraCurricolari()); //TUTTI I PROGETTI SONO QUA DENTRO SIA CURR CHE INNOVAZIONE
    // eslint-disable-next-line
  }, []);

  //navigazione tramite bottoni in base a id selezionato
  const changepage = (id) => {
    switch (id) {
      case "0":
        navigate(PATH_MAPPA);

        break;
      case "1":
        navigate(PATH_RICERCA);

        break;

      case "2":
        window.open("https://cruscottoprogetti.scuoladigitaleliguria.it");
        break;

      case "4":
        setOpen(true);
        break;

      default: //do nothing
    }
  };

  //size button home
  const size = (index) => {
    if (index === 2) {
      return 12;
    } else return 6;
  };

  return (
    <>
      <Grid container className="home">
        {/* Header */}
        <Grid item xs={12} className="loghiPersonali">
          <Grid container sx={{ alignItems: "center" }}>
            {/* Logo */}
            <Grid item xs={12} sm={9} md={9} xl={9.8} className="logoScuola">
              <h1> Osservatorio Scuola Digitale </h1>
              <RegioneLiguriaIcon />
              <LogoScuolaDigitaleVerticaleIcon />
            </Grid>
            {/* Button profilo e gestione progetti */}
            <Grid item xs={12} sm={3} md={3} xl={2.2}>
              <Grid container>
                <Grid item xs={6}>
                  {ruolo !== RUOLO_IST ? (
                    <Grid container className="link">
                      <Grid item xs={12} className="center">
                        <Button className="buttonGestioneProgetti" onClick={() => navigate(PATH_SCRIVANIA)} aria-label="Gestione progetti">
                          <ScrivaniaIcon />
                        </Button>
                      </Grid>
                      <Grid item xs={12} className="center" sx={{ marginTop: "1px" }}>
                        <Link href={PATH_SCRIVANIA} className="link" sx={{ textAlign: "center" }}>
                          {BUTTON_GESTIONE_PROGETTI}
                        </Link>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={6}>
                  <LoggedMenu />
                  <MobileLoggedMenu />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Button inserimento progetto */}
        <Grid item xs={12} className="center inserimento">
          {ruolo !== RUOLO_IST && (
            <Button onClick={() => changepage("4")} className="buttonInserisciProgetto">
              <Card>
                <CardHeader title={BUTTON_INSERIMENTO} />
                <CardContent>
                  <Stack className="icona">
                    <InserisciHomeIcon />
                  </Stack>
                </CardContent>
              </Card>
            </Button>
          )}
        </Grid>
        {/* Button mappa / ricerca / dati sintesi */}
        <Grid item xs={12} className="mappaCercaDati">
          <Grid container spacing={{ xs: 4, lg: 0 }}>
            {ButtonTitle.map((elemento, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={size(index)}
                lg={4}
                sx={{ display: "flex", justifyContent: { xs: "center", lg: index === 0 ? "flex-end" : index === 1 ? "center" : "flex-start" } }}
              >
                <Button onClick={() => changepage(elemento.id)} className={elemento.class}>
                  <Card>
                    <CardHeader title={elemento.testo} />
                    <CardContent>{elemento.icon}</CardContent>
                  </Card>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} className="loghiCoesione">
          <img src={LoghiCoesioneIcon} alt="Loghi FSE Coesione" />
        </Grid>
      </Grid>
      <InserisciProgettiModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};
