import { Grid, Typography } from "@mui/material";
import { labels } from "../../labels";
import { getProfiliInUscita } from "../../modules/istituti";
import { getVal1Slice, getVal2Slice } from "../../modules/configuration";
import { useSelector } from "react-redux";

const { ORDINAMENTO, INDIRIZZO_QUALIFICA, SETTORE, ARTICOLAZIONE } = labels;

export const TableResponsiveProfUscita = (props) => {
  const profiliUscita = useSelector(getProfiliInUscita);
  const valore1slice = useSelector(getVal1Slice);
  const valore2slice = useSelector(getVal2Slice);
  let progetti = profiliUscita.data;

  return (
    <Grid container className="tableResponsive">
      {progetti.slice(valore1slice, valore2slice).map((elem, index) => (
        <Grid item xs={12} sm={12} md={12} xl={12} sx={{ marginTop: "30px", marginBottom: "30px" }} key={index}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                borderColor: props.color,
                bgcolor: props.color,
              }}
              className="center borderTableResponsive"
            >
              <Typography className="campiTableResponsive"></Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="borderTableResponsive"
              sx={{
                borderColor: props.borderColor,
              }}
            >
              <Typography className="campiTableResponsive">{ORDINAMENTO}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="borderTableResponsive"
              sx={{
                borderColor: props.borderColor,
              }}
            >
              <Typography
                sx={{
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                {" "}
                {elem.descr_ordinamento}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="borderTableResponsive"
              sx={{
                borderColor: props.borderColor,
              }}
            >
              {" "}
              <Typography className="campiTableResponsive">{SETTORE}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="borderTableResponsive"
              sx={{
                borderColor: props.borderColor,
              }}
            >
              {" "}
              <Typography
                sx={{
                  marginTop: "5px",
                  marginLeft: "10px",
                }}
              >
                {elem.descr_settore}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="borderTableResponsive"
              sx={{
                borderColor: props.borderColor,
              }}
            >
              {" "}
              <Typography className="campiTableResponsive">{INDIRIZZO_QUALIFICA}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="borderTableResponsive"
              sx={{
                borderColor: props.borderColor,
              }}
            >
              <Typography
                sx={{
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                {" "}
                {elem.descr_indirizzo_qualifica}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="borderTableResponsive"
              sx={{
                borderColor: props.borderColor,
              }}
            >
              <Typography className="campiTableResponsive">{ARTICOLAZIONE}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="borderTableResponsive"
              sx={{
                borderColor: props.borderColor,
              }}
            >
              {" "}
              <Typography
                sx={{
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                {" "}
                {elem.descr_articolazione}
              </Typography>
            </Grid>
          </Grid>{" "}
        </Grid>
      ))}
    </Grid>
  );
};
