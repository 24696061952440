export const Filtri = (props) => (
  <svg viewBox="0 0 39.286 35.558" {...props}>
    <path
      d="M40.286,4.5H3L17.914,22.136V34.329l7.457,3.729V22.136Z"
      transform="translate(-2 -3.5)"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
