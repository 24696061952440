import { Box } from "@mui/material";
import { Main } from "./MainIstituto";
import { useDispatch, useSelector } from "react-redux";
import { getPath } from "../../../modules/configuration";
import { PATH_HOME, PATH_ISTITUTI_SCOLASTICI, PATH_SCHEDA_ISTITUTO } from "../../../path";
import { ScrivaniaIcon } from "../../../styles/icons";
import { getIstituto, resetDatiIstituti } from "../../../modules/istituti";
import { HeaderPage } from "../../shared/Header";
import { LoadingSpinner } from "../../shared";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { MainIstResp } from "./MainIstitutoResponsive";

export const Istituto = () => {
  const dispatch = useDispatch();
  const path = useSelector(getPath);
  const datiIstituto = useSelector(getIstituto);
  const navigate = useNavigate();

  if (datiIstituto.length === 0) navigate(PATH_HOME);

  if (path.length === 0) navigate(PATH_HOME);

  //scelgo colori del tema principali in base alla sezione dalla quale arrivo
  let color1;
  let color2;

  if (path === PATH_ISTITUTI_SCOLASTICI) {
    color1 = "#8E2155 !important";
    color2 = "#DEA4BD !important";
  } else {
    color1 = "#105B70 !important";
    color2 = "#98BCBD !important";
  }

  useEffect(() => {
    return () => dispatch(resetDatiIstituti());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (datiIstituto.fetchEnded) {
      const tabs = document.getElementsByClassName("MuiTab-root");
      if (tabs.length > 0) {
        for (let i = 0; i < tabs.length; i++) {
          tabs[i].removeAttribute("aria-controls");
        }
      }
    }
  }, [datiIstituto]);

  return (
    <Box>
      {" "}
      {datiIstituto.fetchEnded === false ? (
        <Box>
          <LoadingSpinner loading={true} />
        </Box>
      ) : (
        <Box>
          <HeaderPage
            resetFiltri={false}
            linkBack={true}
            color={color1}
            title={datiIstituto.data[0] !== undefined ? datiIstituto.data[0].nome : ""}
            icon={<ScrivaniaIcon />}
            path={path === PATH_SCHEDA_ISTITUTO ? PATH_HOME : path}
          />
          <Box>
            <Box>
              <Box
                sx={{
                  "@media (max-width:480px)": { paddingBottom: "0px", display: "none" },
                }}
              >
                <Main color1={color1} color2={color2} />
              </Box>
              <Box
                sx={{
                  display: "none",
                  "@media (max-width:480px)": { display: "block" },
                }}
              >
                <MainIstResp color1={color1} color2={color2} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
