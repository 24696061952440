import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { labels } from "../../labels";
import { GATEWAY_ERROR } from "../../utility/utils";
import { LogoScuolaDigitaleVerticaleIcon } from "../../styles/icons";

const { GATEWAY_ERROR_INFO, GATEWAY_ERROR_INSTRUCTIONS, GATEWAY_ERROR_HELP } = labels;

export const GatewayError = () => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    window.addEventListener(GATEWAY_ERROR, () => setShowError(true), false);
    // eslint-disable-next-line
  }, []);

  return (
    showError && (
      <Stack sx={{ height: "100%" }}>
        <Box className="gatewayError">
          <Box className="icona">
            <LogoScuolaDigitaleVerticaleIcon />
            <Typography>{GATEWAY_ERROR_INFO}</Typography>
          </Box>
          <Typography>{GATEWAY_ERROR_INSTRUCTIONS}</Typography>
          <Typography>{GATEWAY_ERROR_HELP}</Typography>
        </Box>
      </Stack>
    )
  );
};
