import { Grid, Typography, Box, Checkbox, FormGroup, FormControlLabel, IconButton, TextField, List, FormControl } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveSottoAttivita,
  deleteval,
  getAttivitaFilter,
  getFilter,
  saveattivitapost,
  getDescrizioniSottoAttivita,
  setDescrizioniSottoAttivita,
  getDatiProgetto,
  getDatiProgettoInnovazione,
  getFiltriProgettiAttivita,
  getIdProgetto,
} from "../../../../modules/configuration";
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { getInfoProfilo } from "../../../../modules/profilo";

export const SelectDescriptionModale = (props) => {
  const [visible, setVisibile] = useState(false);
  const [description, setDescription] = useState("");
  let formik = props.formik;
  const dispatch = useDispatch();
  const attivita = props.attivita;
  let sottoAttivita = [];
  let attivitaInserite = [];
  const attivitaFilter = useSelector(getAttivitaFilter);
  const Filter = useSelector(getFilter);
  const [close, setClose] = useState(false);
  const [valori, setInd] = useState(false);
  const idProgNuovo = useSelector(getIdProgetto);
  const percentualeAttivita = props.tipo === "progettoCurricolare" ? 40 / 19 : attivita.length !== 0 ? 50 / attivita.length : 0;
  const profilo = useSelector(getInfoProfilo);
  let descrizioniSottoAttivitaMap = useSelector(getDescrizioniSottoAttivita);
  let descrizioniSottoAttivita = descrizioniSottoAttivitaMap !== undefined ? new Map(JSON.parse(descrizioniSottoAttivitaMap)) : null;
  let checkTex = props.textField;
  let progress = props.progress;
  const codiceScuola = profilo.codiceScuola;
  let progetto = props.progetto;
  const dataProgInn = useSelector(getDatiProgettoInnovazione);
  const dataProg = useSelector(getDatiProgetto);
  const dataProgRicerca = useSelector(getFiltriProgettiAttivita);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  let attivitaPost;
  if (props.tipo === "progettoCurricolare") {
    if (props.section === "contesto") {
      attivitaPost = dataProg[props.section]["attivita"];
    } else {
      attivitaPost = dataProg["attivita"];
    }
  } else {
    if (props.tipo === "ricerca") {
      attivitaPost = dataProgRicerca;
    } else {
      attivitaPost = dataProgInn["attivita"];
    }
  }
  const set = (e, elementoAtt, elementoSottoAtt) => {
    if (e === true) {
      dispatch(saveSottoAttivita({ elementoSottoAtt, elementoAtt }));
    } else {
      let value = elementoSottoAtt;
      let tipo = props.tipo;
      let section = props.section;
      dispatch(deleteval({ value, section, tipo }));
    }
  };

  // eslint-disable-next-line
  attivitaPost.map((e) => {
    sottoAttivita.push(e.finalita + " " + e.descr_campo + " " + e.descr_valore);

    attivitaInserite = attivitaInserite.filter((el) => el !== e.finalita + " " + e.descr_campo);

    attivitaInserite.push(e.finalita + " " + e.descr_campo);
  });

  return (
    <List className="list">
      {(Filter ? attivitaFilter[0] : attivita).map((elem, ind) => (
        <Box key={ind}>
          <Grid container>
            <Grid item xs={1} sm={0.3} md={0.3} xl={0.3} sx={{ marginLeft: "10px" }}>
              {attivitaInserite.map((e) => (e === elem.finalita + " " + elem.descr_campo ? <CheckIcon sx={{ color: "green" }} /> : null))}
            </Grid>
            <Grid item xs={1} sm={0.5} md={0.5} xl={0.5}>
              <IconButton
                sx={{ marginTop: "-8px" }}
                onClick={async() => {
                  setInd(null);
                  await delay(100);
                  setInd(ind);
                  if (ind === valori && close === true) {
                    setClose(false);
                    setVisibile(false);
                  } else {
                    setVisibile(true);
                    setClose(true);
                  }
                }}
              >
                <ExpandCircleDownOutlined className={ind === valori && close === true ? "rotate" : "rotate2"} />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={8}
              sm={10.5}
              md={10.5}
              xl={10.5}
              sx={{
                paddingLeft: "15px",
              }}
            >
              <Grid container>
                <Grid item>
                  <Typography sx={{ fontWeight: "Bold" }}> {elem.finalita}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{" " + elem.descr_campo}</Typography>
                </Grid>{" "}
              </Grid>
            </Grid>
          </Grid>
          {visible && ind === valori ? (
            <Box sx={{ marginLeft: "50px" }}>
              <FormGroup>
                {elem.valori.map((elemento, index) => (
                  <Grid container sx={{ marginTop: "30px" }} key={index}>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        key={elemento.id}
                        sx={{
                          " .MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate": {
                            color: "black",
                          },
                        }}
                        control={
                          <Checkbox
                            name="attivita"
                            checked={sottoAttivita.indexOf(elem.finalita + " " + elem.descr_campo + " " + elemento.descr_valore) > -1}
                            onChange={(e) => {
                              set(e.currentTarget.checked, elem.finalita + " " + elem.descr_campo, elemento.descr_valore);

                              let datiPost = {
                                codice_scuola: codiceScuola,
                                uuid_progetto: progetto === undefined ? null : progetto.data === undefined ? progetto.uuid_progetto : idProgNuovo,
                                id_campo: elem.id_campo,
                                descr_sottotab: elem.descr_sottotab,
                                obbligatorieta: elem.obbligatorieta,
                                descr_campo: elem.descr_campo,
                                id_valore: elemento.id_valore,
                                finalita: elem.finalita,
                                descr_valore: elemento.descr_valore,
                                descr_valore_progetto: description,
                                completamento: progress + Math.floor(percentualeAttivita),
                              };

                              let tipo = props.tipo;
                              let section = props.section;
                              let post = [];
                              post[0] = datiPost;
                              let check = e.currentTarget.checked;
                              if (check === true) {
                                dispatch(saveattivitapost({ post, check, tipo, section }));
                              }
                              if (tipo !== "ricerca") {
                                formik.values.attivita = post;
                              }
                            }}
                            sx={{
                              color: "black",
                              opacity: "1",
                              "&.Mui-checked": { color: "black" },
                            }}
                          />
                        }
                        label={elemento.descr_valore}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {checkTex === false ? (
                        <FormControl sx={{ width: "95%" }}>
                          <TextField
                            placeholder={"Scrivi descrizione"}
                            defaultValue={
                              descrizioniSottoAttivita !== null && descrizioniSottoAttivita.get(elemento.descr_valore) !== undefined
                                ? descrizioniSottoAttivita.get(elemento.descr_valore)[0]
                                : ""
                            }
                            onChangeCapture={(e) => {
                              let elementoSottoAtt = elemento.descr_valore;
                              let elementoSottoAttDesc = e.target.value;
                              dispatch(setDescrizioniSottoAttivita({ elementoSottoAtt, elementoSottoAttDesc }));
                              setDescription(e.target.value);
                              set(true, elem.finalita + " " + elem.descr_campo, elemento.descr_valore);

                              let datiPost = {
                                codice_scuola: codiceScuola,
                                uuid_progetto: progetto === undefined ? null : progetto.data === undefined ? progetto.uuid_progetto : idProgNuovo,
                                id_campo: elem.id_campo,
                                descr_sottotab: elem.descr_sottotab,
                                obbligatorieta: elem.obbligatorieta,
                                descr_campo: elem.descr_campo,
                                id_valore: elemento.id_valore,
                                finalita: elem.finalita,
                                descr_valore: elemento.descr_valore,
                                descr_valore_progetto: e.target.value,
                                completamento: progress + Math.floor(percentualeAttivita),
                              };
                              let tipo = props.tipo;
                              let section = props.section;
                              let post = [];
                              post[0] = datiPost;
                              let check = true;
                              dispatch(saveattivitapost({ post, check, tipo, section }));
                              formik.values.attivita = post;
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": {
                                  border: "none",
                                },
                              },
                              width: "100%",
                              boxShadow: "0px 3px 6px #000000C7",
                              borderRadius: "3px",
                              opacity: "1",
                              "@media (max-width:900px)": { width: "240px" },
                              "@media (max-width:480px)": {
                                marginTop: "15px",
                                marginBottom: "35px",
                                width: "200px",
                              },
                              ".MuiOutlinedInput-root": {
                                borderRadius: "5px",
                              },
                              ".MuiOutlinedInput-input": {
                                padding: "6px",
                              },
                              input: {
                                "&::placeholder": {
                                  fontStyle: "italic",
                                  color: "#595959",
                                  fontSize: "16px",
                                  opacity: 1,
                                },
                              },
                            }}
                          />
                          <Box sx={{ paddingTop: "5px" }}>
                            Numero caratteri inseriti{" "}
                            {descrizioniSottoAttivita !== null && descrizioniSottoAttivita.get(elemento.descr_valore) !== undefined
                              ? descrizioniSottoAttivita.get(elemento.descr_valore)[0] !== null
                                ? descrizioniSottoAttivita.get(elemento.descr_valore)[0].length
                                : null
                              : 0}{" "}
                            / 300
                          </Box>{" "}
                        </FormControl>
                      ) : null}
                    </Grid>
                  </Grid>
                ))}
              </FormGroup>
            </Box>
          ) : null}
        </Box>
      ))}
    </List>
  );
};
