import { Box, Typography } from "@mui/material";
import { EmptyIcon } from "../../styles/icons";
import { labels } from "../../labels";

const { EMPTY_MESSAGE } = labels;

export const Empty = () => (
  <Box className="empty">
    <EmptyIcon />
    <Typography>{EMPTY_MESSAGE}</Typography>
  </Box>
);
