import { useEffect, useState } from "react";
import { FormControl, FormHelperText, IconButton, Typography } from "@mui/material";
import { ExpandCircleDownOutlined as ExpandCircleDownOutlinedIcon, CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { saveValueProgetto, saveValueProgettoInn } from "../../../modules/configuration";
import { DesktopDatePicker } from "@mui/x-date-pickers";

export const Calendar = ({
  placeholder,
  errorDate,
  errorDateText,
  errorText,
  formik,
  label,
  required,
  tipo,
  name,
  ricerca,
  disabled,
  error,
  section,
  handle,
}) => {
  // const [value, setValue] = useState(formik.values[name] !== "" ? dayjs(formik.values[name]) : null);
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();
  const ariaLabel = placeholder.replaceAll(" ", "_").toLowerCase();
  let currentTime = new Date();
  let year = currentTime.getFullYear();
  const format = function date2str(x, y) {
    var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length);
    });
  };
  const border = (formik, name) => {
    if (formik.touched[name] && Boolean(formik.errors[name]) === true) return null;
    else return "none";
  };

  const color = (formik, name) => {
    if (formik.touched[name] && Boolean(formik.errors[name]) === true) return true;
    else return false;
  };

  let onDatiChange;
  if (tipo === "curricolare") {
    onDatiChange = (modifiche) => {
      // eslint-disable-next-line
      modifiche.map((modifica) => {
        const { fieldName, fieldValue } = modifica;
        formik.setFieldValue(fieldName, fieldValue);
        dispatch(saveValueProgetto(modifica));
      });
    };
  } else {
    onDatiChange = (modifiche) => {
      // eslint-disable-next-line
      modifiche.map((modifica) => {
        const { fieldName, fieldValue } = modifica;
        formik.setFieldValue(fieldName, fieldValue);
        dispatch(saveValueProgettoInn(modifica));
      });
    };
  }

  useEffect(() => {
    if (formik.values[name] !== "") setValue(dayjs(formik.values[name]));
    // eslint-disable-next-line
  }, [formik.values[name]]);

  useEffect(() => {
    const inputs = document.getElementsByTagName("input");
    if (inputs.length > 0) {
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].getAttribute("name") === name) inputs[i].setAttribute("aria-label", ariaLabel);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography className="fieldLabel">
        {label}
        {required && " *"}
      </Typography>
      <FormControl fullWidth disabled={disabled} error={error} required={required} sx={{ marginBottom: "20px" }}>
        <DesktopDatePicker
          components={{
            OpenPickerIcon: ExpandCircleDownOutlinedIcon,
          }}
          minDate={dayjs("2013-01-01")}
          id={name}
          maxDate={dayjs(year + 1 + "-12-31")}
          name={name}
          title={name}
          value={value}
          renderValue={() => value}
          onChange={(newValue) => {
            setValue(newValue);
            handle(newValue === undefined ? "" : format(new Date(newValue), "yyyy-MM-dd"));
            if (ricerca !== true) {
              onDatiChange([
                {
                  fieldSection: section,
                  fieldName: name,
                  fieldValue: newValue === undefined ? "" : format(new Date(newValue), "yyyy-MM-dd"),
                },
              ]);
            }
          }}
          sx={{
            borderRadius: "5px",
            border: border(formik, name) !== "none" ? "solid 2px #d32f2f" : null,
            marginBottom: "0px",
            "& svg": { color: color(formik, name) ? "#d32f2f" : "black" },
            input: {
              color: color(formik, name) ? "#d32f2f" : "black",
              padding: "8.5px 8.5px",
            },
          }}
        />
        {value != null && (
          <IconButton
            sx={{
              color: color(formik, name) ? "#d32f2f" : "black",
              paddingTop: formik.touched[name] && Boolean(formik.errors[name]) ? "10px" : null,
              position: "absolute",
              right: { xs: "38px", md: "38px", xl: "38px" },
            }}
            onClick={() => {
              setValue(null);
              handle("");
              onDatiChange([
                {
                  fieldSection: section,
                  fieldName: name,
                  fieldValue: "",
                },
              ]);
            }}
            title="Cancella"
          >
            <CancelOutlinedIcon />
          </IconButton>
        )}
      </FormControl>
      {errorDateText && !errorDate() && (
        <FormHelperText sx={{ color: "#d32f2f", marginTop: "-15px", marginBottom: "-8px !important", marginLeft: "15px" }}>
          {errorDateText + " 2013-01-01 e " + (year + 1) + "-12-31"}
        </FormHelperText>
      )}
      {formik.touched[name] && Boolean(formik.errors[name]) && errorText && (
        <FormHelperText sx={{ color: "#d32f2f", marginTop: "-15px", marginBottom: "-8px !important", marginLeft: "15px" }}>
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};
