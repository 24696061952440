import { Grid, Box } from "@mui/material";
import { useFormik } from "formik";
import { getfiltri, resetFiltriProgetti, setFiltriProgetti } from "../../../../modules/configuration";
import { PATH_ISTITUTI_SCOLASTICI } from "../../../../path";
import { useDispatch, useSelector } from "react-redux";
import { ButtonFiltri } from "../ButtonFiltri";
import { Autocomplete, Select } from "../../../shared";
import { labels } from "../../../../labels";
import { getIstituti } from "../../../../modules/istituti";
import { getIndirizziQualificaIstituto, getProvince, getTipologieIstituto } from "../../../../modules/common";
import { fetchComune, getComune } from "../../../../modules/datiOsservatorio";

const {
  DENOMINAZIONE_ISTITUTO,
  PLACEHOLDER_DENOMINAZIONE,
  PROVINCIA,
  PLACEHOLDER_PROVINCIA,
  TIPOLOGIA_ISTITUTO,
  PLACEHOLDER_TIPOLOGIA,
  COMUNE,
  PLACEHOLDER_COMUNE,
  CODICE_MECCANOGRAFICO,
  PLACEHOLDER_CODICE_MECCANOGRAFICO,
  PROFILI_USCITA,
  PLACEHOLDER_PROFILO_USCITA,
} = labels;

export const FormIstSc = (props) => {
  const dispatch = useDispatch();
  const options = useSelector(getfiltri);
  const istituti = useSelector(getIstituti);
  const comune = useSelector(getComune);
  const istitutiDati = istituti.data;
  const elencoDenominazioni = [];
  istitutiDati.map((e, i) => elencoDenominazioni.push({ id: i, descrizione: e.nome }));
  const codiceMeccElenco = [];
  istitutiDati.map((e, i) => codiceMeccElenco.push({ id: i, descrizione: e.codice_scuola }));
  const province = useSelector(getProvince);
  const profiliUsc = useSelector(getIndirizziQualificaIstituto);
  const tipologiaIstituto = useSelector(getTipologieIstituto);

  const formik = useFormik({
    initialValues: {
      denominazione: options["denominazione"] || "",
      provincia: options["provincia"] || "",
      tipologiaIstituto: options["tipologiaIstituto"] || "",
      comune: options["comune"] || "",
      codiceMeccanografico: options["codiceMeccanografico"] || "",
      cap: options["cap"] || "",
      referente: options["referente"] || "",
      indirizzo: options["indirizzo"] || "",
    },

    onSubmit: (values, { resetForm }) => {
      resetForm({ values: "" });
      dispatch(resetFiltriProgetti());
    },
  });

  const onDatiChange = (modifiche) => {
    // eslint-disable-next-line
    modifiche.map((modifica) => {
      const { fieldName, fieldValue } = modifica;
      formik.setFieldValue(fieldName, fieldValue);
    });
  };

  return (
    <form onSubmit={formik.handleSubmit} id="formRicerca">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} xl={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12}>
              <Autocomplete
                label={DENOMINAZIONE_ISTITUTO}
                placeholder={PLACEHOLDER_DENOMINAZIONE}
                options={elencoDenominazioni}
                value={formik.values.denominazione !== "" ? elencoDenominazioni.find((den) => den.descrizione === formik.values.denominazione) : null}
                onChange={(e, v) => {
                  if (v) {
                    const { descrizione } = v;
                    onDatiChange([{ fieldName: "denominazione", fieldValue: descrizione !== PLACEHOLDER_DENOMINAZIONE ? descrizione : "" }]);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} xl={12}>
              <Select
                label={PROVINCIA}
                placeholder={PLACEHOLDER_PROVINCIA}
                options={province}
                value={formik.values.provincia !== "" ? province.find((ist) => ist.descrizione === formik.values.provincia)?.id : ""}
                onChange={(e, v) => {
                  const { value: id, children: descrizione } = v.props;
                  onDatiChange([{ fieldName: "provincia", fieldValue: descrizione !== PLACEHOLDER_PROVINCIA ? descrizione : "" }]);
                  formik.setFieldValue("comune", "");
                  dispatch(setFiltriProgetti(formik.values));
                  if (descrizione !== PLACEHOLDER_PROVINCIA) dispatch(fetchComune(id));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                label={TIPOLOGIA_ISTITUTO}
                placeholder={PLACEHOLDER_TIPOLOGIA}
                options={tipologiaIstituto}
                value={
                  formik.values.tipologiaIstituto ? tipologiaIstituto.find((ist) => ist.descrizione === formik.values.tipologiaIstituto)?.id : ""
                }
                onChange={(e, v) => {
                  const { children: descrizione } = v.props;
                  onDatiChange([{ fieldName: "tipologiaIstituto", fieldValue: descrizione !== PLACEHOLDER_TIPOLOGIA ? descrizione : "" }]);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label={COMUNE}
                disabled={formik.values.provincia === "" || formik.values.provincia === PLACEHOLDER_COMUNE}
                placeholder={formik.values.provincia !== "" ? PLACEHOLDER_COMUNE : "Seleziona provincia"}
                options={comune.data}
                value={formik.values.comune ? comune.data.find((ist) => ist.descrizione === formik.values.comune)?.id : ""}
                onChange={(e, v) => {
                  const { children: descrizione } = v.props;
                  onDatiChange([{ fieldName: "comune", fieldValue: descrizione !== PLACEHOLDER_COMUNE ? descrizione : "" }]);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} xl={12}>
              <Autocomplete
                label={CODICE_MECCANOGRAFICO}
                placeholder={PLACEHOLDER_CODICE_MECCANOGRAFICO}
                options={codiceMeccElenco}
                value={
                  formik.values.codiceMeccanografico !== ""
                    ? codiceMeccElenco.find((ist) => ist.descrizione === formik.values.codiceMeccanografico)
                    : null
                }
                onChange={(e, v) => {
                  const { descrizione } = v;
                  onDatiChange([
                    { fieldName: "codiceMeccanografico", fieldValue: descrizione !== PLACEHOLDER_CODICE_MECCANOGRAFICO ? descrizione : "" },
                  ]);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} xl={12}>
              <Autocomplete
                label={PROFILI_USCITA}
                placeholder={PLACEHOLDER_PROFILO_USCITA}
                options={profiliUsc}
                value={formik.values.indirizzo !== "" ? profiliUsc.find((ist) => ist.descrizione === formik.values.indirizzo) : null}
                onChange={(e, v) => {
                  const { descrizione } = v;
                  onDatiChange([{ fieldName: "indirizzo", fieldValue: descrizione !== PLACEHOLDER_PROFILO_USCITA ? descrizione : "" }]);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className="center">
        <ButtonFiltri formName="formRicerca" formik={formik} path={PATH_ISTITUTI_SCOLASTICI} onChange={props.handlechange} />
      </Box>
    </form>
  );
};
