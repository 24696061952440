import { createSelector } from "@reduxjs/toolkit";

export const getConfigurationStore = (state) => state.datiIstituti;

export const getIstituto = createSelector(getConfigurationStore, (configuration) => configuration.istituto);
export const getPlessiIstituto = createSelector(getConfigurationStore, (configuration) => configuration.plessiIstituto);
export const getProfiliInUscita = createSelector(getConfigurationStore, (configuration) => configuration.profiliInUscita);
export const getMembriTeam = createSelector(getConfigurationStore, (configuration) => configuration.membriTeam);

export const getIstituti = createSelector(getConfigurationStore, (configuration) => configuration.istitutiScolastici);
