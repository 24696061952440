import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./utility/store";
import { Index } from "./container/Index";
import "./styles/scss/index.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/it";

const container = ReactDOM.createRoot(document.getElementById("root"));
container.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="it"
        localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <Index />
      </LocalizationProvider>
    </PersistGate>
  </Provider>
);
