import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetAttivitaId, saveFiltriProgetti, setFiltriProgetti } from "../../../modules/configuration";
import { labels } from "../../../labels";

const { BUTTON_PULISCI_FILTRI, BUTTON_APPLICA_FILTRI } = labels;

export const ButtonFiltri = (props) => {
  let { formik, onChange } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box>
      <Button
        variant="contained"
        type="submit"
        form={props.formName}
        className="buttonViolaSecondario"
        sx={{ margin: "20px !important" }}
        onClick={(e) => {
          if (onChange != null) onChange(e, 1);
        }}
      >
        {BUTTON_PULISCI_FILTRI}
      </Button>
      <Button
        variant="contained"
        className="buttonViola"
        onClick={(e) => {
          if (onChange != null) onChange(e, 1);
          dispatch(setFiltriProgetti(formik.values));
          dispatch(saveFiltriProgetti(true));
          dispatch(resetAttivitaId());
          navigate(props.path);
        }}
      >
        {BUTTON_APPLICA_FILTRI}
      </Button>
    </Box>
  );
};
