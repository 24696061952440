export const RotellaBozza = (props) => (
  <svg viewBox="0 0 600 600" {...props}>
    <g>
      <path
        stroke="#4d4d4d"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="30px"
        d="m300,501c-111.01,0-201-89.99-201-201,0-52.13,19.84-99.6,52.38-135.32"
      />
      <path
        stroke="#4d4d4d"
        fill="none"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="30px"
        d="m501,300c0,52.2-19.89,99.74-52.51,135.47-36.76,40.27-89.67,65.53-148.49,65.53"
      />
      <g>
        <path
          stroke="#4d4d4d"
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="30px"
          d="m70,129h103.85c7.27,0,13.15,5.89,13.15,13.15v103.85"
        />
        <path
          fill="#4d4d4d"
          d="m184,148c0,5.67-5.25,10.33-12,10.93-.04-7.94-6.26-14.43-14.09-14.9,1.95-4.12,6.85-7.03,12.59-7.03,7.46,0,13.5,4.92,13.5,11Z"
        />
      </g>
    </g>
    <g>
      <path stroke="#32b51f" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="30px" d="m300,99c111.01,0,201,89.99,201,201" />
      <circle fill="#32b51f" cx="501" cy="300" r="27" />
    </g>
  </svg>
);
