import { Backdrop, Box } from "@mui/material";
import { LogoOsservatorioIcon } from "../../styles/icons";

export const LoadingSpinner = ({ loading }) => (
  <Backdrop open={loading} className="loadingSpinner">
    <Box>
      <LogoOsservatorioIcon />
    </Box>
  </Backdrop>
);
