import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, IconButton, Typography, Box } from "@mui/material";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";
import { createRequest } from "../../../utility/createRequest";
import { fetchSezioneMieiProgetti, fetchSezioneProgettiIstituto } from "../../../modules/datiProgetti";
import { DELETE_PROGETTO } from "../../../apiUrl";
import { labels } from "../../../labels";
import { getIdProgetto } from "../../../modules/configuration";
import { getInfoProfilo } from "../../../modules/profilo";

const { TESTO_MODALE_ELIMINA, BUTTON_ELIMINA, BUTTON_ANNULLAMENTO_ELIMINAZIONE } = labels;

export const EliminaModal = (props) => {
  const dispatch = useDispatch();
  const profilo = useSelector(getInfoProfilo);
  const codiceScuola = profilo.codiceScuola;
  let uuid_progetto = useSelector(getIdProgetto);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  let open = props.setOpen;
  const handleDelete = async (codiceScuola, uuid_progetto) => {
    createRequest(DELETE_PROGETTO.method, DELETE_PROGETTO.baseUrl, DELETE_PROGETTO.apiUrl + uuid_progetto);
    await delay(100);
    dispatch(fetchSezioneProgettiIstituto(codiceScuola));
    dispatch(fetchSezioneMieiProgetti());
  };

  return (
    <Box className="gestisciProgetti">
      <Grid container className="deleteModal">
        {" "}
        <Grid item xs={12}>
          <IconButton disableRipple aria-label="delete" className="iconClose" onClick={() => open(false)}>
            <CancelOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "-40px" }}>
          <Typography className="textTitleModalDelete">{TESTO_MODALE_ELIMINA}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "-40px" }}>
          <Grid container>
            <Grid item xs={6} sx={{ justifyContent: "end", display: "flex" }}>
              <Button
                variant="contained"
                type="submit"
                form="myform"
                className="buttonVerdeSecondario"
                onClick={() => {
                  handleDelete(codiceScuola, uuid_progetto);
                  open(false);
                }}
              >
                {BUTTON_ELIMINA}
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ marginLeft: "-10px" }}>
              <Button
                variant="contained"
                type="submit"
                form="myform"
                className="buttonVerde"
                onClick={() => {
                  open(false);
                }}
              >
                {BUTTON_ANNULLAMENTO_ELIMINAZIONE}
              </Button>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
