export const changeDataProgetto = (progetto) => {
  let idAzzDiff = [];
  for (let i = 0; i < progetto.attivita.length; i++) {
    if (progetto.attivita[i].finalita === "*[Azioni di diffusione/disseminazione]") {
      let id = progetto.attivita[i].id_valore;
      idAzzDiff.push(id);
    }
  }
  const progettoInnovazione = {
    anagrafica: {
      titolo_progetto: progetto.titolo_progetto === null ? "" : progetto.titolo_progetto,
      descr_progetto: progetto.descr_progetto === null ? "" : progetto.descr_progetto,
      data_inizio: progetto.data_inizio === null ? "" : progetto.data_inizio,
      descr_grado_scuola: progetto.id_grado_scuola === 0 ? "" : progetto.id_grado_scuola,
      descr_tempo_realiz: progetto.id_tempo_realiz === 0 ? "" : progetto.id_tempo_realiz,
      descr_anno_realiz: progetto.id_anno_realiz === 0 ? "" : progetto.id_anno_realiz,
      CoResponsabile: progetto.id_coreferente === 0 ? "" : progetto.id_coreferente,
      descr_ambiente: progetto.id_ambiente === 0 ? "" : progetto.id_ambiente,
      descr_fondi: progetto.id_fondi === 0 ? "" : progetto.id_fondi,
      beneficiari: progetto.beneficiari.map((e) => e.id),
      nome_mentor: progetto.nome_mentor === null ? "" : progetto.nome_mentor,
      annote: progetto.annote === null ? "" : progetto.annote,
    },
    attivita: progetto.attivita,
    documentazione: {
      descr_documentazione: progetto.documentazione.map((e) => e.id),
      link: progetto.link_documentazione === null ? "" : progetto.link_documentazione,
      azioneDiDiffusioneDisseminazione: idAzzDiff,
      descr_valutazione: progetto.id_valutazione === 0 ? "" : progetto.id_valutazione,
      descr_punti_forza: progetto.punti_forza === null ? "" : progetto.punti_forza,
      descr_punti_debole: progetto.punti_debolezza === null ? "" : progetto.punti_debolezza,
    },
  };
  return progettoInnovazione;
};

export const changeDataProgettoCurr = (progetto) => {
  let attivitaModifica = [];

  for (let i = 0; i < progetto.attivita.length; i++) {
    if (
      progetto.attivita[i].descr_sottotab === "WEB" ||
      progetto.attivita[i].descr_sottotab === "DIDATTICA" ||
      progetto.attivita[i].descr_sottotab === "LAVORO" ||
      progetto.attivita[i].descr_sottotab === "COMUNICAZIONE" ||
      progetto.attivita[i].descr_sottotab === "CONTENUTI" ||
      progetto.attivita[i].descr_sottotab === "SICUREZZA" ||
      progetto.attivita[i].descr_sottotab === "PROBLEM SOLVING"
    ) {
      attivitaModifica.push(progetto.attivita[i]);
    }
  }

  let attivitaContesto = [];
  for (let i = 0; i < progetto.attivita.length; i++) {
    if (progetto.attivita[i].descr_sottotab === "CONTESTO") {
      attivitaContesto.push(progetto.attivita[i]);
    }
  }

  let idAzzDiff = [];
  for (let i = 0; i < progetto.attivita.length; i++) {
    if (progetto.attivita[i].descr_sottotab === "DOCUMENTAZIONE") {
      let id = progetto.attivita[i].id_valore;
      idAzzDiff.push(id);
    }
  }
  const progettoCurricolare = {
    anagrafica: {
      descr_t_progetto: progetto.id_tipo_progetto === 0 ? "" : progetto.id_tipo_progetto,
      descr_progetto: progetto.descr_progetto === null ? "" : progetto.descr_progetto,
      data_inizio: progetto.data_inizio === null ? "" : progetto.data_inizio,
      titolo_progetto: progetto.titolo_progetto === null ? "" : progetto.titolo_progetto,
      num_alunni_imp: progetto.num_alunni_imp === null ? "" : progetto.num_alunni_imp,
      descr_composizione_gruppo: progetto.id_composizione_gruppo === 0 ? "" : progetto.id_composizione_gruppo,
      descr_tipo_attributo: progetto.id_tipo_attributo === 0 ? "" : progetto.id_tipo_attributo,
      descr_grado_scuola: progetto.id_grado_scuola === 0 ? "" : progetto.id_grado_scuola,
      descr_tempo_realiz: progetto.id_tempo_realiz === 0 ? "" : progetto.id_tempo_realiz,
      descr_anno_realiz: progetto.id_anno_realiz === 0 ? "" : progetto.id_anno_realiz,
      descr_studenti_bes: progetto.studenti_bes === 0 ? "" : progetto.studenti_bes,
      descr_attivita_digitale: progetto.id_attivita_digitale === 0 ? "" : progetto.id_attivita_digitale,
      CoResponsabile: progetto.id_coreferente === 0 ? "" : progetto.id_coreferente,
      annote: progetto.annote === null ? "" : progetto.annote,
    },
    contesto: {
      discipline: progetto.discipline.map((e) => e.id),
      interdisciplinarieta: progetto.interdisciplinarieta === null ? "" : progetto.interdisciplinarieta,
      descr_attivita_clil: progetto.id_attivita_clil === 0 ? "" : progetto.id_attivita_clil,
      descr_contesto_accompagnamento: progetto.id_contesto_accompagnamento === 0 ? "" : progetto.id_contesto_accompagnamento,
      nome_mentor: progetto.nome_mentor === null ? "" : progetto.nome_mentor,
      attivita: attivitaContesto,
    },
    attivita: attivitaModifica,
    documentazione: {
      descr_documentazione: progetto.documentazione.map((e) => e.id),
      link: progetto.link_documentazione === null ? "" : progetto.link_documentazione,
      azioneDiDiffusioneDisseminazione: idAzzDiff,
      descr_valutazione: progetto.id_valutazione === 0 ? "" : progetto.id_valutazione,
      descr_punti_forza: progetto.punti_forza === null ? "" : progetto.punti_forza,
      descr_punti_debole: progetto.punti_debolezza === null ? "" : progetto.punti_debolezza,
    },
  };

  return progettoCurricolare;
};
