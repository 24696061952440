import { Link } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import { LogoScuolaDigitaleVerticaleIcon } from "../../styles/icons";
import { labels } from "../../labels";
import { PATH_HOME } from "../../path";

const { BACK_HOME, NOT_FOUND } = labels;

export const PageNotFound = () => (
  <Stack sx={{ height: "100%" }}>
    <Box className="pageNotFound">
      <Box className="icona">
        <LogoScuolaDigitaleVerticaleIcon />
        <Typography>{NOT_FOUND}</Typography>
      </Box>
      <Link to={PATH_HOME}>
        <Button className="buttonVerde">{BACK_HOME}</Button>
      </Link>
    </Box>
  </Stack>
);
