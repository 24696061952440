import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Stack } from "@mui/material";
import { labels } from "../../../labels";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

const { PROFILO_DISABILITA_UTENTE_CONFERMA, PROFILO_DISABILITA_UTENTE_CONFERMA_SI, PROFILO_DISABILITA_UTENTE_CONFERMA_NO } = labels;

export const ModalDisabilitazione = ({ open, onYes, onNo }) => (
  <Dialog open={open} className="modalProfilo">
    <DialogTitle>
      <Stack sx={{ opacity: 0 }}>Modal disabilitazione</Stack>
      <IconButton onClick={onNo} aria-label="Chiudi">
        <CloseOutlinedIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Typography>{PROFILO_DISABILITA_UTENTE_CONFERMA}</Typography>
    </DialogContent>
    <DialogActions>
      <Button className="buttonVerdeSecondario" onClick={onYes}>
        {PROFILO_DISABILITA_UTENTE_CONFERMA_SI}
      </Button>
      <Button className="buttonVerde" onClick={onNo}>
        {PROFILO_DISABILITA_UTENTE_CONFERMA_NO}
      </Button>
    </DialogActions>
  </Dialog>
);
