import { Box, Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { SkeletonDatiPersonali } from "./SkeletonDatiPersonali";
import { SkeletonDatiIstituto } from "./SkeletonDatiIstituto";
import { SkeletonDatiMultimedia } from "./SkeletonDatiMultimedia";

const RectangularSkeleton = (props) => <Skeleton variant="rectangular" {...props} />;

const CircularSkeleton = (props) => <Skeleton variant="circular" {...props} />;

export const SkeletonProfilo = () => (
  <Box className="profilo">
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <SkeletonDatiMultimedia />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card>
          <CardContent>
            <Grid container>
              <SkeletonDatiPersonali />
              <SkeletonDatiIstituto />
              <Grid item xs={12} className="disabilitaButton">
                <Stack>
                  <CircularSkeleton sx={{ width: "40px", height: "40px" }} />
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <RectangularSkeleton sx={{ width: "150px", height: "30px", margin: "auto" }} />
      </Grid>
    </Grid>
  </Box>
);
