import { Button, Grid } from "@mui/material";
import isEqual from "lodash/isEqual";
import { labels } from "../../../labels";

const { ANNULLA_MODIFICHE, SALVA_CHIUDI } = labels;

export const FooterButtons = ({ profilo, tmpProfilo, onSave, onAnnulla }) => {
  const profiloChanged = !isEqual(profilo, tmpProfilo);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} className="footerButtons">
        {profiloChanged && (
          <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-end" } }}>
            <Button className="buttonVerdeSecondario" onClick={onAnnulla}>
              {ANNULLA_MODIFICHE}
            </Button>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={profiloChanged ? 6 : 12}
          sx={{ display: "flex", justifyContent: { xs: "center", md: profiloChanged ? "flex-start" : "center" } }}
        >
          <Button className="buttonVerde" disabled={!profiloChanged} onClick={onSave} type="submit">
            {SALVA_CHIUDI}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
