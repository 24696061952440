import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";
import {
  fetchProfilo,
  fetchProfiloSuccess,
  fetchProfiloFailed,
  verificaRuoloLibero,
  verificaRuoloLiberoSuccess,
  verificaRuoloLiberoFailed,
  verificaRuoloLiberoError,
  aggiornaProfilo,
  aggiornaProfiloSuccess,
  aggiornaProfiloFailed,
  disabilitaProfilo,
  disabilitaProfiloSuccess,
  disabilitaProfiloFailed,
  resetSpinningProfilo,
} from "./index";
import { logout } from "../auth";

export const initialState = {
  data: null,
  fetchEnded: false,
  ruoloLibero: {
    data: null,
    fetchEnded: false,
  },
  profiloAggiornato: null,
  profiloDisabilitato: null,
};

const fetchProfiloHandler = (state) => {
  return update(state, {
    data: { $set: initialState.data },
    fetchEnded: { $set: initialState.fetchEnded },
  });
};

const fetchProfiloSuccessHandler = (state, action) => {
  return update(state, {
    data: { $set: action.payload },
    fetchEnded: { $set: true },
  });
};

const fetchProfiloFailedHandler = (state) => {
  return update(state, {
    fetchEnded: { $set: true },
  });
};

const logoutHandler = (state) => {
  return update(state, { $set: initialState });
};

const verificaRuoloLiberoHandler = (state) => {
  return update(state, {
    ruoloLibero: { $set: initialState.ruoloLibero },
    profiloAggiornato: { $set: initialState.profiloAggiornato },
    profiloDisabilitato: { $set: initialState.profiloDisabilitato },
  });
};

const verificaRuoloLiberoSuccessHandler = (state) => {
  return update(state, {
    ruoloLibero: {
      data: { $set: true },
      fetchEnded: { $set: true },
    },
  });
};

const verificaRuoloLiberoFailedHandler = (state, action) => {
  return update(state, {
    ruoloLibero: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const verificaRuoloLiberoErrorHandler = (state) => {
  return update(state, {
    ruoloLibero: {
      data: { $set: false },
      fetchEnded: { $set: true },
    },
  });
};

const aggiornaProfiloHandler = (state) => {
  return update(state, {
    profiloAggiornato: { $set: initialState.profiloAggiornato },
  });
};

const aggiornaProfiloSuccessHandler = (state) => {
  return update(state, {
    profiloAggiornato: { $set: true },
  });
};

const aggiornaProfiloFailedHandler = (state) => {
  return update(state, {
    profiloAggiornato: { $set: false },
  });
};

const disabilitaProfiloHandler = (state) => {
  return update(state, {
    profiloDisabilitato: { $set: initialState.profiloDisabilitato },
  });
};

const disabilitaProfiloSuccessHandler = (state) => {
  return update(state, {
    profiloDisabilitato: { $set: true },
  });
};

const disabilitaProfiloFailedHandler = (state) => {
  return update(state, {
    profiloDisabilitato: { $set: false },
  });
};

const resetSpinningProfiloHandler = (state) => {
  return update(state, {
    ruoloLibero: { $set: initialState.ruoloLibero },
    profiloAggiornato: { $set: initialState.profiloAggiornato },
    profiloDisabilitato: { $set: initialState.profiloDisabilitato },
  });
};

const profiloSlice = createSlice({
  name: "profilo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProfilo, (state) => fetchProfiloHandler(state));
    builder.addCase(fetchProfiloSuccess, (state, action) => fetchProfiloSuccessHandler(state, action));
    builder.addCase(fetchProfiloFailed, (state) => fetchProfiloFailedHandler(state));
    builder.addCase(logout, (state) => logoutHandler(state));
    builder.addCase(verificaRuoloLibero, (state) => verificaRuoloLiberoHandler(state));
    builder.addCase(verificaRuoloLiberoSuccess, (state) => verificaRuoloLiberoSuccessHandler(state));
    builder.addCase(verificaRuoloLiberoFailed, (state, action) => verificaRuoloLiberoFailedHandler(state, action));
    builder.addCase(verificaRuoloLiberoError, (state) => verificaRuoloLiberoErrorHandler(state));
    builder.addCase(aggiornaProfilo, (state) => aggiornaProfiloHandler(state));
    builder.addCase(aggiornaProfiloSuccess, (state) => aggiornaProfiloSuccessHandler(state));
    builder.addCase(aggiornaProfiloFailed, (state) => aggiornaProfiloFailedHandler(state));
    builder.addCase(disabilitaProfilo, (state) => disabilitaProfiloHandler(state));
    builder.addCase(disabilitaProfiloSuccess, (state) => disabilitaProfiloSuccessHandler(state));
    builder.addCase(disabilitaProfiloFailed, (state) => disabilitaProfiloFailedHandler(state));
    builder.addCase(resetSpinningProfilo, (state) => resetSpinningProfiloHandler(state));
  },
});

export const profiloReducer = profiloSlice.reducer;
