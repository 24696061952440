import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { labels } from "../../../labels";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

const { PROFILO_CONFERMA_MODIFICA_ALERT, PROFILO_CONFERMA_MODIFICA_ALERT_INFO, NO, YES } = labels;

export const ModalConfermaModifiche = ({ open, onYes, onNo }) => (
  <Dialog open={open} className="modalProfilo">
    <DialogTitle>
      <Stack sx={{ opacity: 0 }}>Modal conferma modifiche</Stack>
      <IconButton onClick={onNo} aria-label="Chiudi">
        <CloseOutlinedIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Typography>{PROFILO_CONFERMA_MODIFICA_ALERT}</Typography>
      <Typography>{PROFILO_CONFERMA_MODIFICA_ALERT_INFO}</Typography>
    </DialogContent>
    <DialogActions>
      <Button className="buttonVerdeSecondario" onClick={onNo}>
        {NO}
      </Button>
      <Button className="buttonVerde" onClick={onYes}>
        {YES}
      </Button>
    </DialogActions>
  </Dialog>
);
