import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  getUserInfo,
  getUserInfoSuccess,
  estraiJwtInfo,
  isUserAuthenticated,
  isUserAuthenticatedSuccess,
  isUserAuthenticatedFailed,
  logout,
  fetchSessionInfo,
} from "./index";
import { createRequest, GATEWAY_ERROR } from "../../utility";
import { SESSION_INFO_API, USER_AUTHENTICATED_API, USER_INFO_API } from "../../apiUrl";
import { PATH_ACCESSO, PATH_ACCESSO_OSSERVATORIO } from "../../path";

const isUserAuthenticatedWorker = (listenerApi) => {
  createRequest(USER_AUTHENTICATED_API.method, USER_AUTHENTICATED_API.baseUrl, USER_AUTHENTICATED_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(isUserAuthenticatedSuccess());
      else throw response.message;
    })
    .catch((e) => {
      console.error("Errore durante il recupero delle informazioni di autenticazione:", e);
      listenerApi.dispatch(isUserAuthenticatedFailed());
      if (e.response != null && e.response.status != null && e.response.status === 401) window.location.href = window.origin + PATH_ACCESSO;
      else window.dispatchEvent(new CustomEvent(GATEWAY_ERROR));
    });
};

const sessionInfoHandler = (listenerApi) => {
  createRequest(SESSION_INFO_API.method, SESSION_INFO_API.baseUrl, SESSION_INFO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchSessionInfo(parseInt(response.data.session_timeout)));
      else throw response.message;
    })
    .catch((e) => {
      console.error("Errore durante il recupero della durata della sessione:", e);
    });
};

const getUserInfoWorker = (listenerApi) => {
  createRequest(USER_INFO_API.method, USER_INFO_API.baseUrl, USER_INFO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) {
        const jwtInfo = estraiJwtInfo(response.data);
        if (jwtInfo) listenerApi.dispatch(getUserInfoSuccess(jwtInfo));
      } else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(getUserInfoSuccess(null));
    });
};

const logoutHandler = () => {
  createRequest(USER_AUTHENTICATED_API.method, USER_AUTHENTICATED_API.baseUrl, USER_AUTHENTICATED_API.apiUrl)
    .then((response) => {
      if (response.status === 200) window.location.href = window.origin + "/auth/logoutUser?postLogoutUriCustom=/";
      else throw response.message;
    })
    .catch((e) => {
      if (e.response != null && e.response.status != null && (e.response.status === 401 || e.response.status === 409))
        window.location.href = PATH_ACCESSO_OSSERVATORIO;
      else console.error("Errore durante il logout:", e);
    });
};

export const authMiddleware = createListenerMiddleware();

authMiddleware.startListening({
  actionCreator: isUserAuthenticated,
  effect: async (action, listenerApi) => isUserAuthenticatedWorker(listenerApi),
});

authMiddleware.startListening({
  actionCreator: isUserAuthenticatedSuccess,
  effect: async (action, listenerApi) => sessionInfoHandler(listenerApi),
});

authMiddleware.startListening({
  actionCreator: getUserInfo,
  effect: async (action, listenerApi) => getUserInfoWorker(listenerApi),
});

authMiddleware.startListening({
  actionCreator: logout,
  effect: async (action, listenerApi) => logoutHandler(),
});
