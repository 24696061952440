// Label per i path
export const PATH_HOME = "/";
export const PATH_MAPPA = "/mappa";
export const PATH_CURRICOLARE_EXTRA_CURRICOLARE = "/curricolareExtraCurricolare";
export const PATH_CURRICOLARE_EXTRA_CURRICOLARE_MODIFICA = "/curricolareExtraCurricolareModifica";
export const PATH_BACKOFFICE = "/backoffice";
export const PATH_PROFILO = "/profilo";
export const PATH_STRUMENTI = "/strumenti";
export const PATH_RICERCA = "/ricerca";
export const PATH_SCRIVANIA = "/scrivania";
export const PATH_INNOVAZIONE = "/innovazione";
export const PATH_INNOVAZIONE_MODIFICA = "/innovazioneModifica";
export const PATH_ISTITUTI_SCOLASTICI = "/istitutiScolastici";
export const PATH_PROGETTI_INNOVAZIONE_SCUOLA = "/progettiInnovazioneScuola";
export const PATH_PROGETTI_DOCUMENTATI = "/progettiDocumentati";
export const PATH_PROGETTI_CURRICOLARI_O_EXTRA_CURRICOLARI = "/progettiCurricolariExtraCurricolari";
export const PATH_RICERCA_PROGETTI_INNOVAZIONE_SCUOLA = "/ricercaProgettiInnovazioneScuola";
export const PATH_RICERCA_CURRICOLARE_EXTRA_CURRICOLARE = "/ricercaCurricolariExtraCurricolari";
export const PATH_SCHEDA_ISTITUTO = "/schedaIstituto";
export const PATH_DETTAGLI_PROGETTO = "/dettagliProgetto";
export const PATH_FILTRI_IST_SC = "/FiltriIstitutiScolastici";
export const PATH_ACCESSO = "/accesso";
export const PATH_RISORSE = "/risorse";
export const PATH_DATI_SINTESI = "/datiSintesi";
export const PATH_ACCESSO_OSSERVATORIO = "https://www.scuoladigitaleliguria.it/osservatorio-menu/osservatorio-dei-progetti-innovativi.html";
export const PATH_STRUMENTI_SCHEDA_INNOVAZIONE = "https://www.scuoladigitaleliguria.it/attachments/article/50/Scheda%20innovazione.pdf";
export const PATH_STRUMENTI_DOCUMENTAZIONE_MULTIMEDIALE = "https://www.scuoladigitaleliguria.it/attachments/article/352/_Linee%20guida%20per%20documentazione%20multimediale.pdf"; //prettier-ignore
export const PATH_STRUMENTI_TUTORIAL = "https://youtube.com/playlist?list=PLpYYKFI9kchVrox7L8WGf3pm2nBuTMfiW&feature=shared";
