import RadioGroup from "@mui/material/RadioGroup";
import { Button, Radio, Paper } from "@mui/material";
import { setFilterDataInizio, setFilterDataInizioOrdine } from "../../../modules/configuration/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { MyFormControlLabel } from "./StyleDropdown";
import { labels } from "../../../labels";

const { BUTTON_PULISCI_FILTRI, BUTTON_APPLICA_FILTRI } = labels;

export const DataInserimento = (props) => {
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [value, setValue] = useState("");
  return (
    <Paper className="dropdown">
      <RadioGroup name="use-radio-group" sx={{ margin: "15px", "& .MuiTypography-root": { textAlign: "left" } }} aria-label="data-inserimento">
        <MyFormControlLabel
          value="dataRec"
          label="Data più recente"
          control={
            <Radio
              onClick={(e) => {
                setCheck(true);
                setCheck1(false);
                setValue(e.target.value);
              }}
              checked={check}
            />
          }
        />
        <MyFormControlLabel
          value="dataMenoRec"
          label="Data meno recente"
          control={
            <Radio
              onClick={(e) => {
                setCheck1(true);
                setCheck(false);
                setValue(e.target.value);
              }}
              checked={check1}
            />
          }
        />
      </RadioGroup>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonPulisci"
        onClick={() => {
          setCheck(false);
          setCheck1(false);
          dispatch(setFilterDataInizio(false));
        }}
      >
        {BUTTON_PULISCI_FILTRI}
      </Button>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonApplica"
        onClick={() => {
          dispatch(props.dropDown);
          if (check === false && check1 === false) {
            dispatch(setFilterDataInizio(false));
          } else {
            dispatch(setFilterDataInizio(true));
          }
          dispatch(setFilterDataInizioOrdine(value));
        }}
      >
        {BUTTON_APPLICA_FILTRI}
      </Button>
    </Paper>
  );
};
