import { resetAllOptions, resetFiltriProgetti, resetIndex, saveSceltaAtt, setEdit } from "../../../modules/configuration";
import { Box, IconButton, Typography, Button, Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { labels } from "../../../labels";
import { notificationCodes, setNotification } from "../../../modules/notification";
import { PUT_SBLOCCO_MODIFICA } from "../../../apiUrl";
import { createRequest } from "../../../utility";
import { fetchDettagliProgettoReset } from "../../../modules/datiProgetti";

const { BUTTON_ESCI, BUTTON_ANNULLA, TESTO_CONTROLLO_USCITA_INSERIMENTO } = labels;

export const ModaleHeaderInserisciProgetti = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let set = props.set;
  let uuid_progetto = props.uuid_progetto;
  return (
    <Modal
      open={props.stateModal}
      onClose={() => set(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="inserimentoProgetto"
    >
      <Box className="modaleHome">
        <IconButton disableRipple aria-label="delete" className="closeIcon" onClick={() => set(false)}>
          <CancelOutlinedIcon />
        </IconButton>
        <Box sx={{ marginTop: "75px", textAlign: "center" }}>
          <Typography className="textModaleHome">{TESTO_CONTROLLO_USCITA_INSERIMENTO}</Typography>
          <Button
            variant="contained"
            onClick={() => {
              createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
                uuid_progetto: uuid_progetto,
              })
                .then((response) => {
                  if (response.status === 200) {
                    navigate(props.path);
                    set(false);
                    dispatch(fetchDettagliProgettoReset());
                    dispatch(resetIndex());
                    dispatch(resetFiltriProgetti());
                    set(false);
                    dispatch(resetAllOptions());
                    dispatch(setEdit(false));
                    dispatch(saveSceltaAtt(false));
                  }
                })
                .catch((e) => {
                  dispatch(
                    setNotification({
                      tipo: notificationCodes.ERROR,
                      message: "Errore durante il cambio stato del progetto in modifica",
                      description: "Errore durante il cambio stato del progetto in modifica",
                    })
                  );
                });
            }}
            className="buttonEsci"
          >
            <Typography className="textButtonModaleHome">{BUTTON_ESCI}</Typography>
          </Button>
          <Button variant="contained" onClick={() => set(false)} className="buttonAnnulla">
            <Typography className="textButtonModaleHome">{BUTTON_ANNULLA}</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
