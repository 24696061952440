import { useEffect, useState } from "react";
import { Tabs, Tab, Box, Tooltip, tooltipClasses } from "@mui/material";
import { WarningAmber as WarningAmberIcon } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getDatiProgetto,
  getIdProgetto,
  getProgressContesto,
  getSottoAttivitaId,
  saveProgress,
  setAttivitaSelezionata,
} from "../../../../modules/configuration";
import { styled } from "@mui/material/styles";
import { PageAttivita } from "../pageAttivita/PageAttivita";
import { FooterInserisciProgetti } from "../FooterInserisciProgetti";
import { BinocoloIcon } from "../../../../styles/icons";
import { labels } from "../../../../labels";
import { getAttivitaCurricolari } from "../../../../modules/common";
import { valProgrCurr } from "../progressFunction";
import { ModaleSpostamentoTab } from "../ModaleSpostamentoTab";

const { DIDATTICA, LAVORO, WEB, COMUNICAZIONE, CONTENUTI, SICUREZZA, PROBLEM_SOLVING } = labels;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box
          sx={{
            border: "1px",
            boxShadow: "0px 3px 6px #000000C7",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};
export const tabsAttivita = [
  {
    id: "0",
    testo: DIDATTICA,
    priorità: true,
    binocolo: false,
  },
  {
    id: "1",
    testo: LAVORO,
    priorità: true,
    binocolo: false,
  },
  {
    id: "2",
    testo: WEB,
    priorità: true,
    binocolo: false,
  },
  {
    id: "3",
    testo: COMUNICAZIONE,
    priorità: true,
    binocolo: false,
  },
  {
    id: "4",
    testo: CONTENUTI,
    priorità: true,
    binocolo: false,
  },
  {
    id: "5",
    testo: SICUREZZA,
    priorità: true,
    binocolo: false,
  },
  {
    id: "6",
    testo: PROBLEM_SOLVING,
    priorità: true,
    binocolo: false,
  },
];
export const AttivitaNormal = (props) => {
  let formik = props.formik;
  const dispatch = useDispatch();
  const [avvisoAttObb, setAvvisoAttObb] = useState(props.checkAttOb);
  const progetto = props.progetto;
  const attivita = useSelector(getAttivitaCurricolari);
  const percentualeAttivita = 40 / 19;
  const idProg = useSelector(getIdProgetto);
  const datiProg = useSelector(getDatiProgetto);
  const datiProgetto = datiProg.attivita;
  const attcontprogr = useSelector(getProgressContesto);
  //MAP AATIIVITA E SOTTOATTIVITA RELATIVE INSERITE DALL'UTENTE
  const attivitaMap = useSelector(getSottoAttivitaId);
  let attMap = new Map(JSON.parse(attivitaMap));
  let progressAttivita = 0;
  let attivitaInserite = [];
  datiProgetto.map((e) => attivitaInserite.push(e.id_campo));

  const display = (index) => {
    if (index > 3) {
      return "none";
    }
  };

  attivitaInserite = attivitaInserite.filter(function (e, el) {
    return attivitaInserite.indexOf(e) === el;
  });

  //calcolo valore progress bar

  if (datiProgetto !== undefined) {
    progressAttivita = (attivitaInserite.length !== undefined ? attivitaInserite.length : 0) * percentualeAttivita;
  }

  useEffect(() => {
    setAvvisoAttObb(props.checkAttOb);
    // eslint-disable-next-line
  }, [props.checkAttOb]);

  useEffect(() => {
    dispatch(saveProgress(progressAttivita));
    // eslint-disable-next-line
  }, [progressAttivita]);

  let progress = Math.floor(valProgrCurr(datiProg) + progressAttivita + attcontprogr);
  progress = progress > 100 ? (progress = 100) : progress;
  //DIVIDO ATTIVITA PER TAB

  const checkAttivitaDidattica = (attivita) => {
    const checkCAD = attivita.descr_sottotab === "DIDATTICA";
    return checkCAD;
  };

  const attivitaDidattica = attivita.filter(checkAttivitaDidattica);

  const checkLavoro = (attivita) => {
    const checkCL = attivita.descr_sottotab === "LAVORO";
    return checkCL;
  };

  const attivitaLavoro = attivita.filter(checkLavoro);

  const checkWeb = (attivita) => {
    const checkCW = attivita.descr_sottotab === "WEB";
    return checkCW;
  };

  const attivitaWeb = attivita.filter(checkWeb);

  const checkComunicazione = (attivita) => {
    const checkCC = attivita.descr_sottotab === "COMUNICAZIONE";
    return checkCC;
  };

  const attivitaComunicazione = attivita.filter(checkComunicazione);

  const checkContenuti = (attivita) => {
    const checkCont = attivita.descr_sottotab === "CONTENUTI";
    return checkCont;
  };

  const attivitaContenuti = attivita.filter(checkContenuti);

  const checkSicurezza = (attivita) => {
    const checkCS = attivita.descr_sottotab === "SICUREZZA";
    return checkCS;
  };

  const attivitaSicurezza = attivita.filter(checkSicurezza);

  const checkProblemSolving = (attivita) => {
    const checkCPB = attivita.descr_sottotab === "PROBLEM SOLVING";
    return checkCPB;
  };

  const attivitaPB = attivita.filter(checkProblemSolving);
  //VERIFICO CI SIAN ATTIVITA PER TAB OBBLIGATORIE
  let checkAttDidObb = false;
  let checkAttDid = 0;
  for (let [key] of attMap.entries()) {
    //per eliminare segno obbligatorietà
    // eslint-disable-next-line
    attivitaDidattica.map((opt) =>
      opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkAttDidObb = checkAttDidObb + 1) : null
    );
    //per eliminare segno presenza attivita non inserite
    // eslint-disable-next-line
    attivitaDidattica.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? (checkAttDid = checkAttDid + 1) : null));
    if (checkAttDidObb === 2) {
      tabsAttivita[0].priorità = false;
    } else {
      tabsAttivita[0].priorità = true;
    }
    if (checkAttDid === 6) {
      tabsAttivita[0].binocolo = false;
    } else {
      tabsAttivita[0].binocolo = true;
    }
  }

  let checkLavObb = 0;
  let checkLav = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaLavoro.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkLavObb = checkLavObb + 1) : null));
    // eslint-disable-next-line
    attivitaLavoro.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? (checkLav = checkLav + 1) : null));

    if (checkLavObb === 1) {
      tabsAttivita[1].priorità = false;
    } else {
      tabsAttivita[1].priorità = true;
    }

    if (checkLav === 3) {
      tabsAttivita[1].binocolo = false;
    } else {
      tabsAttivita[1].binocolo = true;
    }
  }

  let checkWebObb = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaWeb.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkWebObb = checkWebObb + 1) : null));

    if (checkWebObb === 3) {
      tabsAttivita[2].priorità = false;
    } else {
      tabsAttivita[2].priorità = true;
    }
  }

  let checkComObb = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaComunicazione.map(
      // eslint-disable-next-line
      (opt) =>
        // eslint-disable-next-line
        opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkComObb = checkComObb + 1) : null
      // eslint-disable-next-line
    );

    if (checkComObb === 2) {
      tabsAttivita[3].priorità = false;
    } else {
      tabsAttivita[3].priorità = true;
    }
  }

  let checkCont = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaContenuti.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkCont = checkCont + 1) : null));

    if (checkCont === 3) {
      tabsAttivita[4].priorità = false;
    } else {
      tabsAttivita[4].priorità = true;
    }
  }

  let checkSic = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaSicurezza.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkSic = checkSic + 1) : null));

    if (checkSic === 1) {
      tabsAttivita[5].priorità = false;
    } else {
      tabsAttivita[5].priorità = true;
    }
  }
  let checkPB = 0;
  for (let [key] of attMap.entries()) {
    // eslint-disable-next-line
    attivitaPB.map((opt) => (opt.finalita + " " + opt.descr_campo === key && opt.obbligatorieta === 1 ? (checkPB = checkPB + 1) : null));

    if (checkPB === 1) {
      tabsAttivita[6].priorità = false;
    } else {
      tabsAttivita[6].priorità = true;
    }
  }

  const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  // Fix accessibilità
  useEffect(() => {
    const divs = document.getElementsByTagName("div");
    if (divs.length > 0) {
      for (let i = 0; i < divs.length; i++) {
        if (divs[i].getAttribute("role") === "tabpanel") divs[i].removeAttribute("aria-labelledby");
      }
    }
    // eslint-disable-next-line
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setAttivitaSelezionata(""));
  };

  return (
    <Box>
      <ModaleSpostamentoTab
        stateModal={props.modalSpostamentoTab}
        setModalSpostamentoTab={props.setModalSpostamentoTab}
        onSave={formik.handleSubmit}
        uuid={progetto.data === undefined ? progetto.uuid_progetto : idProg}
      />
      <Tabs
        TabIndicatorProps={{
          sx: { display: "none" },
        }}
        className="tabsAttivitaCurricolari"
        sx={{
          "& button.Mui-selected": {
            backgroundColor: props.Dettagli === true ? props.color2 : "#FABE10",
            color: props.Dettagli === true ? "white !important" : "black !important",
            ".MuiSvgIcon-root": {
              color: props.Dettagli === true ? "white !important" : "black !important",
            },
            ".cls-1": { fill: props.Dettagli === true ? "white !important" : "black !important" },
          },
        }}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        {tabsAttivita.slice(parseInt(props.slice)).map((elemento, index) => (
          <BootstrapTooltip
            key={index}
            title={elemento.priorità ? "Scegli attivita obbligatorie" : elemento.binocolo ? "Scegli attivita non obbligatorie" : ""}
            placement="bottom"
          >
            <Tab
              key={index}
              label={elemento.testo}
              className="tabAttivitaCurricolari"
              icon={
                elemento.priorità ? (
                  <WarningAmberIcon sx={{ color: avvisoAttObb === false ? "black !important" : "red !important" }} />
                ) : elemento.binocolo ? (
                  <Box
                    sx={{
                      ".cls-1": { fill: "black !important" },
                    }}
                  >
                    {" "}
                    <BinocoloIcon />
                  </Box>
                ) : (
                  <WarningAmberIcon sx={{ display: "none" }} />
                )
              }
              iconPosition="end"
              sx={{
                "@media (max-width:1100px)": {
                  fontSize: "16px",
                  display: display(index),
                },
              }}
            />
          </BootstrapTooltip>
        ))}
      </Tabs>
      <TabPanel value={value} index={0}>
        <PageAttivita
          check={tabsAttivita[0].priorità}
          avvisoAttObb={avvisoAttObb}
          color1={props.color1}
          color2={props.color2}
          modal={props.modal}
          formik={formik}
          progetto={progetto}
          Dettagli={props.Dettagli}
          attivita={attivitaDidattica}
          mode="normal"
          nameTab={"Didattica"}
          section={"attivita"}
          tipo={"progettoCurricolare"}
          progress={progress}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PageAttivita
          check={tabsAttivita[1].priorità}
          avvisoAttObb={avvisoAttObb}
          color1={props.color1}
          color2={props.color2}
          modal={props.modal}
          progetto={progetto}
          formik={formik}
          Dettagli={props.Dettagli}
          attivita={attivitaLavoro}
          mode="normal"
          nameTab={"Lavoro"}
          section={"attivita"}
          tipo={"progettoCurricolare"}
          progress={progress}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PageAttivita
          check={tabsAttivita[2].priorità}
          avvisoAttObb={avvisoAttObb}
          color1={props.color1}
          color2={props.color2}
          modal={props.modal}
          progetto={progetto}
          formik={formik}
          Dettagli={props.Dettagli}
          attivita={attivitaWeb}
          mode="normal"
          nameTab={"Web"}
          section={"attivita"}
          tipo={"progettoCurricolare"}
          progress={progress}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PageAttivita
          check={tabsAttivita[3].priorità}
          avvisoAttObb={avvisoAttObb}
          color1={props.color1}
          color2={props.color2}
          modal={props.modal}
          progetto={progetto}
          formik={formik}
          Dettagli={props.Dettagli}
          attivita={attivitaComunicazione}
          mode="normal"
          nameTab={"Comunicazione"}
          section={"attivita"}
          tipo={"progettoCurricolare"}
          progress={progress}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <PageAttivita
          check={tabsAttivita[4].priorità}
          avvisoAttObb={avvisoAttObb}
          color1={props.color1}
          color2={props.color2}
          progetto={progetto}
          modal={props.modal}
          formik={formik}
          Dettagli={props.Dettagli}
          attivita={attivitaContenuti}
          mode="normal"
          nameTab={"Contenuti"}
          section={"attivita"}
          tipo={"progettoCurricolare"}
          progress={progress}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <PageAttivita
          check={tabsAttivita[5].priorità}
          avvisoAttObb={avvisoAttObb}
          color1={props.color1}
          color2={props.color2}
          modal={props.modal}
          progetto={progetto}
          formik={formik}
          Dettagli={props.Dettagli}
          attivita={attivitaSicurezza}
          mode="normal"
          nameTab={"Sicurezza"}
          section={"attivita"}
          tipo={"progettoCurricolare"}
          progress={progress}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <PageAttivita
          check={tabsAttivita[6].priorità}
          avvisoAttObb={avvisoAttObb}
          color1={props.color1}
          color2={props.color2}
          modal={props.modal}
          progetto={progetto}
          formik={formik}
          Dettagli={props.Dettagli}
          attivita={attivitaPB}
          mode="normal"
          nameTab={"Problem Solving"}
          section={"attivita"}
          tipo={"progettoCurricolare"}
          progress={progress}
        />
      </TabPanel>
      <Box
        sx={{
          paddingBottom: "50px",
          display: "block",
          "@media (max-width:1100px)": { display: "none" },
          "@media (max-width:480px)": { display: "block" },
        }}
      >
        {props.Dettagli === true ? null : <FooterInserisciProgetti onSave={formik.handleSubmit} percentuale={Math.floor(progress)} />}
      </Box>
    </Box>
  );
};
