import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";
import {
  deleteval,
  checkIndex,
  changetabs,
  setDropDown,
  setDropDown2,
  setDropDown3,
  setDropDown4,
  setDropDown5,
  setDropDown6,
  saveFiltriProgetti,
  deleteFiltriProgetti,
  resetFiltriProgetti,
  resetIndex,
  resetDropDown,
  aggiornaValoriSlice,
  resetValoriSlice,
  savePath,
  saveOptionSelect,
  resetAllOptions,
  changeTabsDettagli,
  deleteOptionSelect,
  resetDatiPost,
  saveSottoAttivita,
  setValueCheckOrdineSc,
  saveAttivitaFilter,
  deleteValAttivita,
  setFilterTipologia,
  setFilterSort,
  setFilterDataInizio,
  setFilterDataInizioOrdine,
  setFilterImieiP,
  setFilterProvincia,
  setCountPagination,
  setAttivitaSelezionata,
  setIdProgetto,
  setFiltriProgetti,
  setFilterTitle,
  saveProgress,
  saveattivitapost,
  setEdit,
  titleSet,
  setCopiaAtt,
  setCodiceMecc,
  setButtTypeFooterInsProg,
  setFilterPubblicato,
  setDocumentazioneOptions,
  setAzDiffDiss,
  resetAttivita,
  deleteOptAzioneDiffusione,
  deleteOptDocumentazioneProgetto,
  saveSceltaAtt,
  setTipoBeneficiari,
  setDropDownCompletamento,
  setFilterCompletamento,
  setDescrizioniSottoAttivita,
  saveValueProgetto,
  saveValueProgettoInn,
  setProgettoCurr,
  setProgettoInn,
  saveProgressBar,
  saveProgressCont,
  resetAttPost,
  setIndexGestisciProgetti,
  changeTabsAttività,
  setAttivitaSelezionataTablet,
  setCheckAttivita,
  saveCopiaAttivita,
  resetAttivitaId,
  setIndexTabSpostamento,
} from "./actions";

const initialState = {
  indexTabs: 0,
  indexTabsSpostamento: null,
  indexTabsGestisciProgetti: 0,
  optionSelect: [],
  dropdownCompletamento: false,
  dropdown: false,
  dropdown2: false,
  dropdown3: false,
  dropdown4: false,
  dropdown5: false,
  dropdown6: false,
  filtri: [],
  valore1slice: 0,
  valore2slice: 5,
  path: "",
  progressAttivita: 0,
  progressAttivitaContesto: 0,
  progressBar: 0,
  datiPost: JSON.stringify(Array.from(new Map().entries())),
  attivita: [],
  filtriAttivita: [],
  attivitaFilter: [],
  sottoAttivita: [],
  descrizioniSottoAttivita: JSON.stringify(Array.from(new Map().entries())),
  copiaDescrizioniSA: JSON.stringify(Array.from(new Map().entries())),
  sottoAttivitaId: JSON.stringify(Array.from(new Map().entries())),
  copiaSottoAttivitaId: JSON.stringify(Array.from(new Map().entries())),
  filter: false,
  sceltaAttivita: false,
  valueCheckOrdinSc: "",
  filterTipologia: "",
  progettiFilterSort: false,
  progettiFilterTitle: false,
  progettiFilterCompletamento: false,
  progettiFilterDataInizio: false,
  progettiFilterDataInizioOrdine: "dataRec",
  progettiFilterImieiP: false,
  progettiFilterProvincia: "",
  progettiFilterPubblicato: "",
  countPagination: "",
  attivitaSelezionata: [],
  copiaAttivita: [],
  attivitaSelezionataTablet: [],
  idProgetto: "",
  datiAttivitaPost: [],
  datiAttivitaPostSottoAttId: JSON.stringify(Array.from(new Map().entries())),
  edit: "",
  indexAttività: 0,
  title: "Scegli tipologia",
  codiceMeccanograficoPerFiltroProg: "",
  buttonType: "",
  optionsDocumentazioneProgetto: [],
  optionsAzioneDiffusione: [],
  optionsTipoBeneficiari: [],
  checkAttivita: false,
  progettoCurricolare: {
    anagrafica: {
      descr_t_progetto: "",
      descr_progetto: "",
      data_inizio: "",
      titolo_progetto: "",
      num_alunni_imp: "",
      descr_composizione_gruppo: "",
      descr_tipo_attributo: "",
      descr_grado_scuola: "",
      descr_tempo_realiz: "",
      descr_anno_realiz: "",
      descr_studenti_bes: "",
      descr_attivita_digitale: "",
      CoResponsabile: "",
      annote: "",
    },
    contesto: {
      discipline: [],
      interdisciplinarieta: "",
      descr_attivita_clil: "",
      descr_contesto_accompagnamento: "",
      nome_mentor: "",
      attivita: [],
    },
    attivita: [],
    documentazione: {
      descr_documentazione: [],
      link: "",
      azioneDiDiffusioneDisseminazione: [],
      descr_valutazione: "",
      descr_punti_forza: "",
      descr_punti_debole: "",
    },
  },
  progettoInnovazione: {
    anagrafica: {
      titolo_progetto: "",
      descr_progetto: "",
      data_inizio: "",
      descr_tempo_realiz: "",
      descr_anno_realiz: "",
      descr_ambiente: "",
      descr_grado_scuola: "",
      descr_fondi: "",
      beneficiari: [],
      CoResponsabile: "",
      nome_mentor: "",
      annote: "",
    },
    attivita: [],
    documentazione: {
      descr_documentazione: [],
      link: "",
      azioneDiDiffusioneDisseminazione: [],
      descr_valutazione: "",
      descr_punti_forza: "",
      descr_punti_debole: "",
    },
  },
};

const copiaAttivita = (state, action) => {
  let tipo = action.payload.tipo;
  let section = action.payload.section;

  if (section === "contesto") {
    return update(state, {
      copiaAttivita: { $set: state[tipo][section].attivita },
      copiaSottoAttivitaId: { $set: state.sottoAttivitaId },
      copiaDescrizioniSA: { $set: state.descrizioniSottoAttivita },
    });
  } else {
    if (section === "ricerca") {
      return update(state, {
        copiaAttivita: { $set: state.filtriAttivita },
        copiaSottoAttivitaId: { $set: state.sottoAttivitaId },
        copiaDescrizioniSA: { $set: state.descrizioniSottoAttivita },
      });
    } else {
      return update(state, {
        copiaAttivita: { $set: state[tipo].attivita },
        copiaSottoAttivitaId: { $set: state.sottoAttivitaId },
        copiaDescrizioniSA: { $set: state.descrizioniSottoAttivita },
      });
    }
  }
};

const saveCopiaAtt = (state, action) => {
  let tipo = action.payload.tipo;
  let section = action.payload.section;

  if (section === "contesto") {
    return update(state, {
      [tipo]: { [section]: { attivita: { $set: state.copiaAttivita } } },
      sottoAttivitaId: { $set: state.copiaSottoAttivitaId },
      descrizioniSottoAttivita: { $set: state.copiaDescrizioniSA },
    });
  } else {
    if (section === "ricerca") {
      return update(state, {
        filtriAttivita: { $set: state.copiaAttivita },
        sottoAttivitaId: { $set: state.copiaSottoAttivitaId },
        descrizioniSottoAttivita: { $set: state.copiaDescrizioniSA },
      });
    } else {
      return update(state, {
        [tipo]: { attivita: { $set: state.copiaAttivita } },
        sottoAttivitaId: { $set: state.copiaSottoAttivitaId },
        descrizioniSottoAttivita: { $set: state.copiaDescrizioniSA },
      });
    }
  }
};

const codiceMecc = (state, action) => {
  return update(state, {
    codiceMeccanograficoPerFiltroProg: { $set: action.payload },
  });
};

const setTitleCurrExtra = (state, action) => {
  return update(state, {
    title: { $set: action.payload },
  });
};

const setIndexTabsGestisciProgetti = (state, action) => {
  return update(state, {
    indexTabsGestisciProgetti: { $set: action.payload },
  });
};

const setId = (state, action) => {
  return update(state, {
    idProgetto: { $set: action.payload },
  });
};

const setButtonType = (state, action) => {
  return update(state, {
    buttonType: { $set: action.payload },
  });
};

const savevalueselect = (opt, utente) => {
  let valutente = utente.payload;
  return update(opt, { optionSelect: { $set: [valutente] } });
};

const saveDocumentazione = (opt, utente) => {
  let documentazione = utente.payload;
  return update(opt, {
    optionsDocumentazioneProgetto: { $set: [documentazione] },
  });
};

const saveAzDiffDiss = (opt, utente) => {
  let valutente = utente.payload;
  return update(opt, { optionsAzioneDiffusione: { $set: [valutente] } });
};
const saveTipoBenef = (opt, utente) => {
  let valutente = utente.payload;
  return update(opt, { optionsTipoBeneficiari: { $set: [valutente] } });
};

const saveFiltro = (opt, utente) => {
  let valutente = utente.payload;
  return update(opt, {
    filter: { $set: (opt = valutente) },
  });
};
const resetAttivitaPost = (opt) => {
  return update(opt, {
    datiAttivitaPost: { $set: initialState["datiAttivitaPost"] },
  });
};

const saveScelta = (opt, utente) => {
  let valutente = utente.payload;
  return update(opt, {
    sceltaAttivita: { $set: (opt = valutente) },
  });
};

const resetFiltro = (opt) => {
  return update(opt, {
    dropdown: { $set: false },
    dropdown2: { $set: false },
    dropdown3: { $set: false },
    dropdown4: { $set: false },
    dropdown5: { $set: false },
    dropdown6: { $set: false },
    dropdownCompletamento: { $set: false },
    attivitaFilter: { $set: [] },
    progettoCurricolare: { $set: initialState["progettoCurricolare"] },
    progettoInnovazione: { $set: initialState["progettoInnovazione"] },
    progressAttivita: { $set: 0 },
    progressAttivitaContesto: { $set: 0 },
    idProgetto: { $set: "" },
    progressBar: { $set: 0 },
    copiaAttivita: { $set: [] },
    filtri: { $set: [] },
    filtriAttivita: { $set: [] },
    optionSelect: { $set: initialState["optionSelect"] },
    optionsAzioneDiffusione: { $set: initialState["optionsAzioneDiffusione"] },
    optionsDocumentazioneProgetto: { $set: initialState["optionsDocumentazioneProgetto"] },
    optionsTipoBeneficiari: { $set: [] },
    datiPost: { $set: JSON.stringify(Array.from(new Map().entries())) },
    filter: { $set: false },
    edit: { $set: "" },
    valore1slice: { $set: 0 },
    valore2slice: { $set: 5 },
    filterTipologia: { $set: "" },
    progettiFilterCompletamento: { $set: false },
    progettiFilterSort: { $set: false },
    progettiFilterDataInizio: { $set: false },
    progettiFilterDataInizioOrdine: { $set: "" },
    progettiFilterImieiP: { $set: false },
    progettiFilterProvincia: { $set: "" },
    progettiFilterPubblicato: { $set: "" },
    attivitaSelezionata: { $set: [] },
    attivitaSelezionataTablet: { $set: [] },
    datiAttivitaPost: { $set: [] },
    attivita: { $set: [] },
    sottoAttivita: { $set: [] },
    sottoAttivitaId: { $set: JSON.stringify(Array.from(new Map().entries())) },
    descrizioniSottoAttivita: { $set: JSON.stringify(Array.from(new Map().entries())) },
    copiaSottoAttivitaId: { $set: JSON.stringify(Array.from(new Map().entries())) },
    title: { $set: "Scegli tipologia" },
    checkAttivita: { $set: false },
    copiaDescrizioniSA: { $set: JSON.stringify(Array.from(new Map().entries())) },
    indexTabsSpostamento: { $set: null },
  });
};

const resetAtt = (opt, action) => {
  let tipo = action.payload.tipo;
  let section = action.payload.section;

  if (section === "contesto") {
    return update(opt, {
      datiAttivitaPost: { $set: opt[tipo][section].attivita },
      sottoAttivitaId: { $set: opt.copiaSottoAttivitaId },
      descrizioniSottoAttivita: { $set: opt.copiaDescrizioniSA },
    });
  } else {
    return update(opt, {
      datiAttivitaPost: { $set: opt[tipo].attivita },
      sottoAttivitaId: { $set: opt.copiaSottoAttivitaId },
      descrizioniSottoAttivita: { $set: opt.copiaDescrizioniSA },
    });
  }
};

const resetOptions = (opt) => {
  return update(opt, {
    optionSelect: { $set: [] },
    optionsDocumentazioneProgetto: { $set: [] },
    optionsAzioneDiffusione: { $set: [] },
  });
};

const deleteFiltro = (state, action) => {
  let val = state.filtri;
  let attivita = state.filtriAttivita;
  let valueUt = action.payload;
  let valMap = new Map(JSON.parse(state.sottoAttivitaId));
  let attivitaFilter = state.filtriAttivita;

  if (attivita.length > 0) {
    attivitaFilter = attivita.filter((e) => "*" + e.finalita + " " + e.descr_campo + e.descr_valore !== valueUt);
    attivitaFilter = attivita.filter((e) => e.finalita + " " + e.descr_campo + e.descr_valore !== valueUt);
  }
  let filtriAtt;
  if (attivitaFilter.length === 0) {
    attivitaFilter = "";
    filtriAtt = [];
  } else filtriAtt = attivitaFilter;

  for (let [key, value] of Object.entries(val)) {
    if (value === valueUt) {
      return update(state, {
        filtri: { [key]: { $set: "" } },
      });
    }
  }
  for (let [key, value] of valMap.entries()) {
    value.filter((opt) => (valueUt.includes(opt) ? valMap.delete(key) : null));
  }
  return update(state, {
    sottoAttivitaId: { $set: JSON.stringify(Array.from(valMap.entries())) },
    filtriAttivita: { $set: filtriAtt },
    filtri: { attivita: { $set: attivitaFilter } },
  });
};

const deletev = (state, action) => {
  let val = new Map(JSON.parse(state.sottoAttivitaId));
  let valueDel = action.payload.value;
  let section = action.payload.section;
  let tipo = action.payload.tipo;
  let dataAtt;
  let attivita = state.attivita;
  let attivitaSel = state.attivitaSelezionata;
  //elimino valore dai dati post
  if (tipo === "ricerca") {
    dataAtt = state.filtriAttivita;
  } else {
    if (section === "contesto") {
      dataAtt = state.progettoCurricolare.contesto.attivita;
    } else {
      if (tipo === "progettoCurricolare") {
        dataAtt = state.progettoCurricolare.attivita;
      } else {
        dataAtt = state.progettoInnovazione.attivita;
      }
    }
  }
  let newAtt;
  newAtt = dataAtt.filter((el) => el.descr_valore !== valueDel);

  for (let [key, value] of val.entries()) {
    value = value.filter((opt) => opt !== valueDel);
    if (value.length !== 0) {
      val.set(key, value);
    } else {
      val.delete(key);
      attivitaSel = "";
      attivita = attivita.filter((opt) => opt !== key);
    }
  }

  let sottoAtt = state.sottoAttivita;
  let value = sottoAtt.filter((opt) => opt !== valueDel);
  if (tipo === "ricerca") {
    return update(state, {
      filtri: { attivita: { $set: newAtt } },
      filtriAttivita: { $set: newAtt },
      sottoAttivitaId: { $set: JSON.stringify(Array.from(val.entries())) },
      sottoAttivita: { $set: value },
      attivita: { $set: attivita },
      attivitaSelezionata: { $set: attivitaSel },
      datiAttivitaPost: { $set: newAtt },
    });
  } else {
    if (section === "contesto") {
      return update(state, {
        [tipo]: { [section]: { attivita: { $set: newAtt } } },
        sottoAttivitaId: { $set: JSON.stringify(Array.from(val.entries())) },
        sottoAttivita: { $set: value },
        attivita: { $set: attivita },
        attivitaSelezionata: { $set: attivitaSel },
        datiAttivitaPost: { $set: newAtt },
      });
    } else {
      return update(state, {
        filtriAttivita: { $set: newAtt },
        [tipo]: { attivita: { $set: newAtt } },
        sottoAttivitaId: { $set: JSON.stringify(Array.from(val.entries())) },
        sottoAttivita: { $set: value },
        attivita: { $set: attivita },
        attivitaSelezionata: { $set: attivitaSel },
        datiAttivitaPost: { $set: newAtt },
      });
    }
  }
};

const deleteVattivita = (state, action) => {
  let val = new Map(JSON.parse(state.sottoAttivitaId));
  let valueDelete = action.payload.value;
  let section = action.payload.section;
  let tipo = action.payload.tipo;
  let sottoAttivita = val.get(valueDelete);
  let sottAttSelezionate = state.sottoAttivita;
  let dataAtt;

  //elimino valore dai dati post
  if (section === "contesto") {
    dataAtt = state.progettoCurricolare.contesto.attivita;
  } else {
    if (tipo === "progettoCurricolare") {
      dataAtt = state.progettoCurricolare.attivita;
    } else {
      dataAtt = state.progettoInnovazione.attivita;
    }
  }
  let newAtt;

  sottoAttivita.map((e) => (newAtt = dataAtt.filter((el) => el.descr_valore !== e)));
  let attivitaNew = state.attivita.filter((opt) => opt !== valueDelete);
  for (let i = 0; i < sottoAttivita.length; i++) {
    for (let n = 0; n < sottAttSelezionate.length; n++) {
      if (sottoAttivita[i] === sottAttSelezionate[n]) {
        sottAttSelezionate = sottAttSelezionate.filter((opt) => opt !== sottoAttivita[i]);
      }
    }
  }
  val.delete(valueDelete);
  if (section === "contesto") {
    return update(state, {
      [tipo]: { [section]: { attivita: { $set: newAtt } } },
      sottoAttivitaId: { $set: JSON.stringify(Array.from(val.entries())) },
      attivita: { $set: attivitaNew },
      sottoAttivita: { $set: sottAttSelezionate },
      attivitaSelezionata: { $set: attivitaNew.size === 0 ? [] : attivitaNew[0] },
    });
  } else {
    return update(state, {
      [tipo]: { attivita: { $set: newAtt } },
      sottoAttivitaId: { $set: JSON.stringify(Array.from(val.entries())) },
      attivita: { $set: attivitaNew },
      sottoAttivita: { $set: sottAttSelezionate },
      attivitaSelezionata: { $set: attivitaNew.size === 0 ? [] : attivitaNew[0] },
    });
  }
};

const saveAttPost = (state, action) => {
  let data = action.payload.post;
  let check = action.payload.check;
  let tipo = action.payload.tipo;
  let section = action.payload.section;
  if (tipo === "ricerca") {
    return update(state, {
      filtriAttivita: { $push: data },
    });
  }
  if (check === true) {
    if (section === "contesto") {
      let att = state.progettoCurricolare.contesto.attivita;
      let newAtt = att.filter((e) => e.id_campo + " " + e.id_valore !== data[0].id_campo + " " + data[0].id_valore);
      newAtt.push(data[0]);

      return update(state, {
        [tipo]: {
          [section]: {
            attivita: { $set: newAtt },
          },
        },
      });
    } else {
      let att = state[tipo].attivita;
      let newAtt = att.filter((e) => e.id_campo + " " + e.id_valore !== data[0].id_campo + " " + data[0].id_valore);
      newAtt.push(data[0]);

      return update(state, {
        [tipo]: {
          attivita: { $set: newAtt },
        },
      });
    }
  }
};

const deleteOptionsSelect = (state, action) => {
  const val = state.optionSelect;
  const discipline = state.progettoCurricolare.contesto.discipline;
  let newDisci = [];
  let newoption = [];
  let value = action.payload;
  newoption[0] = val[0].filter((opt) => opt !== value);
  newDisci[0] = discipline.filter((opt) => opt !== value);

  return update(state, {
    optionSelect: {
      $set: newoption,
    },
    progettoCurricolare: { contesto: { discipline: { $set: newDisci[0] } } },
  });
};

const deleteOptionsAzioneDiffusione = (state, action) => {
  const val = state.optionsAzioneDiffusione;
  const azzDiffCurr = state.progettoCurricolare.documentazione.azioneDiDiffusioneDisseminazione;
  const azzDiffInn = state.progettoInnovazione.documentazione.azioneDiDiffusioneDisseminazione;
  let newoption = [];
  let newAzzDiffCurr = [];
  let newAzzDiffInn = [];
  let value = action.payload;
  newoption[0] = val[0].filter((opt) => opt !== value);
  newAzzDiffCurr[0] = azzDiffCurr.filter((opt) => opt !== value);
  newAzzDiffInn[0] = azzDiffInn.filter((opt) => opt !== value);

  return update(state, {
    optionsAzioneDiffusione: { $set: newoption },
    progettoCurricolare: { documentazione: { azioneDiDiffusioneDisseminazione: { $set: newAzzDiffCurr[0] } } },
    progettoInnovazione: { documentazione: { azioneDiDiffusioneDisseminazione: { $set: newAzzDiffInn[0] } } },
  });
};

const deleteOptionsDocumentazioneProgetto = (state, action) => {
  const val = state.optionsDocumentazioneProgetto;
  const docCurr = state.progettoCurricolare.documentazione.descr_documentazione;
  const docInn = state.progettoInnovazione.documentazione.descr_documentazione;
  let newoption = [];
  let newAzzDiffCurr = [];
  let newAzzDiffInn = [];
  let value = action.payload;

  newoption[0] = val[0].filter((opt) => opt !== value);
  newAzzDiffInn[0] = docInn.filter((opt) => opt !== value);
  newAzzDiffCurr[0] = docCurr.filter((opt) => opt !== value);

  return update(state, {
    optionsDocumentazioneProgetto: { $set: newoption },
    progettoCurricolare: { documentazione: { descr_documentazione: { $set: newAzzDiffCurr[0] } } },
    progettoInnovazione: { documentazione: { descr_documentazione: { $set: newAzzDiffInn[0] } } },
  });
};

const checkpage = (state, action) => {
  if (state.indexTabs < action.payload) {
    let newvalue = state.indexTabs + 1;
    return update(state, {
      indexTabs: { $set: (state.indexTabs = newvalue) },
    });
  }
};

const resetind = (state) => {
  return update(state, {
    indexTabs: { $set: 0 },
  });
};

const editSet = (state, action) => {
  return update(state, {
    edit: { $set: action.payload },
  });
};

const tabs = (state, action) => {
  if (action.payload.versione === true) {
    return update(state, {
      indexTabs: { $set: action.payload.index },
      indexTabsSpostamento: { $set: null },
    });
  } else {
    if (action.payload.index < state.indexTabs) {
      return update(state, {
        indexTabs: { $set: action.payload.index },
      });
    }
  }
};

const resetDrop = (state) => {
  return update(state, {
    dropdown: { $set: false },
    dropdown2: { $set: false },
    dropdown3: { $set: false },
    dropdown4: { $set: false },
    dropdown5: { $set: false },
    dropdown6: { $set: false },
    dropdownCompletamento: { $set: false },
  });
};

const setDDCompletamento = (state) => {
  return update(state, {
    dropdownCompletamento: { $set: !state.dropdownCompletamento },
  });
};
const setDD = (state) => {
  return update(state, {
    dropdown: { $set: !state.dropdown },
  });
};
const setDD2 = (state) => {
  return update(state, {
    dropdown2: { $set: !state.dropdown2 },
  });
};
const setDD3 = (state) => {
  return update(state, {
    dropdown3: { $set: !state.dropdown3 },
  });
};
const setDD4 = (state) => {
  return update(state, {
    dropdown4: { $set: !state.dropdown4 },
  });
};
const setDD5 = (state) => {
  return update(state, {
    dropdown5: { $set: !state.dropdown5 },
  });
};
const setDD6 = (state) => {
  return update(state, {
    dropdown6: { $set: !state.dropdown6 },
  });
};

const valSlice = (state, action) => {
  let val2 = action.payload * 5;
  let val1 = action.payload * 5 - 5;
  return update(state, {
    valore1slice: { $set: val1 },
    valore2slice: { $set: val2 },
  });
};

const resetSlice = (state) => {
  return update(state, {
    valore1slice: { $set: 0 },
    valore2slice: { $set: 5 },
  });
};

const saveLastPath = (state, action) => {
  return update(state, {
    path: { $set: action.payload },
  });
};

const changeDettagli = (state, action) => {
  return update(state, {
    indexTabs: { $set: action.payload },
  });
};

const resetDati = (state) => {
  return update(state, {
    datiAttivitaPost: { $set: initialState["datiAttivitaPost"] },
  });
};

const saveFilterAttivita = (state, action) => {
  return update(state, {
    attivitaFilter: { $set: [action.payload] },
  });
};

const setDescrizioniSA = (state, action) => {
  let elemAtt = action.payload.elementoSottoAtt;
  let sottoAtt = action.payload.elementoSottoAttDesc;

  let attivitaElenco = new Map(JSON.parse(state.descrizioniSottoAttivita));
  attivitaElenco.set(elemAtt, [sottoAtt]);

  return update(state, {
    descrizioniSottoAttivita: {
      $set: JSON.stringify(Array.from(attivitaElenco.entries())),
    },
  });
};

const saveDatiSottoAttivita = (state, action) => {
  let elemAtt = action.payload.elementoAtt;
  let sottoAtt = action.payload.elementoSottoAtt;
  let attivitaElenco = new Map(JSON.parse(state.sottoAttivitaId));
  if (attivitaElenco.get(elemAtt) === undefined) {
    attivitaElenco.set(action.payload.elementoAtt, [action.payload.elementoSottoAtt]);
  } else {
    let num = attivitaElenco.get(elemAtt).length;
    let check = false;
    // eslint-disable-next-line
    for (const [key, value] of attivitaElenco.entries()) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] === sottoAtt) {
          check = true;
        }
      }
    }
    if (check === false) {
      attivitaElenco.get(elemAtt)[num] = sottoAtt;
    }
  }

  return update(state, {
    sottoAttivita: { $push: [action.payload.elementoSottoAtt] },
    sottoAttivitaId: {
      $set: JSON.stringify(Array.from(attivitaElenco.entries())),
    },
  });
};

const saveValueCheckOrdineSc = (state, action) => {
  return update(state, {
    valueCheckOrdinSc: { $set: action.payload },
    filterTipologia: { $set: false },
    progettiFilterTitle: { $set: false },
    progettiFilterDataInizio: { $set: false },
    progettiFilterDataInizioOrdine: { $set: false },
    progettiFilterImieiP: { $set: false },
    progettiFilterProvincia: { $set: false },
    progettiFilterPubblicato: { $set: "" },
    progettiFilterSort: { $set: false },
    progettiFilterCompletamento: { $set: false },
  });
};

const setFiltTipologia = (state, action) => {
  return update(state, {
    filterTipologia: { $set: action.payload },
    progettiFilterTitle: { $set: false },
    progettiFilterDataInizio: { $set: false },
    progettiFilterDataInizioOrdine: { $set: false },
    progettiFilterImieiP: { $set: false },
    progettiFilterProvincia: { $set: false },
    progettiFilterPubblicato: { $set: "" },
    progettiFilterSort: { $set: false },
    progettiFilterCompletamento: { $set: false },
    valueCheckOrdinSc: { $set: "" },
  });
};

const changeAttività = (state, action) => {
  return update(state, {
    indexAttività: { $set: action.payload },
  });
};

const setTitle = (state, action) => {
  return update(state, {
    filterTipologia: { $set: "" },
    progettiFilterTitle: { $set: action.payload },
    progettiFilterDataInizio: { $set: false },
    progettiFilterDataInizioOrdine: { $set: false },
    progettiFilterImieiP: { $set: false },
    progettiFilterProvincia: { $set: false },
    progettiFilterPubblicato: { $set: "" },
    progettiFilterSort: { $set: false },
    progettiFilterCompletamento: { $set: false },
    valueCheckOrdinSc: { $set: "" },
  });
};
const setSort = (state, action) => {
  return update(state, {
    filterTipologia: { $set: "" },
    progettiFilterSort: { $set: action.payload },
    progettiFilterDataInizio: { $set: false },
    progettiFilterDataInizioOrdine: { $set: false },
    progettiFilterImieiP: { $set: false },
    progettiFilterProvincia: { $set: false },
    progettiFilterPubblicato: { $set: "" },
    progettiFilterTitle: { $set: false },
    progettiFilterCompletamento: { $set: false },
    valueCheckOrdinSc: { $set: "" },
  });
};
const setCompletamento = (state, action) => {
  return update(state, {
    filterTipologia: { $set: "" },
    progettiFilterCompletamento: { $set: action.payload },
    progettiFilterDataInizio: { $set: false },
    progettiFilterDataInizioOrdine: { $set: false },
    progettiFilterImieiP: { $set: false },
    progettiFilterProvincia: { $set: false },
    progettiFilterPubblicato: { $set: "" },
    progettiFilterTitle: { $set: false },
    progettiFilterSort: { $set: false },
    valueCheckOrdinSc: { $set: "" },
  });
};

const setSortData = (state, action) => {
  return update(state, {
    filterTipologia: { $set: "" },
    progettiFilterDataInizio: { $set: action.payload },
    progettiFilterCompletamento: { $set: false },
    progettiFilterDataInizioOrdine: { $set: false },
    progettiFilterImieiP: { $set: false },
    progettiFilterProvincia: { $set: false },
    progettiFilterPubblicato: { $set: "" },
    progettiFilterTitle: { $set: false },
    progettiFilterSort: { $set: false },
    valueCheckOrdinSc: { $set: "" },
  });
};
const setSortDataOrdine = (state, action) => {
  return update(state, {
    filterTipologia: { $set: "" },
    progettiFilterDataInizioOrdine: { $set: action.payload },
    progettiFilterCompletamento: { $set: false },
    progettiFilterDataInizio: { $set: true },
    progettiFilterImieiP: { $set: false },
    progettiFilterProvincia: { $set: false },
    progettiFilterPubblicato: { $set: "" },
    progettiFilterTitle: { $set: false },
    progettiFilterSort: { $set: false },
    valueCheckOrdinSc: { $set: "" },
  });
};
const setFilterMieiP = (state, action) => {
  return update(state, {
    filterTipologia: { $set: "" },
    progettiFilterImieiP: { $set: action.payload },
    progettiFilterCompletamento: { $set: false },
    progettiFilterDataInizio: { $set: false },
    progettiFilterDataInizioOrdine: { $set: false },
    progettiFilterProvincia: { $set: false },
    progettiFilterPubblicato: { $set: "" },
    progettiFilterTitle: { $set: false },
    progettiFilterSort: { $set: false },
    valueCheckOrdinSc: { $set: "" },
  });
};

const setFilterProv = (state, action) => {
  return update(state, {
    filterTipologia: { $set: "" },
    progettiFilterProvincia: { $set: action.payload },
    progettiFilterCompletamento: { $set: false },
    progettiFilterDataInizio: { $set: false },
    progettiFilterDataInizioOrdine: { $set: false },
    progettiFilterImieiP: { $set: false },
    progettiFilterPubblicato: { $set: "" },
    progettiFilterTitle: { $set: false },
    progettiFilterSort: { $set: false },
    valueCheckOrdinSc: { $set: "" },
  });
};

const setFilterPubb = (state, action) => {
  return update(state, {
    filterTipologia: { $set: "" },
    progettiFilterPubblicato: { $set: action.payload },
    progettiFilterCompletamento: { $set: false },
    progettiFilterDataInizio: { $set: false },
    progettiFilterDataInizioOrdine: { $set: false },
    progettiFilterImieiP: { $set: false },
    progettiFilterProvincia: { $set: false },
    progettiFilterTitle: { $set: false },
    progettiFilterSort: { $set: false },
    valueCheckOrdinSc: { $set: "" },
  });
};

const saveDatiProgress = (state, action) => {
  let progr = action.payload;
  return update(state, {
    progressAttivita: { $set: Math.floor(progr) },
  });
};
const saveDatiProgressContesto = (state, action) => {
  let progr = action.payload;
  return update(state, {
    progressAttivitaContesto: { $set: Math.floor(progr) },
  });
};
const saveDatiProgressBar = (state, action) => {
  let progr = action.payload;
  return update(state, {
    progressBar: { $set: Math.floor(progr) },
  });
};
const setCount = (state, action) => {
  return update(state, {
    countPagination: { $set: action.payload },
  });
};
const setFP = (state, action) => {
  return update(state, {
    filtri: { $set: action.payload },
  });
};

const setAttSel = (state, action) => {
  return update(state, {
    attivitaSelezionata: {
      $set: action.payload,
    },
  });
};

const setIndTabSpost = (state, action) => {
  return update(state, {
    indexTabsSpostamento: {
      $set: action.payload,
    },
  });
};

const setAttSelTablet = (state, action) => {
  return update(state, {
    attivitaSelezionataTablet: {
      $set: action.payload,
    },
  });
};

const saveValProgetto = (state, action) => {
  const { fieldSection, fieldName, fieldValue } = action.payload;
  return update(state, {
    progettoCurricolare: {
      [fieldSection]: { [fieldName]: { $set: fieldValue } },
    },
  });
};
const setProgettoCur = (state, action) => {
  return update(state, {
    progettoCurricolare: { $set: action.payload },
  });
};

const saveValProgettoInn = (state, action) => {
  const { fieldSection, fieldName, fieldValue } = action.payload;
  return update(state, {
    progettoInnovazione: {
      [fieldSection]: { [fieldName]: { $set: fieldValue } },
    },
  });
};

const setProgettoIn = (state, action) => {
  return update(state, {
    progettoInnovazione: { $set: action.payload },
  });
};

const setCheckAtt = (state, action) => {
  return update(state, {
    checkAttivita: { $set: action.payload },
  });
};

const resetAttId = (state, action) => {
  return update(state, {
    sottoAttivitaId: { $set: JSON.stringify(Array.from(new Map().entries())) },
  });
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveOptionSelect, (state, action) => savevalueselect(state, action));
    builder.addCase(resetAttivitaId, (state, action) => resetAttId(state, action));
    builder.addCase(setCheckAttivita, (state, action) => setCheckAtt(state, action));
    builder.addCase(setDocumentazioneOptions, (state, action) => saveDocumentazione(state, action));
    builder.addCase(setAzDiffDiss, (state, action) => saveAzDiffDiss(state, action));
    builder.addCase(setTipoBeneficiari, (state, action) => saveTipoBenef(state, action));
    builder.addCase(deleteval, (state, action) => deletev(state, action));
    builder.addCase(checkIndex, (state, action) => checkpage(state, action));
    builder.addCase(changetabs, (state, action) => tabs(state, action));
    builder.addCase(setDropDownCompletamento, (state, action) => setDDCompletamento(state, action));
    builder.addCase(setDropDown, (state, action) => setDD(state, action));
    builder.addCase(setDropDown2, (state, action) => setDD2(state, action));
    builder.addCase(setDropDown3, (state, action) => setDD3(state, action));
    builder.addCase(setDropDown4, (state, action) => setDD4(state, action));
    builder.addCase(setDropDown5, (state, action) => setDD5(state, action));
    builder.addCase(setDropDown6, (state, action) => setDD6(state, action));
    builder.addCase(setButtTypeFooterInsProg, (state, action) => setButtonType(state, action));
    builder.addCase(saveSceltaAtt, (state, action) => saveScelta(state, action));
    builder.addCase(saveFiltriProgetti, (state, action) => saveFiltro(state, action));
    builder.addCase(setCopiaAtt, (state, action) => copiaAttivita(state, action));
    builder.addCase(resetFiltriProgetti, (state) => resetFiltro(state));
    builder.addCase(deleteFiltriProgetti, (state, action) => deleteFiltro(state, action));
    builder.addCase(resetIndex, (state) => resetind(state));
    builder.addCase(resetDropDown, (state) => resetDrop(state));
    builder.addCase(aggiornaValoriSlice, (state, action) => valSlice(state, action));
    builder.addCase(resetValoriSlice, (state) => resetSlice(state));
    builder.addCase(savePath, (state, action) => saveLastPath(state, action));
    builder.addCase(changeTabsDettagli, (state, action) => changeDettagli(state, action));
    builder.addCase(setFiltriProgetti, (state, action) => setFP(state, action));
    builder.addCase(resetDatiPost, (state) => resetDati(state));
    builder.addCase(resetAllOptions, (state) => resetOptions(state));
    builder.addCase(saveProgress, (state, action) => saveDatiProgress(state, action));
    builder.addCase(saveProgressCont, (state, action) => saveDatiProgressContesto(state, action));
    builder.addCase(saveProgressBar, (state, action) => saveDatiProgressBar(state, action));
    builder.addCase(deleteOptionSelect, (state, action) => deleteOptionsSelect(state, action));
    builder.addCase(deleteOptAzioneDiffusione, (state, action) => deleteOptionsAzioneDiffusione(state, action));
    builder.addCase(deleteOptDocumentazioneProgetto, (state, action) => deleteOptionsDocumentazioneProgetto(state, action));
    builder.addCase(saveSottoAttivita, (state, action) => saveDatiSottoAttivita(state, action));
    builder.addCase(setValueCheckOrdineSc, (state, action) => saveValueCheckOrdineSc(state, action));
    builder.addCase(saveAttivitaFilter, (state, action) => saveFilterAttivita(state, action));
    builder.addCase(deleteValAttivita, (state, action) => deleteVattivita(state, action));
    builder.addCase(setFilterTipologia, (state, action) => setFiltTipologia(state, action));
    builder.addCase(setFilterCompletamento, (state, action) => setCompletamento(state, action));
    builder.addCase(setFilterSort, (state, action) => setSort(state, action));
    builder.addCase(setFilterTitle, (state, action) => setTitle(state, action));
    builder.addCase(setFilterDataInizio, (state, action) => setSortData(state, action));
    builder.addCase(setFilterDataInizioOrdine, (state, action) => setSortDataOrdine(state, action));
    builder.addCase(setFilterImieiP, (state, action) => setFilterMieiP(state, action));
    builder.addCase(setFilterProvincia, (state, action) => setFilterProv(state, action));
    builder.addCase(setFilterPubblicato, (state, action) => setFilterPubb(state, action));
    builder.addCase(changeTabsAttività, (state, action) => changeAttività(state, action));
    builder.addCase(setCountPagination, (state, action) => setCount(state, action));
    builder.addCase(setAttivitaSelezionata, (state, action) => setAttSel(state, action));
    builder.addCase(setAttivitaSelezionataTablet, (state, action) => setAttSelTablet(state, action));
    builder.addCase(setIdProgetto, (state, action) => setId(state, action));
    builder.addCase(saveattivitapost, (state, action) => saveAttPost(state, action));
    builder.addCase(titleSet, (state, action) => setTitleCurrExtra(state, action));
    builder.addCase(setEdit, (state, action) => editSet(state, action));
    builder.addCase(setCodiceMecc, (state, action) => codiceMecc(state, action));
    builder.addCase(resetAttivita, (state, action) => resetAtt(state, action));
    builder.addCase(setDescrizioniSottoAttivita, (state, action) => setDescrizioniSA(state, action));
    builder.addCase(saveValueProgetto, (state, action) => saveValProgetto(state, action));
    builder.addCase(saveValueProgettoInn, (state, action) => saveValProgettoInn(state, action));
    builder.addCase(setProgettoCurr, (state, action) => setProgettoCur(state, action));
    builder.addCase(setProgettoInn, (state, action) => setProgettoIn(state, action));
    builder.addCase(resetAttPost, (state, action) => resetAttivitaPost(state, action));
    builder.addCase(setIndexGestisciProgetti, (state, action) => setIndexTabsGestisciProgetti(state, action));
    builder.addCase(saveCopiaAttivita, (state, action) => saveCopiaAtt(state, action));
    builder.addCase(setIndexTabSpostamento, (state, action) => setIndTabSpost(state, action));
  },
});

export const configuration = configurationSlice.reducer;
