import { useState, useEffect } from "react";
import { Autocomplete as MuiAutocomplete, Box, FormControl, TextField, Typography } from "@mui/material";
import { ExpandCircleDownOutlined as ExpandCircleDownOutlinedIcon } from "@mui/icons-material";
import { labels } from "../../../labels";

const { APRI, NO_DATA } = labels;

export const Autocomplete = ({ label, placeholder, options, value, required, onChange, error, errorText, disabled, filterOptions }) => {
  const ariaLabel = placeholder.replaceAll(" ", "_").toLowerCase();
  const [hasFocus, setHasFocus] = useState(false);

  // Fix accessibilità
  useEffect(() => {
    const autocomplete = document.getElementsByClassName("MuiAutocomplete-input");
    if (autocomplete.length > 0) {
      for (let i = 0; i < autocomplete.length; i++) {
        autocomplete[i].removeAttribute("role");
      }
    }
    const input = document.getElementById(ariaLabel);
    if (input != null) input.setAttribute("aria-label", ariaLabel);
    // eslint-disable-next-line
  }, []);

  let availableOptions = [{ id: null, descrizione: placeholder }];
  if (options) availableOptions.push(...options);

  return (
    <>
      <Typography className="fieldLabel">
        {label}
        {required && " *"}
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: "20px" }}>
        <MuiAutocomplete
          disabled={disabled}
          disablePortal
          options={availableOptions}
          size="small"
          {...(label != null && { id: ariaLabel })}
          openText={APRI}
          noOptionsText={NO_DATA}
          clearIcon={<></>}
          popupIcon={<ExpandCircleDownOutlinedIcon {...(disabled == null && { sx: { color: error ? "#d32f2f" : "black !important" } })} />}
          renderInput={(params) => (
            <TextField
              {...(error && { sx: { "& input": { color: "#d32f2f" } } })}
              {...params}
              required={required}
              aria-label={ariaLabel}
              error={error}
              helperText={errorText}
              {...(!hasFocus && !value && { label: placeholder })}
            />
          )}
          value={value}
          onChange={onChange}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          {...(filterOptions != null && { filterOptions: filterOptions })}
          getOptionLabel={(opt) => opt.descrizione}
          renderOption={(props, option) => {
            let newProps = Object.assign({}, props);
            newProps.key = option.id;
            return (
              <Box component="li" {...newProps} {...(option.id == null && { sx: { fontStyle: "oblique", fontWeight: 600 } })}>
                {option.descrizione}
              </Box>
            );
          }}
        />
      </FormControl>
    </>
  );
};
