import { FormControlLabel, FormGroup, Switch, Grid } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { saveAttivitaFilter, saveFiltriProgetti } from "../../../../modules/configuration";

export const SwitchButton = ({ attivitaProps }) => {
  const [switc, setSwitch] = useState(false);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      checked={switc}
      onClick={() => {
        setSwitch(!switc);
      }}
    />
  ))(({ theme }) => ({
    width: "75px",
    height: "35px",
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      marginLeft: "-6px",
      transitionDuration: "300ms",
      width: "54px",
      color: "#373737",
      "&.Mui-checked": {
        transform: "translateX(33px)",
        color: "#8E2155",

        "& + .MuiSwitch-track": {
          backgroundColor: "#EEB054",
          opacity: 1,
          border: "1px solid #EEB054",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: "30px",
      height: "30px",
    },

    "& .MuiSwitch-track": {
      borderRadius: 40 / 2,
      backgroundColor: "#F0F0F0",
      border: "1px solid #373737",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
    ".MuiSwitch-track": {
      height: "94%",
    },
  }));
  const dispatch = useDispatch();
  const attivita = attivitaProps;
  let obbligatorio = true;
  let attivitaFilter;

  const filterObb = (attivita) => {
    attivitaFilter = obbligatorio === true ? attivita.filter((opt) => opt.obbligatorieta === 1) : attivita;
    dispatch(saveFiltriProgetti(true));
    dispatch(saveAttivitaFilter(attivitaFilter));
    obbligatorio = !obbligatorio;
  };
  if (switc === true) {
    filterObb(attivita);
  } else {
    dispatch(saveAttivitaFilter(attivita));
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6} xl={6}>
        <FormGroup>
          <FormControlLabel
            control={<IOSSwitch sx={{ margin: "15px" }} />}
            label="Mostra solo le attività obbligatorie"
            sx={{
              color: "#000000",
            }}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};
