import { Grid, Skeleton } from "@mui/material";

const RectangularSkeleton = (props) => <Skeleton variant="rectangular" {...props} />;

const FormSkeleton = () => <RectangularSkeleton sx={{ height: "30px" }} />;

export const SkeletonAnagrafica = () => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={4}>
      <FormSkeleton />
    </Grid>
    <Grid item xs={12} md={4}>
      <FormSkeleton />
    </Grid>
    <Grid item xs={12} md={4}>
      <FormSkeleton />
    </Grid>
    <Grid item xs={12} md={4}>
      <RectangularSkeleton sx={{ height: "150px" }} />
    </Grid>
    <Grid item xs={12} md={4} sx={{ display: { xs: "block", md: "flex" } }}>
      <Grid container spacing={2} sx={{ alignContent: { xs: "normal", md: "space-between" } }}>
        <Grid item xs={12}>
          <FormSkeleton />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSkeleton />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSkeleton />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={4} sx={{ display: { xs: "block", md: "flex" } }}>
      <Grid container spacing={2} sx={{ alignContent: { xs: "normal", md: "space-between" } }}>
        <Grid item xs={12}>
          <FormSkeleton />
        </Grid>
        <Grid item xs={12}>
          <FormSkeleton />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={4}>
      <RectangularSkeleton sx={{ height: "150px" }} />
    </Grid>
    <Grid item xs={12} md={4} sx={{ display: { xs: "block", md: "flex" } }}>
      <Grid container spacing={2} sx={{ alignContent: { xs: "normal", md: "space-between" } }}>
        <Grid item xs={12}>
          <FormSkeleton />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSkeleton />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSkeleton />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={4} sx={{ display: { xs: "block", md: "flex" } }}>
      <Grid container spacing={2} sx={{ alignContent: { xs: "normal", md: "space-between" } }}>
        <Grid item xs={12}>
          <FormSkeleton />
        </Grid>
        <Grid item xs={12}>
          <FormSkeleton />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <RectangularSkeleton sx={{ marginTop: "25px", height: "40px" }} />
    </Grid>
  </Grid>
);
