export const Inserisci = (props) => (
  <svg viewBox="0 0 78 93.52" {...props}>
    <g>
      <path
        d="m77.99,27.58c0-.09,0-.18-.03-.26-.01-.07-.04-.13-.06-.2-.02-.07-.04-.14-.06-.21-.03-.07-.07-.13-.1-.2-.03-.06-.06-.12-.1-.18-.05-.08-.11-.14-.17-.21-.03-.04-.06-.08-.09-.11L59.44,7.73s0,0-.01-.01c-.07-.07-.15-.14-.24-.2-.03-.02-.06-.05-.09-.08-.08-.06-.17-.1-.26-.14-.04-.02-.07-.04-.11-.06-.03-.01-.05-.02-.08-.03-.11-.04-.21-.08-.32-.1-.04,0-.08,0-.11-.01-.1-.02-.21-.03-.31-.03,0,0-.01,0-.02,0H10.04c-1.2,0-2.17.97-2.17,2.17v82.1c0,1.2.97,2.17,2.17,2.17h65.79c1.2,0,2.17-.97,2.17-2.17V27.73c0-.05-.01-.1-.01-.15Zm-17.93-12.98l10.63,10.95h-10.63v-10.95Zm13.59,74.56H12.21V11.42h43.5v16.31c0,1.2.97,2.17,2.17,2.17h15.77v59.27Z"
      />
      <rect fill="#111" x="21.83" y="34.59" width="42.41" height="4.35" />
      <rect fill="#111" x="21.83" y="44.95" width="42.41" height="4.35" />
      <rect fill="#111" x="21.83" y="55.31" width="42.41" height="4.35" />
      <rect fill="#111" x="21.83" y="65.67" width="42.41" height="4.35" />
      <rect fill="#111" x="21.83" y="75.7" width="42.41" height="4.35" />
      <path
        fill="#111"
        d="m7.86,82.1h-3.51V4.35h43.5v5.44h4.35v-2.25l2.18,2.25h6.06L51.58.66s0,0-.01-.01c-.07-.07-.15-.14-.24-.2-.03-.02-.06-.05-.09-.08-.08-.06-.17-.1-.26-.14-.04-.02-.07-.04-.11-.06-.03-.01-.05-.02-.08-.03-.11-.04-.21-.08-.32-.1-.04,0-.08,0-.11-.01-.1-.02-.21-.03-.31-.03,0,0-.01,0-.02,0H2.17C.97,0,0,.97,0,2.17v82.1c0,1.2.97,2.17,2.17,2.17h5.69v-4.35Z"
      />
    </g>
  </svg>
);
