import RadioGroup from "@mui/material/RadioGroup";
import { Button, Radio, Paper } from "@mui/material";
import { setFilterImieiP } from "../../../modules/configuration/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { MyFormControlLabel } from "./StyleDropdown";
import { labels } from "../../../labels";

const { BUTTON_PULISCI_FILTRI, BUTTON_APPLICA_FILTRI } = labels;

export const Referente = (props) => {
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);

  return (
    <Paper className="dropdown">
      <RadioGroup name="use-radio-group" sx={{ margin: "15px", "& .MuiTypography-root": { textAlign: "left" } }} aria-label="referente">
        <MyFormControlLabel
          value="mieiProgetti"
          label="Referenti in ordine alfabetico"
          control={<Radio onClick={() => setCheck(true)} checked={check} />}
        />
      </RadioGroup>
      <Button variant="contained" type="submit" form="myform" className="buttonPulisci" onClick={() => setCheck(false)}>
        {BUTTON_PULISCI_FILTRI}
      </Button>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonApplica"
        onClick={() => {
          dispatch(props.dropDown);
          dispatch(setFilterImieiP(check));
        }}
      >
        {BUTTON_APPLICA_FILTRI}
      </Button>
    </Paper>
  );
};
