import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import { HomeIcon, InserisciIcon } from "../../../../../styles/icons";
import { getCodiceMecc, getIdProgetto } from "../../../../../modules/configuration";
import { getProgettiCurricolariExtraCurricolari } from "../../../../../modules/datiProgetti";
import { labels } from "../../../../../labels";
import { LoadingSpinner } from "../../../../shared";
import { PATH_HOME } from "../../../../../path";
import { SkeletonMainInnovazione } from "./SkeletonMainInnovazione";

const { PROGETTI_INNOVAZIONE, BUTTON_HOME } = labels;

export const SkeletonInnovazione = () => {
  const navigate = useNavigate();
  const datiProgetto = useSelector(getIdProgetto);
  const progetti = useSelector(getProgettiCurricolariExtraCurricolari);
  const data = progetti.data;
  const codiceMecc = useSelector(getCodiceMecc);

  const checkId = (data) => {
    const checkID = data.id_progetto === datiProgetto;
    const checkCodMecc = data.codice_scuola === codiceMecc;

    return checkID && checkCodMecc;
  };

  let dataFilter = data.filter(checkId);
  let progetto = dataFilter[0];

  return (
    <Box className="inserimentoProgetto">
      <Box className="header">
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10} md={11} className="leftElement">
            <Stack className="customIcon">
              <InserisciIcon />
            </Stack>
            <Typography className="titlePage" variant="h1">
              {PROGETTI_INNOVAZIONE}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2} md={1} className="buttonHome">
            <IconButton aria-label="Home" onClick={() => navigate(PATH_HOME)}>
              <HomeIcon />
            </IconButton>
            <Link onClick={() => navigate(PATH_HOME)} className="link">
              {BUTTON_HOME}
            </Link>
          </Grid>
        </Grid>
        <Divider className="dividerHeaderProgetti" />
      </Box>
      <SkeletonMainInnovazione />
      {progetto != null && <LoadingSpinner loading={!progetti.fetchEnded} />}
    </Box>
  );
};
