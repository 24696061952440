import { FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRadioGroup } from "@mui/material/RadioGroup";

export const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: "black",
  },
  ".MuiButtonBase-root-MuiRadio-root.Mui-checked": {
    color: "black",
  },
}));

export const MyFormControlLabel = (props) => {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) checked = radioGroup.value === props.value;

  return <StyledFormControlLabel checked={checked} {...props} />;
};
