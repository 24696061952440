import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RadioGroup from "@mui/material/RadioGroup";
import { Button, Radio, Paper } from "@mui/material";
import { getFilterProvincia, setDropDown2, setFilterProvincia } from "../../../modules/configuration";
import { MyFormControlLabel } from "./StyleDropdown";
import { labels } from "../../../labels";

const { BUTTON_PULISCI_FILTRI, BUTTON_APPLICA_FILTRI } = labels;

export const Provincia = () => {
  const dispatch = useDispatch();
  const provincia = useSelector(getFilterProvincia);
  const [check, setCheck] = useState(provincia);

  return (
    <Paper className="dropdown">
      <RadioGroup name="use-radio-group" sx={{ margin: "15px", "& .MuiTypography-root": { textAlign: "left" } }} aria-label="provincia">
        <MyFormControlLabel value="ordine" label="Ordine alfabetico" control={<Radio onClick={() => setCheck(true)} checked={check} />} />
      </RadioGroup>
      <Button variant="contained" type="submit" form="myform" className="buttonPulisci" onClick={() => setCheck(false)}>
        {BUTTON_PULISCI_FILTRI}
      </Button>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonApplica"
        onClick={() => {
          dispatch(setDropDown2());
          dispatch(setFilterProvincia(check));
        }}
      >
        {BUTTON_APPLICA_FILTRI}
      </Button>
    </Paper>
  );
};
