export const PROFILO_COGNOME_API = "cognome";
export const PROFILO_NOME_API = "nome";
export const PROFILO_CODICE_FISCALE_API = "codice_fiscale";
export const PROFILO_EMAIL_API = "email";
export const PROFILO_CELLULARE_API = "num_cellulare";
export const PROFILO_BIOGRAFIA_API = "biografia";
export const PROFILO_AVATAR_API = "immagine_profilo";
export const PROFILO_SOCIAL_API = "link_social";
export const PROFILO_ISCRIZIONE_NEWSLETTER_API = "newsletter";
export const PROFILO_DISABILITATO_API = "disabilitato";
export const PROFILO_DATI_CONFERMATI_API = "confermato";
export const PROFILO_TEAM_API = "id_team";
export const PROFILO_CODICE_SCUOLA_API = "codice_scuola";
export const PROFILO_NOME_SCUOLA_API = "nome_scuola";
export const PROFILO_CODICE_INCARICO_API = "id_incarico";
export const PROFILO_INCARICO_API = "descr_incarico";
export const PROFILO_INCARICO_IN_APPROVAZIONE_API = "confermato";
export const PROFILO_RUOLO_API = "ruoli";
export const PROFILO_DATA_INSERIMENTO_API = "data_inserimento";
export const PROFILO_DATA_DISABILITAZIONE_API = "data_disabilitazione";
// Valori JSON
export const PROFILO_COGNOME = "cognome";
export const PROFILO_NOME = "nome";
export const PROFILO_INIZIALI = "iniziali";
export const PROFILO_CODICE_FISCALE = "cf";
export const PROFILO_EMAIL = "email";
export const PROFILO_CELLULARE = "cellulare";
export const PROFILO_BIOGRAFIA = "biografia";
export const PROFILO_AVATAR = "avatar";
export const PROFILO_SOCIAL = "social";
export const PROFILO_ISCRIZIONE_NEWSLETTER = "iscrizioneNewsletter";
export const PROFILO_DISABILITATO = "disabilitato";
export const PROFILO_DATI_CONFERMATI = "datiConfermati";
export const PROFILO_TEAM = "codiceTeam";
export const PROFILO_CODICE_SCUOLA = "codiceScuola";
export const PROFILO_NOME_SCUOLA = "scuola";
export const PROFILO_CODICE_INCARICO = "idIncarico";
export const PROFILO_INCARICO = "incarico";
export const PROFILO_INCARICO_IN_APPROVAZIONE = "ruoloInAttesaDiApprovazione";
export const PROFILO_RUOLO = "ruolo";
export const PROFILO_DATA_INSERIMENTO = "dataInserimento";
export const PROFILO_DATA_DISABILITAZIONE = "dataDisabilitazione";
// Tipologia ruoli
export const RUOLO_AMM = "SDG_AMM";
export const RUOLO_GES = "SDG_GES";
export const RUOLO_SUP = "SDG_SUP";
export const RUOLO_IST = "SDG_IST";
