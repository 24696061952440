export const Dettagli = (props) => (
  <svg  width="47.5" height="56" viewBox="0 0 124.41 62.98" {...props}>
    <g>
      <g>
        <path
          d="m123.71,29.7c-1.12-1.21-27.84-29.7-61.5-29.7S1.82,28.49.7,29.7c-.93,1.01-.93,2.57,0,3.58,1.12,1.21,27.84,29.7,61.5,29.7s60.38-28.49,61.5-29.7c.93-1.01.93-2.57,0-3.58Zm-117.36,1.79c5.04-4.88,20.3-18.37,
          39.61-23.85-7.62,5.2-12.63,13.95-12.63,23.86s5.01,18.64,12.62,23.85C26.66,49.85,11.4,36.36,6.35,31.49Zm31.37,0c0-13.5,10.98-24.48,24.48-24.48s24.48,10.98,24.48,24.48-10.98,24.48-24.48,24.48-24.48-10.98-24.48-24.48Zm40.73,23.85c7.61-5.2,12.62-13.95,12.62-23.85s-5.01-18.64-12.62-23.85c19.29,5.49,34.55,18.97,39.6,23.85-5.05,4.87-20.31,18.36-39.6,23.85Z"
        />
        <path d="m62.2,15.33c-8.93,0-16.16,7.24-16.16,16.16s7.24,16.16,16.16,16.16,16.16-7.24,16.16-16.16-7.24-16.16-16.16-16.16Zm5.19,16.16c-2.86,0-5.19-2.32-5.19-5.18s2.32-5.19,5.19-5.19,5.18,2.32,5.18,5.19-2.32,5.18-5.18,5.18Z" />
      </g>
    </g>
  </svg>
);
