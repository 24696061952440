import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Box } from "@mui/material";
import {
  getFilterCompletamento,
  getFilterDataInizio,
  getFilterDataInizioOrdine,
  getFilterImieiP,
  getFilterPubblicato,
  getFilterTipologia,
  getFilterTitle,
  getIndexGestisciProgetti,
  getVal1Slice,
  getVal2Slice,
  resetDropDown,
  resetValoriSlice,
} from "../../../modules/configuration";
import { Istituto, PlessiIstituto, MembriTeamIstituto } from "./Tabs";
import { TabPanel } from "../../shared/TabPanelCustom";
import { ProfiliInUscita } from "./Tabs/ProfiliInUscita";
import { getProgettiIstituto } from "../../../modules/datiProgetti";
import { TableProgetti } from "../gestisciProgetti/TableProgetti";
import { labels } from "../../../labels";
import { TableGestisciProgResp } from "../../shared/TableGestisciProgResp";
import { PATH_ISTITUTI_SCOLASTICI } from "../../../path";

const { ISTITUTO_LABEL, PLESSI_ISTITUTO, PROFILI_USCITA, PROGETTI_ISTITUTO, MEMBRI_TEAM } = labels;

export const TableResponsiveCampi = ["Completamento", "Tipologia", "Titolo", "Data inserimento", "Referente", "Pubblicazione"];

export const TableProfiliInUscita = ["Ordinamento", "Settore", "Indirizzo-qualifica", "Articolazione"];

export const TablePlessiIstituto = ["Codice meccanografico", "Denominazione plesso", "Indirizzo", "Comune"];

export const Main = (props) => {
  const dispatch = useDispatch();
  const valore1slice = useSelector(getVal1Slice);
  const valore2slice = useSelector(getVal2Slice);
  let indexTabsGestProg = useSelector(getIndexGestisciProgetti);
  const [value, setValue] = useState(indexTabsGestProg);
  const progettiIstituto = useSelector(getProgettiIstituto);
  let progetti = progettiIstituto.data;
  const filterSort = useSelector(getFilterTitle);
  const filterDataInizio = useSelector(getFilterDataInizio);
  const filterTipologia = useSelector(getFilterTipologia);
  const filterDataInizioOrdine = useSelector(getFilterDataInizioOrdine);
  const filterCompletamento = useSelector(getFilterCompletamento);
  const filterImieiP = useSelector(getFilterImieiP);
  const filterPubblicato = useSelector(getFilterPubblicato);

  //handleChange cambio Tab
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(resetDropDown());
    dispatch(resetValoriSlice());
  };

  const tabs = [
    { label: ISTITUTO_LABEL },
    { label: PLESSI_ISTITUTO },
    { label: PROFILI_USCITA },
    { label: PROGETTI_ISTITUTO },
    { label: MEMBRI_TEAM },
  ];

  //filtro tipologia
  if (filterTipologia !== "") {
    progetti = progetti.filter((opt) => opt.descr_t_progetto === filterTipologia);
  }

  //filtro riordinamento alfabetico
  if (filterSort === true) {
    function SortArray(x, y) {
      return x.titolo_progetto.localeCompare(y.titolo_progetto, "fr", {
        ignorePunctuation: true,
      });
    }
    progetti = [...progetti].sort(SortArray);
  }

  //filtro riordinamento data inserimento dec e crescente
  if (filterDataInizio === true) {
    function SortArray(x, y) {
      let varx = x.data_inserimento;
      let vary = y.data_inserimento;
      if (x.data_inserimento === null) {
        varx = "1900-01-01 00:00:00";
      }
      return varx.localeCompare(vary, "fr", {
        ignorePunctuation: true,
      });
    }
    progetti = [...progetti].sort(SortArray);

    if (filterDataInizioOrdine !== "dataMenoRec") {
      progetti = progetti.reverse();
    }
  }

  //filtro referente miei progetti
  if (filterImieiP === true) {
    function SortArray(x, y) {
      return x.referente_cogn.localeCompare(y.referente_cogn, "fr", {
        ignorePunctuation: true,
      });
    }
    progetti = [...progetti].sort(SortArray);
  }

  //filtro riordinamento completamento
  if (filterCompletamento === "Ordina dal più completo") {
    function SortArray(x, y) {
      let varx = x.completamento;
      let vary = y.completamento;
      return vary - varx;
    }
    progetti = [...progetti].sort(SortArray);
  }
  if (filterCompletamento === "Ordina dal meno completo") {
    function SortArray(x, y) {
      let varx = x.completamento;
      let vary = y.completamento;
      return varx - vary;
    }
    progetti = [...progetti].sort(SortArray);
  }

  //FILTRO PER STATO PUBBLICAZIONE
  if (filterPubblicato !== "") {
    progetti = progetti.filter((opt) => opt.descr_stato_progetto === filterPubblicato);
  }

  return (
    <Box className="istituto">
      <Tabs
        TabIndicatorProps={{
          sx: { display: "none" },
        }}
        value={value}
        onChange={handleChange}
        color="black"
        variant="fullWidth"
        aria-label="full width tabs example"
        className="tabs"
        sx={{
          "& button": {
            borderColor: props.color1,
          },
          "& button.Mui-selected": {
            backgroundColor: props.color1,
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} id={"full-width-tab-" + index} aria-controls={"full-width-tabpanel-" + index} className="textTab" />
        ))}
      </Tabs>

      <TabPanel value={value} index={0}>
        <Istituto color1={props.color1} color2={props.color2} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <PlessiIstituto color2={props.color2} valore1slice={valore1slice} valore2slice={valore2slice} color1={props.color1} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ProfiliInUscita color2={props.color2} valore1slice={valore1slice} valore2slice={valore2slice} color1={props.color1} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TableGestisciProgResp
          indexTabs={value}
          azioni={true}
          tabIstituti={true}
          borderColor={props.color1}
          color={props.color2}
          progetti={progetti}
          path={PATH_ISTITUTI_SCOLASTICI}
        />
        <TableProgetti
          istituto={true}
          valore1slice={valore1slice}
          valore2slice={valore2slice}
          azioni={true}
          progetti={progetti}
          tabIstituti={true}
          color2={props.color2}
          color1={props.color1}
          indexTabs={value}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <MembriTeamIstituto color1={props.color1} />
      </TabPanel>
    </Box>
  );
};
