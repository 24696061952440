import { configureStore } from "@reduxjs/toolkit";
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { createReducers } from "./appReducers";
import { authMiddleware } from "../../modules/auth";
import { commonMiddleware } from "../../modules/common";
import { profiloMiddleware } from "../../modules/profilo";
import { progettiMiddleware } from "../../modules/datiProgetti";
import { datiIstitutoMiddleware } from "../../modules/istituti";
import { datiOsservatorioMiddleware } from "../../modules/datiOsservatorio";

const createStore = (whitelist = ["common"]) => {
  const allReducers = createReducers(whitelist);
  const store = configureStore({
    reducer: allReducers,
    middleware: (getDefaultMiddleware) => {
      let appSagas = getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          // ignoredActionPaths: ["payload.value", "payload.dataDiNascita"],
          // ignoredPaths: ["language.messages"],
        },
      });
      appSagas = appSagas.prepend(
        authMiddleware.middleware,
        commonMiddleware.middleware,
        profiloMiddleware.middleware,
        progettiMiddleware.middleware,
        datiIstitutoMiddleware.middleware,
        datiOsservatorioMiddleware.middleware
      );
      return appSagas;
    },
    devutility: process.env.NODE_ENV !== "production",
  });
  const persistor = persistStore(store);
  return { store, persistor };
};

const { store, persistor } = createStore();

export { store, persistor };
