import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";
import { getUserInfoSuccess, isUserAuthenticatedSuccess, isUserAuthenticatedFailed, fetchSessionInfo } from "./index";

export const initialState = {
  userAuthenticated: null,
  userInfo: null,
  sessionTime: null,
};

const isUserAuthenticatedSuccessHandler = (state) => {
  return update(state, {
    userAuthenticated: { $set: true },
  });
};

const isUserAuthenticatedFailedHandler = (state) => {
  return update(state, {
    userAuthenticated: { $set: false },
  });
};

const getUserInfoSuccessHandler = (state, action) => {
  return update(state, {
    userInfo: { $set: action.payload },
  });
};

const fetchSessionInfoHandler = (state, action) => {
  return update(state, {
    sessionTime: { $set: action.payload },
  });
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(isUserAuthenticatedSuccess, (state) => isUserAuthenticatedSuccessHandler(state));
    builder.addCase(isUserAuthenticatedFailed, (state) => isUserAuthenticatedFailedHandler(state));
    builder.addCase(getUserInfoSuccess, (state, action) => getUserInfoSuccessHandler(state, action));
    builder.addCase(fetchSessionInfo, (state, action) => fetchSessionInfoHandler(state, action));
  },
});

export const authReducer = authSlice.reducer;
