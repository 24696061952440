import { Grid, Skeleton } from "@mui/material";

const RectangularSkeleton = (props) => <Skeleton variant="rectangular" {...props} />;

const CircularSkeleton = (props) => <Skeleton variant="circular" {...props} />;

export const SkeletonDatiMultimedia = () => (
  <Grid item xs={12} className="datiMultimedia">
    <Grid container spacing={2} sx={{ display: "flex", alignItems: "center" }}>
      <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
        <CircularSkeleton sx={{ height: "200px", width: "200px" }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RectangularSkeleton />
          </Grid>
          <Grid item xs={12}>
            <RectangularSkeleton />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="biografia">
        <RectangularSkeleton sx={{ height: "300px" }} />
      </Grid>
    </Grid>
  </Grid>
);
