import { useState, useEffect } from "react";
import { FormControl, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";

export const Input = ({
  label,
  placeholder,
  value,
  disabled,
  required,
  onChange,
  error,
  errorText,
  helperText,
  multiline,
  rows,
  maxLength,
  startAdornment,
  numeroCaratteri,
}) => {
  const ariaLabel = placeholder.replaceAll(" ", "_").toLowerCase();
  const [hasFocus, setHasFocus] = useState(false);

  // Fix accessibilità
  useEffect(() => {
    const input = document.getElementById(ariaLabel);
    if (input != null) input.setAttribute("aria-label", ariaLabel);

    const inputs = document.getElementsByTagName("input");
    if (inputs.length > 0)
      for (var j = 0; j < inputs.length; j++) {
        inputs[j].removeAttribute("aria-invalid");
      }

    const textareas = document.getElementsByTagName("textarea");
    if (textareas.length > 0)
      for (var i = 0; i < textareas.length; i++) {
        textareas[i].setAttribute("aria-label", ariaLabel);
        textareas[i].removeAttribute("aria-invalid");
      }
    // eslint-disable-next-line
  }, []);

  // Fix accessibilità
  useEffect(() => {
    if (error) {
      const inputs = document.getElementsByTagName("input");
      if (inputs.length > 0) {
        for (var i = 0; i < inputs.length; i++) {
          inputs[i].removeAttribute("aria-invalid");
        }
      }

      const textareas = document.getElementsByTagName("textarea");
      if (textareas.length > 0)
        for (var k = 0; k < textareas.length; k++) {
          textareas[k].removeAttribute("aria-invalid");
        }
    }
    // eslint-disable-next-line
  }, [error]);

  return (
    <>
      <Typography className="fieldLabel">
        {label}
        {required && " *"}
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: "20px" }}>
        <TextField
          multiline={multiline}
          inputProps={{
            maxLength: numeroCaratteri,
          }}
          value={value}
          variant="outlined"
          {...(label != null && { id: ariaLabel })}
          {...(!hasFocus && value === "" && { label: placeholder })}
          onChange={onChange}
          disabled={disabled}
          size="small"
          error={error}
          helperText={error ? errorText : helperText}
          {...(disabled && { variant: "filled" })}
          onFocus={() => setHasFocus(true)}
          onBlur={(e) => {
            setHasFocus(false);
          }}
          rows={rows}
          {...(maxLength && {
            inputProps: {
              maxLength: maxLength,
            },
          })}
          {...(multiline == null && {
            InputProps: {
              ...((hasFocus || (value != null && value !== "")) && { startAdornment: startAdornment }),
              ...(value != null &&
                value !== "" && {
                  endAdornment: (
                    <InputAdornment position="end" sx={{ marginRight: "20px" }}>
                      <IconButton title="Cancella" edge="end" onClick={() => onChange({ target: { value: "" } })}>
                        <CancelOutlinedIcon sx={{ color: "black" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }),
            },
          })}
        />
        {numeroCaratteri != null && (
          <Typography sx={{ textAlign: "right", paddingTop: "5px", fontSize: "0.75rem !important", marginBottom: "-23px" }}>
            N° caratteri inseriti {value.length} / {numeroCaratteri}
          </Typography>
        )}
      </FormControl>
    </>
  );
};
