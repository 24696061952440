import { ExpandCircleDownOutlined as ExpandCircleDownOutlinedIcon } from "@mui/icons-material";
import { Typography, Grid, Box, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { CardButton } from "./CardButton";
import { RotellaBozzaIcon, RotellaOsservatorioIcon, RotellaMappaIcon } from "../../../styles/icons";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { changetabs } from "../../../modules/configuration/actions";
import { getIdProgetto, getIndexTabSpostamento, getProgressBar } from "../../../modules/configuration/selectors";
import { FooterInserisciProgetti } from "./FooterInserisciProgetti";
import { labels } from "../../../labels";
import { getInfoProfilo } from "../../../modules/profilo";
import { getStatiPubblicazione } from "../../../modules/common";
import { ModaleSpostamentoTab } from "./ModaleSpostamentoTab";
import { POST_PUBBLICAZIONE, PUT_SBLOCCO_MODIFICA } from "../../../apiUrl";
import { notificationCodes, setNotification } from "../../../modules/notification";
import { createRequest } from "../../../utility";
import { resetIndex, setButtTypeFooterInsProg } from "../../../modules/configuration";

const {
  STATO_PUBBLICAZIONE,
  PUBBLICAZIONE_BOZZA,
  PUBBLICAZIONE_BOZZA_DESCRIZIONE,
  PUBBLICAZIONE_MAPPA,
  PUBBLICAZIONE_MAPPA_DESCRIZIONE,
  PUBBLICAZIONE_OSSERVATORIO,
  PUBBLICAZIONE_OSSERVATORIO_DESCRIZIONE,
} = labels;

export const Pubblicazione = (props) => {
  let progetto = props.progetto;
  const [progress, setProgress] = useState(20);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const dispatch = useDispatch();
  const percentuale = useSelector(getProgressBar);
  const [disabledCondividi, setDisabledCondividi] = useState(true);
  const pubblicazione = useSelector(getStatiPubblicazione);
  const pubblicazioneOptions = pubblicazione;
  const idProg = useSelector(getIdProgetto);
  const profilo = useSelector(getInfoProfilo);
  const codiceScuola = profilo.codiceScuola;
  let idData = progetto.data === undefined ? progetto.uuid_progetto : idProg;
  let statoProgetto = progetto.data === undefined ? progetto.descr_stato_progetto : "BOZZA";
  const index = useSelector(getIndexTabSpostamento);

  const checkState = (scelta) => {
    if (scelta.descrizione === "BOZZA") {
      setDisabledCondividi(true);
      setProgress(20);
      setVisible1(false);
      setVisible2(false);
    }
    if (scelta.descrizione === "OSSERVATORIO") {
      setDisabledCondividi(false);
      setProgress(50);
      setVisible1(true);
      setVisible2(false);
    }
    if (scelta.descrizione === "MAPPA") {
      setDisabledCondividi(false);
      setProgress(100);
      setVisible1(false);
      setVisible2(true);
    }
  };

  useEffect(() => {
    if (progetto.data === undefined) {
      let scelta = {
        descrizione: progetto.descr_stato_progetto,
      };
      checkState(scelta);
    } // eslint-disable-next-line
  }, [progetto]);

  const formik = useFormik({
    initialValues: {
      pubblicazione: statoProgetto,
    },
    onSubmit: () => {
      dispatch(resetIndex());
      createRequest(POST_PUBBLICAZIONE.method, POST_PUBBLICAZIONE.baseUrl, POST_PUBBLICAZIONE.apiUrl, null, {
        codice_scuola: codiceScuola,
        uuid_progetto: idData,
        id_stato_progetto: formik.values["pubblicazione"] === "BOZZA" ? 1 : formik.values["pubblicazione"] === "MAPPA" ? 3 : 2,
        completamento: props.percentuale > 100 ? 100 : percentuale,
      })
        .then((response) => {
          if (response.status === 200) {
            createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
              uuid_progetto: props.idData,
            })
              .then((response) => {
                if (response.status === 200) {
                  dispatch(setButtTypeFooterInsProg("SALVA"));
                  let versione = true;
                  dispatch(changetabs({ index, versione }));
                }
              })
              .catch((e) => {
                dispatch(
                  setNotification({
                    tipo: notificationCodes.ERROR,
                    message: "Errore durante il cambio stato del progetto in modifica",
                    description: "Errore durante il cambio stato del progetto in modifica",
                  })
                );
              });
          }
        })
        .catch((e) => {
          dispatch(
            setNotification({
              tipo: notificationCodes.ERROR,
              message: "Errore durante il salvataggio dello stato di pubblicazione ",
              description: "Si è verificato un errore imprevisto nel salvataggio dello stato di pubblicazione",
            })
          );
        });
    },
  });

  // Fix accessibilità
  useEffect(() => {
    const selects = document.getElementsByClassName("MuiSelect-select");
    if (selects.length > 0) {
      for (let i = 0; i < selects.length; i++) {
        selects[i].removeAttribute("role");
      }
    }
    const customSelects = document.getElementsByClassName("selectPubblicazione");
    if (customSelects.length > 0)
      for (var i = 0, max = customSelects.length; i < max; i++) {
        const selects = customSelects[i].getElementsByClassName("MuiSelect-select");
        if (selects.length > 0) {
          const ariaLabel = selects[0].getAttribute("aria-labelledby");
          const nativeSelect = customSelects[i].getElementsByClassName("MuiSelect-nativeInput");
          if (nativeSelect.length > 0)
            for (var j = 0; j < nativeSelect.length; j++) {
              nativeSelect[j].setAttribute("aria-label", ariaLabel.replace("label_", ""));
            }
        }
      }
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} id="myform">
      <ModaleSpostamentoTab
        stateModal={props.modal}
        setModalSpostamentoTab={props.setModalSpostamentoTab}
        onSave={formik.handleSubmit}
        uuid={progetto.data === undefined ? progetto.uuid_progetto : idProg}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} xl={5}>
          <Typography className="fontBold">{STATO_PUBBLICAZIONE}</Typography>
          <Select
            className="selectPubblicazione"
            renderValue={() => formik.values["pubblicazione"]}
            value={formik.values["pubblicazione"]}
            name={"pubblicazione"}
            onChange={(e) => {
              checkState(e.target.value);
              let ob = e.target.value;
              let val = ob.descrizione;
              formik.setFieldValue("pubblicazione", val);
            }}
            IconComponent={ExpandCircleDownOutlinedIcon}
          >
            {pubblicazioneOptions.map((opt, index) => {
              return (
                <MenuItem value={opt} key={index}>
                  {opt.descrizione}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Grid container sx={{ marginTop: "-150px", "@media (max-width:900px)": { marginTop: "-110px" } }}>
            <Grid item xs={12} sm={6} md={6} xl={5}>
              <Box
                sx={{
                  marginTop: "160px",
                  "@media (max-width:480px)": {
                    position: visible2 ? "absolute" : "none",
                    display: visible2 ? "block" : "none",
                    marginTop: "370px",
                    marginLeft: "0px",
                    zIndex: 3,
                  },
                }}
              >
                <Typography
                  className="fontBold"
                  sx={{
                    cursor: "default",
                    textAlign: "center",
                  }}
                >
                  {PUBBLICAZIONE_MAPPA}
                </Typography>
                <Typography sx={{ cursor: "default", textAlign: "center" }}>{PUBBLICAZIONE_MAPPA_DESCRIZIONE}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2} md={2} xl={2}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    "@media (max-width:480px)": {
                      marginTop: "60px",
                    },
                  }}
                >
                  {progress === 20 ? <RotellaBozzaIcon /> : null}
                  {progress === 50 ? <RotellaOsservatorioIcon /> : null}
                  {progress === 100 ? <RotellaMappaIcon /> : null}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    cursor: "default",
                    textAlign: "center",
                    "@media (max-width:480px)": {
                      position: visible1 ? "absolute" : "none",
                      display: visible1 ? "block" : "none",
                      marginTop: "355px",
                      paddingRight: "50px",
                      zIndex: 3,
                    },
                  }}
                >
                  <Typography
                    className="fontBold"
                    sx={{
                      cursor: "default",
                      textAlign: "center",
                    }}
                  >
                    {PUBBLICAZIONE_OSSERVATORIO}
                  </Typography>
                  <Typography sx={{ cursor: "default", textAlign: "center" }}>{PUBBLICAZIONE_OSSERVATORIO_DESCRIZIONE}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={5}>
              <Box
                sx={{
                  marginTop: "150px",
                  "@media (max-width:900px)": { marginTop: "20px" },
                  "@media (max-width:480px)": {
                    display: visible2 || visible1 ? "none" : "block",
                    marginTop: "-40px",
                    marginBottom: "20px",
                  },
                }}
              >
                <Typography
                  className="fontBold"
                  sx={{
                    cursor: "default",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {PUBBLICAZIONE_BOZZA}
                </Typography>
                <Typography sx={{ cursor: "default", textAlign: "center" }}>{PUBBLICAZIONE_BOZZA_DESCRIZIONE}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ marginTop: visible2 || visible1 ? "80px" : "0px" }}>
        <CardButton disabledCondividi={disabledCondividi} uuid={idData} />
      </Grid>
      <FooterInserisciProgetti
        idPubblicazione={formik.values["pubblicazione"]}
        idData={idData}
        codiceScuola={codiceScuola}
        onSave={formik.handleSubmit}
        avviso={" Innovazione"}
        formik={formik}
        pubblicazione={true}
        percentuale={percentuale}
      />
    </form>
  );
};
