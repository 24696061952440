export const MappaHome = (props) => (
  <svg viewBox="0 0 97.55 107.61" {...props}>
    <g>
      <path
        fill="#8E2155"
        d="m97.52,106l-10.5-45.28c-.14-.6-.67-1.02-1.28-1.02h-21.67c-.73,0-1.32.59-1.32,1.32s.59,1.32,1.32,1.32h20.63l9.89,42.65H2.98l9.89-42.65h20.42c.73,0,1.32-.59,1.32-1.32s-.59-1.32-1.32-1.32H11.82c-.61,0-1.14.42-1.28,1.02L.03,106c-.09.39,0,.8.25,1.12.25.31.63.5,1.03.5h94.92c.4,0,.78-.18,1.03-.5.25-.31.34-.73.25-1.12Z"
      />
      <path
        fill="#8E2155"
        d="m50.36,75.31c6.77-9.03,23.66-35.46,23.66-50.07S62.71,0,48.78,0s-25.24,11.3-25.24,25.24,16.89,41.04,23.66,50.07c.79,1.05,2.37,1.05,3.16,0Zm-16.75-49.3c0-8.38,6.79-15.17,15.17-15.17s15.17,6.79,15.17,15.17-6.79,15.17-15.17,15.17-15.17-6.79-15.17-15.17Z"
      />
      <path
        fill="#8E2155"
        d="m63.26,92.81c.11,0,.23-.02.34-.05,1.32-.38,2.61-.89,3.81-1.5.61-.31.86-1.06.55-1.67-.31-.61-1.06-.86-1.67-.55-1.07.54-2.2.99-3.38,1.33-.66.19-1.04.88-.85,1.54.16.54.65.9,1.19.9Z"
      />
      <path
        fill="#8E2155"
        d="m41.93,85.1c-.61-.31-1.36-.07-1.67.55-.31.61-.07,1.36.55,1.67,1.08.55,2.06,1.16,3.2,1.91.21.14.45.2.68.2.4,0,.8-.2,1.04-.56.38-.57.22-1.34-.35-1.72-1.21-.79-2.26-1.45-3.44-2.05Z"
      />
      <path
        fill="#8E2155"
        d="m51.99,90.67c-.66-.2-1.35.18-1.54.84-.19.66.18,1.35.84,1.54,1.3.38,2.64.6,4.09.67.02,0,.04,0,.06,0,.66,0,1.2-.52,1.24-1.18.03-.68-.49-1.27-1.18-1.3-1.26-.06-2.41-.25-3.52-.58Z"
      />
      <path
        fill="#8E2155"
        d="m73.98,86.16c.88-.99,1.7-2.08,2.45-3.24.37-.58.21-1.34-.37-1.71-.58-.37-1.34-.21-1.71.37-.68,1.06-1.43,2.04-2.22,2.93-.46.51-.41,1.3.1,1.75.24.21.53.31.82.31.34,0,.68-.14.93-.42Z"
      />
      <path
        fill="#8E2155"
        d="m78.76,76.35c.52,0,1-.33,1.18-.85.54-1.63.87-3.01,1.04-3.89.14-.67-.29-1.33-.97-1.46-.67-.14-1.33.3-1.46.97-.17.81-.47,2.09-.97,3.61-.21.65.14,1.35.79,1.57.13.04.26.06.39.06Z"
      />
      <path
        fill="#8E2155"
        d="m33.4,82.81c-1.21-.08-2.4-.07-3.56.03-.68.06-1.18.67-1.12,1.35.06.68.66,1.19,1.35,1.12,1.02-.09,2.08-.1,3.15-.03.16.01.33.02.49.04.04,0,.08,0,.11,0,.63,0,1.17-.48,1.23-1.13.06-.68-.44-1.29-1.12-1.35-.18-.02-.36-.03-.54-.04Z"
      />
      <path
        fill="#8E2155"
        d="m13.02,93.81c-1.32,2.03-1.95,3.55-1.97,3.61-.26.63.04,1.36.68,1.62.15.06.31.09.47.09.49,0,.95-.29,1.15-.77,0-.01.58-1.38,1.76-3.2.37-.57.21-1.34-.37-1.71s-1.34-.21-1.71.37Z"
      />
      <path
        fill="#8E2155"
        d="m21.86,85.3c-1.17.65-2.31,1.43-3.38,2.31-.53.44-.6,1.22-.17,1.75.24.3.6.45.96.45.28,0,.56-.09.79-.28.96-.79,1.97-1.48,3.01-2.06.6-.33.81-1.09.48-1.69s-1.09-.82-1.69-.48Z"
      />
    </g>
  </svg>
);
