import { Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  checkIndex,
  getButtonType,
  getEdit,
  resetDatiPost,
  resetFiltriProgetti,
  resetIndex,
  getDatiProgettoInnovazione,
  getProgressAttivita,
  saveValueProgettoInn,
  getTipoBeneficiari,
  getIdProgetto,
  setIdProgetto,
  getIndexTabSpostamento,
  changetabs,
  saveProgressBar,
} from "../../../../modules/configuration";
import { useEffect } from "react";
import { FooterInserisciProgetti } from "../FooterInserisciProgetti";
import { PATH_HOME } from "../../../../path";
import { useNavigate } from "react-router-dom";
import { labels } from "../../../../labels";
import { PROFILO_TEAM, getInfoProfilo } from "../../../../modules/profilo";
import { Autocomplete, Calendar, Input, Select } from "../../../shared";
import { getCommonStore } from "../../../../modules/common";
import { fetchAttivitaInnovazione } from "../../../../modules/datiOsservatorio";
import { valProgrInn } from "../progressFunction";
import { SelectCheckList } from "../../../shared/form/SelectCheckList";
import { POST_ANAGRAFICA_INNOVAZIONE, PUT_ANAGRAFICA_INNOVAZIONE, PUT_BLOCCO_MODIFICA, PUT_SBLOCCO_MODIFICA } from "../../../../apiUrl";
import { notificationCodes, setNotification } from "../../../../modules/notification";
import { createRequest } from "../../../../utility";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { ModaleSpostamentoTab } from "../ModaleSpostamentoTab";

const {
  REQUIRED_FIELD,
  DATA_INIZIO_PROGETTO,
  PLACEHOLDER_DATA,
  SUPPORTO_PROGETTO,
  AREA_COINVOLTA,
  PLACEHOLDER_AREA_COINVOLTA,
  FONDI_CONTESTO,
  PLACEHOLDER_FONDI_CONTESTO,
  PLACEHOLDER_SUPPORTO_DEL_PROGETTO,
  CO_RESPONSABILE,
  PLACEHOLDER_TEAM_DIGITALE,
  ORDINE_SCUOLA,
  PLACEHOLDER_ORDINE_SCUOLA,
  TITOLO_PROGETTO,
  ABSTRACT,
  TIPO_BENEFICIARI,
  PLACEHOLDER_TIPOLOGIA_BENEFICIARIO,
  NOTE,
  PLACEHOLDER_DESCRIVI,
  PLACEHOLDER_ABSTRACT,
  ANNO_REALIZZAZIONE,
  TEMPO_REALIZZAZIONE,
  PLACEHOLDER_TEMPO_REALIZZAZIONE,
  PLACEHOLDER_ANNO_REALIZZAZIONE,
  PLACEHOLDER_TITOLO_PROGETTO,
} = labels;

export const AnagraficaInnovazione = (props) => {
  let progetto = props.progetto;
  const dispatch = useDispatch();
  const allOptions = useSelector(getCommonStore);
  const optionsBeneficiariSelected = useSelector(getTipoBeneficiari);

  let allOptionsFiltered = {};

  for (const [key, value] of Object.entries(allOptions)) {
    allOptionsFiltered[key] = value.data !== null ? value.data : [];
  }

  let allOptionsFilteredFetchEnded = {};

  for (const [key, value] of Object.entries(allOptions)) {
    allOptionsFilteredFetchEnded[key] = value.fetchEnded !== null ? value.fetchEnded : [];
  }

  const datiProg = useSelector(getDatiProgettoInnovazione);
  const attivitaProgress = useSelector(getProgressAttivita);
  let progress = Math.floor(valProgrInn(datiProg) + attivitaProgress);
  progress = progress > 100 ? 100 : progress;
  dispatch(saveProgressBar(progress));

  const datiProgetto = datiProg.anagrafica;
  const idProg = useSelector(getIdProgetto);
  const buttonType = useSelector(getButtonType);
  const navigate = useNavigate();
  const edit = useSelector(getEdit);
  const index = useSelector(getIndexTabSpostamento);
  const profilo = useSelector(getInfoProfilo);
  const codiceScuola = profilo.codiceScuola;
  const idReferente = profilo[PROFILO_TEAM];
  //controllo redirect home se valori options obbligatorie vuote
  if (
    allOptionsFilteredFetchEnded.areaCoinvolta === false ||
    allOptionsFilteredFetchEnded.ordineScuola === false ||
    allOptionsFilteredFetchEnded.annoRealizzazione === false ||
    allOptionsFilteredFetchEnded.tempoRealizzazione === false ||
    allOptionsFilteredFetchEnded.fondiContesto === false
  ) {
    navigate(PATH_HOME);
  }

  useEffect(() => {
    if (edit !== true) {
      dispatch(setIdProgetto(uuidv4()));
    }
    // eslint-disable-next-line
  }, []);

  //se mi trovo in fase di modifica setto i valori di formik con i valori progetto corrispondenti
  useEffect(() => {
    formik.setFieldValue("titolo_progetto", datiProgetto["titolo_progetto"]);
    formik.setFieldValue("descr_progetto", datiProgetto["descr_progetto"]);
    formik.setFieldValue("data_inizio", datiProgetto["data_inizio"]);
    formik.setFieldValue("descr_tempo_realiz", datiProgetto["descr_tempo_realiz"]);
    formik.setFieldValue("descr_anno_realiz", datiProgetto["descr_anno_realiz"]);
    formik.setFieldValue("descr_ambiente", datiProgetto["descr_ambiente"]);
    formik.setFieldValue("descr_grado_scuola", datiProgetto["descr_grado_scuola"]);
    formik.setFieldValue("descr_fondi", datiProgetto["descr_fondi"]);
    formik.setFieldValue("beneficiari", optionsBeneficiariSelected[0] !== undefined ? optionsBeneficiariSelected[0] : optionsBeneficiariSelected);
    formik.setFieldValue("CoResponsabile", datiProgetto["CoResponsabile"]);
    formik.setFieldValue("nome_mentor", datiProgetto["nome_mentor"]);
    formik.setFieldValue("nome_mentor", datiProgetto["nome_mentor"]);
    formik.setFieldValue("annote", datiProgetto["annote"]);
    // eslint-disable-next-line
  }, [datiProgetto, optionsBeneficiariSelected]);

  //controllo data inserita
  const checkDate = () => {
    let currentTime = new Date();
    let year = currentTime.getFullYear();
    if (dayjs(datiProgetto["data_inizio"]) > dayjs("2013-01-01") && dayjs(datiProgetto["data_inizio"]) < dayjs(year + 1 + "-12-31")) {
      return true;
    }
    if (datiProgetto["data_inizio"] === "") {
      return true;
    } else return false;
  };

  const validationSchema = yup.object({
    titolo_progetto: yup.string().required(),
    descr_progetto: yup.string().required(),
    descr_tempo_realiz: yup.string().required(),
    descr_anno_realiz: yup.string().required(),
    descr_ambiente: yup.string().required(),
    descr_grado_scuola: yup.string().required(),
    descr_fondi: yup.string().required(),
    data_inizio: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      titolo_progetto: datiProgetto["titolo_progetto"],
      descr_progetto: datiProgetto["descr_progetto"],
      data_inizio: datiProgetto["data_inizio"],
      descr_tempo_realiz: datiProgetto["descr_tempo_realiz"],
      descr_anno_realiz: datiProgetto["descr_anno_realiz"],
      descr_ambiente: datiProgetto["descr_ambiente"],
      descr_grado_scuola: datiProgetto["descr_grado_scuola"],
      descr_fondi: datiProgetto["descr_fondi"],
      beneficiari: optionsBeneficiariSelected[0] !== undefined ? optionsBeneficiariSelected[0] : optionsBeneficiariSelected,
      CoResponsabile: datiProgetto["CoResponsabile"],
      nome_mentor: datiProgetto["nome_mentor"],
      annote: datiProgetto["annote"],
    },

    validationSchema: validationSchema,

    onSubmit: () => {
      dispatch(resetDatiPost());
      if (checkDate() === true) {
        let dataPost = {
          codice_scuola: codiceScuola,
          uuid_progetto: progetto.data === undefined ? progetto.uuid_progetto : idProg,
          id_tipo_progetto: 2,
          descr_progetto: formik.values.descr_progetto,
          data_inizio: formik.values.data_inizio,
          titolo_progetto: formik.values.titolo_progetto,
          id_ambiente: formik.values.descr_ambiente,
          id_grado_scuola: formik.values.descr_grado_scuola,
          id_tempo_realiz: formik.values.descr_tempo_realiz,
          id_anno_realiz: formik.values.descr_anno_realiz,
          nome_mentor: formik.values.nome_mentor,
          annote: formik.values.annote,
          id_fondi: formik.values.descr_fondi,
          id_beneficiario: datiProgetto["beneficiari"],
          id_coreferente: formik.values.CoResponsabile,
          id_referente: idReferente,
          completamento: progress,
        };

        if (edit !== true) {
          createRequest(POST_ANAGRAFICA_INNOVAZIONE.method, POST_ANAGRAFICA_INNOVAZIONE.baseUrl, POST_ANAGRAFICA_INNOVAZIONE.apiUrl, null, dataPost)
            .then((response) => {
              if (response.status === 200) {
                if (buttonType === "AVANTI") {
                  createRequest(PUT_BLOCCO_MODIFICA.method, PUT_BLOCCO_MODIFICA.baseUrl, PUT_BLOCCO_MODIFICA.apiUrl, null, { uuid_progetto: idProg })
                    .then((response) => {
                      if (response.status === 200) {
                        dispatch(checkIndex(3));
                      }
                    })
                    .catch((e) => {
                      dispatch(
                        setNotification({
                          tipo: notificationCodes.ERROR,
                          message: "Errore durante il cambio stato del progetto in modifica",
                          description: "Errore durante il cambio stato del progetto in modifica",
                        })
                      );
                    });
                } else {
                  navigate(PATH_HOME);
                  dispatch(resetFiltriProgetti());
                  dispatch(resetIndex());
                }
              }
            })
            .catch((e) => {
              dispatch(
                setNotification({
                  tipo: notificationCodes.ERROR,
                  message: "Errore durante il salvataggio dei dati della sezione anagrafica ",
                  description: "Si è verificato un errore imprevisto nel salvataggio dei dati della sezione anagrafica",
                })
              );
            });
        } else {
          createRequest(PUT_ANAGRAFICA_INNOVAZIONE.method, PUT_ANAGRAFICA_INNOVAZIONE.baseUrl, PUT_ANAGRAFICA_INNOVAZIONE.apiUrl, null, dataPost)
            .then((response) => {
              if (response.status === 200) {
                if (buttonType === "AVANTI") {
                  if (index !== null) {
                    let versione = true;
                    dispatch(changetabs({ index, versione }));
                  } else {
                    dispatch(checkIndex("3"));
                  }
                } else {
                  createRequest(PUT_SBLOCCO_MODIFICA.method, PUT_SBLOCCO_MODIFICA.baseUrl, PUT_SBLOCCO_MODIFICA.apiUrl, null, {
                    uuid_progetto: progetto.length === undefined ? progetto.uuid_progetto : idProg,
                  })
                    .then((response) => {
                      if (response.status === 200) {
                        navigate(PATH_HOME);
                        dispatch(resetFiltriProgetti());
                        dispatch(resetIndex());
                      }
                    })
                    .catch((e) => {
                      dispatch(
                        setNotification({
                          tipo: notificationCodes.ERROR,
                          message: "Errore durante il cambio stato del progetto in modifica",
                          description: "Errore durante il cambio stato del progetto in modifica",
                        })
                      );
                    });
                }
              }
            })
            .catch((e) => {
              dispatch(
                setNotification({
                  tipo: notificationCodes.ERROR,
                  message: "Errore durante il salvataggio dei dati della sezione anagrafica ",
                  description: "Si è verificato un errore imprevisto nel salvataggio dei dati della sezione anagrafica",
                })
              );
            });
        }
      }
    },
  });

  const onDatiChange = (modifiche) => {
    // eslint-disable-next-line
    modifiche.map((modifica) => {
      const { fieldName, fieldValue } = modifica;
      formik.setFieldValue(fieldName, fieldValue);
      dispatch(saveValueProgettoInn(modifica));
    });
  };

  return (
    <form onSubmit={formik.handleSubmit} id="myform">
      <ModaleSpostamentoTab
        stateModal={props.modal}
        setModalSpostamentoTab={props.setModalSpostamentoTab}
        onSave={formik.handleSubmit}
        uuid={progetto.data === undefined ? progetto.uuid_progetto : idProg}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <Input
            label={TITOLO_PROGETTO + "*"}
            placeholder={PLACEHOLDER_TITOLO_PROGETTO}
            value={formik.values.titolo_progetto}
            multiline={true}
            onChange={(e) => {
              onDatiChange([{ fieldSection: "anagrafica", fieldName: "titolo_progetto", fieldValue: e.target.value }]);
            }}
            {...(formik.touched["titolo_progetto"] &&
              Boolean(formik.errors["titolo_progetto"]) && {
                error: true,
                errorText: REQUIRED_FIELD,
              })}
          />
          <Input
            label={ABSTRACT + "*"}
            rows={8.1}
            numeroCaratteri={500}
            infoNumeroCaratteri={true}
            placeholder={PLACEHOLDER_ABSTRACT}
            value={formik.values.descr_progetto}
            multiline={true}
            onChange={(e) => {
              onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_progetto", fieldValue: e.target.value }]);
            }}
            {...(formik.touched["descr_progetto"] &&
              Boolean(formik.errors["descr_progetto"]) && {
                error: true,
                errorText: REQUIRED_FIELD,
              })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <Calendar
            tipo={"innovazione"}
            label={DATA_INIZIO_PROGETTO + "*"}
            placeholder={PLACEHOLDER_DATA}
            name={"data_inizio"}
            errorText={REQUIRED_FIELD}
            formik={formik}
            section={"anagrafica"}
            handle={formik.handleChange("data_inizio")}
          />
          <Select
            label={TEMPO_REALIZZAZIONE + "*"}
            placeholder={PLACEHOLDER_TEMPO_REALIZZAZIONE}
            options={allOptionsFiltered.tempoRealizzazioneProgetto}
            value={
              formik.values.descr_tempo_realiz !== ""
                ? allOptionsFiltered.tempoRealizzazioneProgetto.find((e) => e.id === formik.values.descr_tempo_realiz)?.id
                : null
            }
            onChange={(e, v) => {
              const { value: id } = v.props;
              onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_tempo_realiz", fieldValue: id }]);
            }}
            {...(formik.touched["descr_tempo_realiz"] &&
              Boolean(formik.errors["descr_tempo_realiz"]) && {
                error: true,
                errorText: REQUIRED_FIELD,
              })}
          />
          <Select
            label={ANNO_REALIZZAZIONE + "*"}
            placeholder={PLACEHOLDER_ANNO_REALIZZAZIONE}
            options={allOptionsFiltered.anniScolasticiRealizzazione}
            value={
              formik.values.descr_anno_realiz !== ""
                ? allOptionsFiltered.anniScolasticiRealizzazione.find((e) => e.id === formik.values.descr_anno_realiz)?.id
                : null
            }
            onChange={(e, v) => {
              const { value: id } = v.props;
              onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_anno_realiz", fieldValue: id }]);
            }}
            {...(formik.touched["descr_anno_realiz"] &&
              Boolean(formik.errors["descr_anno_realiz"]) && {
                error: true,
                errorText: REQUIRED_FIELD,
              })}
          />
          <Select
            label={AREA_COINVOLTA + "*"}
            placeholder={PLACEHOLDER_AREA_COINVOLTA}
            options={allOptionsFiltered.areeCoinvolte}
            value={
              formik.values.descr_ambiente !== "" ? allOptionsFiltered.areeCoinvolte.find((e) => e.id === formik.values.descr_ambiente)?.id : null
            }
            onChange={(e, v) => {
              const { value: id } = v.props;
              onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_ambiente", fieldValue: id }]);
              dispatch(fetchAttivitaInnovazione(id));
            }}
            {...(formik.touched["descr_ambiente"] &&
              Boolean(formik.errors["descr_ambiente"]) && {
                error: true,
                errorText: REQUIRED_FIELD,
              })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <Autocomplete
            label={ORDINE_SCUOLA + "*"}
            placeholder={PLACEHOLDER_ORDINE_SCUOLA}
            options={allOptionsFiltered.ordiniScuola}
            value={
              formik.values.descr_grado_scuola !== ""
                ? allOptionsFiltered.ordiniScuola.find((elem) => elem.id === formik.values.descr_grado_scuola)
                : null
            }
            onChange={(e, v) => {
              const { id } = v;
              onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_grado_scuola", fieldValue: id }]);
            }}
            {...(formik.touched["descr_grado_scuola"] &&
              Boolean(formik.errors["descr_grado_scuola"]) && {
                error: true,
                errorText: REQUIRED_FIELD,
              })}
          />
          <Autocomplete
            label={FONDI_CONTESTO + "*"}
            placeholder={PLACEHOLDER_FONDI_CONTESTO}
            options={allOptionsFiltered.fondiContestoProgetto}
            value={
              formik.values.descr_fondi !== "" ? allOptionsFiltered.fondiContestoProgetto.find((elem) => elem.id === formik.values.descr_fondi) : null
            }
            onChange={(e, v) => {
              const { id } = v;
              onDatiChange([{ fieldSection: "anagrafica", fieldName: "descr_fondi", fieldValue: id }]);
            }}
            {...(formik.touched["descr_fondi"] &&
              Boolean(formik.errors["descr_fondi"]) && {
                error: true,
                errorText: REQUIRED_FIELD,
              })}
          />
          <SelectCheckList
            tipo={"inn"}
            optionsSelected={optionsBeneficiariSelected}
            options={allOptionsFiltered.tipologieBeneficiarioProgetto}
            name={"beneficiari"}
            formik={formik}
            title={TIPO_BENEFICIARI}
            placeholder={PLACEHOLDER_TIPOLOGIA_BENEFICIARIO}
            errorText={REQUIRED_FIELD}
          />
          <Select
            label={CO_RESPONSABILE}
            placeholder={PLACEHOLDER_TEAM_DIGITALE}
            options={allOptionsFiltered.coResponsabili}
            value={
              formik.values.CoResponsabile !== "" ? allOptionsFiltered.coResponsabili.find((e) => e.id === formik.values.CoResponsabile)?.id : null
            }
            onChange={(e, v) => {
              const { value: id } = v.props;
              onDatiChange([{ fieldSection: "anagrafica", fieldName: "CoResponsabile", fieldValue: id }]);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={3}>
          <Input
            label={SUPPORTO_PROGETTO}
            numeroCaratteri={100}
            infoNumeroCaratteri={true}
            placeholder={PLACEHOLDER_SUPPORTO_DEL_PROGETTO}
            value={formik.values.nome_mentor}
            multiline={true}
            onChange={(e) => {
              onDatiChange([{ fieldSection: "anagrafica", fieldName: "nome_mentor", fieldValue: e.target.value }]);
            }}
          />
          <Input
            label={NOTE}
            rows={8.2}
            numeroCaratteri={500}
            infoNumeroCaratteri={true}
            placeholder={PLACEHOLDER_DESCRIVI}
            value={formik.values.annote}
            multiline={true}
            onChange={(e) => {
              onDatiChange([{ fieldSection: "anagrafica", fieldName: "annote", fieldValue: e.target.value }]);
            }}
          />
        </Grid>
      </Grid>
      <FooterInserisciProgetti onSave={formik.handleSubmit} percentuale={progress} />
      <Button type="submit" sx={{ display: "none" }}>
        Salva
      </Button>
    </form>
  );
};
