import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { ArrowCircleRightOutlined as ArrowCircleRightOutlinedIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { AnagraficaInnovazione } from "./AnagraficaInnovazione";
import { useSelector, useDispatch } from "react-redux";
import {
  changeTabsDettagli,
  changetabs,
  getDatiProgettoInnovazione,
  getIndexTabSpostamento,
  getIndexTabs,
  saveProgress,
  saveSottoAttivita,
  setAttivitaSelezionata,
  setAzDiffDiss,
  setButtTypeFooterInsProg,
  setDescrizioniSottoAttivita,
  setDocumentazioneOptions,
  setEdit,
  setIndexTabSpostamento,
  setTipoBeneficiari,
} from "../../../../modules/configuration";
import { TabPanel } from "../../../shared/TabPanelCustom";
import { labels } from "../../../../labels";
import { Pubblicazione } from "../Pubblicazione";
import { AttivitaInnovazione } from "./AttivitaInnovazione";
import { Documentazione } from "../Documentazione";
import { useEffect, useState } from "react";
import { fetchAttivitaInnovazione, getAttivitaInnovazione } from "../../../../modules/datiOsservatorio";

const { ANAGRAFICA, ATTIVITA, DOCUMENTAZIONE, PUBBLICAZIONE } = labels;

export const gestisciprogetti = [
  {
    id: "0",
    testo: ANAGRAFICA,
  },
  {
    id: "1",
    testo: ATTIVITA,
  },
  {
    id: "2",
    testo: DOCUMENTAZIONE,
  },
  {
    id: "3",
    testo: PUBBLICAZIONE,
  },
];
export const MainInnovazione = (props) => {
  const indexTabs = useSelector(getIndexTabs);
  let indexString = indexTabs.toString();
  const dispatch = useDispatch();
  let progetto = props.progetto;
  const [modalSpostamentoTab, setModalSpostamentoTab] = useState(false);
  const [sbloccoTab, setSbloccoTab] = useState(0);
  const [versione, setVersione] = useState(false);
  const attivitaInnovazione = useSelector(getAttivitaInnovazione);
  const attivita = attivitaInnovazione.data;
  const datiProg = useSelector(getDatiProgettoInnovazione);
  const datiProgetto = datiProg.anagrafica;
  const index = useSelector(getIndexTabSpostamento);

  useEffect(() => {
    if (progetto.data === undefined) {
      dispatch(setEdit(true));
      setVersione(false);

      //     caso in cui grado scuola gia scelto quindi chiamata non parte dalla scelta nella select ,casi di modifica
      if (datiProgetto["descr_ambiente"]) {
        dispatch(fetchAttivitaInnovazione(datiProgetto["descr_ambiente"]));
      }
      dispatch(setDocumentazioneOptions(datiProg.documentazione["descr_documentazione"]));
      dispatch(setAzDiffDiss(datiProg.documentazione["azioneDiDiffusioneDisseminazione"]));
      dispatch(setTipoBeneficiari(datiProg.documentazione["beneficiari"]));
    }
    const attivitaData = datiProg.attivita.filter((e) => e.id_campo !== 30);

    for (let i = 0; i < attivitaData.length; i++) {
      let elementoSottoAtt = attivitaData[i].descr_valore;
      let elementoSottoAttDesc = attivitaData[i].descr_valore_progetto;
      dispatch(setDescrizioniSottoAttivita({ elementoSottoAtt, elementoSottoAttDesc }));
    }
    //per sezione modifica creo map in redux con attivita dati
    for (let i = 0; i < attivitaData.length; i++) {
      let elementoAtt = attivitaData[i].finalita + " " + attivitaData[i].descr_campo;
      let elementoSottoAtt = attivitaData[i].descr_valore;
      dispatch(saveSottoAttivita({ elementoSottoAtt, elementoAtt }));
      dispatch(setAttivitaSelezionata("in"));
    }

    //calcolo valore progress bar
    const percentualeAttivita = attivita.length !== 0 ? 50 / attivita.length : 0;
    let attivitaInn = datiProg.attivita.filter((e) => e.id_campo !== 30);
    //calcolo valore progress bar
    let progressAttivita = Math.floor(attivitaInn.length * percentualeAttivita);
    dispatch(saveProgress(progressAttivita));
    if (progetto.data === undefined) {
      if (progetto.completamento < 22 || progetto.completamento === progressAttivita + 32 || progetto.completamento > progressAttivita + 32) {
        dispatch(changeTabsDettagli(0));
        setSbloccoTab(4);
      } else {
        if ((progetto.completamento > 22 && progetto.completamento < progressAttivita + 22) || progetto.completamento === 22) {
          dispatch(changeTabsDettagli(1));
          setSbloccoTab(1);
        } else {
          if (
            (progetto.completamento > progressAttivita + 22 && progetto.completamento < progressAttivita + 34) ||
            (progetto.completamento === progressAttivita + 22 && progetto.completamento < progressAttivita + 34)
          ) {
            dispatch(changeTabsDettagli(2));
            setSbloccoTab(2);
          }
        }
      }
    }

    // eslint-disable-next-line
  }, [progetto]);

  useEffect(() => {
    if (index !== null) {
      let versione = true;
      dispatch(changetabs({ index, versione }));
    }
    // eslint-disable-next-line
  }, [progetto]);

  useEffect(() => {
    if (progetto.data === undefined && indexTabs > sbloccoTab) {
      setSbloccoTab(indexTabs);
    }
    // eslint-disable-next-line
  }, [indexTabs]);

  const color = (index) => {
    if (indexTabs === index) return "#FABE10";
    else {
      if (progetto.data === undefined) {
        if (index <= sbloccoTab) return "#FFE7A4";
        else return "#FFFFFF";
      } else {
        if (index < indexTabs) return "#FFE7A4";
        else return "#FFFFFF";
      }
    }
  };

  const decoration = (index) => {
    if (indexTabs === index) return "underline";
    else return "none";
  };

  const visible = (id) => {
    if (id > 2) return false;
    else return true;
  };

  const cursor = (index) => {
    if (progetto.data === undefined) {
      if (index > indexTabs) return "default";
    } else {
      if (index > sbloccoTab) return "default";
    }
  };

  return (
    <TabContext value={indexString}>
      <Grid container spacing={2}>
        {gestisciprogetti.map((elemento, index) => (
          <Grid item xs={12} sm={visible(index) ? 3 : 3} md={visible(index) ? 3.2 : 2.4} xl={visible(index) ? 3.2 : 2.4} key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10} sm={10} xl={visible(index) ? 10 : 12}>
                <Tab
                  label={elemento.testo}
                  value={index}
                  disableRipple
                  disableFocusRipple
                  onClick={() => {
                    if (index <= (progetto.data === undefined ? sbloccoTab : indexTabs)) {
                      dispatch(setButtTypeFooterInsProg("AVANTI"));
                      dispatch(setIndexTabSpostamento(index));
                      setModalSpostamentoTab(true);
                      dispatch(setEdit(true));
                      setVersione(true);
                    }
                  }}
                  sx={{
                    bgcolor: color(index),
                    textDecoration: decoration(index),
                    cursor: cursor(index),
                  }}
                  className="tabSezioni"
                />
              </Grid>
              {visible(index) && (
                <Grid item xs={2} md={2} sm={2} xl={2}>
                  <ArrowCircleRightOutlinedIcon className="arrowCircleIconTab" />
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <TabPanel value={"0"} index={indexString}>
        <AnagraficaInnovazione
          modal={modalSpostamentoTab}
          setModalSpostamentoTab={setModalSpostamentoTab}
          versione={versione}
          tipo={"inn"}
          formik={props.formik}
          progetto={progetto}
        />
      </TabPanel>
      <TabPanel value={"1"} index={indexString}>
        <AttivitaInnovazione modal={modalSpostamentoTab} setModalSpostamentoTab={setModalSpostamentoTab} versione={versione} progetto={progetto} />
      </TabPanel>
      <TabPanel value={"2"} index={indexString}>
        <Documentazione
          modal={modalSpostamentoTab}
          setModalSpostamentoTab={setModalSpostamentoTab}
          versione={versione}
          formik={props.formik}
          tipo={"inn"}
          progetto={progetto}
          idCampo={30}
        />
      </TabPanel>
      <TabPanel value={"3"} index={indexString}>
        <Pubblicazione
          modal={modalSpostamentoTab}
          setModalSpostamentoTab={setModalSpostamentoTab}
          versione={versione}
          numindex={3}
          progetto={progetto}
        />
      </TabPanel>
    </TabContext>
  );
};
