export const RegioneLiguria = (props) => (
  <svg viewBox="0 0 462.2 462.9" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#FFFFFF"
      d="M76.2,227.4V59.7h0v-3c0-3.1,0.2-6,0.6-8.8c0.4-2.8,1.1-5.5,2.1-8.1c1-2.5,2.3-5,4.1-7.3
	c1.7-2.3,3.8-4.5,6.4-6.7c3.9-3.2,11-6.4,20.4-9.3c9.4-2.9,21.3-5.6,34.8-7.9c13.2-2.3,27.7-4.1,42.9-5.3h0
	c14.2-1.2,29-1.8,43.6-1.8c14.7,0,29.4,0.6,43.6,1.8h0c15.1,1.2,29.7,3.1,42.9,5.3c13.4,2.3,25.3,5,34.8,7.9
	c9.4,2.9,16.5,6.1,20.4,9.3c2.5,2.1,4.6,4.3,6.4,6.7c1.8,2.4,3.1,4.8,4.1,7.3c1,2.6,1.7,5.2,2.1,8.1c0.4,2.7,0.6,5.6,0.6,8.8v3l0,0
	v170.7H76.2V227.4z"
    />
    <rect x="223.7" y="2.3" fill="#E30613" width="14.9" height="227.2" />
    <rect x="76.5" y="115.9" fill="#E30613" width="308.4" height="14.9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#9D9C9C"
      d="M80.1,236.6l75.1,4l2.9,0.1l-0.1,2.9v2.1c0,12.3,2.5,24,7,34.7c4.7,11.1,11.5,21.1,20,29.4
	c8.5,8.4,18.7,15.1,30,19.7c10.3,4.2,21.6,6.6,33.4,6.9h1.9c11.8,0,23.1-2.2,33.5-6.2c10.8-4.1,20.6-10.2,29-17.8
	c8.3-7.5,15.2-16.5,20.2-26.5c4.9-9.7,8.1-20.5,9.1-31.8l0.2-2.7h43.6l-0.5,3.4c-2.7,18.4-8.6,35.6-17.1,51.2
	c-8.8,16-20.3,30.3-33.9,42.2c-14,12.2-30.1,21.9-47.8,28.6c-17.1,6.4-35.6,9.9-54.7,9.9c-20.4,0-39.9-3.9-57.9-11.1
	c-18.6-7.4-35.4-18.3-49.6-31.9c-14.1-13.4-25.6-29.5-33.8-47.4c-7.9-17.3-12.7-36.4-13.7-56.4l-0.2-3.3L80.1,236.6L80.1,236.6z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#1D1D1B"
      d="M79.2,56.7L79.2,56.7v170.7H383V56.7h0c0-5.9-0.7-11-2.5-15.8c-1.8-4.6-4.9-8.8-9.6-12.8
	c-7.2-6.1-27.5-12.1-53.7-16.6c-25.4-4.3-56.1-7.2-86-7.1c-29.9-0.1-60.7,2.7-86,7.1C118.9,16,98.6,22.1,91.3,28.1
	c-4.7,4-7.8,8.2-9.6,12.8C79.9,45.6,79.2,50.8,79.2,56.7 M74.8,56.7L74.8,56.7c0-6.4,0.8-12.1,2.8-17.4c2.1-5.3,5.5-10.1,10.9-14.6
	c7.8-6.6,28.9-13,55.8-17.6c25.6-4.4,56.6-7.2,86.8-7.1c30.2-0.1,61.2,2.8,86.8,7.1c27,4.6,48,11,55.9,17.6
	c5.4,4.5,8.8,9.3,10.9,14.6c2.1,5.2,2.8,11,2.8,17.4l0,0v175.2H74.8L74.8,56.7L74.8,56.7z"
    />
    <polygon
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#9D9C9C"
      points="313.1,153.5 306.4,167.8 290.6,166.5 299.6,179.5 290.6,192.5 306.4,191.2 313.2,205.4 319.8,191.2 
	335.6,192.4 326.6,179.5 335.6,166.6 319.8,167.8 "
    />
    <polygon
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#9D9C9C"
      points="149.4,153.5 142.6,167.8 126.8,166.5 135.9,179.5 126.8,192.5 142.6,191.2 149.4,205.4 156.1,191.2 
	171.9,192.4 162.9,179.5 171.9,166.6 156.1,167.8 "
    />
    <polygon
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#9D9C9C"
      points="313.1,34.4 306.4,48.7 290.6,47.4 299.6,60.4 290.6,73.4 306.4,72.1 313.2,86.3 319.8,72.1 335.6,73.3 
	326.6,60.4 335.6,47.4 319.8,48.7 "
    />
    <polygon
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#9D9C9C"
      points="149.4,34.4 142.6,48.7 126.8,47.4 135.9,60.4 126.8,73.4 142.6,72.1 149.4,86.3 156.1,72.1 171.9,73.3 
	162.9,60.4 171.9,47.4 156.1,48.7 "
    />
    <rect x="228.5" y="230.1" fill="#1D1D1B" width="5.2" height="105.3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#1D1D1B"
      d="M154.9,243.5l-75.1-4c2,40.1,19.5,75.9,46.6,101.8c27.4,26.1,64.6,42.2,105.4,42.2c38.3,0,73.6-14.1,100.5-37.7
	c26.3-23,44.6-55,50-91.6h-37.4c-2.2,23.9-13.5,45.1-30.3,60.3c-17,15.4-39.7,24.7-64.5,24.7l0,0c-26.3,0-50.1-10.5-67.4-27.4
	c-17.2-16.9-27.9-40.4-27.9-66.2l0,0C154.9,245.1,154.9,244.4,154.9,243.5 M159.6,240.1l-0.1,1c0,0.8-0.1,1.6-0.1,2.3
	c0,0.6,0,1.3,0,2.2l0,0c0,24.6,10.2,46.9,26.6,63c16.4,16.1,39.1,26.1,64.2,26.1l0,0c23.6,0,45.3-8.9,61.5-23.6
	c16.2-14.7,27-35.1,29-58.2v-2.8h46.6v1.9l0,0c-5,38.9-24.2,72.8-51.9,97.1c-27.7,24.3-64.1,38.8-103.5,38.8
	c-42,0-80.3-16.5-108.5-43.4c-28-26.8-46.1-63.8-48-105.3h0l-0.2-4L159.6,240.1L159.6,240.1z"
    />
    <path
      d="M0,462.3v-36h13.3c4.9,0,8.5,0.9,10.8,2.5s3.5,4.3,3.5,7.9c0,2-0.5,3.7-1.5,5.1c-1,1.4-2.6,2.6-4.7,3.6
	c0.9,0.3,1.7,0.9,2.4,1.6c0.7,0.8,1.3,1.7,1.9,2.9l5.7,12.3H21.2l-4.6-10.4c-0.7-1.6-1.6-2.8-2.5-3.4c-0.9-0.6-2.3-0.9-4-0.9
	c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.5,0v14.6L0,462.3L0,462.3z M13.3,441c1.7,0,2.9-0.4,3.7-1s1.2-1.8,1.2-3.2s-0.4-2.4-1.3-3.1
	s-2.1-1.1-3.7-1.1H9v8.4L13.3,441L13.3,441z"
    />
    <polygon
      points="34.2,462.3 34.2,426.3 58.2,426.3 58.2,432.9 43.2,432.9 43.2,440.3 57.5,440.3 57.5,446.9 43.2,446.9 43.2,455.5 
	58.7,455.5 58.7,462.3 "
    />
    <path
      d="M97.2,458c-2.2,1.6-4.5,2.9-7,3.7c-2.5,0.8-5.2,1.2-8.1,1.2s-5.4-0.4-7.8-1.3c-2.4-0.9-4.4-2.1-6.1-3.8
	c-1.8-1.7-3.2-3.8-4.2-6.1c-1-2.3-1.4-4.8-1.4-7.5c0-5.6,1.9-10.1,5.6-13.5c3.7-3.4,8.7-5.1,14.8-5.1c2.2,0,4.5,0.3,6.7,0.8
	c2.2,0.5,4.4,1.4,6.7,2.5v7.9c-2.4-1.2-4.7-2.1-6.8-2.7c-2.2-0.6-4.2-0.9-6.1-0.9c-3.6,0-6.5,1-8.6,3c-2.1,2-3.1,4.7-3.1,8.1
	s1,6.1,3,8.1c2,2,4.6,3,7.9,3c0.9,0,1.8-0.1,2.6-0.3c0.9-0.2,1.8-0.5,2.6-0.8v-4.5h-4.9V443h14.1L97.2,458L97.2,458z"
    />
    <rect x="103.7" y="426.3" width="9.3" height="36" />
    <path
      d="M118.5,444.3c0-2.7,0.5-5.1,1.4-7.4c0.9-2.3,2.3-4.3,4.2-6.1c1.8-1.7,3.9-3,6.2-3.8s4.9-1.3,7.7-1.3s5.4,0.4,7.8,1.3
	c2.3,0.9,4.4,2.1,6.2,3.8c1.9,1.8,3.3,3.8,4.2,6.1c1,2.3,1.4,4.7,1.4,7.4s-0.5,5.1-1.4,7.4c-0.9,2.3-2.4,4.3-4.2,6
	c-1.8,1.7-3.9,3-6.2,3.9c-2.3,0.9-4.9,1.3-7.7,1.3s-5.4-0.4-7.7-1.3c-2.3-0.9-4.4-2.2-6.2-3.9c-1.8-1.8-3.3-3.8-4.2-6
	C119,449.4,118.5,447,118.5,444.3 M127.8,444.3c0,3.2,1,5.8,2.9,7.9c1.9,2.1,4.4,3.1,7.4,3.1s5.5-1,7.4-3.1c1.9-2.1,2.9-4.7,2.9-7.9
	s-1-5.9-2.9-7.9c-1.9-2.1-4.4-3.1-7.4-3.1s-5.4,1-7.4,3.1C128.7,438.4,127.8,441.1,127.8,444.3z"
    />
    <polygon
      points="171.8,439.7 171.8,462.3 162.9,462.3 162.9,426.3 170.8,426.3 190,447.7 190,426.3 198.9,426.3 198.9,462.3 
	191.9,462.3 "
    />
    <polygon
      points="206,462.3 206,426.3 230.1,426.3 230.1,432.9 215,432.9 215,440.3 229.4,440.3 229.4,446.9 215,446.9 215,455.5 
	230.5,455.5 230.5,462.3 "
    />
    <polygon points="250.6,462.3 250.6,426.3 259.6,426.3 259.6,455.3 275.1,455.3 275.1,462.3 " />
    <rect x="279.4" y="426.3" width="9.3" height="36" />
    <path
      d="M328.7,458c-2.2,1.6-4.5,2.9-7,3.7c-2.5,0.8-5.1,1.2-8.1,1.2s-5.4-0.4-7.8-1.3c-2.4-0.9-4.4-2.1-6.1-3.8
	c-1.8-1.7-3.2-3.8-4.1-6.1c-1-2.3-1.4-4.8-1.4-7.5c0-5.6,1.9-10.1,5.6-13.5s8.7-5.1,14.8-5.1c2.2,0,4.5,0.3,6.7,0.8
	c2.2,0.5,4.4,1.4,6.7,2.5v7.9c-2.4-1.2-4.6-2.1-6.8-2.7c-2.2-0.6-4.2-0.9-6.1-0.9c-3.6,0-6.5,1-8.6,3c-2.1,2-3.1,4.7-3.1,8.1
	s1,6.1,3,8.1c2,2,4.6,3,7.9,3c0.9,0,1.8-0.1,2.6-0.3c0.9-0.2,1.8-0.5,2.6-0.8v-4.5h-5V443h14.1L328.7,458L328.7,458z"
    />
    <path
      d="M368.8,426.3v20.6c0,5-1.5,9-4.5,11.8c-3,2.8-7.2,4.2-12.5,4.2s-9.5-1.4-12.5-4.2c-3-2.8-4.5-6.7-4.5-11.8v-20.6h9.1v20.2
	c0,2.9,0.7,5,2,6.6c1.4,1.5,3.4,2.3,6,2.3s4.6-0.8,5.9-2.3c1.4-1.5,2-3.7,2-6.6v-20.2L368.8,426.3L368.8,426.3z"
    />
    <path
      d="M375.8,462.3v-36h13.3c4.9,0,8.5,0.9,10.8,2.5c2.3,1.7,3.5,4.3,3.5,7.9c0,2-0.5,3.7-1.5,5.1c-1,1.4-2.6,2.6-4.7,3.6
	c0.9,0.3,1.7,0.9,2.4,1.6c0.7,0.8,1.3,1.7,1.9,2.9l5.7,12.3H397l-4.6-10.4c-0.7-1.6-1.6-2.8-2.5-3.4c-1-0.6-2.3-0.9-4-0.9
	c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.5,0v14.6L375.8,462.3L375.8,462.3z M389.1,441c1.7,0,2.9-0.4,3.7-1s1.2-1.8,1.2-3.2
	s-0.4-2.4-1.3-3.1c-0.9-0.7-2.1-1.1-3.7-1.1h-4.3v8.4L389.1,441L389.1,441z"
    />
    <rect x="410.2" y="426.3" width="9.3" height="36" />
    <path d="M422.2,462.3l15.7-36h10.4l14,36h-9.5l-2.6-7h-15.5l-2.9,7H422.2z M437.2,449.1h10.6l-5-13.7L437.2,449.1z" />
  </svg>
);
