import { createSelector } from "@reduxjs/toolkit";

export const getConfigurationStore = (state) => state.datiProgetti;

export const getProgettiBozza = createSelector(getConfigurationStore, (configuration) => configuration.progettiBozza);
export const getProgettiConclusi = createSelector(getConfigurationStore, (configuration) => configuration.progettiConclusi);
export const getProgettiIstituto = createSelector(getConfigurationStore, (configuration) => configuration.progettiIstituto);
export const getProgettiIstitutiScolastici = createSelector(getConfigurationStore, (configuration) => configuration.progettiIstitutiScolastici);
export const getProgettiCurricolariExtraCurricolari = createSelector(
  getConfigurationStore,
  (configuration) => configuration.progettiCurricolariExtraCurricolari
);
export const getProgettiInnovazioneScuola = createSelector(getConfigurationStore, (configuration) => configuration.progettiInnovazioneScuola);
export const getCodiceProgetto = createSelector(getConfigurationStore, (configuration) => configuration.codiceProgetto);
export const getDettagliProgetto = createSelector(getConfigurationStore, (configuration) => configuration.dettagliProgetto);
