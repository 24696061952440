import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { HeaderProgetti } from "../HeaderProgetti";
import { MainTableProgetti } from "../MainTableProgetti";
import { aggiornaValoriSlice, getFilter, getFiltriProgettiAttivita, getfiltri } from "../../../../modules/configuration";
import { PATH_HOME, PATH_PROGETTI_INNOVAZIONE_SCUOLA, PATH_RICERCA_PROGETTI_INNOVAZIONE_SCUOLA } from "../../../../path";
import { TableResponsive } from "../../../shared/TableResponsive";
import { getProgettiCurricolariExtraCurricolari } from "../../../../modules/datiProgetti";
import { PaginationShared } from "../../../shared/Pagination";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "../../gestisciProgetti/TableProgetti";
import { labels } from "../../../../labels";
import { LoadingSpinner } from "../../../shared";
import { fetchAttivitaInnovazione } from "../../../../modules/datiOsservatorio";

const { PROGETTI_INNOVAZIONE, AVVISO_RISULTATO_RICERCA } = labels;

const TableResponsiveCampi = ["Completamento", "Tipologia", "Titolo", "Data inserimento", "Referente", "Pubblicazione"];

export const ProgettiInnovazioneScuola = () => {
  const options = useSelector(getfiltri);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const progetti = useSelector(getProgettiCurricolariExtraCurricolari);

  //passo parametro 0 per recuperare l'elenco totale delle attività inerenti alla sezione innovazione e non in base all'id ambiente
  useEffect(() => {
    dispatch(fetchAttivitaInnovazione("/" + 0));
    // eslint-disable-next-line
  });

  //REDIRECT IN CASO IN CUI CHIAMATA PER I DATI DEGLI ISTITUTI NON ANDASSE A BUON FINE
  if (progetti.fetchEnded === false) {
    navigate(PATH_HOME);
  }
  let prog = progetti.data;

  const sottoAtt = useSelector(getFiltriProgettiAttivita);
  const checkProgInn = (prog) => {
    const checkInn = prog.id_tipo_progetto === 2;
    const checkStato = prog.id_stato_progetto !== 1;

    return checkInn && checkStato;
  };

  let data = prog.filter(checkProgInn);
  const filtri = useSelector(getfiltri);
  const filter = useSelector(getFilter);
  const [page, setPage] = useState(1);
  function handlechange(e, value) {
    dispatch(aggiornaValoriSlice(value));
    setPage(value);
  }

  const checkAttivita = (attivita) => {
    if (sottoAtt.length === undefined) {
      return true;
    } else {
      let check = 0;
      for (let i = 0; i < sottoAtt.length; i++) {
        for (let j = 0; j < attivita.length; j++) {
          if (
            sottoAtt[i].finalita + sottoAtt[i].descr_valore + sottoAtt[i].descr_campo ===
            (attivita[j].obbligatorieta === 1 ? "*" : "") + attivita[j].finalita + attivita[j].descr_valore + attivita[j].descr_campo
          ) {
            check = check + 1;
          }
        }
      }
      if (check === sottoAtt.length) {
        return true;
      } else return false;
    }
  };

  const checkBeneficiari = (data) => {
    for (let i = 0; i < data.beneficiari.length; i++) {
      if (data.beneficiari[i].descr_beneficiario === filtri.tipologiaBeneficiario) {
        return true;
      }
    }
    return false;
  };

  const checkDoc = (data) => {
    for (let i = 0; i < data.documentazione.length; i++) {
      if (data.documentazione[i].descr_documentazione === filtri.documentazione) {
        return true;
      }
    }
    return false;
  };

  const checkFiltriProgInn = (data) => {
    //bisogna mettere nomi campi corretti (vedi istituti)
    const checkTitoloInn = filtri.titolo === "" ? true : data.titolo_progetto === filtri.titolo;
    const checkDataInizioInn = filtri.dataInizio === "" ? true : format(new Date(data.data_inizio), "yyyy-MM-dd") === filtri.dataInizio;
    const checkOrdineScuolaInn = filtri.ordineDiScuola === "" ? true : data.descr_grado_scuola === filtri.ordineDiScuola;
    const checkTipologiaBeneficiario = filtri.tipologiaBeneficiario === "" ? true : checkBeneficiari(data);
    const checkCodiceMeccanograficoInn = filtri.codiceMeccanografico === "" ? true : data.codice_scuola === filtri.codiceMeccanografico;
    const checkFondiEcontestoFinanziamento =
      filtri.fondiEcontestoFinanziamento === "" ? true : data.descr_fondi === filtri.fondiEcontestoFinanziamento;
    const checkDataInserimentoInn =
      filtri.dataInserimento === "" ? true : format(new Date(data.data_inserimento), "yyyy-MM-dd") === filtri.dataInserimento;
    const checkAnnoRealizzazioneInn = filtri.annoRealizzazione === "" ? true : data.descr_anno_realiz === filtri.annoRealizzazione;
    const checkProvinciaInn = filtri.provincia === "" ? true : data.provincia === filtri.provincia;
    const checkDenominazioneInn = filtri.denominazione === "" ? true : data.nomeScuola === filtri.denominazione;
    const checkTempoRealizzazioneInn = filtri.tempoRealizzazione === "" ? true : data.descr_tempo_realiz === filtri.tempoRealizzazione;
    const checkReferenteInn = filtri.referente === "" ? true : data.referente_cogn + " " + data.referente_nome === filtri.referente;
    const checkPubblicatoInn = filtri.pubblicato === "" ? true : data.descr_stato_progetto === filtri.pubblicato;
    const checkDocumentatoInn = filtri.documentato === "" ? true : data.link_documentazione === null ? "NO" : "SI" === filtri.documentato;
    const checkfinalitaInn = filtri.attivita === "" ? true : checkAttivita(data.attivita);
    const checkDocumentazioneInn = filtri.documentazione === "" ? true : checkDoc(data);
    const checkValutazioneInn = filtri.valutazione === "" ? true : data.descr_valutazione === filtri.valutazione;
    const checkAreaCoinvolta = filtri.areaCoinvolta === "" ? true : data.descr_ambiente === filtri.areaCoinvolta;
    const checkAbstractInn = filtri.abstract === "" ? true : data.descr_progetto !== null ? data.descr_progetto.includes(filtri.abstract) : false;
    const checkcoReferenti = filtri.coReferenti === "" ? true : data.coReferente_cognome + " " + data.coReferente_nome === filtri.coReferenti;

    return (
      checkTitoloInn &&
      checkDataInizioInn &&
      checkOrdineScuolaInn &&
      checkTipologiaBeneficiario &&
      checkCodiceMeccanograficoInn &&
      checkFondiEcontestoFinanziamento &&
      checkDataInserimentoInn &&
      checkAnnoRealizzazioneInn &&
      checkProvinciaInn &&
      checkDenominazioneInn &&
      checkTempoRealizzazioneInn &&
      checkReferenteInn &&
      checkPubblicatoInn &&
      checkDocumentatoInn &&
      checkfinalitaInn &&
      checkDocumentazioneInn &&
      checkValutazioneInn &&
      checkAreaCoinvolta &&
      checkAbstractInn &&
      checkcoReferenti
    );
  };
  data = filter === true ? data.filter(checkFiltriProgInn) : data;

  return (
    <Box className="ricerca">
      <HeaderProgetti title={PROGETTI_INNOVAZIONE} location={PATH_RICERCA_PROGETTI_INNOVAZIONE_SCUOLA} options={options} />
      <Box>
        <MainTableProgetti progetti={data} path={PATH_PROGETTI_INNOVAZIONE_SCUOLA} />
        <TableResponsive
          borderColor={"#8E2155"}
          color={"#DEA4BD"}
          table={TableResponsiveCampi}
          progetti={data}
          path={PATH_PROGETTI_INNOVAZIONE_SCUOLA}
        />
      </Box>
      {data.length === 0 && progetti.fetchEnded === true ? (
        <Box className="center" sx={{ marginTop: "50px" }}>
          <Typography sx={{ fontSize: "22px", fontWeight: "Bold" }}>{AVVISO_RISULTATO_RICERCA}</Typography>
        </Box>
      ) : null}
      <Box className="center">
        {Math.ceil(data.length / 5) > 1 ? (
          <PaginationShared page={page} handlechange={handlechange} count={Math.ceil(data.length / 5)} color={"#8E2155 !important"} />
        ) : null}
      </Box>
      <LoadingSpinner loading={!progetti.fetchEnded} />
    </Box>
  );
};
