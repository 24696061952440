import { Box } from "@mui/system";
import { MainCurrExtraC } from "./MainCurrExtraC";
import { HomeIcon, InserisciIcon } from "../../../../styles/icons";
import { useSelector } from "react-redux";
import { getIdProgetto, getTitle } from "../../../../modules/configuration";
import { labels } from "../../../../labels";
import { Divider, Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import { ModaleHeaderInserisciProgetti } from "./../ModaleHeaderInserisciProgetti";
import { useState } from "react";
import { getInfoProfilo } from "../../../../modules/profilo";
import { SkeletonCurricolareExtraCurrurricolare } from "./skeleton/SkeletonCurricolareExtraCurrurricolare";
import { PATH_HOME } from "../../../../path";
import { getDettagliProgetto } from "../../../../modules/datiProgetti";
import { useBeforeunload } from "react-beforeunload";

const { PROGETTI, BUTTON_HOME } = labels;

export const CurricolareExtraCurrurricolare = () => {
  const [modalOpen, set] = useState(false);
  //nel caso di modifica estraggo progetto
  const title = useSelector(getTitle);
  const profilo = useSelector(getInfoProfilo);
  const idProg = useSelector(getIdProgetto);
  const dettagliProgetto = useSelector(getDettagliProgetto);
  let progetto = dettagliProgetto;


  //controllo in caso di chiusura tab o scheda da browser invece che da app durante inserimento 
  useBeforeunload(() => "Are you sure to close this tab?");


  return profilo ? (
    <Box className="inserimentoProgetto">
      <Box className="header">
        <Grid container spacing={2}>
          <Grid item xs={9} sm={10} md={11} className="leftElement">
            <Stack className="customIcon">
              <InserisciIcon />
            </Stack>
            <Typography className="titlePage" variant="h1">
              {PROGETTI} {title !== "Inserisci tipologia di progetto" ? title : "Scegli tipologia"}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2} md={1} className="buttonHome">
            <IconButton aria-label="Home" onClick={() => set(true)}>
              <HomeIcon />
            </IconButton>
            <Link onClick={() => set(true)} className="link" sx={{ cursor: "pointer" }}>
              {BUTTON_HOME}
            </Link>
          </Grid>
        </Grid>
        <Divider className="dividerHeaderProgetti" />
      </Box>

      <ModaleHeaderInserisciProgetti
        uuid_progetto={progetto.data === undefined ? progetto.uuid_progetto : idProg}
        set={set}
        stateModal={modalOpen}
        path={PATH_HOME}
      />
      <Box>
        <MainCurrExtraC progetto={progetto} />
      </Box>
    </Box>
  ) : (
    <SkeletonCurricolareExtraCurrurricolare />
  );
};
