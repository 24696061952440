import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { WarningAmberOutlined as WarningAmberOutlinedIcon } from "@mui/icons-material";
import { setPrivacy } from "../../modules/profilo";
import { labels } from "../../labels";
import { PRIVACY_ERROR } from "../../utility";
import { PATH_RISORSE } from "../../path";

const { PRIVACY_ERROR_INFO, PRIVACY_ERROR_INSTRUCTIONS, PRIVACY_ERROR_HELP, PRIVACY_INFORMATIVA, PRIVACY_ACCETTA } = labels;

export const PrivacyError = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showInformativa, setShowInformativa] = useState(false);

  useEffect(() => {
    window.addEventListener(PRIVACY_ERROR, () => setShowModal(true), false);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography id="Privacy_modal" sx={{ display: "none" }}>
        Privacy error modal
      </Typography>
      <Dialog
        open={showModal}
        className="privacyError"
        aria-labelledby="Privacy_modal"
        sx={{
          "& .MuiDialog-paper": {
            width: showInformativa ? "calc(100% - 64px)" : "60%",
            height: showInformativa ? "calc(100% - 64px)" : "60%",
          },
        }}
      >
        {showInformativa ? (
          <Card>
            <CardContent>
              <iframe title={"gdpr.pdf"} src={window.origin + PATH_RISORSE + "/gdpr.pdf"} type="application/pdf" />
            </CardContent>
          </Card>
        ) : (
          <DialogContent>
            <WarningAmberOutlinedIcon />
            <Typography variant="h3">{PRIVACY_ERROR_INFO}</Typography>
            <Typography>{PRIVACY_ERROR_INSTRUCTIONS}</Typography>
            <Typography>{PRIVACY_ERROR_HELP}</Typography>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            className="buttonViola"
            onClick={() => {
              if (showInformativa) {
                dispatch(setPrivacy());
                setShowModal(false);
              } else setShowInformativa(true);
            }}
          >
            {showInformativa ? PRIVACY_ACCETTA : PRIVACY_INFORMATIVA}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
