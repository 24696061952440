import { Box, Typography } from "@mui/material";
import { FormIstSc } from "./FormIstSc";
import { TableRicerca } from "./TableRicerca";
import { aggiornaValoriSlice, getFilter, getFilterSort, getfiltri } from "../../../../modules/configuration";
import { useDispatch, useSelector } from "react-redux";
import { PaginationShared } from "../../../shared/Pagination";
import { useState } from "react";
import { getIstituti } from "../../../../modules/istituti";
import { TableResponsiveIstituti } from "../../../shared/TableResponsiveIstituti";
import { PATH_HOME, PATH_RICERCA } from "../../../../path";
import { useNavigate } from "react-router-dom";
import { labels } from "../../../../labels";
import { HeaderPage } from "../../../shared/Header";
import { CercaIcon } from "../../../../styles/icons";
import { LoadingSpinner } from "../../../shared";

const { DENOMINAZIONE, CODICE_MECCANOGRAFICO, TIPOLOGIA_ISTITUTO, COMUNE, ISTITUTI_SCOLASTICI, AVVISO_ASSENZA_ISTITUTI } = labels;

export const IstitutiScolastici = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const datiIstituti = useSelector(getIstituti);
  const filtri = useSelector(getfiltri);
  const filterSort = useSelector(getFilterSort);
  const filter = useSelector(getFilter);

  //REDIRECT IN CASO IN CUI CHIAMATA PER I DATI DEGLI ISTITUTI NON ANDASSE A BUON FINE
  if (datiIstituti.fetchEnded === false) {
    navigate(PATH_HOME);
  }
  let data = datiIstituti.data;

  const checkIndirizzoQualifica = (indirizzi) => {
    for (let i = 0; i < indirizzi.length; i++) {
      if (indirizzi[i].descr_indirizzo === filtri.indirizzo) {
        return true;
      }
    }
  };

  const check = (data) => {
    const checkDenominazione = filtri.denominazione === "" ? true : data.nome === filtri.denominazione;
    const checkProvincia =
      filtri.provincia === ""
        ? true
        : (data.descr_prov === "IMPERIA" ? "IM" : data.descr_prov === "GENOVA" ? "GE" : data.descr_prov === "SAVONA" ? "SV" : "SP") ===
          filtri.provincia;
    const checkTipologiaIstituto = filtri.tipologiaIstituto === "" ? true : data.descr_istituto === filtri.tipologiaIstituto;
    const checkComune = filtri.comune === "" ? true : data.descr_com === filtri.comune;
    const checkCodiceMeccanografico = filtri.codiceMeccanografico === "" ? true : data.codice_scuola === filtri.codiceMeccanografico;
    const checkIndirizzo = filtri.indirizzo === "" ? true : checkIndirizzoQualifica(data.indirizzi_qualifica);
    return checkTipologiaIstituto && checkDenominazione && checkProvincia && checkIndirizzo && checkCodiceMeccanografico && checkComune;
  };

  let istituti = filter === true ? data.filter(check) : data;

  function handlechange(e, value) {
    dispatch(aggiornaValoriSlice(value));
    setPage(value);
  }
  
  //filtro riordinamento alfabetico Denominazione scuola
  if (filterSort === DENOMINAZIONE) {
    function SortArray(x, y) {
      return x.nome.localeCompare(y.nome, "fr", {
        ignorePunctuation: true,
      });
    }
    istituti = [...istituti].sort(SortArray);
  }

  if (filterSort === CODICE_MECCANOGRAFICO) {
    function SortArray(x, y) {
      return x.codice_scuola.localeCompare(y.codice_scuola, "fr", {
        ignorePunctuation: true,
      });
    }
    istituti = [...istituti].sort(SortArray);
  }

  if (filterSort === TIPOLOGIA_ISTITUTO) {
    function SortArray(x, y) {
      return x.descr_istituto.localeCompare(y.descr_istituto, "fr", {
        ignorePunctuation: true,
      });
    }
    istituti = [...istituti].sort(SortArray);
  }

  if (filterSort === COMUNE) {
    function SortArray(x, y) {
      return x.descr_com.localeCompare(y.descr_com, "fr", {
        ignorePunctuation: true,
      });
    }
    istituti = [...istituti].sort(SortArray);
  }

  return (
    <Box className="ricerca">
      <HeaderPage icon={<CercaIcon />} title={ISTITUTI_SCOLASTICI} color={"#8E2155"} linkBack={true} path={PATH_RICERCA} />
      <Box>
        <FormIstSc handlechange={handlechange} />
      </Box>
      <Box>
        <TableRicerca data={istituti} />
        <TableResponsiveIstituti borderColor={"#8E2155"} color={"#DEA4BD"} progetti={istituti} />
      </Box>
      {istituti.length > 0 ? null : (
        <Box className="center">
          <Typography sx={{ fontSize: "22px", fontWeight: "Bold", marginTop: "20px" }}>{AVVISO_ASSENZA_ISTITUTI} </Typography>
        </Box>
      )}
      <Box sx={{ margin: "20px", justifyContent: "center", display: "flex" }}>
        {Math.ceil(istituti.length / 5) > 1 ? (
          <PaginationShared page={page} count={Math.ceil(istituti.length / 5)} handlechange={handlechange} color={"#8E2155 !important"} />
        ) : null}
      </Box>
      <LoadingSpinner loading={!datiIstituti.fetchEnded} />
    </Box>
  );
};
