import { Typography, Button, Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Stack } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";
import { labels } from "../../labels";

const { LOGOUT_CONFIRM, YES, NO } = labels;

export const LogoutModal = ({ open, onYes, onNo }) => (
  <Dialog open={open} className="modalProfilo">
    <DialogTitle>
      <Stack sx={{ opacity: 0 }}>Modal logout</Stack>
      <IconButton onClick={onNo} aria-label="Chiudi">
        <CloseOutlinedIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Typography>{LOGOUT_CONFIRM}</Typography>
    </DialogContent>
    <DialogActions>
      <Button className="buttonVerdeSecondario" onClick={onYes}>
        {YES}
      </Button>
      <Button className="buttonVerde" onClick={onNo}>
        {NO}
      </Button>
    </DialogActions>
  </Dialog>
);
