import { MainInnovazione } from "./MainInnovazione";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { BackIcon, HomeIcon, InserisciIcon } from "../../../../styles/icons";
import { ModaleHeaderInserisciProgetti } from "../ModaleHeaderInserisciProgetti";
import { getDettagliProgetto } from "../../../../modules/datiProgetti";
import { labels } from "../../../../labels";
import { Divider, Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { LoadingSpinner } from "../../../shared";
import { SkeletonInnovazione } from "./skeleton/SkeletonInnovazione";
import { getInfoProfilo } from "../../../../modules/profilo";
import { PATH_HOME, PATH_SCRIVANIA } from "../../../../path";
import { useBeforeunload } from "react-beforeunload";

const { PROGETTI_INNOVAZIONE, BUTTON_HOME } = labels;

export const PageInnModifica = () => {
  const [path, setPath] = useState(false);
  const [modalOpen, set] = useState(false);
  const profilo = useSelector(getInfoProfilo);
  const dettagliProgetto = useSelector(getDettagliProgetto);
  let progetto = dettagliProgetto.data;

  //controllo in caso di chiusura tab o scheda da browser invece che da app durante inserimento
  useBeforeunload(() => "Are you sure to close this tab?");

  return profilo ? (
    <Box className="inserimentoProgetto">
      <Box className="header">
        <Grid container spacing={2}>
          <Grid item xs={1} sm={0.5}>
            <IconButton
              aria-label="Back"
              className="backButton"
              onClick={() => {
                set(true);
                setPath(PATH_SCRIVANIA);
              }}
            >
              <BackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6} sm={8.5} md={10} className="leftElement">
            {" "}
            <Stack
              className="customIcon"
              sx={{
                "@media (max-width: 460px)": {
                  display: "none",
                },
              }}
            >
              <InserisciIcon />
            </Stack>
            <Typography className="titlePage" variant="h1">
              {PROGETTI_INNOVAZIONE}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2} md={1} className="buttonHome">
            <IconButton
              aria-label="Home"
              onClick={() => {
                set(true);
                setPath(PATH_HOME);
              }}
            >
              <HomeIcon />
            </IconButton>
            <Link
              onClick={() => {
                set(true);
                setPath(PATH_HOME);
              }}
              className="link"
              sx={{ cursor: "pointer" }}
            >
              {BUTTON_HOME}
            </Link>
          </Grid>
        </Grid>
        <Divider className="dividerHeaderProgetti" />
      </Box>
      <ModaleHeaderInserisciProgetti uuid_progetto={progetto.uuid_progetto} set={set} stateModal={modalOpen} path={path} />
      <MainInnovazione progetto={progetto} />
      <LoadingSpinner loading={!dettagliProgetto.fetchEnded} />
    </Box>
  ) : (
    <SkeletonInnovazione />
  );
};
