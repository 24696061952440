import { createAction } from "@reduxjs/toolkit";

export const fetchCommonInfo = createAction("FETCH_COMMON_INFO@common");

export const fetchEfficaciaProgetto = createAction("FETCH_EFFICACIA_PROGETTO@common");

export const fetchEfficaciaProgettoSuccess = createAction("FETCH_EFFICACIA_PROGETTO_SUCCESS@common");

export const fetchEfficaciaProgettoFailed = createAction("FETCH_EFFICACIA_PROGETTO_FAILED@common");

export const fetchTipologiaProgetto = createAction("FETCH_TIPOLOGIA_PROGETTO@common");

export const fetchTipologiaProgettoSuccess = createAction("FETCH_TIPOLOGIA_PROGETTO_SUCCESS@common");

export const fetchTipologiaProgettoFailed = createAction("FETCH_TIPOLOGIA_PROGETTO_FAILED@common");

export const fetchTipologiaIstituto = createAction("FETCH_TIPOLOGIA_ISTITUTO@common");

export const fetchTipologiaIstitutoSuccess = createAction("FETCH_TIPOLOGIA_ISTITUTO_SUCCESS@common");

export const fetchTipologiaIstitutoFailed = createAction("FETCH_TIPOLOGIA_ISTITUTO_FAILED@common");

export const fetchTipologiaStudentiBes = createAction("FETCH_TIPOLOGIA_STUDENTI_BES@common");

export const fetchTipologiaStudentiBesSuccess = createAction("FETCH_TIPOLOGIA_STUDENTI_BES_SUCCESS@common");

export const fetchTipologiaStudentiBesFailed = createAction("FETCH_TIPOLOGIA_STUDENTI_BES_FAILED@common");

export const fetchStatoPubblicazione = createAction("FETCH_STATO_PUBBLICAZIONE@common");

export const fetchStatoPubblicazioneSuccess = createAction("FETCH_STATO_PUBBLICAZIONE_SUCCESS@common");

export const fetchStatoPubblicazioneFailed = createAction("FETCH_STATO_PUBBLICAZIONE_FAILED@common");

export const fetchProvince = createAction("FETCH_PROVINCE@common");

export const fetchProvinceSuccess = createAction("FETCH_PROVINCE_SUCCESS@common");

export const fetchProvinceFailed = createAction("FETCH_PROVINCE_FAILED@common");

export const fetchIstitutiScolastici = createAction("FETCH_ISTITUTI_SCOLASTICI@common");

export const fetchIstitutiScolasticiSuccess = createAction("FETCH_ISTITUTI_SCOLASTICI_SUCCESS@common");

export const fetchIstitutiScolasticiFailed = createAction("FETCH_ISTITUTI_SCOLASTICI_FAILED@common");

export const fetchTipologiaDocumentazione = createAction("FETCH_TIPOLOGIA_DOCUMENTAZIONE@common");

export const fetchTipologiaDocumentazioneSuccess = createAction("FETCH_TIPOLOGIA_DOCUMENTAZIONE_SUCCESS@common");

export const fetchTipologiaDocumentazioneFailed = createAction("FETCH_TIPOLOGIA_DOCUMENTAZIONE_FAILED@common");

export const fetchContestoAccompagnamento = createAction("FETCH_CONTESTO_ACCOMPAGNAMENTO@common");

export const fetchContestoAccompagnamentoSuccess = createAction("FETCH_CONTESTO_ACCOMPAGNAMENTO_SUCCESS@common");

export const fetchContestoAccompagnamentoFailed = createAction("FETCH_CONTESTO_ACCOMPAGNAMENTO_FAILED@common");

export const fetchIncarichi = createAction("FETCH_INCARICHI@common");

export const fetchIncarichiSuccess = createAction("FETCH_INCARICHI_SUCCESS@common");

export const fetchIncarichiFailed = createAction("FETCH_INCARICHI_FAILED@common");

export const fetchIndirizzoQualificaIstituto = createAction("FETCH_INDIRIZZO_QUALIFICA_ISTITUTO@common");

export const fetchIndirizzoQualificaIstitutoSuccess = createAction("FETCH_INDIRIZZO_QUALIFICA_ISTITUTO_SUCCESS@common");

export const fetchIndirizzoQualificaIstitutoFailed = createAction("FETCH_INDIRIZZO_QUALIFICA_ISTITUTO_FAILED@common");

export const fetchAttivitaCurricolari = createAction("FETCH_ATTIVITA_CURRICOLARI@common");

export const fetchAttivitaCurricolariSuccess = createAction("FETCH_ATTIVITA_CURRICOLARI_SUCCESS@common");

export const fetchAttivitaCurricolariFailed = createAction("FETCH_ATTIVITA_CURRICOLARI_FAILED@common");

export const fetchAttivitaCurricolariRicerca = createAction("FETCH_ATTIVITA_CURRICOLARI_RICERCA@common");

export const fetchAttivitaCurricolariRicercaSuccess = createAction("FETCH_ATTIVITA_CURRICOLARI_RICERCA_SUCCESS@common");

export const fetchAttivitaCurricolariRicercaFailed = createAction("FETCH_ATTIVITA_CURRICOLARI_RICERCA_FAILED@common");

export const fetchAttivitaClil = createAction("FETCH_ATTIVITA_CLIL@common");

export const fetchAttivitaClilSuccess = createAction("FETCH_ATTIVITA_CLIL_SUCCESS@common");

export const fetchAttivitaClilFailed = createAction("FETCH_ATTIVITA_CLIL_FAILED@common");

export const fetchTipologiaBeneficiarioProgetto = createAction("FETCH_TIPOLOGIA_BENEFICIARIO_PROGETTO@common");

export const fetchTipologiaBeneficiarioProgettoSuccess = createAction("FETCH_TIPOLOGIA_BENEFICIARIO_PROGETTO_SUCCESS@common");

export const fetchTipologiaBeneficiarioProgettoFailed = createAction("FETCH_TIPOLOGIA_BENEFICIARIO_PROGETTO_FAILED@common");

export const fetchTempoRealizzazioneProgetto = createAction("FETCH_TEMPO_REALIZZAZIONE_PROGETTO@common");

export const fetchTempoRealizzazioneProgettoSuccess = createAction("FETCH_TEMPO_REALIZZAZIONE_PROGETTO_SUCCESS@common");

export const fetchTempoRealizzazioneProgettoFailed = createAction("FETCH_TEMPO_REALIZZAZIONE_PROGETTO_FAILED@common");

export const fetchTematicaProgetto = createAction("FETCH_TEMATICA_PROGETTO@common");

export const fetchTematicaProgettoSuccess = createAction("FETCH_TEMATICA_PROGETTO_SUCCESS@common");

export const fetchTematicaProgettoFailed = createAction("FETCH_TEMATICA_PROGETTO_FAILED@common");

export const fetchOrdineScuola = createAction("FETCH_ORDINE_SCUOLA@common");

export const fetchOrdineScuolaSuccess = createAction("FETCH_ORDINE_SCUOLA_SUCCESS@common");

export const fetchOrdineScuolaFailed = createAction("FETCH_ORDINE_SCUOLA_FAILED@common");

export const fetchFondiContestoProgetto = createAction("FETCH_FONDI_CONTESTO_PROGETTO@common");

export const fetchFondiContestoProgettoSuccess = createAction("FETCH_FONDI_CONTESTO_PROGETTO_SUCCESS@common");

export const fetchFondiContestoProgettoFailed = createAction("FETCH_FONDI_CONTESTO_PROGETTO_FAILED@common");

export const fetchComposizioneGruppoStudenti = createAction("FETCH_COMPOSIZIONE_GRUPPO_STUDENTI@common");

export const fetchComposizioneGruppoStudentiSuccess = createAction("FETCH_COMPOSIZIONE_GRUPPO_STUDENTI_SUCCESS@common");

export const fetchComposizioneGruppoStudentiFailed = createAction("FETCH_COMPOSIZIONE_GRUPPO_STUDENTI_FAILED@common");

export const fetchAttivitaDigitale = createAction("FETCH_ATTIVITA_DIGITALE@common");

export const fetchAttivitaDigitaleSuccess = createAction("FETCH_ATTIVITA_DIGITALE_SUCCESS@common");

export const fetchAttivitaDigitaleFailed = createAction("FETCH_ATTIVITA_DIGITALE_FAILED@common");

export const fetchAreaCoinvolta = createAction("FETCH_AREA_COINVOLTA@common");

export const fetchAreaCoinvoltaSuccess = createAction("FETCH_AREA_COINVOLTA_SUCCESS@common");

export const fetchAreaCoinvoltaFailed = createAction("FETCH_AREA_COINVOLTA_FAILED@common");

export const fetchAnnoScolasticoRealizzazione = createAction("FETCH_ANNO_SCOLASTICO_REALIZZAZIONE@common");

export const fetchAnnoScolasticoRealizzazioneSuccess = createAction("FETCH_ANNO_SCOLASTICO_REALIZZAZIONE_SUCCESS@common");

export const fetchAnnoScolasticoRealizzazioneFailed = createAction("FETCH_ANNO_SCOLASTICO_REALIZZAZIONE_FAILED@common");

export const fetchTitolo = createAction("FETCH_TITOLO@common");

export const fetchTitoloSuccess = createAction("FETCH_TITOLO_SUCCESS@common");

export const fetchTitoloFailed = createAction("FETCH_TITOLO_FAILED@common");

export const fetchAzioniDiffusioneDisseminazione = createAction("FETCH_AZIONI_DIFFUSIONE_DISSEMINAZIONE@common");

export const fetchAzioniDiffusioneDisseminazioneSuccess = createAction("FETCH_AZIONI_DIFFUSIONE_DISSEMINAZIONE_SUCCESS@common");

export const fetchAzioniDiffusioneDisseminazioneFailed = createAction("FETCH_AZIONI_DIFFUSIONE_DISSEMINAZIONE_FAILED@common");

export const fetchAttivitaValutazioneEfficaciaProgetto = createAction("FETCH_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO@common");

export const fetchAttivitaValutazioneEfficaciaProgettoSuccess = createAction("FETCH_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_SUCCESS@common");

export const fetchAttivitaValutazioneEfficaciaProgettoFailed = createAction("FETCH_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_FAILED@common");

export const fetchReferenti = createAction("FETCH_REFERENTI@common");

export const fetchReferentiSuccess = createAction("FETCH_REFERENTI_SUCCESS@common");

export const fetchReferentiFailed = createAction("FETCH_REFERENTI_FAILED@common");

export const fetchCoReferenti = createAction("FETCH_CO_REFERENTI@common");

export const fetchCoReferentiSuccess = createAction("FETCH_CO_REFERENTI_SUCCESS@common");

export const fetchCoReferentiFailed = createAction("FETCH_CO_REFERENTI_FAILED@common");

export const fetchCoResponsabili = createAction("FETCH_CO_RESPONSABILI@common");

export const fetchCoResponsabiliSuccess = createAction("FETCH_CO_RESPONSABILI_SUCCESS@common");

export const fetchCoResponsabiliFailed = createAction("FETCH_CO_RESPONSABILI_FAILED@common");

