import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";
import {
  fetchEfficaciaProgetto,
  fetchEfficaciaProgettoSuccess,
  fetchEfficaciaProgettoFailed,
  fetchTipologiaProgetto,
  fetchTipologiaProgettoSuccess,
  fetchTipologiaProgettoFailed,
  fetchTipologiaIstituto,
  fetchTipologiaIstitutoSuccess,
  fetchTipologiaIstitutoFailed,
  fetchTipologiaStudentiBes,
  fetchTipologiaStudentiBesSuccess,
  fetchTipologiaStudentiBesFailed,
  fetchStatoPubblicazione,
  fetchStatoPubblicazioneSuccess,
  fetchStatoPubblicazioneFailed,
  fetchProvince,
  fetchProvinceSuccess,
  fetchProvinceFailed,
  fetchIstitutiScolastici,
  fetchIstitutiScolasticiSuccess,
  fetchIstitutiScolasticiFailed,
  fetchTipologiaDocumentazione,
  fetchTipologiaDocumentazioneSuccess,
  fetchTipologiaDocumentazioneFailed,
  fetchContestoAccompagnamento,
  fetchContestoAccompagnamentoSuccess,
  fetchContestoAccompagnamentoFailed,
  fetchIncarichi,
  fetchIncarichiSuccess,
  fetchIncarichiFailed,
  fetchIndirizzoQualificaIstituto,
  fetchIndirizzoQualificaIstitutoSuccess,
  fetchIndirizzoQualificaIstitutoFailed,
  fetchAttivitaCurricolari,
  fetchAttivitaCurricolariSuccess,
  fetchAttivitaCurricolariFailed,
  fetchAttivitaClil,
  fetchAttivitaClilSuccess,
  fetchAttivitaClilFailed,
  fetchTipologiaBeneficiarioProgetto,
  fetchTipologiaBeneficiarioProgettoSuccess,
  fetchTipologiaBeneficiarioProgettoFailed,
  fetchTempoRealizzazioneProgetto,
  fetchTempoRealizzazioneProgettoSuccess,
  fetchTempoRealizzazioneProgettoFailed,
  fetchTematicaProgetto,
  fetchTematicaProgettoSuccess,
  fetchTematicaProgettoFailed,
  fetchOrdineScuola,
  fetchOrdineScuolaSuccess,
  fetchOrdineScuolaFailed,
  fetchFondiContestoProgetto,
  fetchFondiContestoProgettoSuccess,
  fetchFondiContestoProgettoFailed,
  fetchComposizioneGruppoStudenti,
  fetchComposizioneGruppoStudentiSuccess,
  fetchComposizioneGruppoStudentiFailed,
  fetchAttivitaDigitale,
  fetchAttivitaDigitaleSuccess,
  fetchAttivitaDigitaleFailed,
  fetchAreaCoinvolta,
  fetchAreaCoinvoltaSuccess,
  fetchAreaCoinvoltaFailed,
  fetchAnnoScolasticoRealizzazione,
  fetchAnnoScolasticoRealizzazioneSuccess,
  fetchAnnoScolasticoRealizzazioneFailed,
  fetchTitolo,
  fetchTitoloSuccess,
  fetchTitoloFailed,
  fetchAzioniDiffusioneDisseminazione,
  fetchAzioniDiffusioneDisseminazioneSuccess,
  fetchAzioniDiffusioneDisseminazioneFailed,
  fetchAttivitaValutazioneEfficaciaProgetto,
  fetchAttivitaValutazioneEfficaciaProgettoSuccess,
  fetchAttivitaValutazioneEfficaciaProgettoFailed,
  fetchReferentiSuccess,
  fetchReferentiFailed,
  fetchReferenti,
  fetchCoReferentiSuccess,
  fetchCoReferentiFailed,
  fetchCoReferenti,
  fetchCoResponsabili,
  fetchCoResponsabiliSuccess,
  fetchCoResponsabiliFailed,
  fetchAttivitaCurricolariRicercaSuccess,
  fetchAttivitaCurricolariRicerca,
  fetchAttivitaCurricolariRicercaFailed,
} from "./index";

export const initialState = {
  efficaciaProgetto: {
    data: null,
    fetchEnded: false,
  },
  tipologieProgetto: {
    data: null,
    fetchEnded: false,
  },
  tipologieIstituto: {
    data: null,
    fetchEnded: false,
  },
  tipologieStudentiBes: {
    data: null,
    fetchEnded: false,
  },
  statiPubblicazione: {
    data: null,
    fetchEnded: false,
  },
  province: {
    data: null,
    fetchEnded: false,
  },
  istitutiScolastici: {
    data: null,
    fetchEnded: false,
  },
  tipologieDocumentazione: {
    data: null,
    fetchEnded: false,
  },
  contestiAccompagnamento: {
    data: null,
    fetchEnded: false,
  },
  incarichi: {
    data: null,
    fetchEnded: false,
  },
  indirizziQualificaIstituto: {
    data: null,
    fetchEnded: false,
  },
  attivitaCurricolari: {
    data: null,
    fetchEnded: false,
  },
  attivitaCurricolariRicerca: {
    data: null,
    fetchEnded: false,
  },
  attivitaClil: {
    data: null,
    fetchEnded: false,
  },
  tipologieBeneficiarioProgetto: {
    data: null,
    fetchEnded: false,
  },
  tempoRealizzazioneProgetto: {
    data: null,
    fetchEnded: false,
  },
  tematicheProgetto: {
    data: null,
    fetchEnded: false,
  },
  ordiniScuola: {
    data: null,
    fetchEnded: false,
  },
  fondiContestoProgetto: {
    data: null,
    fetchEnded: false,
  },
  composizioneGruppoStudenti: {
    data: null,
    fetchEnded: false,
  },
  attivitaDigitali: {
    data: null,
    fetchEnded: false,
  },
  areeCoinvolte: {
    data: null,
    fetchEnded: false,
  },
  anniScolasticiRealizzazione: {
    data: null,
    fetchEnded: false,
  },
  titoli: {
    data: null,
    fetchEnded: false,
  },
  azioniDiffusioneDisseminazione: {
    data: null,
    fetchEnded: false,
  },
  attivitaValutazioneEfficaciaProgetto: {
    data: null,
    fetchEnded: false,
  },
  referenti: {
    data: null,
    fetchEnded: false,
  },
  coResponsabili: {
    data: null,
    fetchEnded: false,
  },
  coReferenti: {
    data: null,
    fetchEnded: false,
  },
};

const fetchEfficaciaProgettoHandler = (state) => {
  return update(state, {
    efficaciaProgetto: { $set: initialState.efficaciaProgetto },
  });
};

const fetchEfficaciaProgettoSuccessHandler = (state, action) => {
  return update(state, {
    efficaciaProgetto: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchEfficaciaProgettoFailedHandler = (state) => {
  return update(state, {
    efficaciaProgetto: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipologiaProgettoHandler = (state) => {
  return update(state, {
    tipologieProgetto: { $set: initialState.tipologieProgetto },
  });
};

const fetchTipologiaProgettoSuccessHandler = (state, action) => {
  return update(state, {
    tipologieProgetto: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipologiaProgettoFailedHandler = (state) => {
  return update(state, {
    tipologieProgetto: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipologiaIstitutoHandler = (state) => {
  return update(state, {
    tipologieIstituto: { $set: initialState.tipologieIstituto },
  });
};

const fetchTipologiaIstitutoSuccessHandler = (state, action) => {
  return update(state, {
    tipologieIstituto: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipologiaIstitutoFailedHandler = (state) => {
  return update(state, {
    tipologieIstituto: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipologiaStudentiBesHandler = (state) => {
  return update(state, {
    tipologieStudentiBes: { $set: initialState.tipologieStudentiBes },
  });
};

const fetchTipologiaStudentiBesSuccessHandler = (state, action) => {
  return update(state, {
    tipologieStudentiBes: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipologiaStudentiBesFailedHandler = (state) => {
  return update(state, {
    tipologieStudentiBes: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchStatoPubblicazioneHandler = (state) => {
  return update(state, {
    statiPubblicazione: { $set: initialState.statiPubblicazione },
  });
};

const fetchStatoPubblicazioneSuccessHandler = (state, action) => {
  return update(state, {
    statiPubblicazione: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchStatoPubblicazioneFailedHandler = (state) => {
  return update(state, {
    statiPubblicazione: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProvinceHandler = (state) => {
  return update(state, {
    province: { $set: initialState.province },
  });
};

const fetchProvinceSuccessHandler = (state, action) => {
  return update(state, {
    province: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProvinceFailedHandler = (state) => {
  return update(state, {
    province: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIstitutiScolasticiHandler = (state) => {
  return update(state, {
    istitutiScolastici: { $set: initialState.istitutiScolastici },
  });
};

const fetchIstitutiScolasticiSuccessHandler = (state, action) => {
  return update(state, {
    istitutiScolastici: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIstitutiScolasticiFailedHandler = (state) => {
  return update(state, {
    istitutiScolastici: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipologiaDocumentazioneHandler = (state) => {
  return update(state, {
    tipologieDocumentazione: { $set: initialState.tipologieDocumentazione },
  });
};

const fetchTipologiaDocumentazioneSuccessHandler = (state, action) => {
  return update(state, {
    tipologieDocumentazione: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipologiaDocumentazioneFailedHandler = (state) => {
  return update(state, {
    tipologieDocumentazione: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchContestoAccompagnamentoHandler = (state) => {
  return update(state, {
    contestiAccompagnamento: { $set: initialState.contestiAccompagnamento },
  });
};

const fetchContestoAccompagnamentoSuccessHandler = (state, action) => {
  return update(state, {
    contestiAccompagnamento: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchContestoAccompagnamentoFailedHandler = (state) => {
  return update(state, {
    contestiAccompagnamento: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIncarichiHandler = (state) => {
  return update(state, {
    incarichi: { $set: initialState.incarichi },
  });
};

const fetchIncarichiSuccessHandler = (state, action) => {
  return update(state, {
    incarichi: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIncarichiFailedHandler = (state) => {
  return update(state, {
    incarichi: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIndirizzoQualificaIstitutoHandler = (state) => {
  return update(state, {
    indirizziQualificaIstituto: { $set: initialState.indirizziQualificaIstituto },
  });
};

const fetchIndirizzoQualificaIstitutoSuccessHandler = (state, action) => {
  return update(state, {
    indirizziQualificaIstituto: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIndirizzoQualificaIstitutoFailedHandler = (state) => {
  return update(state, {
    indirizziQualificaIstituto: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaCurricolariHandler = (state) => {
  return update(state, {
    attivitaCurricolari: { $set: initialState.attivitaCurricolari },
  });
};

const fetchAttivitaCurricolariSuccessHandler = (state, action) => {
  return update(state, {
    attivitaCurricolari: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaCurricolariFailedHandler = (state) => {
  return update(state, {
    attivitaCurricolari: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaCurricolariRicercaHandler = (state) => {
  return update(state, {
    attivitaCurricolariRicerca: { $set: initialState.attivitaCurricolari },
  });
};

const fetchAttivitaCurricolariRicercaSuccessHandler = (state, action) => {
  return update(state, {
    attivitaCurricolariRicerca: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaCurricolariRicercaFailedHandler = (state) => {
  return update(state, {
    attivitaCurricolariRicerca: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaClilHandler = (state) => {
  return update(state, {
    attivitaClil: { $set: initialState.attivitaClil },
  });
};

const fetchAttivitaClilSuccessHandler = (state, action) => {
  return update(state, {
    attivitaClil: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaClilFailedHandler = (state) => {
  return update(state, {
    attivitaClil: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipologiaBeneficiarioProgettoHandler = (state) => {
  return update(state, {
    tipologieBeneficiarioProgetto: { $set: initialState.tipologieBeneficiarioProgetto },
  });
};

const fetchTipologiaBeneficiarioProgettoSuccessHandler = (state, action) => {
  return update(state, {
    tipologieBeneficiarioProgetto: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTipologiaBeneficiarioProgettoFailedHandler = (state) => {
  return update(state, {
    tipologieBeneficiarioProgetto: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTempoRealizzazioneProgettoHandler = (state) => {
  return update(state, {
    tempoRealizzazioneProgetto: { $set: initialState.tempoRealizzazioneProgetto },
  });
};

const fetchTempoRealizzazioneProgettoSuccessHandler = (state, action) => {
  return update(state, {
    tempoRealizzazioneProgetto: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTempoRealizzazioneProgettoFailedHandler = (state) => {
  return update(state, {
    tempoRealizzazioneProgetto: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTematicaProgettoHandler = (state) => {
  return update(state, {
    tematicheProgetto: { $set: initialState.tematicheProgetto },
  });
};

const fetchTematicaProgettoSuccessHandler = (state, action) => {
  return update(state, {
    tematicheProgetto: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTematicaProgettoFailedHandler = (state) => {
  return update(state, {
    tematicheProgetto: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchOrdineScuolaHandler = (state) => {
  return update(state, {
    ordiniScuola: { $set: initialState.ordiniScuola },
  });
};

const fetchOrdineScuolaSuccessHandler = (state, action) => {
  return update(state, {
    ordiniScuola: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchOrdineScuolaFailedHandler = (state) => {
  return update(state, {
    ordiniScuola: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchFondiContestoProgettoHandler = (state) => {
  return update(state, {
    fondiContestoProgetto: { $set: initialState.fondiContestoProgetto },
  });
};

const fetchFondiContestoProgettoSuccessHandler = (state, action) => {
  return update(state, {
    fondiContestoProgetto: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchFondiContestoProgettoFailedHandler = (state) => {
  return update(state, {
    fondiContestoProgetto: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchComposizioneGruppoStudentiHandler = (state) => {
  return update(state, {
    composizioneGruppoStudenti: { $set: initialState.composizioneGruppoStudenti },
  });
};

const fetchComposizioneGruppoStudentiSuccessHandler = (state, action) => {
  return update(state, {
    composizioneGruppoStudenti: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchComposizioneGruppoStudentiFailedHandler = (state) => {
  return update(state, {
    composizioneGruppoStudenti: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaDigitaleHandler = (state) => {
  return update(state, {
    attivitaDigitali: { $set: initialState.attivitaDigitali },
  });
};

const fetchAttivitaDigitaleSuccessHandler = (state, action) => {
  return update(state, {
    attivitaDigitali: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaDigitaleFailedHandler = (state) => {
  return update(state, {
    attivitaDigitali: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAreaCoinvoltaHandler = (state) => {
  return update(state, {
    areeCoinvolte: { $set: initialState.areeCoinvolte },
  });
};

const fetchAreaCoinvoltaSuccessHandler = (state, action) => {
  return update(state, {
    areeCoinvolte: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAreaCoinvoltaFailedHandler = (state) => {
  return update(state, {
    areeCoinvolte: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAnnoScolasticoRealizzazioneHandler = (state) => {
  return update(state, {
    anniScolasticiRealizzazione: { $set: initialState.anniScolasticiRealizzazione },
  });
};

const fetchAnnoScolasticoRealizzazioneSuccessHandler = (state, action) => {
  return update(state, {
    anniScolasticiRealizzazione: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAnnoScolasticoRealizzazioneFailedHandler = (state) => {
  return update(state, {
    anniScolasticiRealizzazione: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTitoloHandler = (state) => {
  return update(state, {
    titoli: { $set: initialState.titoli },
  });
};

const fetchTitoloSuccessHandler = (state, action) => {
  return update(state, {
    titoli: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchTitoloFailedHandler = (state) => {
  return update(state, {
    titoli: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAzioniDiffusioneDisseminazioneHandler = (state) => {
  return update(state, {
    azioniDiffusioneDisseminazione: { $set: initialState.azioniDiffusioneDisseminazione },
  });
};

const fetchAzioniDiffusioneDisseminazioneSuccessHandler = (state, action) => {
  return update(state, {
    azioniDiffusioneDisseminazione: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAzioniDiffusioneDisseminazioneFailedHandler = (state) => {
  return update(state, {
    azioniDiffusioneDisseminazione: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaValutazioneEfficaciaProgettoHandler = (state) => {
  return update(state, {
    attivitaValutazioneEfficaciaProgetto: { $set: initialState.attivitaValutazioneEfficaciaProgetto },
  });
};

const fetchAttivitaValutazioneEfficaciaProgettoSuccessHandler = (state, action) => {
  return update(state, {
    attivitaValutazioneEfficaciaProgetto: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchAttivitaValutazioneEfficaciaProgettoFailedHandler = (state) => {
  return update(state, {
    attivitaValutazioneEfficaciaProgetto: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchReferentiHandler = (state) => {
  return update(state, {
    referenti: { $set: initialState.referenti },
  });
};

const fetchReferentiSuccessHandler = (state, action) => {
  return update(state, {
    referenti: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchReferentiFailedHandler = (state) => {
  return update(state, {
    referenti: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchCoReferentiHandler = (state) => {
  return update(state, {
    coReferenti: { $set: initialState.referenti },
  });
};

const fetchCoReferentiSuccessHandler = (state, action) => {
  return update(state, {
    coReferenti: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchCoReferentiFailedHandler = (state) => {
  return update(state, {
    coReferenti: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchCoResponsabiliHandler = (state) => {
  return update(state, {
    coResponsabili: { $set: initialState.coResponsabili },
  });
};

const fetchCoResponsabiliSuccessHandler = (state, action) => {
  return update(state, {
    coResponsabili: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchCoResponsabiliFailedHandler = (state) => {
  return update(state, {
    coResponsabili: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEfficaciaProgetto, (state) => fetchEfficaciaProgettoHandler(state));
    builder.addCase(fetchEfficaciaProgettoSuccess, (state, action) => fetchEfficaciaProgettoSuccessHandler(state, action));
    builder.addCase(fetchEfficaciaProgettoFailed, (state) => fetchEfficaciaProgettoFailedHandler(state));
    builder.addCase(fetchTipologiaProgetto, (state) => fetchTipologiaProgettoHandler(state));
    builder.addCase(fetchTipologiaProgettoSuccess, (state, action) => fetchTipologiaProgettoSuccessHandler(state, action));
    builder.addCase(fetchTipologiaProgettoFailed, (state) => fetchTipologiaProgettoFailedHandler(state));
    builder.addCase(fetchTipologiaIstituto, (state) => fetchTipologiaIstitutoHandler(state));
    builder.addCase(fetchTipologiaIstitutoSuccess, (state, action) => fetchTipologiaIstitutoSuccessHandler(state, action));
    builder.addCase(fetchTipologiaIstitutoFailed, (state) => fetchTipologiaIstitutoFailedHandler(state));
    builder.addCase(fetchTipologiaStudentiBes, (state) => fetchTipologiaStudentiBesHandler(state));
    builder.addCase(fetchTipologiaStudentiBesSuccess, (state, action) => fetchTipologiaStudentiBesSuccessHandler(state, action));
    builder.addCase(fetchTipologiaStudentiBesFailed, (state) => fetchTipologiaStudentiBesFailedHandler(state));
    builder.addCase(fetchStatoPubblicazione, (state) => fetchStatoPubblicazioneHandler(state));
    builder.addCase(fetchStatoPubblicazioneSuccess, (state, action) => fetchStatoPubblicazioneSuccessHandler(state, action));
    builder.addCase(fetchStatoPubblicazioneFailed, (state) => fetchStatoPubblicazioneFailedHandler(state));
    builder.addCase(fetchProvince, (state) => fetchProvinceHandler(state));
    builder.addCase(fetchProvinceSuccess, (state, action) => fetchProvinceSuccessHandler(state, action));
    builder.addCase(fetchProvinceFailed, (state) => fetchProvinceFailedHandler(state));
    builder.addCase(fetchIstitutiScolastici, (state) => fetchIstitutiScolasticiHandler(state));
    builder.addCase(fetchIstitutiScolasticiSuccess, (state, action) => fetchIstitutiScolasticiSuccessHandler(state, action));
    builder.addCase(fetchIstitutiScolasticiFailed, (state) => fetchIstitutiScolasticiFailedHandler(state));
    builder.addCase(fetchTipologiaDocumentazione, (state) => fetchTipologiaDocumentazioneHandler(state));
    builder.addCase(fetchTipologiaDocumentazioneSuccess, (state, action) => fetchTipologiaDocumentazioneSuccessHandler(state, action));
    builder.addCase(fetchTipologiaDocumentazioneFailed, (state) => fetchTipologiaDocumentazioneFailedHandler(state));
    builder.addCase(fetchContestoAccompagnamento, (state) => fetchContestoAccompagnamentoHandler(state));
    builder.addCase(fetchContestoAccompagnamentoSuccess, (state, action) => fetchContestoAccompagnamentoSuccessHandler(state, action));
    builder.addCase(fetchContestoAccompagnamentoFailed, (state) => fetchContestoAccompagnamentoFailedHandler(state));
    builder.addCase(fetchIncarichi, (state) => fetchIncarichiHandler(state));
    builder.addCase(fetchIncarichiSuccess, (state, action) => fetchIncarichiSuccessHandler(state, action));
    builder.addCase(fetchIncarichiFailed, (state) => fetchIncarichiFailedHandler(state));
    builder.addCase(fetchIndirizzoQualificaIstituto, (state) => fetchIndirizzoQualificaIstitutoHandler(state));
    builder.addCase(fetchIndirizzoQualificaIstitutoSuccess, (state, action) => fetchIndirizzoQualificaIstitutoSuccessHandler(state, action));
    builder.addCase(fetchIndirizzoQualificaIstitutoFailed, (state) => fetchIndirizzoQualificaIstitutoFailedHandler(state));
    builder.addCase(fetchAttivitaCurricolari, (state) => fetchAttivitaCurricolariHandler(state));
    builder.addCase(fetchAttivitaCurricolariSuccess, (state, action) => fetchAttivitaCurricolariSuccessHandler(state, action));
    builder.addCase(fetchAttivitaCurricolariFailed, (state) => fetchAttivitaCurricolariFailedHandler(state));
    builder.addCase(fetchAttivitaCurricolariRicerca, (state) => fetchAttivitaCurricolariRicercaHandler(state));
    builder.addCase(fetchAttivitaCurricolariRicercaSuccess, (state, action) => fetchAttivitaCurricolariRicercaSuccessHandler(state, action));
    builder.addCase(fetchAttivitaCurricolariRicercaFailed, (state) => fetchAttivitaCurricolariRicercaFailedHandler(state));
    builder.addCase(fetchAttivitaClil, (state) => fetchAttivitaClilHandler(state));
    builder.addCase(fetchAttivitaClilSuccess, (state, action) => fetchAttivitaClilSuccessHandler(state, action));
    builder.addCase(fetchAttivitaClilFailed, (state) => fetchAttivitaClilFailedHandler(state));
    builder.addCase(fetchTipologiaBeneficiarioProgetto, (state) => fetchTipologiaBeneficiarioProgettoHandler(state));
    builder.addCase(fetchTipologiaBeneficiarioProgettoSuccess, (state, action) => fetchTipologiaBeneficiarioProgettoSuccessHandler(state, action));
    builder.addCase(fetchTipologiaBeneficiarioProgettoFailed, (state) => fetchTipologiaBeneficiarioProgettoFailedHandler(state));
    builder.addCase(fetchTempoRealizzazioneProgetto, (state) => fetchTempoRealizzazioneProgettoHandler(state));
    builder.addCase(fetchTempoRealizzazioneProgettoSuccess, (state, action) => fetchTempoRealizzazioneProgettoSuccessHandler(state, action));
    builder.addCase(fetchTempoRealizzazioneProgettoFailed, (state) => fetchTempoRealizzazioneProgettoFailedHandler(state));
    builder.addCase(fetchTematicaProgetto, (state) => fetchTematicaProgettoHandler(state));
    builder.addCase(fetchTematicaProgettoSuccess, (state, action) => fetchTematicaProgettoSuccessHandler(state, action));
    builder.addCase(fetchTematicaProgettoFailed, (state) => fetchTematicaProgettoFailedHandler(state));
    builder.addCase(fetchOrdineScuola, (state) => fetchOrdineScuolaHandler(state));
    builder.addCase(fetchOrdineScuolaSuccess, (state, action) => fetchOrdineScuolaSuccessHandler(state, action));
    builder.addCase(fetchOrdineScuolaFailed, (state) => fetchOrdineScuolaFailedHandler(state));
    builder.addCase(fetchFondiContestoProgetto, (state) => fetchFondiContestoProgettoHandler(state));
    builder.addCase(fetchFondiContestoProgettoSuccess, (state, action) => fetchFondiContestoProgettoSuccessHandler(state, action));
    builder.addCase(fetchFondiContestoProgettoFailed, (state) => fetchFondiContestoProgettoFailedHandler(state));
    builder.addCase(fetchComposizioneGruppoStudenti, (state) => fetchComposizioneGruppoStudentiHandler(state));
    builder.addCase(fetchComposizioneGruppoStudentiSuccess, (state, action) => fetchComposizioneGruppoStudentiSuccessHandler(state, action));
    builder.addCase(fetchComposizioneGruppoStudentiFailed, (state) => fetchComposizioneGruppoStudentiFailedHandler(state));
    builder.addCase(fetchAttivitaDigitale, (state) => fetchAttivitaDigitaleHandler(state));
    builder.addCase(fetchAttivitaDigitaleSuccess, (state, action) => fetchAttivitaDigitaleSuccessHandler(state, action));
    builder.addCase(fetchAttivitaDigitaleFailed, (state) => fetchAttivitaDigitaleFailedHandler(state));
    builder.addCase(fetchAreaCoinvolta, (state) => fetchAreaCoinvoltaHandler(state));
    builder.addCase(fetchAreaCoinvoltaSuccess, (state, action) => fetchAreaCoinvoltaSuccessHandler(state, action));
    builder.addCase(fetchAreaCoinvoltaFailed, (state) => fetchAreaCoinvoltaFailedHandler(state));
    builder.addCase(fetchAnnoScolasticoRealizzazione, (state) => fetchAnnoScolasticoRealizzazioneHandler(state));
    builder.addCase(fetchAnnoScolasticoRealizzazioneSuccess, (state, action) => fetchAnnoScolasticoRealizzazioneSuccessHandler(state, action));
    builder.addCase(fetchAnnoScolasticoRealizzazioneFailed, (state) => fetchAnnoScolasticoRealizzazioneFailedHandler(state));
    builder.addCase(fetchTitolo, (state) => fetchTitoloHandler(state));
    builder.addCase(fetchTitoloSuccess, (state, action) => fetchTitoloSuccessHandler(state, action));
    builder.addCase(fetchTitoloFailed, (state) => fetchTitoloFailedHandler(state));
    builder.addCase(fetchAzioniDiffusioneDisseminazione, (state) => fetchAzioniDiffusioneDisseminazioneHandler(state));
    builder.addCase(fetchAzioniDiffusioneDisseminazioneSuccess, (state, action) => fetchAzioniDiffusioneDisseminazioneSuccessHandler(state, action));
    builder.addCase(fetchAzioniDiffusioneDisseminazioneFailed, (state) => fetchAzioniDiffusioneDisseminazioneFailedHandler(state));
    builder.addCase(fetchAttivitaValutazioneEfficaciaProgetto, (state) => fetchAttivitaValutazioneEfficaciaProgettoHandler(state));
    builder.addCase(fetchAttivitaValutazioneEfficaciaProgettoSuccess, (state, action) => fetchAttivitaValutazioneEfficaciaProgettoSuccessHandler(state, action)); //prettier-ignore
    builder.addCase(fetchAttivitaValutazioneEfficaciaProgettoFailed, (state) => fetchAttivitaValutazioneEfficaciaProgettoFailedHandler(state));
    builder.addCase(fetchReferenti, (state) => fetchReferentiHandler(state));
    builder.addCase(fetchReferentiSuccess, (state, action) => fetchReferentiSuccessHandler(state, action));
    builder.addCase(fetchReferentiFailed, (state) => fetchReferentiFailedHandler(state));
    builder.addCase(fetchCoReferenti, (state) => fetchCoReferentiHandler(state));
    builder.addCase(fetchCoReferentiSuccess, (state, action) => fetchCoReferentiSuccessHandler(state, action));
    builder.addCase(fetchCoReferentiFailed, (state) => fetchCoReferentiFailedHandler(state));
    builder.addCase(fetchCoResponsabili, (state) => fetchCoResponsabiliHandler(state));
    builder.addCase(fetchCoResponsabiliSuccess, (state, action) => fetchCoResponsabiliSuccessHandler(state, action));
    builder.addCase(fetchCoResponsabiliFailed, (state) => fetchCoResponsabiliFailedHandler(state));
  },
});

export const commonReducer = commonSlice.reducer;
