import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { PersonOff as PersonOffIcon } from "@mui/icons-material/";
import { labels } from "../../../labels";

const { PROFILO_DISABILITA_UTENTE } = labels;

export const DisabilitaButton = ({ onClick }) => (
  <Grid item xs={12} className="disabilitaButton">
    <Stack>
      <IconButton onClick={onClick} aria-label="Disabilita profilo">
        <PersonOffIcon />
      </IconButton>
      <Typography>{PROFILO_DISABILITA_UTENTE}</Typography>
    </Stack>
  </Grid>
);
