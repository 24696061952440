export const Back = (props) => (
  <svg width="47.5" height="56" viewBox="0 0 47.5 56" {...props}>
    <g id="Raggruppa_2131" data-name="Raggruppa 2131" transform="translate(6202 -10947.5)">
      <path
        id="Icon_ionic-ios-arrow-back"
        data-name="Icon ionic-ios-arrow-back"
        d="M16.421,21.19,27.772,9.848A2.144,2.144,0,0,0,24.735,6.82L11.875,19.671a2.14,2.14,0,0,0-.063,2.956L24.727,35.568a2.144,2.144,0,0,0,3.037-3.028Z"
        transform="translate(-6213.25 10953.807)"
      />
      <line id="Linea_150" data-name="Linea 150" y2="56" transform="translate(-6156 10947.5)" fill="none" stroke="#000" strokeWidth="3" />
    </g>
  </svg>
);
