import { Grid, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { ArrowCircleRightOutlined as ArrowCircleRightOutlinedIcon } from "@mui/icons-material";
import { TabPanel } from "../../../../shared/TabPanelCustom";
import { labels } from "../../../../../labels";
import { SkeletonAnagraficaInnovazione } from "./SkeletonAnagraficaInnovazione";

const { ANAGRAFICA, ATTIVITA, DOCUMENTAZIONE, PUBBLICAZIONE } = labels;

const gestisciprogetti = [
  {
    id: "0",
    testo: ANAGRAFICA,
  },
  {
    id: "1",
    testo: ATTIVITA,
  },
  {
    id: "2",
    testo: DOCUMENTAZIONE,
  },
  {
    id: "3",
    testo: PUBBLICAZIONE,
  },
];

export const SkeletonMainInnovazione = () => {
  const indexTabs = 0;
  let indexString = indexTabs.toString();

  const color = (index) => {
    if (indexTabs === index) return "#FABE10";
    else {
      if (index < indexTabs) return "#FFE7A4";
      else return "#FFFFFF";
    }
  };

  const decoration = (index) => {
    if (indexTabs === index) return "underline";
    else return "none";
  };

  const visible = (id) => {
    if (id > 2) return false;
    else return true;
  };

  const cursor = (index) => {
    if (index > indexTabs) return "default";
  };

  return (
    <TabContext value={indexString}>
      <Grid container spacing={7} sx={{ marginTop: "-40px" }}>
        {gestisciprogetti.map((elemento, index) => (
          <Grid item xs={12} sm={visible(index) ? 3.2 : 1.5} md={5} xl={visible(index) ? 3.2 : 2.4} key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10} sm={10} xl={visible(index) ? 10 : 12}>
                <Tab
                  label={elemento.testo}
                  value={index}
                  disableRipple
                  disableFocusRipple
                  sx={{
                    bgcolor: color(index),
                    textDecoration: decoration(index),
                    cursor: cursor(index),
                  }}
                  className="tabSezioni"
                />
              </Grid>
              {visible(index) && (
                <Grid item xs={2} md={2} sm={2} xl={2}>
                  <ArrowCircleRightOutlinedIcon className="arrowCircleIconTab" />
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <TabPanel value={"0"} index={indexString}>
        <SkeletonAnagraficaInnovazione />
      </TabPanel>
    </TabContext>
  );
};
