import { createAction } from "@reduxjs/toolkit";

export const fetchProfilo = createAction("FETCH_PROFILO@profilo");

export const fetchProfiloSuccess = createAction("FETCH_PROFILO_SUCCESS@profilo");

export const fetchProfiloFailed = createAction("FETCH_PROFILO_FAILED@profilo");

export const checkPrivacy = createAction("CHECK_PRIVACY@profilo");

export const setPrivacy = createAction("SET_PRIVACY@profilo");

export const verificaRuoloLibero = createAction("VERIFICA_RUOLO_LIBERO@profilo");

export const verificaRuoloLiberoSuccess = createAction("VERIFICA_RUOLO_LIBERO_SUCCESS@profilo");

export const verificaRuoloLiberoFailed = createAction("VERIFICA_RUOLO_LIBERO_FAILED@profilo");

export const verificaRuoloLiberoError = createAction("VERIFICA_RUOLO_LIBERO_ERROR@profilo");

export const aggiornaProfilo = createAction("AGGIORNA_PROFILO@profilo");

export const aggiornaProfiloSuccess = createAction("AGGIORNA_PROFILO_SUCCESS@profilo");

export const aggiornaProfiloFailed = createAction("AGGIORNA_PROFILO_FAILED@profilo");

export const disabilitaProfilo = createAction("DISABILITA_PROFILO@profilo");

export const disabilitaProfiloSuccess = createAction("DISABILITA_PROFILO_SUCCESS@profilo");

export const disabilitaProfiloFailed = createAction("DISABILITA_PROFILO_FAILED@profilo");

export const resetSpinningProfilo = createAction("RESET_SPINNING_PROFILO@profilo");
