import { createSlice } from "@reduxjs/toolkit";
import update from "immutability-helper";
import { REDUX_ISTITUTO, REDUX_MEMBRI_TEAM, REDUX_ISTITUTI_SCOLASTICI, REDUX_PLESSI_ISTITUTO, REDUX_PROFILI_USCITA } from "../../constants";
import {
  fetchDatiIstitutiFailed,
  fetchDatiIstitutiSuccess,
  fetchDatiIstitutoFailed,
  fetchDatiIstitutoSuccess,
  fetchMembriTeamFailed,
  fetchMembriTeamSuccess,
  fetchPlessiIstitutoFailed,
  fetchPlessiIstitutoSuccess,
  fetchProfiliInUscitaFailed,
  fetchProfiliInUscitaSuccess,
  resetDatiIstituti,
} from "./";

const initialState = {
  //ISTITUTO
  [REDUX_ISTITUTI_SCOLASTICI]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_ISTITUTO]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_PLESSI_ISTITUTO]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_PROFILI_USCITA]: {
    data: [],
    fetchEnded: false,
  },

  [REDUX_MEMBRI_TEAM]: {
    data: [],
    fetchEnded: false,
  },
};
//ISTITUTI

const fetchIstitutiScSuccess = (state, action) => {
  return update(state, {
    [REDUX_ISTITUTI_SCOLASTICI]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIstitutiScFailed = (state, action) => {
  return update(state, {
    [REDUX_ISTITUTI_SCOLASTICI]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIstitutoSuccess = (state, action) => {
  return update(state, {
    [REDUX_ISTITUTO]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchIstitutoFailed = (state, action) => {
  return update(state, {
    [REDUX_ISTITUTO]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchPlessiIstSuccess = (state, action) => {
  return update(state, {
    [REDUX_PLESSI_ISTITUTO]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchPlessiIstFailed = (state, action) => {
  return update(state, {
    [REDUX_PLESSI_ISTITUTO]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProfiliUscSuccess = (state, action) => {
  return update(state, {
    [REDUX_PROFILI_USCITA]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchProfiliUscFailed = (state, action) => {
  return update(state, {
    [REDUX_PROFILI_USCITA]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const fetchMembriSuccess = (state, action) => {
  return update(state, {
    [REDUX_MEMBRI_TEAM]: {
      data: { $set: action.payload },
      fetchEnded: { $set: true },
    },
  });
};

const fetchMembriFailed = (state, action) => {
  return update(state, {
    [REDUX_MEMBRI_TEAM]: {
      data: { $set: [] },
      fetchEnded: { $set: true },
    },
  });
};

const resetDatiIstitutiHandler = (state) => {
  return update(state, {
    [REDUX_ISTITUTO]: { $set: initialState[REDUX_ISTITUTO] },
    [REDUX_PLESSI_ISTITUTO]: { $set: initialState[REDUX_PLESSI_ISTITUTO] },
    [REDUX_PROFILI_USCITA]: { $set: initialState[REDUX_PROFILI_USCITA] },
    [REDUX_MEMBRI_TEAM]: { $set: initialState[REDUX_MEMBRI_TEAM] },
  });
};

export const istitutiSlice = createSlice({
  name: "istituti",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //ISTITUTI
    builder.addCase(fetchDatiIstitutiSuccess, (state, action) => fetchIstitutiScSuccess(state, action));
    builder.addCase(fetchDatiIstitutiFailed, (state, action) => fetchIstitutiScFailed(state, action));
    builder.addCase(fetchDatiIstitutoSuccess, (state, action) => fetchIstitutoSuccess(state, action));
    builder.addCase(fetchDatiIstitutoFailed, (state, action) => fetchIstitutoFailed(state, action));
    builder.addCase(fetchPlessiIstitutoSuccess, (state, action) => fetchPlessiIstSuccess(state, action));
    builder.addCase(fetchPlessiIstitutoFailed, (state, action) => fetchPlessiIstFailed(state, action));
    builder.addCase(fetchProfiliInUscitaSuccess, (state, action) => fetchProfiliUscSuccess(state, action));
    builder.addCase(fetchProfiliInUscitaFailed, (state, action) => fetchProfiliUscFailed(state, action));
    builder.addCase(fetchMembriTeamSuccess, (state, action) => fetchMembriSuccess(state, action));
    builder.addCase(fetchMembriTeamFailed, (state, action) => fetchMembriFailed(state, action));
    builder.addCase(resetDatiIstituti, (state) => resetDatiIstitutiHandler(state));
  },
});

export const datiIstitutiReducer = istitutiSlice.reducer;
