import { styled } from "@mui/material/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid, Box } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import { getPlessiIstituto } from "../../../../modules/istituti";
import { aggiornaValoriSlice, getCountPagination, setCountPagination } from "../../../../modules/configuration";
import { useEffect, useState } from "react";
import { PaginationShared } from "../../../shared/Pagination";
import { labels } from "../../../../labels";
import { TableResponsivePlessiIst } from "../../../shared/TableResponsivePlessiIst";

const { DENOMINAZIONE, CODICE_MECCANOGRAFICO, INDIRIZZO, COMUNE, AVVISO_ASSENZA_PLESSI } = labels;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const PlessiIstituto = (props) => {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: props.color2,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const countPagination = useSelector(getCountPagination);
  const plessiIstituto = useSelector(getPlessiIstituto);
  let progetti = plessiIstituto.data;

  //cambiamento pagina aggiorno valori
  function handlechange(e, value) {
    dispatch(aggiornaValoriSlice(value));
    setPage(value);
  }

  const createData = (codiceMeccanografico, denominazione, comune, indirizzo) => ({
    codiceMeccanografico,
    denominazione,
    comune,
    indirizzo,
  });
  
  let rows = [""];
  if (progetti.length !== 0) {
    rows = progetti.map((elem, index) => createData(elem.codice_plesso, elem.nome, elem.descr_com, elem.indirizzo));
  }

  //calcolo paginazione  
  useEffect(() => {
    //set count pagination
    dispatch(setCountPagination(Math.ceil(progetti.length / 5)));
    // eslint-disable-next-line
  }, [progetti]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          "@media (max-width:960px)": {
            display: "none",
          },
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell color={props.color2} align="center">
                  <Typography sx={{ fontWeight: "Bold" }}>{CODICE_MECCANOGRAFICO}</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography sx={{ fontWeight: "Bold" }}>{DENOMINAZIONE}</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography sx={{ fontWeight: "Bold" }}>{COMUNE}</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography sx={{ fontWeight: "Bold" }}>{INDIRIZZO}</Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(props.valore1slice, props.valore2slice).map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">{row.codiceMeccanografico}</StyledTableCell>
                  <StyledTableCell align="center">{row.denominazione}</StyledTableCell>
                  <StyledTableCell align="center">{row.comune}</StyledTableCell>
                  <StyledTableCell align="center">{row.indirizzo}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginTop: "30px" }}>
          {countPagination > 1 ? <PaginationShared page={page} handlechange={handlechange} color={props.color1} count={countPagination} /> : null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "none",
            "@media (max-width:960px)": {
              display: "block",
            },
          }}
        >
          <TableResponsivePlessiIst borderColor={props.color1} color={props.color1} />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", marginBottom: "30px" }}>
        {progetti.length === 0 && <Typography sx={{ fontSize: "22px", fontWeight: "Bold" }}>{AVVISO_ASSENZA_PLESSI}</Typography>}
      </Grid>
    </Grid>
  );
};
