import { useDispatch } from "react-redux";
import { saveCopiaAttivita, saveFiltriProgetti, setAttivitaSelezionata, setAttivitaSelezionataTablet } from "../../../../modules/configuration";
import { Typography, Grid, CircularProgress, Paper, Button, Box, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { getSottoAttivitaId } from "../../../../modules/configuration";
import { labels } from "../../../../labels";
import { SwitchButton as Switch } from "./Switch";
import { SelectDescriptionModale } from "./SelectDescriptionModale";
import { useEffect } from "react";
import { HighlightOff } from "@mui/icons-material";

const { BUTTON_SALVA, TITOLO_MODALE_ATTIVITA, TESTO_MODALE_ATTIVITA, TESTO_MODALE_ATTIVITA_RICERCA, TITOLO_MODALE_ATTIVITA_RICERCA } = labels;

export const ModaleAttivita = (props) => {
  const dispatch = useDispatch();
  const setOpen = props.modal;
  const attivita = props.attivita;
  const numAttivita = attivita.length;
  const sottoAttivitaId = useSelector(getSottoAttivitaId);
  let sottAtt = new Map(JSON.parse(sottoAttivitaId));
  let arrayFiltrato = [];

  useEffect(() => {
    for (let [key] of sottAtt.entries()) {
      attivita.map((opt) => opt.finalita + " " + opt.descr_campo === key && arrayFiltrato.push(key));
    }
    if (props.attivitaSelezTablet === true) {
      dispatch(setAttivitaSelezionataTablet(arrayFiltrato[0]));
    } else {
      dispatch(setAttivitaSelezionata(arrayFiltrato[0]));
    }
    // eslint-disable-next-line
  }, [sottAtt]);

  const length = () => {
    let i = 0;
    for (let [key] of sottAtt.entries()) {
      // eslint-disable-next-line
      attivita.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? i++ : null));
    }
    return i;
  };

  return (
    <Box className="inserimentoProgetto">
      <Grid container className="modaleAttivita">
        <Grid item xs={12}>
          {" "}
          <Grid
            container
            sx={{
              paddingLeft: "50px",
              marginTop: "50px",
              "@media (max-width:460px)": { paddingLeft: "25px" },
            }}
          >
            {" "}
            <Grid item xs={5}>
              {props.textField === false ? (
                <Typography className="title">{TITOLO_MODALE_ATTIVITA}</Typography>
              ) : (
                <Typography className="title">{TITOLO_MODALE_ATTIVITA_RICERCA}</Typography>
              )}{" "}
            </Grid>
            <Grid item xs={6} sx={{ marginTop: "-7px" }}>
              {props.textField === false ? (
                <Box>
                  <CircularProgress variant="determinate" className="circularProgress" size={72} thickness={4} value={100} />
                  <Typography className="textProgressValue">
                    {length()} / {numAttivita}
                  </Typography>
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={1} className="positionIconCloseModaleAtt">
              <IconButton
                onClick={() => {
                  let tipo = props.tipo;
                  let section = props.section;
                  dispatch(saveCopiaAttivita({ section, tipo }));
                  setOpen(false);
                }}
              >
                <HighlightOff className="iconCloseModaleAtt" />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              xl={12}
              sx={{
                display: "block",
                "@media (max-width:460px)": { display: "none" },
              }}
            >
              {props.textField === false ? (
                <Typography className="descrizioneModale">{TESTO_MODALE_ATTIVITA}</Typography>
              ) : (
                <Typography className="descrizioneModale">{TESTO_MODALE_ATTIVITA_RICERCA}</Typography>
              )}
            </Grid>
            <Grid item xs={12} sx={{ margin: "10px" }}>
              <Switch attivitaProps={attivita} />
            </Grid>{" "}
            <Grid item xs={12}>
              <Paper className="paper">
                <SelectDescriptionModale
                  progress={props.progress}
                  progetto={props.progetto}
                  formik={props.formik}
                  attivita={attivita}
                  textField={props.textField}
                  tipo={props.tipo}
                  section={props.section}
                />
              </Paper>{" "}
            </Grid>{" "}
          </Grid>{" "}
        </Grid>
        <Grid item xs={12} className="center">
          <Button
            variant="contained"
            onClick={() => {
              dispatch(saveFiltriProgetti(false));
              setOpen(false);
            }}
            className="buttonModaleAttivita"
          >
            <Typography className="text">{BUTTON_SALVA}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
