import {
  PROFILO_COGNOME_API,
  PROFILO_NOME_API,
  PROFILO_CODICE_FISCALE_API,
  PROFILO_EMAIL_API,
  PROFILO_CELLULARE_API,
  PROFILO_BIOGRAFIA_API,
  PROFILO_AVATAR_API,
  PROFILO_SOCIAL_API,
  PROFILO_ISCRIZIONE_NEWSLETTER_API,
  PROFILO_DISABILITATO_API,
  PROFILO_TEAM_API,
  PROFILO_CODICE_SCUOLA_API,
  PROFILO_NOME_SCUOLA_API,
  PROFILO_CODICE_INCARICO_API,
  PROFILO_INCARICO_API,
  PROFILO_INCARICO_IN_APPROVAZIONE_API,
  PROFILO_RUOLO_API,
  PROFILO_DATA_INSERIMENTO_API,
  PROFILO_DATA_DISABILITAZIONE_API,
  // Valori JSON
  PROFILO_COGNOME,
  PROFILO_NOME,
  PROFILO_INIZIALI,
  PROFILO_CODICE_FISCALE,
  PROFILO_EMAIL,
  PROFILO_CELLULARE,
  PROFILO_BIOGRAFIA,
  PROFILO_AVATAR,
  PROFILO_SOCIAL,
  PROFILO_ISCRIZIONE_NEWSLETTER,
  PROFILO_DISABILITATO,
  PROFILO_TEAM,
  PROFILO_CODICE_SCUOLA,
  PROFILO_NOME_SCUOLA,
  PROFILO_CODICE_INCARICO,
  PROFILO_INCARICO,
  PROFILO_INCARICO_IN_APPROVAZIONE,
  PROFILO_RUOLO,
  PROFILO_DATA_INSERIMENTO,
  PROFILO_DATA_DISABILITAZIONE,
  PROFILO_DATI_CONFERMATI_API,
  PROFILO_DATI_CONFERMATI,
} from "./";

export const estraiDatiProfilo = (data) => ({
  [PROFILO_COGNOME]: data[PROFILO_COGNOME_API],
  [PROFILO_NOME]: data[PROFILO_NOME_API],
  [PROFILO_INIZIALI]: (data[PROFILO_NOME_API] + " " + data[PROFILO_COGNOME_API]).split(" ").map((word) => word[0]).join(""), // prettier-ignore
  [PROFILO_CODICE_FISCALE]: data[PROFILO_CODICE_FISCALE_API],
  [PROFILO_EMAIL]: data[PROFILO_EMAIL_API],
  [PROFILO_CELLULARE]: data[PROFILO_CELLULARE_API] !== 0 ? data[PROFILO_CELLULARE_API] : "",
  [PROFILO_BIOGRAFIA]: data[PROFILO_BIOGRAFIA_API] ? data[PROFILO_BIOGRAFIA_API] : "",
  [PROFILO_AVATAR]: data[PROFILO_AVATAR_API],
  [PROFILO_SOCIAL]: data[PROFILO_SOCIAL_API] ? data[PROFILO_SOCIAL_API] : "",
  [PROFILO_ISCRIZIONE_NEWSLETTER]: data[PROFILO_ISCRIZIONE_NEWSLETTER_API] === "NO" ? false : true,
  [PROFILO_DISABILITATO]: data[PROFILO_DISABILITATO_API] === "NO" ? false : true,
  [PROFILO_DATI_CONFERMATI]: data[PROFILO_DATI_CONFERMATI_API] === "NO" ? false : true,
  [PROFILO_TEAM]: data[PROFILO_TEAM_API],
  [PROFILO_CODICE_SCUOLA]: data[PROFILO_CODICE_SCUOLA_API],
  [PROFILO_NOME_SCUOLA]: data[PROFILO_NOME_SCUOLA_API],
  [PROFILO_CODICE_INCARICO]: data[PROFILO_CODICE_INCARICO_API],
  [PROFILO_INCARICO]: data[PROFILO_INCARICO_API],
  [PROFILO_INCARICO_IN_APPROVAZIONE]: data[PROFILO_INCARICO_IN_APPROVAZIONE_API] === "NO" ? true : false,
  [PROFILO_RUOLO]: data[PROFILO_RUOLO_API],
  [PROFILO_DATA_INSERIMENTO]: data[PROFILO_DATA_INSERIMENTO_API],
  [PROFILO_DATA_DISABILITAZIONE]: data[PROFILO_DATA_DISABILITAZIONE_API],
});

export const preparaDatiAggiornamento = (data) => ({
  [PROFILO_COGNOME_API]: data[PROFILO_COGNOME],
  [PROFILO_NOME_API]: data[PROFILO_NOME],
  [PROFILO_CODICE_FISCALE_API]: data[PROFILO_CODICE_FISCALE],
  [PROFILO_EMAIL_API]: data[PROFILO_EMAIL],
  [PROFILO_CELLULARE_API]: data[PROFILO_CELLULARE] !== "" ? data[PROFILO_CELLULARE] : 0,
  [PROFILO_BIOGRAFIA_API]: data[PROFILO_BIOGRAFIA] !== "" ? data[PROFILO_BIOGRAFIA] : null,
  [PROFILO_AVATAR_API]: data[PROFILO_AVATAR],
  [PROFILO_SOCIAL_API]: data[PROFILO_SOCIAL] !== "" ? data[PROFILO_SOCIAL] : null,
  [PROFILO_ISCRIZIONE_NEWSLETTER_API]: data[PROFILO_ISCRIZIONE_NEWSLETTER] ? "SI" : "NO",
  [PROFILO_DISABILITATO_API]: data[PROFILO_DISABILITATO] ? "SI" : "NO",
  [PROFILO_TEAM_API]: data[PROFILO_TEAM],
  [PROFILO_CODICE_SCUOLA_API]: data[PROFILO_CODICE_SCUOLA],
  [PROFILO_NOME_SCUOLA_API]: data[PROFILO_NOME_SCUOLA],
  [PROFILO_CODICE_INCARICO_API]: data[PROFILO_CODICE_INCARICO],
  [PROFILO_INCARICO_API]: data[PROFILO_INCARICO],
  [PROFILO_RUOLO_API]: data[PROFILO_RUOLO],
  [PROFILO_DATA_INSERIMENTO_API]: data[PROFILO_DATA_INSERIMENTO],
  [PROFILO_DATA_DISABILITAZIONE_API]: data[PROFILO_DATA_DISABILITAZIONE],
});
