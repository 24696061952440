import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { labels } from "../../../labels";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

const { PROFILO_RUOLO_NON_UNICO, PROFILO_RUOLO_NON_UNICO_CONTINUA, YES, NO } = labels;

export const ModalRuoloLibero = ({ incaricato, open, onYes, onNo }) => (
  <Dialog open={open} className="modalProfilo">
    <DialogTitle>
      <Stack sx={{ opacity: 0 }}>Modal ruolo libero</Stack>
      <IconButton onClick={onNo} aria-label="Chiudi">
        <CloseOutlinedIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Typography>{PROFILO_RUOLO_NON_UNICO + "(" + incaricato + ")"}</Typography>
      <Typography>{PROFILO_RUOLO_NON_UNICO_CONTINUA}</Typography>
    </DialogContent>
    <DialogActions>
      <Button className="buttonVerdeSecondario" onClick={onNo}>
        {NO}
      </Button>
      <Button className="buttonVerde" onClick={onYes}>
        {YES}
      </Button>
    </DialogActions>
  </Dialog>
);
