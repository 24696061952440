export const NOME = "nome";
export const COGNOME = "cognome";
export const SESSO = "sesso";
export const DATA_NASCITA = "dataDiNascita";
export const PROVINCIA_NASCITA = "provinciaNascita";
export const LUOGO_NASCITA = "luogoDiNascita";
export const CF = "codiceFiscale";
export const INDIRIZZO = "indirizzoResidenza";
export const EMAIL = "email";
export const TELEFONO = "telefono";
export const DOCUMENTO_IDENTITA = "documentoIdentita";
export const DATA_SCADENZA_DOCUMENTO_IDENTITA = "dataScadenzaDocumentoIdentita";
export const CODICE_SPID = "codiceSPID";
export const P_IVA = "partitaIVA";
export const RAGIONE_SOCIALE = "ragioneSociale";
export const SEDE_LEGALE = "sedeLegale";
export const DOMICILIO_DIGITALE = "domicilioDigitale";
// Possibili campi ritorno userInfo
export const NOME_API = "name";
export const COGNOME_API = "familyName";
export const SESSO_API = "gender";
export const DATA_NASCITA_API = "dateOfBirth";
export const PROVINCIA_NASCITA_API = "countyOfBirth";
export const LUOGO_NASCITA_API = "placeOfBirth";
export const CF_API = "fiscalNumber";
export const INDIRIZZO_API = "address";
export const EMAIL_API = "email";
export const TELEFONO_API = "mobilePhone";
export const DOCUMENTO_IDENTITA_API = "idCard";
export const DATA_SCADENZA_DOCUMENTO_IDENTITA_API = "expirationDate";
export const CODICE_SPID_API = "spidCode";
export const P_IVA_API = "ivaCode";
export const RAGIONE_SOCIALE_API = "companyName";
export const SEDE_LEGALE_API = "registeredOffice";
export const DOMICILIO_DIGITALE_API = "digitalAddress";
// Possibili campi ritorno userInfo prima delle modifiche dei claims
export const NOME_OLD_API = "Nome";
export const COGNOME_OLD_API = "Cognome";
export const SESSO_OLD_API = "Sesso";
export const DATA_NASCITA_OLD_API = "DataNascita";
export const PROVINCIA_NASCITA_OLD_API = "ProvinciaNascita";
export const LUOGO_NASCITA_OLD_API = "LuogoNascita";
export const CF_OLD_API = "CodiceFiscale";
export const INDIRIZZO_OLD_API = "DomicilioFisico";
export const EMAIL_OLD_API = "E-mail";
export const TELEFONO_OLD_API = "NumeroCellulare";
export const DOCUMENTO_IDENTITA_OLD_API = "Documentoidentita";
export const DATA_SCADENZA_DOCUMENTO_IDENTITA_OLD_API = "DataScadenzaIdentita";
export const CODICE_SPID_OLD_API = "CodiceSPID";
export const P_IVA_OLD_API = "PartitaIVA";
export const RAGIONE_SOCIALE_OLD_API = "RagioneSociale";
export const SEDE_LEGALE_OLD_API = "SedeLegale";
export const DOMICILIO_DIGITALE_OLD_API = "DomicilioDigitale";
