import RadioGroup from "@mui/material/RadioGroup";
import { Button, Radio, Paper } from "@mui/material";
import { setDropDown5, setFilterPubblicato } from "../../../modules/configuration/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { MyFormControlLabel } from "./StyleDropdown";
import { labels } from "../../../labels";

const { BUTTON_PULISCI_FILTRI, BUTTON_APPLICA_FILTRI } = labels;

export const Pubblicazione = () => {
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  return (
    <Paper className="dropdown">
      <RadioGroup name="use-radio-group" sx={{ margin: "15px", "& .MuiTypography-root": { textAlign: "left" } }} aria-label="pubblicazione">
        <MyFormControlLabel
          value="osservatorio"
          label="Osservatorio"
          control={
            <Radio
              onClick={() => {
                setCheck1(true);
                setCheck2(false);
                setValue("OSSERVATORIO");
              }}
              checked={check1}
            />
          }
        />
        <MyFormControlLabel
          value="mappa"
          label="Mappa"
          control={
            <Radio
              onClick={() => {
                setCheck2(true);
                setCheck1(false);
                setValue("MAPPA");
              }}
              checked={check2}
            />
          }
        />
      </RadioGroup>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonPulisci"
        onClick={() => {
          setCheck1(false);
          setCheck2(false);
        }}
      >
        {BUTTON_PULISCI_FILTRI}
      </Button>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonApplica"
        onClick={() => {
          dispatch(setDropDown5());
          dispatch(setFilterPubblicato(value));
        }}
      >
        {BUTTON_APPLICA_FILTRI}
      </Button>
    </Paper>
  );
};
