import { Grid, Divider, Typography, Box, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { getIstituto } from "../../../../modules/istituti";
import { IstitutoIcon } from "../../../../styles/icons";
import { labels } from "../../../../labels";
import { PATH_HOME } from "../../../../path";
import { useNavigate } from "react-router-dom";

const {
  TIPOLOGIA_ISTITUTO,
  CONTATTI_ISTITUTO,
  TITOLO_DATI_ISTITUTO,
  DENOMINAZIONE,
  CODICE_MECCANOGRAFICO,
  PROVINCIA,
  INDIRIZZO,
  COMUNE,
  MAIL,
  NUMERO_SEGRETERIA,
  WEB,
  AVVISO_CONTATTO_ISTITUTO,
} = labels;

export const Istituto = (props) => {
  const datiIstituto = useSelector(getIstituto);
  const navigate = useNavigate();
  if (datiIstituto.data.length === 0) navigate(PATH_HOME);
  return (
    <Grid container spacing={2} className="tabIstituto">
      <Grid item xs={0} sm={0} md={2} xl={3} className="center">
        <Grid container>
          <Grid item xs={12}>
            {" "}
            <Box
              sx={{
                ".cls-1": { fill: "#000000 !important", width: "240px", height: "280px", paddingLeft: "50px" },
                "@media (max-width:900px)": {
                  display: "none !important",
                },
                "@media (max-width:1530px)": {
                  ".cls-1": { fill: "#000000 !important", width: "150px !important", height: "280px", marginLeft: "-20px" },
                },
                "@media (max-width:1280px)": {
                  ".cls-1": { fill: "#000000 !important", width: "130px !important", height: "280px", marginLeft: "-50px" },
                },
              }}
            >
              {" "}
              <IstitutoIcon />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              "@media (max-width:900px)": {
                display: "none !important",
              },
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: "Bold", fontSize: "16px !important", padding: "5px" }}>{AVVISO_CONTATTO_ISTITUTO}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Link
                  sx={{ cursor: "pointer", wordWrap: "break-word", color: "black", textDecorationColor: "black" }}
                  onClick={() => window.open("mailto:scuoladigitale@regione.liguria.it")}
                >
                  <Typography
                    sx={{
                      fontWeight: "Bold",
                      "@media (max-width:1540px)": {
                        fontSize: "16px !important",
                      },
                      "@media (max-width:1470px)": {
                        fontSize: "13px !important",
                      },
                      "@media (max-width:1050px)": {
                        fontSize: "10px !important",
                      },
                    }}
                  >
                    {" "}
                    {"scuoladigitale@regione.liguria.it"}{" "}
                  </Typography>
                </Link>
              </Grid>
            </Grid>{" "}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={6} xl={5.5}>
        <Box
          sx={{
            height: "450px",
            backgroundColor: props.color2,
            textAlign: "center",
            "@media (max-width:960px)": {
              height: "600px",
            },
          }}
        >
          <Grid container className="center">
            <Grid item xs={12} md={12} xl={12}>
              <Typography className="titleSezioneIstituto"> {TITOLO_DATI_ISTITUTO}</Typography>
            </Grid>
            <Divider
              className="divider"
              sx={{
                color: props.color1,
              }}
            />
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Grid container>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography className="titleCampi">{DENOMINAZIONE}</Typography>
                        <Typography> {datiIstituto.data.length !== 0 ? datiIstituto.data[0].nome : null}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="titleCampi">{PROVINCIA}</Typography>
                        <Typography>{datiIstituto.data.length !== 0 ? datiIstituto.data[0].descr_prov : null} </Typography>
                      </Grid>
                    </Grid>
                  </Grid>{" "}
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography className="titleCampi">{CODICE_MECCANOGRAFICO}</Typography>
                        <Typography> {datiIstituto.data.length !== 0 ? datiIstituto.data[0].codice_scuola : null} </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className="titleCampi">{COMUNE}</Typography>
                        <Typography>{datiIstituto.data.length !== 0 ? datiIstituto.data[0].descr_com : null} </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>{" "}
                <Grid item xs={12} sm={12} md={12} xl={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography className="titleCampi">{TIPOLOGIA_ISTITUTO}</Typography>
                      <Typography>{datiIstituto.data.length !== 0 ? datiIstituto.data[0].descr_istituto : null}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography className="titleCampi">{INDIRIZZO}</Typography>
                      <Typography sx={{ marginBottom: "26px" }}> {datiIstituto.data.length !== 0 ? datiIstituto.data[0].ind_via : null} </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={5.5} md={4} xl={3.5}>
        <Box
          sx={{
            height: "450px",
            backgroundColor: props.color2,
            textAlign: "center",
            "@media (max-width:960px)": {
              height: "600px",
            },
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12} xl={12}>
              <Typography className="titleSezioneIstituto">{CONTATTI_ISTITUTO}</Typography>
            </Grid>
            <Divider
              className="divider"
              sx={{
                color: props.color1,
              }}
            />
            <Grid item xs={12} md={12} xl={12}>
              <Typography className="titleCampi">{MAIL}</Typography>
              <Link
                sx={{ cursor: "pointer", fontSize: "18px", wordWrap: "break-word", color: "black", textDecorationColor: "black" }}
                onClick={() => window.open(datiIstituto.data.length !== 0 ? "mailto:" + datiIstituto.data[0].email : null)}
              >
                <Typography> {datiIstituto.data.length !== 0 ? datiIstituto.data[0].email : null} </Typography>
              </Link>
              <Typography className="titleCampi">{NUMERO_SEGRETERIA}</Typography>
              <Typography> {datiIstituto.data.length !== 0 ? datiIstituto.data[0].numero_segreteria : null} </Typography>
              <Typography className="titleCampi">{WEB}</Typography>
              <Link
                sx={{ cursor: "pointer", fontSize: "18px", wordWrap: "break-word", color: "black", textDecorationColor: "black" }}
                onClick={() => window.open(datiIstituto.data.length !== 0 ? datiIstituto.data[0].sito_web : null)}
              >
                <Typography
                  sx={{
                    "@media (max-width:960px)": {
                      fontSize: "16px !important",
                    },
                  }}
                >
                  {datiIstituto.data.length !== 0 ? datiIstituto.data[0].sito_web : null}
                </Typography>{" "}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          justifyContent: "center",
          display: "none !important",
          "@media (max-width:900px)": {
            display: "block !important",
          },
        }}
      >
        <Grid container>
          <Grid item sm={6.5} md={12}>
            <Typography
              sx={{
                fontWeight: "Bold",
                fontSize: "16px !important",
                paddingLeft: "5px",
                "@media (max-width:840px)": {
                  fontSize: "15px !important",
                },
              }}
            >
              {AVVISO_CONTATTO_ISTITUTO}
            </Typography>
          </Grid>
          <Grid item sm={4} md={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Link
              sx={{ cursor: "pointer", wordWrap: "break-word", color: "black", textDecorationColor: "black" }}
              onClick={() => window.open("mailto:scuoladigitale@regione.liguria.it")}
            >
              <Typography
                sx={{
                  fontWeight: "Bold",
                  fontSize: "16px !important",

                  "@media (max-width:0px)": {
                    fontSize: "10px !important",
                  },
                }}
              >
                {" "}
                {"scuoladigitale@regione.liguria.it"}{" "}
              </Typography>
            </Link>
          </Grid>
        </Grid>{" "}
      </Grid>
    </Grid>
  );
};
