import { Grid, Typography, CircularProgress, IconButton, Box, Modal, List, Paper } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import {
  getSottoAttivitaId,
  deleteValAttivita,
  deleteval,
  getSceltaAttivita,
  getAttivitaSelezionataTablet,
  setAttivitaSelezionataTablet,
} from "../../../../modules/configuration";
import { ModaleAttivita } from "./ModaleAttivita";
import { OptionsCheckModale } from "../../../shared/OptionsCheckModale";
import { labels } from "../../../../labels";
import { useEffect, useState } from "react";

const { CONTENUTI_CORRELATI, ATTIVITA, NUMERO_ATTIVITA_INSERITE } = labels;

export const PageAttivitaTablet = (props) => {
  const dispatch = useDispatch();
  const formik = props.formik;
  const [open, setOpen] = useState(false); //modale
  let progress = props.progress;
  // ELENCO TOTALE DELLE ATTIVITA DA BACK END
  const attivita = props.attivita;
  const numAttivita = attivita !== undefined ? attivita.length : 0;
  const sceltaAttivita = useSelector(getSceltaAttivita);

  //MAP ATTIIVITA E SOTTOATTIVITA RELATIVE INSERITE DALL'UTENTE
  const attivitaMap = useSelector(getSottoAttivitaId);
  let attMap = new Map(JSON.parse(attivitaMap));

  //ATTIVITA SCELTA DALL'UTENTE PER VISUALIZZARE LE RELATIVE SOTTOATTIVITA
  let attivitaSelezionata = useSelector(getAttivitaSelezionataTablet);
  let sottAttSelezionate = attMap.get(attivitaSelezionata);

  let arrayFiltrato = [];
  useEffect(() => {
    for (let [key] of attMap.entries()) {
      attivita.map((opt) => opt.finalita + " " + opt.descr_campo === key && arrayFiltrato.push(key));
    }
    if (sceltaAttivita === false) {
      dispatch(setAttivitaSelezionataTablet(arrayFiltrato[0]));
    }
    // eslint-disable-next-line
  }, [attivita]);

  let array = [];

  const checkAttivita = (attMap) => {
    i = -1;
    for (let [key] of attMap.entries()) {
      //CONTROLLO PER EVITARE CHE LE ATTIVITA VENGANO VISUALIZZATE NEL CAMPO ERRATO
      i = i + 1;
      attivita.map(
        // eslint-disable-next-line
        (opt, index) =>
          opt.finalita + " " + opt.descr_campo === key &&
          array.push(
            <Box sx={{ marginLeft: "20px" }} key={index}>
              <OptionsCheckModale
                attivitaSelezTablet={true}
                dettagli={props.Dettagli}
                clickSelezione={true}
                color={"#FFFFFF"}
                border={props.Dettagli === false ? "solid 3px #FABE10" : "solid 3px" + props.color1}
                color2={props.color2}
                width={"95%"}
                elemento={key}
                index={i}
                formik={formik}
                click={(e) => {
                  let value = key;
                  let tipo = props.tipo;
                  let section = props.section;
                  dispatch(deleteValAttivita({ value, section, tipo }));
                }}
              />
            </Box>
          )
      );
    }

    return <>{array}</>;
  };

  const length = () => {
    let i = 0;
    for (let [key] of attMap.entries()) {
      // eslint-disable-next-line
      attivita.map((opt) => (opt.finalita + " " + opt.descr_campo === key ? i++ : null));
    }
    return i;
  };
  let i;
  const checkSA = (sottAttSelezionate) => {
    let array = [];
    i = -1;
    //CONTROLLO PER EVITARE CHE LE SOTTO ATTIVITA VENGANO VISUALIZZATE NEL CAMPO ERRATO
    i = i + 1;
    array.push(
      <Box sx={{ marginLeft: "20px" }} key={i}>
        {typeof sottAttSelezionate !== "string" ? (
          sottAttSelezionate.map((elemento, ind) => (
            <OptionsCheckModale
              dettagli={props.Dettagli}
              color={"#FFFFFF"}
              border={props.Dettagli === false ? "solid 3px #FABE10" : "solid 3px" + props.color1}
              width={"95%"}
              elemento={elemento}
              index={ind}
              formik={formik}
              click={(e) => {
                let value = elemento;
                let tipo = props.tipo;
                let section = props.section;
                dispatch(deleteval({ value, section, tipo }));
              }}
            />
          ))
        ) : (
          <OptionsCheckModale
            dettagli={props.Dettagli}
            color={"#FFFFFF"}
            border={props.Dettagli === false ? "solid 3px #FABE10" : "solid 3px" + props.color1}
            width={"95%"}
            elemento={sottAttSelezionate[0]}
            index={0}
            formik={formik}
            click={(e) => {
              let value = sottAttSelezionate[0];
              let tipo = props.tipo;
              let section = props.section;
              dispatch(deleteval({ value, section, tipo }));
            }}
          />
        )}
      </Box>
    );

    return <>{array}</>;
  };

  return (
    <form onSubmit={props.Dettagli === true ? null : formik.handleSubmit} id={"myForm_" + props.mode + "_" + props.nameTab}>
      <Grid container className="inserimentoProgetto">
        <Box
          sx={{
            marginLeft: "40px",
            marginRight: "40px",
            marginTop: "10px",
            marginBottom: "20px",
            "@media (max-width:480px)": {
              paddingLeft: "20px",
              paddingRight: "40px",
              margin: "0px",
            },
          }}
        >
          {/* ATTIVITA INSERITE */}
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Grid container sx={{ marginBottom: "30px" }}>
              <Grid item xs={12} sm={4} md={2.4} xl={2.4}>
                <Typography
                  sx={{
                    marginTop: "20px",
                    fontSize: "18px",
                  }}
                >
                  {NUMERO_ATTIVITA_INSERITE}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} md={2} xl={2}>
                <CircularProgress
                  variant="determinate"
                  sx={{
                    zIndex: "0",
                    color: "green",
                  }}
                  size={60}
                  thickness={2}
                  value={100}
                />
                <Typography
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    marginTop: "-46px",
                    marginLeft: "14px",
                  }}
                >
                  {length()} / {numAttivita}
                </Typography>{" "}
              </Grid>
            </Grid>
            {props.avvisoAttObb === true && props.check === true ? (
              <Typography sx={{ color: "red" }}>Non hai inserito tutte le attività obbligatorie</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Grid container>
              {/* ATTIVITA */}
              <Grid item xs={12} sm={12} md={12} xl={6}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={
                          props.nameTab === "Infrastrutture (rete, hardware, apparati)"
                            ? 5.2
                            : props.nameTab === "Formazione e Aggiornamento" ||
                                props.nameTab === "Comunicazione" ||
                                props.nameTab === "Problem Solving"
                              ? 4.8
                              : props.nameTab === "Web"
                                ? 3
                                : props.nameTab === "Sistemi informativi (sito, piattaforme)"
                                  ? 6
                                  : 3.8
                        }
                        md={
                          props.nameTab === "Infrastrutture (rete, hardware, apparati)"
                            ? 5.2
                            : props.nameTab === "Formazione e Aggiornamento" ||
                                props.nameTab === "Comunicazione" ||
                                props.nameTab === "Problem Solving"
                              ? 4.8
                              : props.nameTab === "Web"
                                ? 3
                                : props.nameTab === "Sistemi informativi (sito, piattaforme)"
                                  ? 6
                                  : 3.8
                        }
                        xl={
                          props.nameTab === "Formazione e Aggiornamento" ||
                          props.nameTab === "Infrastrutture (rete, hardware, apparati)" ||
                          props.nameTab === "Sistemi informativi (sito, piattaforme)"
                            ? 8
                            : props.nameTab === "Pratiche innovative digitali"
                              ? 7
                              : props.nameTab === "Comunicazione" || props.nameTab === "Problem Solving"
                                ? 4.8
                                : props.nameTab === "Web"
                                  ? 3
                                  : 3.8
                        }
                      >
                        <Typography
                          sx={{
                            marginTop: "10px",
                            fontWeight: "Bold",
                            fontSize: "20px",
                          }}
                        >
                          {ATTIVITA} {props.nameTab}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={2} md={2} xl={2}>
                        {props.Dettagli ? null : (
                          <IconButton
                            aria-label="Aggiungi attività"
                            onClick={() => setOpen(true)}
                            sx={{
                              marginLeft: "-50px",
                              "@media (max-width:1414px)": {
                                marginLeft: "0px",
                              },
                            }}
                          >
                            <AddCircleOutlineIcon
                              aria-label="Aggiungi attività"
                              sx={{
                                color: "black",
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          </IconButton>
                        )}
                        <Modal
                          open={open}
                          onClose={() => setOpen(false)}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box>
                            <ModaleAttivita
                              attivitaSelezTablet={true}
                              progetto={props.progetto}
                              formik={formik}
                              attivita={attivita}
                              modal={setOpen}
                              textField={false}
                              section={props.section}
                              tipo={props.tipo}
                              progress={progress}
                            />
                          </Box>
                        </Modal>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                      <Paper
                        sx={{
                          width: "600px",
                          height: "240px",
                          backgroundColor: "#F0F0F0",
                          borderRadius: "20px",
                          "@media (max-width:900px)": {
                            width: "590px",
                          },
                          "@media (max-width:640px)": {
                            width: "300px",
                          },
                          "@media (max-width:480px)": {
                            width: "270px",
                            height: "400px",
                            marginBottom: "50px",
                          },
                        }}
                      >
                        {attMap.size > 0 ? (
                          <List
                            sx={{
                              overflow: "auto",
                              maxWidth: "800px",
                              maxHeight: "240px",
                              backgroundColor: "#F0F0F0",
                              borderRadius: "20px",
                              "@media (max-width:480px)": {
                                width: "270px",
                                height: "400px",
                              },
                            }}
                          >
                            {" "}
                            {checkAttivita(attMap)}
                          </List>
                        ) : null}
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* SOTTOATTIVITA */}
              <Grid item xs={12} sm={12} md={12} xl={6}>
                <Grid container sx={{ marginTop: "15px" }}>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Grid container>
                      <Grid item xs={12} sm={4} md={4} xl={4}>
                        <Typography sx={{ fontWeight: "Bold", fontSize: "17px" }}>{CONTENUTI_CORRELATI}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Paper
                      sx={{
                        marginTop: "5px",
                        width: "600px",
                        height: "240px",
                        backgroundColor: "#F0F0F0",
                        borderRadius: "20px",
                        "@media (max-width:900px)": {
                          width: "590px",
                        },
                        "@media (max-width:640px)": {
                          width: "300px",
                        },
                        "@media (max-width:480px)": {
                          width: "270px",
                          height: "400px",
                          marginBottom: "50px",
                        },
                      }}
                    >
                      {sottAttSelezionate !== undefined && attivitaSelezionata !== "" && attMap.size > 0 ? (
                        <List
                          sx={{
                            overflow: "auto",
                            maxHeight: "240px",
                            backgroundColor: "#F0F0F0",
                            borderRadius: "20px",
                            "@media (max-width:480px)": {
                              width: "270px",
                              height: "400px",
                            },
                          }}
                        >
                          {checkSA(sottAttSelezionate)}
                        </List>
                      ) : null}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <IconButton aria-label="submit" type="submit" sx={{ display: "none" }} />
    </form>
  );
};
