import { createAction } from "@reduxjs/toolkit";

export const fetchSezioneIstituto = createAction("SEZIONE_ISTITUTI@istituti");

export const fetchSezioneIstitutiScolastici = createAction("SEZIONE_PROGETTI_ISTITUTI_SCOLASTICI@istituti");

export const fetchDatiIstitutiSuccess = createAction("FETCH_DATI_ISTITUTI_SUCCESS@istituti");

export const fetchDatiIstitutiFailed = createAction("FETCH_DATI_ISTITUTI_FAILED@istituti");

export const fetchDatiIstitutoSuccess = createAction("FETCH_DATI_ISTITUTO_SUCCESS@istituti");

export const fetchDatiIstitutoFailed = createAction("FETCH_DATI_ISTITUTO_FAILED@istituti");

export const fetchPlessiIstitutoSuccess = createAction("FETCH_PLESSI_ISTITUTO_SUCCESS@istituti");

export const fetchPlessiIstitutoFailed = createAction("FETCH_PLESSI_ISTITUTO_FAILED@istituti");

export const fetchProfiliInUscitaSuccess = createAction("FETCH_PROFILI_IN_USCITA_SUCCESS@istituti");

export const fetchProfiliInUscitaFailed = createAction("FETCH_PROFILI_IN_USCITA_FAILED@istituti");

export const fetchMembriTeamSuccess = createAction("FETCH_MEMBRI_TEAM_SUCCESS@istituti");

export const fetchMembriTeamFailed = createAction("FETCH_MEMBRI_TEAM_FAILED@istituti");

export const resetDatiIstituti = createAction("RESET_DATI_ISTITUTI@istituti");
