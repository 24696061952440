import {
  resetAttPost,
  resetAttivitaId,
  setIdProgetto,
  setIndexTabSpostamento,
  setProgettoCurr,
  setProgettoInn,
} from "../../../modules/configuration";
import { Box, IconButton, Typography, Button, Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";
import { labels } from "../../../labels";
import { notificationCodes, setNotification } from "../../../modules/notification";
import { GET_DETTAGLI_PROGETTI } from "../../../apiUrl";
import { createRequest } from "../../../utility";

import { changeDataProgetto, changeDataProgettoCurr, fetchDettagliProgettoFiled, fetchDettagliProgettoSuccess } from "../../../modules/datiProgetti";

const { SAGAS_DETTAGLI_PROGETTO_TITLE, SAGAS_DETTAGLI_PROGETTO_DESCRIPTION } = labels;

export const ModaleSpostamentoTab = (props) => {
  const dispatch = useDispatch();
  let onSave = props.onSave;
  let set = props.setModalSpostamentoTab;
  let id = props.uuid;

  const datiProg = (id) => {
    dispatch(setIdProgetto(id));
    createRequest(GET_DETTAGLI_PROGETTI.method, GET_DETTAGLI_PROGETTI.baseUrl, GET_DETTAGLI_PROGETTI.apiUrl + "/" + id, {
      responseType: "json",
      decompress: true,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data != null) {
            dispatch(fetchDettagliProgettoSuccess(response.data));
            const tipoProgetto = response.data.descr_t_progetto;
            if (tipoProgetto != null) {
              if (tipoProgetto === "Innovazione della Scuola") dispatch(setProgettoInn(changeDataProgetto(response.data)));
              else dispatch(setProgettoCurr(changeDataProgettoCurr(response.data)));
            }
          }
        } else throw response.message;
      })
      .catch((e) => {
        console.error(SAGAS_DETTAGLI_PROGETTO_TITLE, ":", e);
        dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_DETTAGLI_PROGETTO_TITLE, description: SAGAS_DETTAGLI_PROGETTO_DESCRIPTION })); //prettier-ignore
        dispatch(fetchDettagliProgettoFiled());
      });
  };
  return (
    <Modal
      open={props.stateModal}
      onClose={() => set(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="inserimentoProgetto"
    >
      <Box className="modaleHome" sx={{ height: "350px !important", "@media (max-width:640px)": { height: "450px !important" } }}>
        <IconButton
          disableRipple
          aria-label="delete"
          className="closeIcon"
          onClick={() => {
            dispatch(setIndexTabSpostamento(null));
            set(false);
          }}
        >
          <CancelOutlinedIcon />
        </IconButton>
        <Box sx={{ marginTop: "75px", textAlign: "center" }}>
          <Typography className="textModaleHome">
            Spostandoti di sezione eventuali dati non salvati nella presente sezione andranno persi. Vuoi continuare?
          </Typography>
          <Button
            variant="contained"
            className="buttonEsci"
            onClick={() => {
              datiProg(id);
              dispatch(resetAttPost());
              dispatch(resetAttivitaId());
              set(false);
            }}
            sx={{ width: "180px !important" }}
          >
            <Typography className="textButtonModaleHome">Si, senza salvare</Typography>
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onSave();
              set(false);
            }}
            className="buttonAnnulla"
          >
            <Typography className="textButtonModaleHome">Si, salva i dati</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
