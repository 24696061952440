import { Grid, Skeleton, Typography } from "@mui/material";
import { School as SchoolIcon } from "@mui/icons-material";
import { labels } from "../../../labels";

const { PROFILO_ISTITUTO_APPARTENENZA } = labels;

const RectangularSkeleton = (props) => <Skeleton variant="rectangular" {...props} />;

export const SkeletonDatiIstituto = () => (
  <Grid item xs={12} className="datiIstituto">
    <Grid container spacing={2}>
      <Grid item xs={12} className="headerSezione">
        <SchoolIcon />
        <Typography>{PROFILO_ISTITUTO_APPARTENENZA}</Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <RectangularSkeleton />
      </Grid>
      <Grid item xs={12} md={3} sx={{ display: "flex", justifyContent: { xs: "flex-start", md: "flex-end" } }}>
        <RectangularSkeleton sx={{ width: "100%" }} />
      </Grid>
      <Grid item xs={12}>
        <RectangularSkeleton sx={{ width: "100%" }} />
      </Grid>
      <Grid item xs={12}>
        <RectangularSkeleton sx={{ width: "100%" }} />
      </Grid>
    </Grid>
  </Grid>
);
