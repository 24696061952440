import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { ArrowCircleRightOutlined as ArrowCircleRightOutlinedIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Anagrafica } from "./Anagrafica";
import { Attivita } from "./Attivita";
import { useSelector, useDispatch } from "react-redux";
import {
  changeTabsDettagli,
  changetabs,
  getDatiProgetto,
  getIndexTabSpostamento,
  getIndexTabs,
  saveOptionSelect,
  saveProgress,
  saveProgressCont,
  saveSottoAttivita,
  setAttivitaSelezionata,
  setAzDiffDiss,
  setButtTypeFooterInsProg,
  setDescrizioniSottoAttivita,
  setDocumentazioneOptions,
  setEdit,
  setIndexTabSpostamento,
} from "../../../../modules/configuration";
import { TabPanel } from "../../../shared/TabPanelCustom";
import { Pubblicazione } from "../Pubblicazione";
import { labels } from "../../../../labels";
import { Contesto } from "./Contesto";
import { Documentazione } from "../Documentazione";
import { useEffect, useState } from "react";
import { fetchDisciplineCoinvolte } from "../../../../modules/datiOsservatorio";

const { ANAGRAFICA, CONTESTO, ATTIVITA, DOCUMENTAZIONE, PUBBLICAZIONE } = labels;

export const gestisciprogettibutton = [
  //button curr,extracurr
  {
    id: "0",
    testo: ANAGRAFICA,
  },
  {
    id: "1",
    testo: CONTESTO,
  },
  {
    id: "2",
    testo: ATTIVITA,
  },
  {
    id: "3",
    testo: DOCUMENTAZIONE,
  },
  {
    id: "4",
    testo: PUBBLICAZIONE,
  },
];
export const MainCurrExtraC = (props) => {
  const indexTabs = useSelector(getIndexTabs);
  let indexString = indexTabs.toString();
  const dispatch = useDispatch();
  let progetto = props.progetto;
  const [versione, setVersione] = useState(false);
  const datiProg = useSelector(getDatiProgetto);
  const datiProgettoAtt = datiProg.contesto.attivita;
  const datiProgetto = datiProg.anagrafica;
  const [modalSpostamentoTab, setModalSpostamentoTab] = useState(false);
  const [sbloccoTab, setSbloccoTab] = useState(0);
  const index = useSelector(getIndexTabSpostamento);

  useEffect(() => {
    let attivitaInserite = [];
    // eslint-disable-next-line
    datiProgettoAtt.map((e) => {
      attivitaInserite.push(e.id_campo);
      attivitaInserite = attivitaInserite.filter(function (e, el) {
        return attivitaInserite.indexOf(e) === el;
      });
    });
    let progrCont = attivitaInserite.length * 2;

    dispatch(saveProgressCont(progrCont));
    //per versione modifica siccome chiamata non parte con onchange della scelta nella select
    if (datiProgetto["descr_grado_scuola"]) {
      dispatch(fetchDisciplineCoinvolte("/" + datiProgetto["descr_grado_scuola"])); //devi passar id
    }
    dispatch(setDocumentazioneOptions(datiProg.documentazione["descr_documentazione"]));
    dispatch(setAzDiffDiss(datiProg.documentazione["azioneDiDiffusioneDisseminazione"]));
    dispatch(saveOptionSelect(datiProg.contesto["discipline"]));

    for (let i = 0; i < datiProg.attivita.length; i++) {
      let elementoAtt = datiProg.attivita[i].finalita + " " + datiProg.attivita[i].descr_campo;
      let elementoSottoAtt = datiProg.attivita[i].descr_valore;
      dispatch(saveSottoAttivita({ elementoSottoAtt, elementoAtt }));
      dispatch(setAttivitaSelezionata("in"));
    }

    for (let i = 0; i < datiProg.contesto.attivita.length; i++) {
      let elementoAtt = datiProg.contesto.attivita[i].finalita + " " + datiProg.contesto.attivita[i].descr_campo;
      let elementoSottoAtt = datiProg.contesto.attivita[i].descr_valore;
      dispatch(saveSottoAttivita({ elementoSottoAtt, elementoAtt }));
      dispatch(setAttivitaSelezionata("in"));
    }
    for (let i = 0; i < datiProg.attivita.length; i++) {
      let elementoSottoAtt = datiProg.attivita[i].descr_valore;
      let elementoSottoAttDesc = datiProg.attivita[i].descr_valore_progetto;
      dispatch(setDescrizioniSottoAttivita({ elementoSottoAtt, elementoSottoAttDesc }));
    }
    for (let i = 0; i < datiProg.contesto.attivita.length; i++) {
      let elementoSottoAtt = datiProg.contesto.attivita[i].descr_valore;
      let elementoSottoAttDesc = datiProg.contesto.attivita[i].descr_valore_progetto;
      dispatch(setDescrizioniSottoAttivita({ elementoSottoAtt, elementoSottoAttDesc }));
    }
    const percentualeAttivita = 40 / 19;
    let progressAttivita = datiProg.attivita.length * percentualeAttivita;

    dispatch(saveProgress(progressAttivita));

    // versione modifica
    if (progetto.data === undefined) {
      dispatch(setEdit(true));
      setVersione(false);
      if (progetto.completamento < 18 || progetto.completamento === 67 || progetto.completamento > 67) {
        dispatch(changeTabsDettagli(0));
        setSbloccoTab(5);
      } else {
        if ((progetto.completamento > 18 && progetto.completamento < 30) || (progetto.completamento === 18 && progetto.completamento < 30)) {
          dispatch(changeTabsDettagli(1));
          setSbloccoTab(1);
        } else {
          if ((progetto.completamento > 30 && progetto.completamento < 57) || (progetto.completamento === 30 && progetto.completamento < 57)) {
            dispatch(changeTabsDettagli(2));
            setSbloccoTab(2);
          } else {
            if ((progetto.completamento > 57 && progetto.completamento < 67) || (progetto.completamento === 57 && progetto.completamento < 67)) {
              dispatch(changeTabsDettagli(3));
              setSbloccoTab(3);
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [progetto]);

  useEffect(() => {
    if (index !== null) {
      let versione = true;
      dispatch(changetabs({ index, versione }));
    }
    // eslint-disable-next-line
  }, [progetto]);

  useEffect(() => {
    if (progetto.data === undefined && indexTabs > sbloccoTab) {
      setSbloccoTab(indexTabs);
    }
    // eslint-disable-next-line
  }, [indexTabs]);

  const color = (index) => {
    if (indexTabs === index) return "#FABE10";
    else {
      if (progetto.data === undefined) {
        if (index <= sbloccoTab) return "#FFE7A4";
        else return "#FFFFFF";
      } else {
        if (index < indexTabs) return "#FFE7A4";
        else return "#FFFFFF";
      }
    }
  };

  const decoration = (index) => {
    if (indexTabs === index) return "underline";
    else return "none";
  };

  const visible = (id) => {
    if (id > 3) return false;
    else return true;
  };

  const cursor = (index) => {
    if (progetto.data === undefined) {
      if (index > sbloccoTab) return "default";
    } else {
      if (index > indexTabs) return "default";
    }
  };

  return (
    <TabContext value={indexString}>
      <Grid container spacing={2}>
        {gestisciprogettibutton.map((elemento, index) => (
          <Grid item xs={12} sm={visible(index) ? (index === 3 ? 3 : index === 2 ? 2 : 2.5) : 2} xl={visible(index) ? 2.5 : 2} key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={visible(index) ? 10 : 12} sm={visible(index) ? 10 : 12} xl={visible(index) ? 10 : 12}>
                <Tab
                  label={elemento.testo}
                  value={index}
                  disableRipple
                  disableFocusRipple
                  onClick={() => {
                    if (index <= (progetto.data === undefined ? sbloccoTab : indexTabs)) {
                      dispatch(setButtTypeFooterInsProg("AVANTI"));
                      dispatch(setIndexTabSpostamento(index));
                      setModalSpostamentoTab(true);
                      dispatch(setEdit(true));
                      setVersione(true);
                    }
                  }}
                  sx={{
                    bgcolor: color(index),
                    textDecoration: decoration(index),
                    cursor: cursor(index),
                  }}
                  className="tabSezioni"
                />
              </Grid>
              {visible(index) && (
                <Grid item xs={0} md={2} sm={2} xl={2}>
                  <ArrowCircleRightOutlinedIcon className="arrowCircleIconTab" />
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <TabPanel value={"0"} index={indexString}>
        <Anagrafica progetto={progetto} modal={modalSpostamentoTab} setModalSpostamentoTab={setModalSpostamentoTab} versione={versione} />
      </TabPanel>
      <TabPanel value={"1"} index={indexString}>
        <Contesto progetto={progetto} modal={modalSpostamentoTab} setModalSpostamentoTab={setModalSpostamentoTab} versione={versione} />
      </TabPanel>
      <TabPanel value={"2"} index={indexString}>
        <Attivita
          Dettagli={false}
          progetto={progetto}
          modal={modalSpostamentoTab}
          setModalSpostamentoTab={setModalSpostamentoTab}
          versione={versione}
        />
      </TabPanel>
      <TabPanel value={"3"} index={indexString}>
        <Documentazione
          tipo={"curr"}
          progetto={progetto}
          idCampo={29}
          modal={modalSpostamentoTab}
          setModalSpostamentoTab={setModalSpostamentoTab}
          versione={versione}
        />
      </TabPanel>
      <TabPanel value={"4"} index={indexString}>
        <Pubblicazione
          numindex={4}
          progetto={progetto}
          modal={modalSpostamentoTab}
          setModalSpostamentoTab={setModalSpostamentoTab}
          versione={versione}
        />
      </TabPanel>
    </TabContext>
  );
};
