import { createAction } from "@reduxjs/toolkit";


export const fetchComune = createAction("FETCH_COMUNE@data");

export const fetchComuneSuccess = createAction("FETCH_COMUNE_SUCCESS@data");

export const fetchComuneFailed = createAction("FETCH_COMUNE_FAILED@data");

export const fetchAttivitaInnovazione = createAction("FETCH_ATTIVITA_INNOVAZIONE@data");

export const fetchAttivitaInnovazioneSuccess = createAction("FETCH_ATTIVITA_INNOVAZIONE_SUCCESS@data");

export const fetchAttivitaInnovazioneFailed = createAction("FETCH_ATTIVITA_INNOVAZIONE_FAILED@data");

export const fetchCodiceProgetto = createAction("FETCH_CODICE_PROGETTO@data");

export const fetchCodiceProgettoSuccess = createAction("FETCH_CODICE_PROGETTO_SUCCESS@data");

export const fetchCodiceProgettoFailed = createAction("FETCH_CODICE_PROGETTO_FAILED@data");

export const fetchDisciplineCoinvolte = createAction("FETCH_DISCIPLINE_COINVOLTE@data");

export const fetchDisciplineCoinvolteSuccess = createAction("FETCH_DISCIPLINE_COINVOLTE_SUCCESS@data");

export const fetchDisciplineCoinvolteFailed = createAction("FETCH_DISCIPLINE_COINVOLTE_FAILED@data");

export const fetchIdNuovoProgetto = createAction("FETCH_ID_NUOVO_PROGETTO@data");

export const fetchIdNuovoProgettoSuccess = createAction("FETCH_ID_NUOVO_PROGETTO_SUCCESS@data");

export const fetchIdNuovoProgettoFailed = createAction("FETCH_ID_NUOVO_PROGETTO_FAILED@data");

