import { MappaHomeIcon } from "../../styles/icons";
import { Box } from "@mui/material";
import { labels } from "../../labels";
import { HeaderPage } from "../shared/Header";

const { MAPPA } = labels;
export const Mappa = () => (
  <Box>
    {" "}
    <HeaderPage icon={<MappaHomeIcon />} title={MAPPA} color={"#8E2155"} />
    <Box sx={{ marginTop: "20px" }}>
      <iframe
        title="mappa"
        style={{ width: "100%", height: "700px" }}
        src="https://srvcarto.regione.liguria.it/geoservices/apps/viewer/pages/apps/scuola-digitale/"
        name="Mappa"
      />{" "}
    </Box>
  </Box>
);
