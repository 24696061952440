import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Radio, Paper, RadioGroup } from "@mui/material";
import { getValueCheckOrdineSc, setDropDown3, setValueCheckOrdineSc } from "../../../modules/configuration";
import { MyFormControlLabel } from "./StyleDropdown";
import { labels } from "../../../labels";

const { BUTTON_PULISCI_FILTRI, BUTTON_APPLICA_FILTRI } = labels;

export const OrdineScuola = () => {
  const dispatch = useDispatch();
  const ordineScuola = useSelector(getValueCheckOrdineSc);
  const [check, setCheck] = useState(ordineScuola);

  return (
    <Paper className="dropdown">
      <RadioGroup name="use-radio-group" sx={{ margin: "15px", "& .MuiTypography-root": { textAlign: "left" } }} aria-label="ordine-scuola">
        <MyFormControlLabel value="ordine" label="Ordine alfabetico" control={<Radio onClick={() => setCheck(true)} checked={check} />} />
      </RadioGroup>
      <Button variant="contained" type="submit" form="myform" className="buttonPulisci" onClick={() => setCheck(false)}>
        {BUTTON_PULISCI_FILTRI}
      </Button>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonApplica"
        onClick={() => {
          dispatch(setDropDown3());
          dispatch(setValueCheckOrdineSc(check));
        }}
      >
        {BUTTON_APPLICA_FILTRI}
      </Button>
    </Paper>
  );
};
