import { Grid, Typography } from "@mui/material";
import { format } from "../../pages/gestisciProgetti/TableProgetti";
import { labels } from "../../../labels";

const {
  TIPOLOGIA_PROGETTO,
  ABSTRACT,
  STUDENTI_BES,
  NOTE,
  DATA_INIZIO_PROGETTO,
  TITOLO_PROGETTO,
  NUMERO_STUDENTI,
  COMPOSIZIONE_GRUPPO_STUDENTI,
  TEMA_PREVALENTE,
  GRADO_SCUOLA,
  TEMPO_REALIZZAZIONE,
  ANNO_REALIZZAZIONE,
  ATTIVITA_DIGITALE,
  CO_RESPONSABILE,
  REFERENTE_PROGETTO,
} = labels;

export const AnagraficaDettCurr = (props) => {
  let progetto = props.progetto;

  return (
    <Grid container className="dettagli">
      {/* Form */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} xl={4}>
            <Typography className="textTitle">{TITOLO_PROGETTO}</Typography>
            <Typography className="dato">{progetto.titolo_progetto === null ? "-" : progetto.titolo_progetto} </Typography>
            <Typography className="textTitle">{REFERENTE_PROGETTO}</Typography>
            <Typography className="dato">
              {" "}
              {(progetto.referente_cogn === null ? "-" : progetto.referente_cogn) +
                " " +
                (progetto.referente_nome === null ? "-" : progetto.referente_nome)}{" "}
            </Typography>
            <Typography className="textTitle">{ABSTRACT}</Typography>
            <Typography className="dato">{progetto.descr_progetto === null ? "-" : progetto.descr_progetto} </Typography>
            <Typography className="textTitle">{STUDENTI_BES}</Typography>
            <Typography className="dato">{progetto.descr_studenti_bes === null ? "-" : progetto.descr_studenti_bes} </Typography>
            <Typography className="textTitle">{NOTE}</Typography>
            <Typography className="dato">{progetto.annote === null ? "-" : progetto.annote} </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} xl={4}>
            <Typography className="textTitle">{DATA_INIZIO_PROGETTO}</Typography>
            <Typography className="dato">
              {progetto.data_inizio !== undefined ? format(new Date(progetto.data_inizio), "dd-MM-yyyy") : "-"}
            </Typography>
            <Typography className="textTitle">{GRADO_SCUOLA}</Typography>
            <Typography className="dato">{progetto.descr_grado_scuola === null ? "-" : progetto.descr_grado_scuola} </Typography>

            <Typography className="textTitle">{NUMERO_STUDENTI}</Typography>
            <Typography className="dato">{progetto.num_alunni_imp === null ? "-" : progetto.num_alunni_imp}</Typography>
            <Typography className="textTitle">{COMPOSIZIONE_GRUPPO_STUDENTI}</Typography>
            <Typography className="dato">{progetto.descr_composizione_gruppo === null ? "-" : progetto.descr_composizione_gruppo} </Typography>
            <Typography className="textTitle">{TEMA_PREVALENTE}</Typography>
            <Typography className="dato">{progetto.descr_tipo_attributo === null ? "-" : progetto.descr_tipo_attributo} </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4} xl={3}>
            <Typography className="textTitle">{TIPOLOGIA_PROGETTO}</Typography>
            <Typography className="dato">{progetto.descr_t_progetto === null ? "-" : progetto.descr_t_progetto}</Typography>
            <Typography className="textTitle">{TEMPO_REALIZZAZIONE}</Typography>
            <Typography className="dato">{progetto.descr_tempo_realiz === null ? "-" : progetto.descr_tempo_realiz} </Typography>
            <Typography className="textTitle">{ANNO_REALIZZAZIONE}</Typography>
            <Typography className="dato">{progetto.descr_anno_realiz === null ? "-" : progetto.descr_anno_realiz} </Typography>
            <Typography className="textTitle">{ATTIVITA_DIGITALE}</Typography>
            <Typography className="dato">{progetto.descr_attivita_digitale === null ? "-" : progetto.descr_attivita_digitale} </Typography>
            <Typography className="textTitle">{CO_RESPONSABILE}</Typography>
            <Typography className="dato">
              {(progetto.coReferente_cognome === null ? "-" : progetto.coReferente_cognome) +
                " " +
                (progetto.coReferente_nome === null ? "" : progetto.coReferente_nome)}{" "}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
