import { createAction } from "@reduxjs/toolkit";

export const getUserInfo = createAction("GET_USER_INFO@auth");

export const getUserInfoSuccess = createAction("GET_USER_INFO_SUCCESS@auth");

export const isUserAuthenticated = createAction("IS_USER_AUTHENTICATED@auth");

export const isUserAuthenticatedSuccess = createAction("IS_USER_AUTHENTICATED_SUCCESS@auth");

export const isUserAuthenticatedFailed = createAction("IS_USER_AUTHENTICATED_FAILED@auth");

export const logout = createAction("LOGOUT@auth");

export const fetchSessionInfo = createAction("FETCH_SESSION_INFO@auth");
