import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Grid } from "@mui/material";
import { getNotification, notificationCodes } from "../../modules/notification";
import "react-toastify/dist/ReactToastify.css";

export const Notification = () => {
  const notificationInStore = useSelector(getNotification);

  const NotificationContent = () =>
    notificationInStore.message != null && notificationInStore.description != null ? (
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ fontWeight: 600 }}>
          {notificationInStore.message}
        </Grid>
        <Grid item xs={12} sx={{ fontSize: "0.8em" }}>
          {notificationInStore.description}
        </Grid>
      </Grid>
    ) : (
      notificationInStore.message
    );

  useEffect(() => {
    if (notificationInStore)
      toast(<NotificationContent />, {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
        autoClose: notificationInStore.duration != null ? notificationInStore.duration : false,
        type: notificationInStore.type != null ? notificationInStore.type : notificationCodes.INFO,
      });
    else toast.dismiss();
    // eslint-disable-next-line
  }, [notificationInStore]);

  return <ToastContainer />;
};
