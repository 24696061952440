import RadioGroup from "@mui/material/RadioGroup";
import { Button, Radio, Paper } from "@mui/material";
import { setDropDownCompletamento, setFilterCompletamento } from "../../../modules/configuration/actions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { MyFormControlLabel } from "./StyleDropdown";
import { getFilterCompletamento } from "../../../modules/configuration";
import { labels } from "../../../labels";

const { BUTTON_PULISCI_FILTRI, BUTTON_APPLICA_FILTRI } = labels;

export const Completamento = () => {
  const dispatch = useDispatch();
  const filterCompletamento = useSelector(getFilterCompletamento);
  const [check, setCheck] = useState(filterCompletamento === "Ordina dal più completo" ? true : false);
  const [check1, setCheck1] = useState(filterCompletamento === "Ordina dal meno completo" ? true : false);
  const [value, setValue] = useState("");

  return (
    <Paper className="dropdown">
      <RadioGroup name="use-radio-group" sx={{ margin: "15px", "& .MuiTypography-root": { textAlign: "left" } }} aria-label="completamento">
        <MyFormControlLabel
          value="Ordina dal più completo"
          label="Ordina dal più completo"
          control={
            <Radio
              onClick={(e) => {
                setCheck1(false);
                setCheck(true);
                setValue(e.target.value);
              }}
              checked={check}
            />
          }
        />
        <MyFormControlLabel
          value="Ordina dal meno completo"
          label="Ordina dal meno completo"
          control={
            <Radio
              onClick={(e) => {
                setCheck1(true);
                setCheck(false);
                setValue(e.target.value);
              }}
              checked={check1}
            />
          }
        />
      </RadioGroup>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonPulisci"
        onClick={() => {
          setCheck(false);
          setCheck1(false);
        }}
      >
        {BUTTON_PULISCI_FILTRI}
      </Button>
      <Button
        variant="contained"
        type="submit"
        form="myform"
        className="buttonApplica"
        onClick={() => {
          dispatch(setDropDownCompletamento());
          dispatch(setFilterCompletamento(value));
        }}
      >
        {BUTTON_APPLICA_FILTRI}
      </Button>
    </Paper>
  );
};
