import { useDispatch } from "react-redux";
import { Typography, Link, IconButton, Stack } from "@mui/material";
import { Upload as UploadIcon, PhotoSizeSelectActualOutlined as PhotoSizeSelectActualOutlinedIcon } from "@mui/icons-material";
import { labels } from "../../../labels";
import { DeleteElementIcon } from "../../../styles/icons";
import { setNotification, notificationCodes } from "../../../modules/notification";

const {
  PROFILO_UPLOAD_AVATAR,
  PROFILO_AVATAR_A_SISTEMA,
  PROFILO_UPLOAD_ERROR_DIMENSION_TITLE,
  PROFILO_UPLOAD_ERROR_DIMENSION_DESCRIPTION,
  PROFILO_UPLOAD_AVATAR_DIMENSIONE,
} = labels;

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const UploadAvatar = ({ avatar, selectedImage, onAdd, onDelete }) => {
  const dispatch = useDispatch();

  return avatar ? (
    <Stack className="nomeAvatar" sx={{ justifyContent: { xs: "center", md: "flex-start" } }}>
      <PhotoSizeSelectActualOutlinedIcon />
      <Typography>{selectedImage ? selectedImage.name : PROFILO_AVATAR_A_SISTEMA}</Typography>
      <IconButton onClick={onDelete} aria-label="Elimina">
        <DeleteElementIcon />
      </IconButton>
    </Stack>
  ) : (
    <Stack className="uploadAvatarStack">
      <Link
        variant="contained"
        component="label"
        className="uploadAvatar"
        sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
        onChange={async (e) => {
          const file = e.target.files[0];
          if (file.size > 3000000) {
            dispatch(setNotification({ tipo: notificationCodes.ERROR, message: PROFILO_UPLOAD_ERROR_DIMENSION_TITLE, description: PROFILO_UPLOAD_ERROR_DIMENSION_DESCRIPTION })); //prettier-ignore
            return;
          } else {
            const base64 = await getBase64(file);
            onAdd({
              url: URL.createObjectURL(file),
              base64: base64,
              name: file.name,
            });
          }
        }}
      >
        <UploadIcon />
        <Typography>{PROFILO_UPLOAD_AVATAR}</Typography>
        <input hidden accept="image/*" type="file" />
      </Link>
      <Typography className="dimensioneFile">{PROFILO_UPLOAD_AVATAR_DIMENSIONE}</Typography>
    </Stack>
  );
};
