import { CircularProgress as JoyCircle } from "@mui/joy";
import { Box, Typography } from "@mui/material";
export const Progress = (props) => (
  <Box sx={{ position: "relative", display: "flex", justifyContent: "center" }}>
    <JoyCircle determinate size="lg" value={props.progress} color="success" />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="caption" component="div" color="text.secondary" fontSize={"14px !important"}>{`${Math.round(
        props.progress
      )}%`}</Typography>
    </Box>
  </Box>
);
