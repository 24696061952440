import { createSelector } from "@reduxjs/toolkit";

export const getConfigurationStore = (state) => state.configuration;

export const getIndexTabs = createSelector(getConfigurationStore, (configuration) => configuration.indexTabs);

export const getOptions = createSelector(getConfigurationStore, (configuration) => configuration.options);

export const getDropDownCompletameto = createSelector(getConfigurationStore, (configuration) => configuration.dropdownCompletamento);

export const getDropDown = createSelector(getConfigurationStore, (configuration) => configuration.dropdown);

export const getDropDown2 = createSelector(getConfigurationStore, (configuration) => configuration.dropdown2);

export const getDropDown3 = createSelector(getConfigurationStore, (configuration) => configuration.dropdown3);

export const getDropDown4 = createSelector(getConfigurationStore, (configuration) => configuration.dropdown4);

export const getDropDown5 = createSelector(getConfigurationStore, (configuration) => configuration.dropdown5);

export const getDropDown6 = createSelector(getConfigurationStore, (configuration) => configuration.dropdown6);

export const getfiltri = createSelector(getConfigurationStore, (configuration) => configuration.filtri);

export const getVal1Slice = createSelector(getConfigurationStore, (configuration) => configuration.valore1slice);

export const getVal2Slice = createSelector(getConfigurationStore, (configuration) => configuration.valore2slice);

export const getPath = createSelector(getConfigurationStore, (configuration) => configuration.path);

export const getProgressAttivita = createSelector(getConfigurationStore, (configuration) => configuration.progressAttivita);

export const getOptionSelect = createSelector(getConfigurationStore, (configuration) => configuration.optionSelect);

export const getIndexAttività = createSelector(getConfigurationStore, (configuration) => configuration.indexAttività);

export const getProgressBar = createSelector(getConfigurationStore, (configuration) => configuration.progressBar);

export const getProgressContesto = createSelector(getConfigurationStore, (configuration) => configuration.progressAttivitaContesto);

export const getIndPost = createSelector(getConfigurationStore, (configuration) => configuration.indexPost);

export const getSottoAttivita = createSelector(getConfigurationStore, (configuration) => configuration.sottoAttivita);

export const getSottoAttivitaId = createSelector(getConfigurationStore, (configuration) => configuration.sottoAttivitaId);

export const getValueCheckOrdineSc = createSelector(getConfigurationStore, (configuration) => configuration.valueCheckOrdinSc);

export const getFilter = createSelector(getConfigurationStore, (configuration) => configuration.filter);

export const getAttivitaFilter = createSelector(getConfigurationStore, (configuration) => configuration.attivitaFilter);

export const getFilterTipologia = createSelector(getConfigurationStore, (configuration) => configuration.filterTipologia);

export const getFilterCompletamento = createSelector(getConfigurationStore, (configuration) => configuration.progettiFilterCompletamento);

export const getFilterPubblicato = createSelector(getConfigurationStore, (configuration) => configuration.progettiFilterPubblicato);

export const getProgFilterTipologia = createSelector(getConfigurationStore, (configuration) => configuration.progettiFilterTipologia);

export const getFilterSort = createSelector(getConfigurationStore, (configuration) => configuration.progettiFilterSort);

export const getFilterDataInizio = createSelector(getConfigurationStore, (configuration) => configuration.progettiFilterDataInizio);

export const getFilterDataInizioOrdine = createSelector(getConfigurationStore, (configuration) => configuration.progettiFilterDataInizioOrdine);

export const getFilterImieiP = createSelector(getConfigurationStore, (configuration) => configuration.progettiFilterImieiP);

export const getFilterProvincia = createSelector(getConfigurationStore, (configuration) => configuration.progettiFilterProvincia);

export const getCountPagination = createSelector(getConfigurationStore, (configuration) => configuration.countPagination);

export const getAttivitaSelezionata = createSelector(getConfigurationStore, (configuration) => configuration.attivitaSelezionata);

export const getAbilitazioneBottoni = createSelector(getConfigurationStore, (configuration) => configuration.buttonPubblicazione);

export const getFilterTitle = createSelector(getConfigurationStore, (configuration) => configuration.progettiFilterTitle);

export const getIdProgetto = createSelector(getConfigurationStore, (configuration) => configuration.idProgetto);

export const getAttivitaPostCopia = createSelector(getConfigurationStore, (configuration) => configuration.datiAttivitaPostCopia);

export const getAttivitaPost = createSelector(getConfigurationStore, (configuration) => configuration.datiAttivitaPost);

export const getTitle = createSelector(getConfigurationStore, (configuration) => configuration.title);

export const getCodiceMecc = createSelector(getConfigurationStore, (configuration) => configuration.codiceMeccanograficoPerFiltroProg);

export const getButtonType = createSelector(getConfigurationStore, (configuration) => configuration.buttonType);

export const getDocOptions = createSelector(getConfigurationStore, (configuration) => configuration.optionsDocumentazioneProgetto);

export const getAzzOptions = createSelector(getConfigurationStore, (configuration) => configuration.optionsAzioneDiffusione);

export const getTipoBeneficiari = createSelector(getConfigurationStore, (configuration) => configuration.optionsTipoBeneficiari);

export const getDatiPost = createSelector(getConfigurationStore, (configuration) => configuration.datiPost);

export const getEdit = createSelector(getConfigurationStore, (configuration) => configuration.edit);

export const getSceltaAttivita = createSelector(getConfigurationStore, (configuration) => configuration.sceltaAttivita);

export const getDescrizioniSottoAttivita = createSelector(getConfigurationStore, (configuration) => configuration.descrizioniSottoAttivita);

export const getDatiProgetto = createSelector(getConfigurationStore, (configuration) => configuration.progettoCurricolare);

export const getDatiProgettoInnovazione = createSelector(getConfigurationStore, (configuration) => configuration.progettoInnovazione);

export const getDatiAttivitaPostSottoAttId = createSelector(getConfigurationStore, (configuration) => configuration.datiAttivitaPostSottoAttId);

export const getFiltriProgettiAttivita = createSelector(getConfigurationStore, (configuration) => configuration.filtriAttivita);

export const getIndexGestisciProgetti = createSelector(getConfigurationStore, (configuration) => configuration.indexTabsGestisciProgetti);

export const getIndexTabAttivita = createSelector(getConfigurationStore, (configuration) => configuration.indexAttività);

export const getAttivitaSelezionataTablet = createSelector(getConfigurationStore, (configuration) => configuration.attivitaSelezionataTablet);

export const getCheckAttivita = createSelector(getConfigurationStore, (configuration) => configuration.checkAttivita);

export const getIndexTabSpostamento = createSelector(getConfigurationStore, (configuration) => configuration.indexTabsSpostamento);



