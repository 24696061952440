import { useEffect, useState } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect, Stack, Typography } from "@mui/material";
import { ExpandCircleDownOutlined as ExpandCircleDownOutlinedIcon } from "@mui/icons-material";

export const Select = ({ label, placeholder, options, value, required, onChange, error, errorText, disabled }) => {
  const ariaLabel = placeholder.replaceAll(" ", "_").toLowerCase();
  const ariaLabelInputLabel = ariaLabel + "_inputLabel";
  const ariaLabelStack = ariaLabel + "_stack";
  const [hasFocus, setHasFocus] = useState(false);

  // Fix accessibilità
  useEffect(() => {
    const selects = document.getElementsByClassName("MuiSelect-select");
    if (selects.length > 0) {
      for (let i = 0; i < selects.length; i++) {
        selects[i].removeAttribute("role");
      }
    }
    const stack = document.getElementById(ariaLabelStack);
    if (stack != null) {
      const customSelects = stack.getElementsByClassName("customSelect");
      if (customSelects.length > 0)
        for (var i = 0, max = customSelects.length; i < max; i++) {
          const selects = customSelects[i].getElementsByClassName("MuiSelect-select");
          if (selects.length > 0) {
            const nativeSelect = customSelects[i].getElementsByClassName("MuiSelect-nativeInput");
            if (nativeSelect.length > 0)
              for (var k = 0; k < nativeSelect.length; k++) {
                nativeSelect[k].setAttribute("id", ariaLabel);
                nativeSelect[k].setAttribute("aria-label", ariaLabel);
              }
          }
        }
    }
    const label = document.getElementById(ariaLabelInputLabel);
    if (label != null) label.setAttribute("for", ariaLabel);
    // eslint-disable-next-line
  }, []);

  let availableOptions = [{ id: "", descrizione: placeholder }];
  if (options) availableOptions.push(...options);

  return (
    <>
      <Typography className="fieldLabel">
        {label}
        {required && " *"}
      </Typography>
      <FormControl fullWidth disabled={disabled} error={error} required={required} sx={{ marginBottom: "20px" }}>
        {!hasFocus && !value && (
          <InputLabel size="small" id={ariaLabelInputLabel}>
            {placeholder}
          </InputLabel>
        )}
        <Stack id={ariaLabelStack}>
          <MuiSelect
            className="customSelect"
            value={value}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            onChange={onChange}
            size="small"
            IconComponent={ExpandCircleDownOutlinedIcon}
            sx={{ "& svg": { color: error ? "#d32f2f" : "black" } }}
          >
            {availableOptions.map((opt) => (
              <MenuItem key={opt.id} value={opt.id} {...(opt.id === "" && { sx: { fontStyle: "oblique", fontWeight: 600 } })}>
                {opt.descrizione}
              </MenuItem>
            ))}
          </MuiSelect>
          {error && errorText && <FormHelperText>{errorText}</FormHelperText>}
        </Stack>
      </FormControl>
    </>
  );
};
