import { createRequest } from "../../utility/createRequest.jsx";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { labels } from "../../labels.jsx";
import {
  fetchAttivitaInnovazione,
  fetchAttivitaInnovazioneFailed,
  fetchAttivitaInnovazioneSuccess,
  fetchComune,
  fetchComuneFailed,
  fetchComuneSuccess,
  fetchDisciplineCoinvolte,
  fetchDisciplineCoinvolteFailed,
  fetchDisciplineCoinvolteSuccess,
} from "./";
import { setNotification, notificationCodes } from "../notification";
import { ATTIVITA_INNOVAZIONE_API, COMUNE_API, DISCIPLINE_COINVOLTE_API } from "../../apiUrl.jsx";

const {
  SAGAS_COMUNE_TITLE,
  SAGAS_COMUNE_DESCRIPTION,

  SAGAS_ATTIVITA_INNOVAZIONE_TITLE,
  SAGAS_ATTIVITA_INNOVAZIONE_DESCRIPTION,
  SAGAS_DISCIPLINE_COINVOLTE_DESCRIPTION,
  SAGAS_DISCIPLINE_COINVOLTE_TITLE,
} = labels;

const fetchComuneData = (action, listenerApi) => {
  createRequest(COMUNE_API.method, COMUNE_API.baseUrl, COMUNE_API.apiUrl + "/" + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchComuneSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_COMUNE_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_COMUNE_TITLE, description: SAGAS_COMUNE_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchComuneFailed());
    });
};

const fetchAttivitaInnovazioneData = (action, listenerApi) => {
  createRequest(ATTIVITA_INNOVAZIONE_API.method, ATTIVITA_INNOVAZIONE_API.baseUrl, ATTIVITA_INNOVAZIONE_API.apiUrl + "/" + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchAttivitaInnovazioneSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ATTIVITA_INNOVAZIONE_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ATTIVITA_INNOVAZIONE_TITLE, description: SAGAS_ATTIVITA_INNOVAZIONE_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchAttivitaInnovazioneFailed());
    });
};

const fetchDisciplineCoinvolteData = (action, listenerApi) => {
  createRequest(DISCIPLINE_COINVOLTE_API.method, DISCIPLINE_COINVOLTE_API.baseUrl, DISCIPLINE_COINVOLTE_API.apiUrl + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchDisciplineCoinvolteSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_DISCIPLINE_COINVOLTE_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_DISCIPLINE_COINVOLTE_TITLE, description: SAGAS_DISCIPLINE_COINVOLTE_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchDisciplineCoinvolteFailed());
    });
};

export const datiOsservatorioMiddleware = createListenerMiddleware();

datiOsservatorioMiddleware.startListening({
  actionCreator: fetchComune,
  effect: async (action, listenerApi) => {
    fetchComuneData(action, listenerApi);
  },
});

datiOsservatorioMiddleware.startListening({
  actionCreator: fetchAttivitaInnovazione,
  effect: async (action, listenerApi) => {
    fetchAttivitaInnovazioneData(action, listenerApi);
  },
});

datiOsservatorioMiddleware.startListening({
  actionCreator: fetchDisciplineCoinvolte,
  effect: async (action, listenerApi) => {
    fetchDisciplineCoinvolteData(action, listenerApi);
  },
});
