import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Notification, GatewayError } from "../components";
import { SwitchContent } from "./SwitchContent";
import { getSessionTime, getUserAuthenticatedInfoFromStore, isUserAuthenticated } from "../modules/auth";
import { PATH_ACCESSO_OSSERVATORIO } from "../path";

export const Index = () => {
  const dispatch = useDispatch();
  const userAuthenticated = useSelector(getUserAuthenticatedInfoFromStore);
  const sessionTime = useSelector(getSessionTime);

  useEffect(() => {
    if (window.location.search.includes("?utenteSloggato=true")) window.location.href = PATH_ACCESSO_OSSERVATORIO;
    else {
      if (window.location.search.includes("?utenteAutenticato=true")) window.location.href = window.origin + "/";
      dispatch(isUserAuthenticated());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Notification />
      <GatewayError />
      {userAuthenticated && sessionTime && (
        <BrowserRouter>
          <SwitchContent />
        </BrowserRouter>
      )}
    </>
  );
};
