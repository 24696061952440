export const DatiSintesi = (props) => (
  <svg viewBox="0 0 198.828 164.04" {...props}>
    <defs>
      <clipPath id="clip-path">
        <rect width="198.828" height="164.04" fill="none" />
      </clipPath>
    </defs>
    <g transform="translate(21704.828 -7652.865)">
      <g transform="translate(-21704.828 7652.865)">
        <g clipPath="url(#clip-path)">
          <path
            d="M198.785,155.758c-.007-.031-.018-.058-.027-.089a1.359,1.359,0,0,0-.092-.225.806.806,0,0,0-.047-.087,1.425,1.425,0,0,0-.167-.223c-.011-.013-.02-.027-.031-.038a1.417,1.417,0,0,0-.259-.2l-.022-.018-10.858-6.527a1.42,1.42,0,1,0-1.463,2.434l6.471,3.89H9.368V6.539l3.89,6.471a1.42,1.42,0,1,0,2.434-1.463L9.165.688,9.147.666a1.5,1.5,0,0,0-.2-.259L8.906.377A1.469,1.469,0,0,0,8.68.208C8.653.192,8.624.177,8.595.163A1.317,1.317,0,0,0,8.369.071C8.34.062,8.311.051,8.282.043A1.461,1.461,0,0,0,7.948,0a1.435,1.435,0,0,0-.333.043c-.031.007-.06.018-.089.027A1.447,1.447,0,0,0,7.3.163.78.78,0,0,0,7.215.21,1.336,1.336,0,0,0,6.99.377L6.954.407a1.342,1.342,0,0,0-.2.259c-.005.007-.014.013-.018.022L.2,11.547A1.42,1.42,0,0,0,2.637,13.01l3.89-6.471V156.092a1.421,1.421,0,0,0,1.422,1.42H192.289l-6.471,3.89a1.421,1.421,0,0,0,1.463,2.436l10.858-6.528.022-.018a1.342,1.342,0,0,0,.259-.2l.031-.036a1.442,1.442,0,0,0,.167-.225c.018-.029.033-.056.047-.085a1.447,1.447,0,0,0,.092-.226c.009-.029.02-.058.027-.089a1.3,1.3,0,0,0,0-.666"
            transform="translate(0 0)"
          />
          <rect width="21.483" height="34.988" transform="translate(15.058 114.577)" />
          <rect width="21.483" height="51.284" transform="translate(42.347 98.279)" />
          <rect width="21.483" height="67.581" transform="translate(69.635 81.982)" />
          <rect width="21.483" height="83.878" transform="translate(96.924 65.685)" />
          <rect width="21.483" height="100.175" transform="translate(124.212 49.388)" />
          <rect width="21.483" height="116.472" transform="translate(151.501 33.093)" />
        </g>
      </g>
    </g>
  </svg>
);
