import {
  NOME,
  COGNOME,
  SESSO,
  DATA_NASCITA,
  PROVINCIA_NASCITA,
  LUOGO_NASCITA,
  CF,
  INDIRIZZO,
  EMAIL,
  TELEFONO,
  DOCUMENTO_IDENTITA,
  DATA_SCADENZA_DOCUMENTO_IDENTITA,
  CODICE_SPID,
  P_IVA,
  RAGIONE_SOCIALE,
  SEDE_LEGALE,
  DOMICILIO_DIGITALE,
  NOME_API,
  COGNOME_API,
  SESSO_API,
  DATA_NASCITA_API,
  PROVINCIA_NASCITA_API,
  LUOGO_NASCITA_API,
  CF_API,
  INDIRIZZO_API,
  EMAIL_API,
  TELEFONO_API,
  DOCUMENTO_IDENTITA_API,
  DATA_SCADENZA_DOCUMENTO_IDENTITA_API,
  CODICE_SPID_API,
  P_IVA_API,
  RAGIONE_SOCIALE_API,
  SEDE_LEGALE_API,
  DOMICILIO_DIGITALE_API,
  // Vecchi claims
  NOME_OLD_API,
  COGNOME_OLD_API,
  SESSO_OLD_API,
  DATA_NASCITA_OLD_API,
  PROVINCIA_NASCITA_OLD_API,
  LUOGO_NASCITA_OLD_API,
  CF_OLD_API,
  INDIRIZZO_OLD_API,
  EMAIL_OLD_API,
  TELEFONO_OLD_API,
  DOCUMENTO_IDENTITA_OLD_API,
  DATA_SCADENZA_DOCUMENTO_IDENTITA_OLD_API,
  CODICE_SPID_OLD_API,
  P_IVA_OLD_API,
  RAGIONE_SOCIALE_OLD_API,
  SEDE_LEGALE_OLD_API,
  DOMICILIO_DIGITALE_OLD_API,
} from "./index";

export const estraiJwtInfo = (jwtInfo) => {
  var userInfo = null;
  if (jwtInfo)
    userInfo = {
      ...(jwtInfo[NOME_API] != null && { [NOME]: jwtInfo[NOME_API] }),
      ...(jwtInfo[COGNOME_API] != null && { [COGNOME]: jwtInfo[COGNOME_API] }),
      ...(jwtInfo[SESSO_API] != null && { [SESSO]: jwtInfo[SESSO_API].toUpperCase() }),
      ...(jwtInfo[DATA_NASCITA_API] != null && { [DATA_NASCITA]: jwtInfo[DATA_NASCITA_API] }),
      ...(jwtInfo[PROVINCIA_NASCITA_API] != null && { [PROVINCIA_NASCITA]: jwtInfo[PROVINCIA_NASCITA_API] }),
      ...(jwtInfo[LUOGO_NASCITA_API] != null && { [LUOGO_NASCITA]: jwtInfo[LUOGO_NASCITA_API] }),
      ...(jwtInfo[CF_API] != null && { [CF]: jwtInfo[CF_API] }),
      ...(jwtInfo[INDIRIZZO_API] != null && { [INDIRIZZO]: jwtInfo[INDIRIZZO_API] }),
      ...(jwtInfo[EMAIL_API] != null && { [EMAIL]: jwtInfo[EMAIL_API] }),
      ...(jwtInfo[TELEFONO_API] != null && { [TELEFONO]: jwtInfo[TELEFONO_API] }),
      ...(jwtInfo[DOCUMENTO_IDENTITA_API] != null && { [DOCUMENTO_IDENTITA]: jwtInfo[DOCUMENTO_IDENTITA_API] }),
      ...(jwtInfo[DATA_SCADENZA_DOCUMENTO_IDENTITA_API] != null && { [DATA_SCADENZA_DOCUMENTO_IDENTITA]: jwtInfo[DATA_SCADENZA_DOCUMENTO_IDENTITA_API] }), //prettier-ignore
      ...(jwtInfo[CODICE_SPID_API] != null && { [CODICE_SPID]: jwtInfo[CODICE_SPID_API] }),
      ...(jwtInfo[P_IVA_API] != null && { [P_IVA]: jwtInfo[P_IVA_API] }),
      ...(jwtInfo[RAGIONE_SOCIALE_API] != null && { [RAGIONE_SOCIALE]: jwtInfo[RAGIONE_SOCIALE_API] }),
      ...(jwtInfo[SEDE_LEGALE_API] != null && { [SEDE_LEGALE]: jwtInfo[SEDE_LEGALE_API] }),
      ...(jwtInfo[DOMICILIO_DIGITALE_API] != null && { [DOMICILIO_DIGITALE]: jwtInfo[DOMICILIO_DIGITALE_API] }),
      // Vecchi claims
      ...(jwtInfo[NOME_OLD_API] != null && { [NOME]: jwtInfo[NOME_OLD_API] }),
      ...(jwtInfo[COGNOME_OLD_API] != null && { [COGNOME]: jwtInfo[COGNOME_OLD_API] }),
      ...(jwtInfo[SESSO_OLD_API] != null && { [SESSO]: jwtInfo[SESSO_OLD_API].toUpperCase() }),
      ...(jwtInfo[DATA_NASCITA_OLD_API] != null && { [DATA_NASCITA]: jwtInfo[DATA_NASCITA_OLD_API] }),
      ...(jwtInfo[PROVINCIA_NASCITA_OLD_API] != null && { [PROVINCIA_NASCITA]: jwtInfo[PROVINCIA_NASCITA_OLD_API] }),
      ...(jwtInfo[LUOGO_NASCITA_OLD_API] != null && { [LUOGO_NASCITA]: jwtInfo[LUOGO_NASCITA_OLD_API] }),
      ...(jwtInfo[CF_OLD_API] != null && { [CF]: jwtInfo[CF_OLD_API] }),
      ...(jwtInfo[INDIRIZZO_OLD_API] != null && { [INDIRIZZO]: jwtInfo[INDIRIZZO_OLD_API] }),
      ...(jwtInfo[EMAIL_OLD_API] != null && { [EMAIL]: jwtInfo[EMAIL_OLD_API] }),
      ...(jwtInfo[TELEFONO_OLD_API] != null && { [TELEFONO]: jwtInfo[TELEFONO_OLD_API] }),
      ...(jwtInfo[DOCUMENTO_IDENTITA_OLD_API] != null && { [DOCUMENTO_IDENTITA]: jwtInfo[DOCUMENTO_IDENTITA_OLD_API] }),
      ...(jwtInfo[DATA_SCADENZA_DOCUMENTO_IDENTITA_OLD_API] != null && { [DATA_SCADENZA_DOCUMENTO_IDENTITA]: jwtInfo[DATA_SCADENZA_DOCUMENTO_IDENTITA_OLD_API] }), //prettier-ignore
      ...(jwtInfo[CODICE_SPID_OLD_API] != null && { [CODICE_SPID]: jwtInfo[CODICE_SPID_OLD_API] }),
      ...(jwtInfo[P_IVA_OLD_API] != null && { [P_IVA]: jwtInfo[P_IVA_OLD_API] }),
      ...(jwtInfo[RAGIONE_SOCIALE_OLD_API] != null && { [RAGIONE_SOCIALE]: jwtInfo[RAGIONE_SOCIALE_OLD_API] }),
      ...(jwtInfo[SEDE_LEGALE_OLD_API] != null && { [SEDE_LEGALE]: jwtInfo[SEDE_LEGALE_OLD_API] }),
      ...(jwtInfo[DOMICILIO_DIGITALE_OLD_API] != null && { [DOMICILIO_DIGITALE]: jwtInfo[DOMICILIO_DIGITALE_OLD_API] }),
    };
  return userInfo;
};
