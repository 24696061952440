import { createSelector } from "@reduxjs/toolkit";

const getProfiloStore = (state) => state.profilo;

export const getInfoProfilo = createSelector(getProfiloStore, (profilo) => profilo.data);

export const fetchInfoProfiloEnded = createSelector(getProfiloStore, (profilo) => profilo.fetchEnded);

export const getEsitoAggiornamento = createSelector(getProfiloStore, (profilo) => profilo.profiloAggiornato);

export const getEsitoDisabilitazione = createSelector(getProfiloStore, (profilo) => profilo.profiloDisabilitato);

export const getEsitoRuoloLibero = createSelector(getProfiloStore, (profilo) => profilo.ruoloLibero.data);

export const fetchRuoloLiberoEnded = createSelector(getProfiloStore, (profilo) => profilo.ruoloLibero.fetchEnded);
