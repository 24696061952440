import { createSelector } from "@reduxjs/toolkit";

export const getCommonStore = (state) => state.common;

export const getEfficaciaProgetto = createSelector(getCommonStore, (common) => common.efficaciaProgetto.data);

export const fetchEfficaciaProgettoEnded = createSelector(getCommonStore, (common) => common.efficaciaProgetto.fetchEnded);

export const getTipologieProgetto = createSelector(getCommonStore, (common) => common.tipologieProgetto.data);

export const fetchTipologieProgettoEnded = createSelector(getCommonStore, (common) => common.tipologieProgetto.fetchEnded);

export const getTipologieIstituto = createSelector(getCommonStore, (common) => common.tipologieIstituto.data);

export const fetchTipologieIstitutoEnded = createSelector(getCommonStore, (common) => common.tipologieIstituto.fetchEnded);

export const getTipologieStudentiBes = createSelector(getCommonStore, (common) => common.tipologieStudentiBes.data);

export const fetchTipologieStudentiBesEnded = createSelector(getCommonStore, (common) => common.tipologieStudentiBes.fetchEnded);

export const getStatiPubblicazione = createSelector(getCommonStore, (common) => common.statiPubblicazione.data);

export const fetchStatiPubblicazioneEnded = createSelector(getCommonStore, (common) => common.statiPubblicazione.fetchEnded);

export const getProvince = createSelector(getCommonStore, (common) => common.province.data);

export const fetchProvinceEnded = createSelector(getCommonStore, (common) => common.province.fetchEnded);

export const getIstitutiScolastici = createSelector(getCommonStore, (common) => common.istitutiScolastici.data);

export const fetchIstitutiScolasticiEnded = createSelector(getCommonStore, (common) => common.istitutiScolastici.fetchEnded);

export const getTipologieDocumentazione = createSelector(getCommonStore, (common) => common.tipologieDocumentazione.data);

export const fetchTipologieDocumentazioneEnded = createSelector(getCommonStore, (common) => common.tipologieDocumentazione.fetchEnded);

export const getContestiAccompagnamento = createSelector(getCommonStore, (common) => common.contestiAccompagnamento.data);

export const fetchContestiAccompagnamentoEnded = createSelector(getCommonStore, (common) => common.contestiAccompagnamento.fetchEnded);

export const getIncarichi = createSelector(getCommonStore, (common) => common.incarichi.data);

export const fetchIncarichiEnded = createSelector(getCommonStore, (common) => common.incarichi.fetchEnded);

export const getIndirizziQualificaIstituto = createSelector(getCommonStore, (common) => common.indirizziQualificaIstituto.data);

export const fetchIndirizziQualificaIstitutoEnded = createSelector(getCommonStore, (common) => common.indirizziQualificaIstituto.fetchEnded);

export const getAttivitaCurricolari = createSelector(getCommonStore, (common) => common.attivitaCurricolari.data);

export const getAttivitaCurricolariRicerca = createSelector(getCommonStore, (common) => common.attivitaCurricolariRicerca.data);

export const fetchAttivitaCurricolariEnded = createSelector(getCommonStore, (common) => common.attivitaCurricolari.fetchEnded);

export const getAttivitaClil = createSelector(getCommonStore, (common) => common.attivitaClil.data);

export const fetchAttivitaClilEnded = createSelector(getCommonStore, (common) => common.attivitaClil.fetchEnded);

export const getTipologieBeneficiarioProgetto = createSelector(getCommonStore, (common) => common.tipologieBeneficiarioProgetto.data);

export const fetchTipologieBeneficiarioProgettoEnded = createSelector(getCommonStore, (common) => common.tipologieBeneficiarioProgetto.fetchEnded);

export const getTempoRealizzazioneProgetto = createSelector(getCommonStore, (common) => common.tempoRealizzazioneProgetto.data);

export const fetchTempoRealizzazioneProgettoEnded = createSelector(getCommonStore, (common) => common.tempoRealizzazioneProgetto.fetchEnded);

export const getTematicheProgetto = createSelector(getCommonStore, (common) => common.tematicheProgetto.data);

export const fetchTematicheProgettoEnded = createSelector(getCommonStore, (common) => common.tematicheProgetto.fetchEnded);

export const getOrdiniScuola = createSelector(getCommonStore, (common) => common.ordiniScuola.data);

export const fetchOrdiniScuolaEnded = createSelector(getCommonStore, (common) => common.ordiniScuola.fetchEnded);

export const getFondiContestoProgetto = createSelector(getCommonStore, (common) => common.fondiContestoProgetto.data);

export const fetchFondiContestoProgettoEnded = createSelector(getCommonStore, (common) => common.fondiContestoProgetto.fetchEnded);

export const getComposizioneGruppoStudenti = createSelector(getCommonStore, (common) => common.composizioneGruppoStudenti.data);

export const fetchComposizioneGruppoStudentiEnded = createSelector(getCommonStore, (common) => common.composizioneGruppoStudenti.fetchEnded);

export const getAttivitaDigitali = createSelector(getCommonStore, (common) => common.attivitaDigitali.data);

export const fetchAttivitaDigitaliEnded = createSelector(getCommonStore, (common) => common.attivitaDigitali.fetchEnded);

export const getAreeCoinvolte = createSelector(getCommonStore, (common) => common.areeCoinvolte.data);

export const fetchAreeCoinvolteEnded = createSelector(getCommonStore, (common) => common.areeCoinvolte.fetchEnded);

export const getAnniScolasticiRealizzazione = createSelector(getCommonStore, (common) => common.anniScolasticiRealizzazione.data);

export const fetchAnniScolasticiRealizzazioneEnded = createSelector(getCommonStore, (common) => common.anniScolasticiRealizzazione.fetchEnded);

export const getTitoli = createSelector(getCommonStore, (common) => common.titoli.data);

export const fetchTitoliEnded = createSelector(getCommonStore, (common) => common.titoli.fetchEnded);

export const getAzioniDiffusioneDisseminazione = createSelector(getCommonStore, (common) => common.azioniDiffusioneDisseminazione.data);

export const fetchAzioniDiffusioneDisseminazioneEnded = createSelector(getCommonStore, (common) => common.azioniDiffusioneDisseminazione.fetchEnded);

export const getAttivitaValutazioneEfficaciaProgetto = createSelector(getCommonStore, (common) => common.attivitaValutazioneEfficaciaProgetto.data);

export const fetchAttivitaValutazioneEfficaciaProgettoEnded = createSelector(getCommonStore, (common) => common.attivitaValutazioneEfficaciaProgetto.fetchEnded); //prettier-ignore

export const fetchCommonInfoEnded =
  fetchEfficaciaProgettoEnded &&
  fetchTipologieProgettoEnded &&
  fetchTipologieIstitutoEnded &&
  fetchTipologieStudentiBesEnded &&
  fetchStatiPubblicazioneEnded &&
  fetchProvinceEnded &&
  fetchIstitutiScolasticiEnded &&
  fetchTipologieDocumentazioneEnded &&
  fetchContestiAccompagnamentoEnded &&
  fetchIncarichiEnded &&
  fetchIndirizziQualificaIstitutoEnded &&
  fetchAttivitaCurricolariEnded &&
  fetchAttivitaClilEnded &&
  fetchTipologieBeneficiarioProgettoEnded &&
  fetchTempoRealizzazioneProgettoEnded &&
  fetchTematicheProgettoEnded &&
  fetchOrdiniScuolaEnded &&
  fetchFondiContestoProgettoEnded &&
  fetchComposizioneGruppoStudentiEnded &&
  fetchAttivitaDigitaliEnded &&
  fetchAreeCoinvolteEnded &&
  fetchAnniScolasticiRealizzazioneEnded &&
  fetchTitoliEnded &&
  fetchAzioniDiffusioneDisseminazioneEnded &&
  fetchAttivitaValutazioneEfficaciaProgettoEnded;
