import { Pagination } from "@mui/material";
export const PaginationShared = (props) => (
  <Pagination
    count={props.count}
    page={props.page}
    onChange={props.handlechange}
    sx={{
      marginTop:'20px',
      "MuiPaginationItem-root": {
        boxShadow: "0px 3px 6px #000000C7",
        fontWeight: "Bold",
        fontSize: "18px",
        backgroundColor: "white",
      },
      ".Mui-selected": {
        backgroundColor: props.color,
        color: "white",
      },
      ".Mui-selected:hover": {
        backgroundColor: props.color,
      },
    }}
  />
);
