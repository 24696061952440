import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { ArrowCircleRightOutlined as ArrowCircleRightOutlinedIcon } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { changeTabsDettagli, getIndexTabs } from "../../../modules/configuration";
import { TabPanel } from "../../shared/TabPanelCustom";
import { PageAttivita } from "../inserisciProgetti/pageAttivita/PageAttivita";
import { getAttivitaInnovazione } from "../../../modules/datiOsservatorio";
import { labels } from "../../../labels";
import { AnagraficaDettInn } from "./AnagraficaDettInn";
import { DocumentazioneDett } from "./DocumentazioneDett";

const { ANAGRAFICA, ATTIVITA, DOCUMENTAZIONE } = labels;

export const tabs = [
  {
    id: "5",
    testo: ANAGRAFICA,
  },
  {
    id: "6",
    testo: ATTIVITA,
  },
  {
    id: "7",
    testo: DOCUMENTAZIONE,
  },
];
export const TabDettagliInnovazione = (props) => {
  const indexTabs = useSelector(getIndexTabs);
  let indexString = indexTabs.toString();
  const dispatch = useDispatch();
  let progetto = props.progetto;
  const AttivitaInnovazione = useSelector(getAttivitaInnovazione);
  let attInn = AttivitaInnovazione.data;
  let attivita = attInn.filter((e) => e.id_campo !== 30);

  // stabilisco il colore del tab in base a se selezionato o no
  const color = (index) => {
    if (indexTabs === index) return props.color1;
    else return props.color2;
  };

  // stabilisco la decorazione del testo del tab in base a se selezionato oppure no
  const colorText = (index) => {
    if (indexTabs === index) return "white";
    else return "black";
  };

  // stabilisco il colore del testo del tab in base a se selezionato oppure no
  const decoration = (index) => {
    if (indexTabs === index) return "underline";
    else return "none";
  };

  // stabilisco visibilità freccia
  const visible = (id) => {
    if (id > 1) return false;
    else return true;
  };

  //stabilisco il cursore in base a se il tab è selezionabile o oppure no
  const cursor = (index) => {
    if (index > indexTabs) return "default";
  };

  return (
    <TabContext value={indexString}>
      <Grid container spacing={2} sx={{ marginTop: "-30px" }}>
        {tabs.map((elemento, index) => (
          <Grid item xs={12} sm={visible(index) ? 4.5 : 3} md={visible(index) ? 4.5 : 3} xl={visible(index) ? 4.5 : 3} key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={visible(index) ? 9 : 12} sm={visible(index) ? 9 : 12} xl={visible(index) ? 9 : 12}>
                <Tab
                  label={elemento.testo}
                  value={index}
                  disableRipple
                  disableFocusRipple
                  onClick={() => dispatch(changeTabsDettagli(index))}
                  className="tabOvali"
                  sx={{
                    bgcolor: color(index),
                    color: colorText(index),
                    textDecoration: decoration(index),
                    cursor: cursor(index),
                  }}
                />
              </Grid>
              {visible(index) && (
                <Grid
                  item
                  xs={0}
                  md={3}
                  sm={3}
                  xl={2}
                  sx={{
                    display: "block",
                    "@media (max-width:610px)": { display: "none" },
                  }}
                >
                  <ArrowCircleRightOutlinedIcon className="arrowCircleIcon" />
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <TabPanel value={"0"} index={indexString}>
        <Box sx={{ height: "550px" }}>
          <AnagraficaDettInn progetto={progetto} />
        </Box>
      </TabPanel>
      <TabPanel value={"1"} index={indexString}>
        <Box sx={{ marginBottom: "50px" }}>
          <Box sx={{ height: "550px" }}>
            <PageAttivita Dettagli={true} attivita={attivita} color1={props.color1} color2={props.color1} />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={"2"} index={indexString}>
        <Box sx={{ height: "550px" }}>
          <DocumentazioneDett progetto={progetto} tipologiaProg={30} />
        </Box>
      </TabPanel>
    </TabContext>
  );
};
