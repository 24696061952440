import { useNavigate } from "react-router-dom";
import { Grid, Button, IconButton, Typography, Dialog, DialogTitle, DialogContent, Stack } from "@mui/material";
import { CancelOutlined as CancelOutlinedIcon } from "@mui/icons-material";
import { PATH_CURRICOLARE_EXTRA_CURRICOLARE, PATH_INNOVAZIONE } from "../../../path";
import { labels } from "../../../labels";

const { MODALE_INSERIMENTO, BUTTON_INSERIMENTO_CURRICOLARE, BUTTON_INSERIMENTO_INNOVAZIONE } = labels;

export const InserisciProgettiModal = ({ open, onClose }) => {
  const navigate = useNavigate();

  return (
    <Dialog className="modaleInserimento" open={open} onClose={onClose} aria-labelledby="modaleInserimento">
      <DialogTitle>
        <Stack sx={{ opacity: 0 }}>Modale inserisci progetto</Stack>
        <IconButton disableRipple onClick={onClose} aria-label="chiudi">
          <CancelOutlinedIcon
            sx={{ "@media screen and (max-width: 460px)": { width: "26px !important", height: "26px !important", marginTop: "-12px" } }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography className="modalHeaderTitle">{MODALE_INSERIMENTO}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button disableRipple onClick={() => navigate(PATH_CURRICOLARE_EXTRA_CURRICOLARE)}>
              {BUTTON_INSERIMENTO_CURRICOLARE}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button disableRipple onClick={() => navigate(PATH_INNOVAZIONE)}>
              {BUTTON_INSERIMENTO_INNOVAZIONE}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
