import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Close as CloseIcon } from "@mui/icons-material/";
import { Grid, Typography, IconButton, Box, Link, Button, Paper } from "@mui/material";
import { CercaIcon, FiltriIcon } from "../../../styles/icons";
import { deleteFiltriProgetti, resetDropDown, saveFiltriProgetti } from "../../../modules/configuration";
import { PATH_RICERCA } from "../../../path";
import { HeaderPage } from "../../shared/Header";
import { labels } from "../../../labels";

const { BUTTON_FILTRI } = labels;

export const HeaderProgetti = (props) => {
  let options = props.options;
  const dispatch = useDispatch();
  const navigate = useNavigate();


  //filtri per visualizzazione filtri nelle chip 
  let filtri = [];
  let i = 0;

  for (const [key, value] of Object.entries(options)) {
    if (value !== undefined && value !== "") {
      if (key === "attivita") {
        for (let j = 0; j < value.length; j++) {
          filtri[i] = value[j].finalita + " " + value[j].descr_campo + value[j].descr_valore;
          i++;
        }
      } else {
        filtri[i] = value;
        i++;
      }
    }
  }


  //calcolo larghezza chip
  const lengthE = (elem) => {
    let l;
    if (elem.length > 20) {
      l = elem.length + 33;
    } else {
      l = "25px";
    }
    return l;
  };

  return (
    <Box>
      <HeaderPage icon={<CercaIcon />} title={props.title} color={"#8E2155"} linkBack={true} path={PATH_RICERCA} />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={11} sm={10}>
            {filtri.map((elemento, index) => (
              <Paper
                className="visualizzazioneFiltri"
                sx={{
                  height: lengthE(elemento),
                }}
                key={{ index }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={9} className="gridContenutoFiltro">
                    <Typography className="textFiltro"> {elemento} </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      disableRipple
                      onClick={() => {
                        dispatch(deleteFiltriProgetti(elemento));
                      }}
                      sx={{
                        marginTop: "-9px",
                        marginLeft: "20px",
                      }}
                    >
                      <CloseIcon className="iconFiltro" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            sx={{
              textAlign: "center",
            }}
          >
            <Box sx={{ ":hover": { textDecoration: "underline" } }}>
              <Button
                aria-label="Filtri"
                variant="outlined"
                className="buttonIconOpenFilterPage"
                onClick={() => {
                  navigate(props.location);
                  dispatch(resetDropDown());
                  dispatch(saveFiltriProgetti(false));
                }}
              >
                <FiltriIcon />
              </Button>
              <Link href={props.location} underline="hover" sx={{ color: "black" }}>
                <Typography className="textButtonIconOpenFilterPage">{BUTTON_FILTRI}</Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
