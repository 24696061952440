export const valProgrCurr = (dati) => {
  let valProgr = 0;
  let datiProgetto = dati.anagrafica;
  //CALCOLO PROGRESS BAR
  if (datiProgetto["descr_t_progetto"] !== "" && datiProgetto["descr_t_progetto"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["descr_progetto"] !== "" && datiProgetto["descr_progetto"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["descr_studenti_bes"] !== "" && datiProgetto["descr_studenti_bes"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["data_inizio"] !== "" && datiProgetto["data_inizio"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["titolo_progetto"] !== "" && datiProgetto["titolo_progetto"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["num_alunni_imp"] !== "" && datiProgetto["num_alunni_imp"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["descr_composizione_gruppo"] !== "" && datiProgetto["descr_composizione_gruppo"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["descr_tipo_attributo"] !== "" && datiProgetto["descr_tipo_attributo"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["descr_grado_scuola"] !== "" && datiProgetto["descr_grado_scuola"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["descr_tempo_realiz"] !== "" && datiProgetto["descr_tempo_realiz"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["descr_anno_realiz"] !== "" && datiProgetto["descr_anno_realiz"] !== null) {
    valProgr = valProgr + 1.67;
  }
  if (datiProgetto["descr_attivita_digitale"] !== "" && datiProgetto["descr_attivita_digitale"] !== null) {
    valProgr = valProgr + 1.67;
  }

  datiProgetto = dati.contesto;

  //CALCOLO PROGRESS BAR
  if (datiProgetto["interdisciplinarieta"] !== "" && datiProgetto["interdisciplinarieta"] !== null) {
    valProgr = valProgr + 3;
  }
  if (datiProgetto["descr_attivita_clil"] !== "" && datiProgetto["descr_attivita_clil"] !== null) {
    valProgr = valProgr + 2;
  }
  if (datiProgetto["descr_contesto_accompagnamento"] !== "" && datiProgetto["descr_contesto_accompagnamento"] !== null) {
    valProgr = valProgr + 3;
  }

  datiProgetto = dati.documentazione;

  //CALCOLO PROGRESS BAR
  if (datiProgetto["descr_documentazione"] !== null && datiProgetto["descr_documentazione"] !== undefined) {
    if (datiProgetto["descr_documentazione"].length > 0 && datiProgetto["descr_documentazione"] !== null) {
      valProgr = valProgr + 3.4;
    }
  }
  if (datiProgetto["link"] !== "" && datiProgetto["link"] !== null) {
    valProgr = valProgr + 3.4;
  }
  if (datiProgetto["azioneDiDiffusioneDisseminazione"].length > 0 && datiProgetto["azioneDiDiffusioneDisseminazione"] !== null) {
    valProgr = valProgr + 3.4;
  }
  if (datiProgetto["descr_valutazione"] !== "" && datiProgetto["descr_valutazione"] !== null) {
    valProgr = valProgr + 3.4;
  }
  if (datiProgetto["descr_punti_forza"] !== "" && datiProgetto["descr_punti_forza"] !== null) {
    valProgr = valProgr + 3.4;
  }
  if (datiProgetto["descr_punti_debole"] !== "" && datiProgetto["descr_punti_debole"] !== null) {
    valProgr = valProgr + 3.4;
  }

  return valProgr;
};
export const valProgrInn = (dati) => {
  let valProgr = 0;
  let datiProgetto = dati.anagrafica;
  //CALCOLO PROGRESS BAR

  if (datiProgetto["titolo_progetto"] !== "" && datiProgetto["titolo_progetto"] !== null) {
    valProgr = valProgr + 2.8;
  }
  if (datiProgetto["descr_progetto"] !== "" && datiProgetto["descr_progetto"] !== null) {
    valProgr = valProgr + 2.8;
  }
  if (datiProgetto["data_inizio"] !== "" && datiProgetto["data_inizio"] !== null) {
    valProgr = valProgr + 2.8;
  }
  if (datiProgetto["descr_tempo_realiz"] !== "" && datiProgetto["descr_tempo_realiz"] !== null) {
    valProgr = valProgr + 2.8;
  }
  if (datiProgetto["descr_anno_realiz"] !== "" && datiProgetto["descr_anno_realiz"] !== "") {
    valProgr = valProgr + 2.8;
  }
  if (datiProgetto["descr_ambiente"] !== "" && datiProgetto["descr_ambiente"] !== null) {
    valProgr = valProgr + 2.8;
  }
  if (datiProgetto["descr_grado_scuola"] !== "" && datiProgetto["descr_grado_scuola"] !== null) {
    valProgr = valProgr + 2.8;
  }
  if (datiProgetto["descr_fondi"] !== "" && datiProgetto["descr_fondi"] !== null) {
    valProgr = valProgr + 2.8;
  }
  if (datiProgetto["beneficiari"] !== null && datiProgetto["beneficiari"] !== undefined) {
    if (datiProgetto["beneficiari"].length > 0) {
      valProgr = valProgr + 2.8;
    }
  }
  datiProgetto = dati.documentazione;
  if (datiProgetto["descr_documentazione"] !== null && datiProgetto["descr_documentazione"] !== undefined) {
    if (datiProgetto["descr_documentazione"].length > 0) {
      valProgr = valProgr + 4.2;
    }
  }
  if (datiProgetto["link"] !== "" && datiProgetto["link"] !== null) {
    valProgr = valProgr + 4.2;
  }
  if (
    datiProgetto["azioneDiDiffusioneDisseminazione"].length > 0 &&
    datiProgetto["azioneDiDiffusioneDisseminazione"] !== null &&
    datiProgetto["azioneDiDiffusioneDisseminazione"] !== undefined
  ) {
    valProgr = valProgr + 4.2;
  }
  if (datiProgetto["descr_valutazione"] !== "" && datiProgetto["descr_valutazione"] !== null) {
    valProgr = valProgr + 4.2;
  }
  if (datiProgetto["descr_punti_forza"] !== "" && datiProgetto["descr_punti_forza"] !== null) {
    valProgr = valProgr + 4.2;
  }
  if (datiProgetto["descr_punti_debole"] !== "" && datiProgetto["descr_punti_debole"] !== null) {
    valProgr = valProgr + 4.2;
  }

  return valProgr;
};
