import { useEffect, useState } from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import { PROFILO_AVATAR, PROFILO_BIOGRAFIA, PROFILO_COGNOME, PROFILO_NOME } from "../../../modules/profilo";
import { UploadAvatar } from "./UploadAvatar";
import { labels } from "../../../labels";
import { Input } from "../../shared";

const { PROFILO_BIOGRAFIA_LABEL, PROFILO_BIOGRAFIA_PLACEHOLDER } = labels;

export const DatiMultimedia = ({ datiProfilo, onChange }) => {
  const avatar = datiProfilo[PROFILO_AVATAR];
  const nome = datiProfilo[PROFILO_NOME];
  const cognome = datiProfilo[PROFILO_COGNOME];
  const biografia = datiProfilo[PROFILO_BIOGRAFIA];
  const [selectedImage, setSelectedImage] = useState(null);
  const [rowsNumber, setRowsNumber] = useState(window.innerWidth < 900 ? 8 : 21);

  useEffect(() => {
    const handleResize = () => setRowsNumber(window.innerWidth < 900 ? 8 : 21);

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Grid item xs={12} className="datiMultimedia">
      <Grid container spacing={2} sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Avatar src={avatar} alt="Immagine profilo" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="nomeCognome" sx={{ textAlign: { xs: "center", md: "left" } }}>
                {nome + " " + cognome}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <UploadAvatar
                avatar={avatar}
                selectedImage={selectedImage}
                onAdd={(img) => {
                  setSelectedImage(img);
                  onChange([{ fieldName: PROFILO_AVATAR, fieldValue: img.base64 }]);
                }}
                onDelete={() => {
                  setSelectedImage(null);
                  onChange([{ fieldName: PROFILO_AVATAR, fieldValue: "" }]);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="biografia">
          <Input
            multiline
            helperText={biografia.length + "/500"}
            label={PROFILO_BIOGRAFIA_LABEL}
            placeholder={PROFILO_BIOGRAFIA_PLACEHOLDER}
            onChange={(e) => onChange([{ fieldName: PROFILO_BIOGRAFIA, fieldValue: e.target.value }])}
            value={biografia}
            rows={rowsNumber}
            maxLength={500}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
