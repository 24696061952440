import { createListenerMiddleware } from "@reduxjs/toolkit";
import { createRequest } from "../../utility/createRequest";
import { GET_ISTITUTI_SCOLASTICI, GET_ISTITUTO, GET_MEMBRI_TEAM, GET_PLESSI_ISTITUTO, GET_PROFILI_IN_USCITA } from "../../apiUrl";
import {
  fetchDatiIstitutiFailed,
  fetchDatiIstitutiSuccess,
  fetchDatiIstitutoFailed,
  fetchDatiIstitutoSuccess,
  fetchMembriTeamFailed,
  fetchMembriTeamSuccess,
  fetchPlessiIstitutoFailed,
  fetchPlessiIstitutoSuccess,
  fetchProfiliInUscitaFailed,
  fetchProfiliInUscitaSuccess,
  fetchSezioneIstitutiScolastici,
  fetchSezioneIstituto,
} from "./";
import { notificationCodes, setNotification } from "../notification";
import { labels } from "../../labels";

const {
  SAGAS_ISTITUTI_SCOLASTICI_TITLE,
  SAGAS_ISTITUTI_SCOLASTICI_DESCRIPTION,
  SAGAS_ISTITUTO_TITLE,
  SAGAS_ISTITUTO_DESCRIPTION,
  SAGAS_PLESSI_ISTITUTO_TITLE,
  SAGAS_PLESSI_ISTITUTO_DESCRIPTION,
  SAGAS_PROFILI_USCITA_TITLE,
  SAGAS_PROFILI_USCITA_DESCRIPTION,
  SAGAS_MEMBRI_TEAM_TITLE,
  SAGAS_MEMBRI_TEAM_DESCRIPTION,
} = labels;

const fetchIstitutiScolastici = (listenerApi) => {
  createRequest(GET_ISTITUTI_SCOLASTICI.method, GET_ISTITUTI_SCOLASTICI.baseUrl, GET_ISTITUTI_SCOLASTICI.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchDatiIstitutiSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ISTITUTI_SCOLASTICI_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ISTITUTI_SCOLASTICI_TITLE, description: SAGAS_ISTITUTI_SCOLASTICI_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchDatiIstitutiFailed());
    });
};

const fetchIstituto = (action, listenerApi) => {
  createRequest(GET_ISTITUTO.method, GET_ISTITUTO.baseUrl, GET_ISTITUTO.apiUrl + "/" + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchDatiIstitutoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ISTITUTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ISTITUTO_TITLE, description: SAGAS_ISTITUTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchDatiIstitutoFailed());
    });
};

const fetchPlessiIstituto = (action, listenerApi) => {
  createRequest(GET_PLESSI_ISTITUTO.method, GET_PLESSI_ISTITUTO.baseUrl, GET_PLESSI_ISTITUTO.apiUrl + "/" + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchPlessiIstitutoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_PLESSI_ISTITUTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_PLESSI_ISTITUTO_TITLE, description: SAGAS_PLESSI_ISTITUTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchPlessiIstitutoFailed());
    });
};

const fetchProfiliInUscita = (action, listenerApi) => {
  createRequest(GET_PROFILI_IN_USCITA.method, GET_PROFILI_IN_USCITA.baseUrl, GET_PROFILI_IN_USCITA.apiUrl + "/" + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchProfiliInUscitaSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_PROFILI_USCITA_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_PROFILI_USCITA_TITLE, description: SAGAS_PROFILI_USCITA_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchProfiliInUscitaFailed());
    });
};

const fetchMembriTeam = (action, listenerApi) => {
  createRequest(GET_MEMBRI_TEAM.method, GET_MEMBRI_TEAM.baseUrl, GET_MEMBRI_TEAM.apiUrl + "/" + action.payload)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchMembriTeamSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_MEMBRI_TEAM_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_MEMBRI_TEAM_TITLE, description: SAGAS_MEMBRI_TEAM_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchMembriTeamFailed());
    });
};

export const datiIstitutoMiddleware = createListenerMiddleware();

datiIstitutoMiddleware.startListening({
  actionCreator: fetchSezioneIstituto,
  effect: async (action, listenerApi) => {
    fetchIstituto(action, listenerApi);
    fetchProfiliInUscita(action, listenerApi);
    fetchMembriTeam(action, listenerApi);
    fetchPlessiIstituto(action, listenerApi);
  },
});

datiIstitutoMiddleware.startListening({
  actionCreator: fetchSezioneIstitutiScolastici,
  effect: async (action, listenerApi) => {
    fetchIstitutiScolastici(listenerApi);
  },
});
