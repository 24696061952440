import { Box, Typography } from "@mui/material";
import { DatiSintesiIcon, paginaDatiSintesi } from "../../styles/icons";
import { HeaderPage } from "../shared/Header";

export const DatiSintesi = () => {
  return (
    <Box>
      <Box>
        {" "}
        <HeaderPage icon={<DatiSintesiIcon />} title={"Dati sintesi"} color={"#8E2155"} />
      </Box>
      <Box sx={{ justifyContent: "center", display: "flex" }}>
        <Typography sx={{ fontSize: "50px" }}>Work in progress</Typography>
      </Box>
      <Box sx={{ justifyContent: "center", display: "flex" }}>
        <img style={{ width: "90%", height: "650px" }} src={paginaDatiSintesi} alt="icona" />
      </Box>
    </Box>
  );
};
