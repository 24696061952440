import { Divider, Grid, Skeleton, Typography } from "@mui/material";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import { labels } from "../../../labels";

const { PROFILO_DATI_PERSONALI } = labels;

const RectangularSkeleton = (props) => <Skeleton variant="rectangular" {...props} />;

export const SkeletonDatiPersonali = () => (
  <Grid item xs={12} className="datiPersonali">
    <Grid container spacing={2}>
      <Grid item xs={12} className="headerSezione">
        <AccountCircleIcon />
        <Typography>{PROFILO_DATI_PERSONALI}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <RectangularSkeleton />
      </Grid>
      <Grid item xs={12} md={6}>
        <RectangularSkeleton />
      </Grid>
      <Grid item xs={12} md={6}>
        <RectangularSkeleton />
      </Grid>
      <Grid item xs={12} md={6}>
        <RectangularSkeleton />
      </Grid>
      <Grid item xs={12}>
        <RectangularSkeleton sx={{ width: { xs: "100%", md: "49%" } }} />
      </Grid>
      <Grid item xs={12}>
        <RectangularSkeleton sx={{ width: { xs: "100%", md: "49%" } }} />
      </Grid>
    </Grid>
    <Divider />
  </Grid>
);
