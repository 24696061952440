export const InserisciHome = (props) => (
  <svg viewBox="0 0 99.83 107.531" {...props}>
    <g transform="translate(-13299.561 10146.333)">
      <path d="M1022.459,772.067h13.993V758.074h-13.993Zm2.19-11.8h9.612v9.612h-9.612Z" transform="translate(12288.661 -10894.911)" />
      <path d="M1544.389,838.745h41.37a1.1,1.1,0,0,0,0-2.19h-41.37a1.1,1.1,0,1,0,0,2.19Z" transform="translate(11785.591 -10970.716)" />
      <path d="M1544.389,1059.921h35.286a1.1,1.1,0,0,0,0-2.19h-35.286a1.1,1.1,0,1,0,0,2.19Z" transform="translate(11785.591 -11184.348)" />
      <path d="M1022.459,1392.786h13.993v-13.993h-13.993Zm2.19-11.8h9.612v9.612h-9.612Z" transform="translate(12288.661 -11494.459)" />
      <path d="M1544.389,1459.465h41.37a1.1,1.1,0,0,0,0-2.19h-41.37a1.1,1.1,0,1,0,0,2.19Z" transform="translate(11785.591 -11570.264)" />
      <path d="M1544.389,1680.641h35.286a1.1,1.1,0,0,0,0-2.19h-35.286a1.1,1.1,0,1,0,0,2.19Z" transform="translate(11785.591 -11783.896)" />
      <path d="M1022.459,2013.506h13.993v-13.993h-13.993Zm2.19-11.8h9.612v9.612h-9.612Z" transform="translate(12288.661 -12094.007)" />
      <path d="M1544.389,2080.185h41.37a1.1,1.1,0,0,0,0-2.19h-41.37a1.1,1.1,0,1,0,0,2.19Z" transform="translate(11785.591 -12169.813)" />
      <path d="M1544.389,2301.361h24.578a1.1,1.1,0,0,0,0-2.19h-24.578a1.1,1.1,0,1,0,0,2.19Z" transform="translate(11785.591 -12383.444)" />
      <path d="M1022.459,2634.225h13.993v-13.992h-13.993Zm2.19-11.8h9.612v9.613h-9.612Z" transform="translate(12288.661 -12693.555)" />
      <path d="M1562.032,2698.715h-17.643a1.095,1.095,0,1,0,0,2.19h17.643a1.095,1.095,0,1,0,0-2.19Z" transform="translate(11785.591 -12769.36)" />
      <path d="M1558.5,2919.89h-14.114a1.1,1.1,0,1,0,0,2.19H1558.5a1.1,1.1,0,0,0,0-2.19Z" transform="translate(11785.591 -12982.992)" />
      <path
        d="M781.4,551.98a25.436,25.436,0,0,0-13.49-13.49q-.557-.235-1.122-.443v-45A13.374,13.374,0,0,0,753.4,479.667H696.945a13.374,13.374,0,0,0-13.384,13.384v67.165A13.374,13.374,0,0,0,696.945,573.6h38.628A25.357,25.357,0,0,0,781.4,551.98Zm-94.917,8.236V493.051a10.476,10.476,0,0,1,10.464-10.464H753.4a10.476,10.476,0,0,1,10.464,10.464V537.17a25.367,25.367,0,0,0-29.594,33.51H696.945A10.476,10.476,0,0,1,686.481,560.216Zm71.56,24.792a23.16,23.16,0,1,1,23.16-23.16A23.186,23.186,0,0,1,758.041,585.008Z"
        transform="translate(12616 -10626)"
      />
      <path
        d="M2447.949,2454.558h-11.909v-11.91a1.688,1.688,0,0,0-3.376,0v11.91h-11.909a1.688,1.688,0,1,0,0,3.376h11.909v11.91a1.688,1.688,0,0,0,3.376,0v-11.91h11.909a1.688,1.688,0,1,0,0-3.376Z"
        transform="translate(10939.689 -12520.397)"
      />
    </g>
  </svg>
);
