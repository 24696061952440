const START_SESSION_EVENT = "startSession";
const GATEWAY_ERROR = "gatewayError";
const PRIVACY_ERROR = "privacyObsolete";

const validateCellulare = (cellulare) => {
  const regex = /^((3[1-9][0-9]))(\d{7})$/;
  return regex.test(cellulare);
};

const validateUrl = (url) => {
  var regex = new RegExp(
    "^(https?:\\/\\/)?" + // protocollo
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // nome dominio
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // o indirizzo ip (v4)
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // porta e percorso
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!regex.test(url);
};

export { START_SESSION_EVENT, GATEWAY_ERROR, PRIVACY_ERROR, validateCellulare, validateUrl };
