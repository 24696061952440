export const CercaHome = (props) => (
  <svg viewBox="0 0 84.12 84.1" {...props}>
    <g>
      <path
        fill="#8E2155"
        d="m80.1,66.36l-22.03-16.14c3.64-5.19,5.78-11.5,5.78-18.3C63.85,14.32,49.53,0,31.93,0S0,14.32,0,31.93s14.32,31.93,31.93,31.93c6.8,0,13.11-2.14,18.3-5.78,4.78,6.53,16.14,22.03,16.14,22.03,3.8,5.32,12.05,5.34,15.86,0,3.21-4.38,2.26-10.53-2.12-13.74Zm-48.18-12.23c-12.25,0-22.21-9.96-22.21-22.21S19.68,9.71,31.93,9.71s22.21,9.96,22.21,22.21-9.96,22.21-22.21,22.21Z"
      />
    </g>
  </svg>
);
