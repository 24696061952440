import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import { Person as PersonIcon } from "@mui/icons-material";
import { getSessionTime, getUserInfo } from "../../modules/auth";
import { labels } from "../../labels";
import { START_SESSION_EVENT } from "../../utility/utils";

const { SESSION_COUNTDOWN_BUTTON, SESSION_COUNTDOWN_INFO, SESSION_COUNTDOWN_LOGIN, SESSION_COUNTDOWN_SECONDS, SESSION_COUNTDOWN_SESSION_EXPIRED } = labels; // prettier-ignore

export const SessionCountdown = () => {
  const dispatch = useDispatch();
  const delta = 90000; // differenza fra timer attuale a scadenza alla quale far apparire il modale
  const sessionTime = useSelector(getSessionTime);
  const [targetTime, setTargetTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    if (targetTime && currentTime < targetTime)
      setTimeout(() => {
        setCurrentTime(dayjs());
      }, [targetTime - currentTime <= delta ? 1000 : 10000]);
    // eslint-disable-next-line
  }, [currentTime, targetTime]);

  useEffect(() => {
    window.addEventListener(START_SESSION_EVENT, () => setTargetTime(dayjs().add(sessionTime, "s")), false);
    // eslint-disable-next-line
  }, []);

  return (
    sessionTime &&
    currentTime &&
    targetTime && (
      <Dialog open={targetTime - currentTime <= delta} className="sessionCountdown">
        <Stack>
          {targetTime > currentTime ? (
            <>
              <Typography>{SESSION_COUNTDOWN_INFO}</Typography>
              <Typography className="secondi">{parseInt((targetTime - currentTime) / 1000)}</Typography>
              <Typography>{SESSION_COUNTDOWN_SECONDS}</Typography>
              <Button onClick={() => dispatch(getUserInfo())} className="buttonVerde">
                {SESSION_COUNTDOWN_BUTTON}
              </Button>
            </>
          ) : (
            <>
              <Typography>{SESSION_COUNTDOWN_SESSION_EXPIRED}</Typography>
              <Button
                className="buttonVerde"
                startIcon={<PersonIcon />}
                onClick={() =>
                  (window.location.href =
                    window.origin + "/auth/loginUser?postLoginUriCustom=" + (window.location.pathname !== "blank" ? window.location.pathname : "/"))
                }
              >
                {SESSION_COUNTDOWN_LOGIN}
              </Button>
            </>
          )}
        </Stack>
      </Dialog>
    )
  );
};
