import axios from "axios";
import { START_SESSION_EVENT } from "./utils.jsx";
import { PATH_ACCESSO } from "../path.jsx";

const customHeader = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const DELETE = "DELETE";

const buildApiRequestParams = (method, baseUrl, apiUrl, queryParams, payload, headers) => {
  let pathUrl = baseUrl;
  if (apiUrl != null && apiUrl.length > 0) pathUrl += apiUrl;

  let apiHeaders = customHeader;
  if (headers != null) apiHeaders = { ...apiHeaders, ...headers };

  let queryParamsString = null;
  if (queryParams != null) {
    queryParamsString = "?";
    for (let [key, value] of Object.entries(queryParams)) {
      queryParamsString += "" + key + "=" + value + "&";
    }
    queryParamsString = queryParamsString.substr(0, queryParamsString.length - 1);
  }

  if (queryParamsString != null) pathUrl += queryParamsString;

  return {
    method: method,
    url: pathUrl,
    headers: apiHeaders,
    ...(payload && { data: payload }),
  };
};

const apiCreateRequest = (method, baseUrl, apiUrl, queryParams, payload, headers) => {
  const apiParams = buildApiRequestParams(method, baseUrl, apiUrl, queryParams, payload, { ...headers });
  if (apiParams.url) return axios(apiParams);
  else return { status: 400, message: "missing url parameter" };
};

const createRequest = (method, baseUrl, apiUrl, queryParams, payload, headers) => {
  return apiCreateRequest(method, baseUrl, apiUrl, queryParams, payload, headers)
    .then((res) => res)
    .catch((e) => {
      if (e.response != null && e.response.status != null && (e.response.status === 401 || e.response.status === 409))
        window.location.href = window.origin + PATH_ACCESSO;
      throw e;
    })
    .finally(() => {
      let pathUrl = baseUrl;
      if (apiUrl != null && apiUrl.length > 0) pathUrl += apiUrl;
      const authUrls = ["/auth/api", "auth/userInfo"];
      if (authUrls.some((authUrl) => pathUrl.includes(authUrl))) window.dispatchEvent(new CustomEvent(START_SESSION_EVENT));
    });
};

export { GET, POST, PUT, DELETE, createRequest };
