import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  fetchProfilo,
  fetchProfiloSuccess,
  fetchProfiloFailed,
  checkPrivacy,
  estraiDatiProfilo,
  PROFILO_DISABILITATO,
  setPrivacy,
  preparaDatiAggiornamento,
  verificaRuoloLibero,
  verificaRuoloLiberoSuccess,
  verificaRuoloLiberoFailed,
  verificaRuoloLiberoError,
  aggiornaProfilo,
  aggiornaProfiloFailed,
  aggiornaProfiloSuccess,
  disabilitaProfilo,
  disabilitaProfiloSuccess,
  disabilitaProfiloFailed,
} from "./index";
import { fetchCommonInfo } from "../common";
import { isUserAuthenticatedSuccess } from "../auth";
import { setNotification, notificationCodes } from "../notification";
import { createRequest, PRIVACY_ERROR } from "../../utility";
import {
  PROFILO_API,
  CHECK_PRIVACY_API,
  SET_PRIVACY_API,
  VERIFICA_RUOLO_API,
  AGGIORNA_PROFILO_API,
  DISABILITA_PROFILO_API,
  AGGIORNA_RUOLO_ISTITUTO_API,
} from "../../apiUrl";
import { labels } from "../../labels";
import { PATH_ACCESSO, PATH_ACCESSO_OSSERVATORIO } from "../../path";

const {
  SAGAS_PROFILO_ERROR_TITLE,
  SAGAS_PROFILO_ERROR_DESCRIPTION,
  SAGAS_CHECK_PRIVACY_ERROR_TITLE,
  SAGAS_CHECK_PRIVACY_ERROR_DESCRIPTION,
  SAGAS_SET_PRIVACY_SUCCESS_TITLE,
  SAGAS_SET_PRIVACY_SUCCESS_DESCRIPTION,
  SAGAS_SET_PRIVACY_ERROR_TITLE,
  SAGAS_SET_PRIVACY_ERROR_DESCRIPTION,
  SAGAS_VERIFICA_RUOLO_LIBERO_ERROR_TITLE,
  SAGAS_VERIFICA_RUOLO_LIBERO_ERROR_DESCRIPTION,
  SAGAS_UPDATE_PROFILO_SUCCESS_TITLE,
  SAGAS_UPDATE_PROFILO_SUCCESS_DESCRIPTION,
  SAGAS_UPDATE_PROFILO_ERROR_TITLE,
  SAGAS_UPDATE_PROFILO_ERROR_DESCRIPTION,
  SAGAS_DISABILITA_PROFILO_ERROR_TITLE,
  SAGAS_DISABILITA_PROFILO_ERROR_DESCRIPTION,
} = labels;

const fetchProfiloWorker = (listenerApi) => {
  createRequest(PROFILO_API.method, PROFILO_API.baseUrl, PROFILO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) {
        const profilo = estraiDatiProfilo(response.data);
        if (profilo[PROFILO_DISABILITATO]) window.location.href = window.origin + PATH_ACCESSO;
        else listenerApi.dispatch(fetchProfiloSuccess(profilo));
      } else if (response.status === 204) window.location.href = window.origin + PATH_ACCESSO;
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_PROFILO_ERROR_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_PROFILO_ERROR_TITLE, description: SAGAS_PROFILO_ERROR_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchProfiloFailed());
    });
};

const checkPrivacyWorker = (listenerApi) => {
  createRequest(CHECK_PRIVACY_API.method, CHECK_PRIVACY_API.baseUrl, CHECK_PRIVACY_API.apiUrl)
    .then((response) => {
      if (response.status === 200) console.log("Privacy aggiornata alla versione più recente");
      else if (response.status === 204) window.dispatchEvent(new CustomEvent(PRIVACY_ERROR));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_CHECK_PRIVACY_ERROR_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_CHECK_PRIVACY_ERROR_TITLE, description: SAGAS_CHECK_PRIVACY_ERROR_DESCRIPTION })); //prettier-ignore
    });
};

const setPrivacyWorker = (listenerApi) => {
  createRequest(SET_PRIVACY_API.method, SET_PRIVACY_API.baseUrl, SET_PRIVACY_API.apiUrl)
    .then((response) => {
      if (response.status === 200)
        listenerApi.dispatch(setNotification({ tipo: notificationCodes.SUCCESS, message: SAGAS_SET_PRIVACY_SUCCESS_TITLE, description: SAGAS_SET_PRIVACY_SUCCESS_DESCRIPTION })); //prettier-ignore
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_SET_PRIVACY_ERROR_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_SET_PRIVACY_ERROR_TITLE, description: SAGAS_SET_PRIVACY_ERROR_DESCRIPTION })); //prettier-ignore
    });
};

const verificaRuoloLiberoWorker = (action, listenerApi) => {
  const { codiceScuola, idIncarico } = action.payload.payload;
  createRequest(VERIFICA_RUOLO_API.method, VERIFICA_RUOLO_API.baseUrl, VERIFICA_RUOLO_API.apiUrl + "/" + codiceScuola + "/" + idIncarico)
    .then((response) => {
      if (response != null && response.status != null) {
        if (response.status === 200) listenerApi.dispatch(verificaRuoloLiberoFailed(response.data));
        else if (response.status === 204) listenerApi.dispatch(verificaRuoloLiberoSuccess(action.payload));
      } else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(verificaRuoloLiberoError());
      console.error(SAGAS_VERIFICA_RUOLO_LIBERO_ERROR_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_VERIFICA_RUOLO_LIBERO_ERROR_TITLE, description: SAGAS_VERIFICA_RUOLO_LIBERO_ERROR_DESCRIPTION })); //prettier-ignore
    });
};

const aggiornaProfiloWorker = (action, listenerApi) => {
  const { payload, cambiRuoloScuola } = action.payload;
  createRequest(AGGIORNA_PROFILO_API.method, AGGIORNA_PROFILO_API.baseUrl, AGGIORNA_PROFILO_API.apiUrl, null, preparaDatiAggiornamento(payload)) //prettier-ignore
    .then((response) => {
      if (response != null && response.status != null && response.status === 200) {
        if (cambiRuoloScuola) {
          createRequest(AGGIORNA_RUOLO_ISTITUTO_API.method, AGGIORNA_RUOLO_ISTITUTO_API.baseUrl, AGGIORNA_RUOLO_ISTITUTO_API.apiUrl, null, preparaDatiAggiornamento(payload)) //prettier-ignore
            .then((response) => {
              if (response != null && response.status != null && response.status === 200) {
                listenerApi.dispatch(setNotification({ tipo: notificationCodes.SUCCESS, message: SAGAS_UPDATE_PROFILO_SUCCESS_TITLE, description: SAGAS_UPDATE_PROFILO_SUCCESS_DESCRIPTION })); //prettier-ignore
                listenerApi.dispatch(aggiornaProfiloSuccess());
              } else throw response.message;
            })
            .catch((e) => {
              listenerApi.dispatch(aggiornaProfiloFailed());
              console.error(SAGAS_UPDATE_PROFILO_ERROR_TITLE, ":", e);
              listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_UPDATE_PROFILO_ERROR_TITLE, description: SAGAS_UPDATE_PROFILO_ERROR_DESCRIPTION })); //prettier-ignore
            });
        } else {
          listenerApi.dispatch(setNotification({ tipo: notificationCodes.SUCCESS, message: SAGAS_UPDATE_PROFILO_SUCCESS_TITLE, description: SAGAS_UPDATE_PROFILO_SUCCESS_DESCRIPTION })); //prettier-ignore
          listenerApi.dispatch(aggiornaProfiloSuccess());
        }
      } else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(aggiornaProfiloFailed());
      console.error(SAGAS_UPDATE_PROFILO_ERROR_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_UPDATE_PROFILO_ERROR_TITLE, description: SAGAS_UPDATE_PROFILO_ERROR_DESCRIPTION })); //prettier-ignore
    });
};

const disabilitaProfiloWorker = (listenerApi) => {
  createRequest(DISABILITA_PROFILO_API.method, DISABILITA_PROFILO_API.baseUrl, DISABILITA_PROFILO_API.apiUrl)
    .then((response) => {
      if (response != null && response.status != null && response.status === 200) listenerApi.dispatch(disabilitaProfiloSuccess());
      else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(disabilitaProfiloFailed());
      console.error(SAGAS_DISABILITA_PROFILO_ERROR_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_DISABILITA_PROFILO_ERROR_TITLE, description: SAGAS_DISABILITA_PROFILO_ERROR_DESCRIPTION })); //prettier-ignore
    });
};

export const profiloMiddleware = createListenerMiddleware();

profiloMiddleware.startListening({
  actionCreator: isUserAuthenticatedSuccess,
  effect: async (action, listenerApi) => {
    if (!listenerApi.getState().profilo.data) listenerApi.dispatch(fetchProfilo());
  },
});

profiloMiddleware.startListening({
  actionCreator: fetchProfilo,
  effect: async (action, listenerApi) => fetchProfiloWorker(listenerApi),
});

profiloMiddleware.startListening({
  actionCreator: fetchProfiloSuccess,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(checkPrivacy());
    listenerApi.dispatch(fetchCommonInfo());
  },
});

profiloMiddleware.startListening({
  actionCreator: checkPrivacy,
  effect: async (action, listenerApi) => checkPrivacyWorker(listenerApi),
});

profiloMiddleware.startListening({
  actionCreator: setPrivacy,
  effect: async (action, listenerApi) => setPrivacyWorker(listenerApi),
});

profiloMiddleware.startListening({
  actionCreator: verificaRuoloLibero,
  effect: async (action, listenerApi) => verificaRuoloLiberoWorker(action, listenerApi),
});

profiloMiddleware.startListening({
  actionCreator: verificaRuoloLiberoSuccess,
  effect: async (action, listenerApi) => listenerApi.dispatch(aggiornaProfilo(action.payload)),
});

profiloMiddleware.startListening({
  actionCreator: aggiornaProfilo,
  effect: async (action, listenerApi) => aggiornaProfiloWorker(action, listenerApi),
});

profiloMiddleware.startListening({
  actionCreator: disabilitaProfilo,
  effect: async (action, listenerApi) => disabilitaProfiloWorker(listenerApi),
});

profiloMiddleware.startListening({
  actionCreator: aggiornaProfiloSuccess,
  effect: async (action, listenerApi) => listenerApi.dispatch(fetchProfilo()),
});

profiloMiddleware.startListening({
  actionCreator: disabilitaProfiloSuccess,
  effect: async (action, listenerApi) => (window.location.href = PATH_ACCESSO_OSSERVATORIO),
});
