import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { authReducer } from "../../modules/auth";
import { commonReducer } from "../../modules/common";
import { notificationReducer } from "../../modules/notification";
import { configuration } from "../../modules/configuration";
import { profiloReducer } from "../../modules/profilo";
import { datiProgettiReducer } from "../../modules/datiProgetti";
import { datiIstitutiReducer } from "../../modules/istituti";
import { datiOsservatorioReducer } from "../../modules/datiOsservatorio";

// Importare eventuali altri reducers ed aggiungerli qua sotto

const applicationReducers = combineReducers({
  auth: authReducer,
  common: commonReducer,
  notification: notificationReducer,
  configuration: configuration,
  profilo: profiloReducer,
  datiProgetti: datiProgettiReducer,
  datiIstituti: datiIstitutiReducer,
  datiOsservatorio: datiOsservatorioReducer,
});

export const createReducers = (whitelist) => {
  const persistConfig = {
    key: "root",
    storage,
    whitelist: whitelist,
  };
  return persistReducer(persistConfig, applicationReducers);
};
